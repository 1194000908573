//------------------------------------
// #Sections
//------------------------------------


// Generic
.bg-wrapper,
.pict-wrapper {

  img {
    position: relative;
    z-index: 1;
  }

  &:before,
  &:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: rem( 100 );
    z-index: 2;
  }
  /* &:before {
    background: linear-gradient( to right, rgba( $white, 1 ) 0%, rgba( $white, 0.9 ) 15%, rgba( $white, 0.1 ) 85%, rgba( $white, 0 ) 100% );
    left: 0;
  }
  &:after {
    background: linear-gradient( to left, rgba( $white, 1 ) 0%, rgba( $white, 0.9 ) 15%, rgba( $white, 0.1 ) 85%, rgba( $white, 0 ) 100% );
    right: 0;
  }
  
  .grey &,
  &.grey {
    &:before {
      background: linear-gradient( to right, rgba( $grey-light3, 1 ) 0%, rgba( $grey-light3, 0.9 ) 15%, rgba( $grey-light3, 0.1 ) 85%, rgba( $grey-light3, 0 ) 100% );
    }
    &:after {
      background: linear-gradient( to left, rgba( $grey-light3, 1 ) 0%, rgba( $grey-light3, 0.9 ) 15%, rgba( $grey-light3, 0.1 ) 85%, rgba( $grey-light3, 0 ) 100% );
    }
  }
  .push-hero &,
  .push-facebook &,
  &.green {
    &:before {
      background: linear-gradient( to right, rgba( $theme, 1 ) 0%, rgba( $theme, 0.9 ) 15%, rgba( $theme, 0.1 ) 85%, rgba( $theme, 0 ) 100% );
    }
    &:after {
      background: linear-gradient( to left, rgba( $theme, 1 ) 0%, rgba( $theme, 0.9 ) 15%, rgba( $theme, 0.1 ) 85%, rgba( $theme, 0 ) 100% );
    }
  } */
}
