//------------------------------------
// #SECTIONS
//------------------------------------

// Main section
.main-section {
  .aside {
    .alt-grey {
      background: $theme-secondary-grey;
    }
  }
}

// Site map
.site-map-cols {
  .col {
    margin: 0 0 rem(1);

    &:last-of-type {
      margin: 0;
    }
  }

  .cat-title {
    font-family: $font-advent-pro;
    margin: 0;
    text-transform: uppercase;

    a {
      background: $white;
      color: $theme3;
      display: block;
      font-size: rem(16);
      padding: rem(6) rem(22) rem(4);
      position: relative;

      > span {
        white-space: nowrap;

        &:before {
          @include valign;
          height: rem(35);
        }

        span {
          display: inline-block;
          vertical-align: middle;
          white-space: normal;
        }
      }

      picture + span,
      img + span {
        span {
          padding-right: rem(50);
        }
      }
    }

    img {
      position: absolute;
      right: rem(10);
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-prev {
      color: $theme3;
      height: rem(10);
      left: rem(12);
      transform: matrix(-1, 0, -0, -1, 0, 0);
      margin-top: rem(-5);
      position: absolute;
      top: 50%;
      width: rem(6);
    }
  }

  .cat-push {
    display: none;
  }

  .cat-list {
    margin: 0;
    padding: 0 0 rem(30);

    a {
      border-bottom: 1px solid $white;
      box-sizing: border-box;
      display: block;
      margin-top: rem(-1);
      padding: rem(7) rem(25) rem(6) rem(10);
      position: relative;

      &:before,
      &:after {
        content: ">";
        color: $white;
        display: inline-block;
        margin: 0 rem(2) 0 0;
        position: absolute;
        transition: $transition;
      }

      &:before {
        left: 0;
        top: rem(4);
      }

      &:after {
        opacity: 0;
        right: rem(8);
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        background: #102b33;
        border-color: #102b33;
        color: $white;

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  #nav & {
    .cat-list {
      display: none;
    }
  }
}

// Directory Section
.directory-section {
  background: #ecf6ff;

  header {
    padding: rem(26) 0 rem(10);
  }

  .content {
    position: relative;
    transition: padding 0.5s $transition-timing-function;

    .wrap {
      z-index: 2;
    }

    &.visible {
      padding: rem(311) 0 0;

      .map-container {
        opacity: 1;
      }
    }
  }

  .map-container {
    // display: none;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.4s $transition-timing-function;
    width: 100%;
    z-index: 1;

    .map {
      height: rem(278);
    }

    .bt-results {
      background: rgba(#3c4d53, 0.8);
      border: 0 none;
      display: block;
      color: $white;
      font-size: rem(18);
      font-weight: 500;
      padding: rem(5) 0;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;

      &:after {
        content: "";
        @include triangle(10px, $white, down);
        display: inline-block;
        margin: rem(-5) 0 0 rem(6);
        vertical-align: middle;
      }
    }
  }

  .close {
    background: none;
    border: 0 none;
    padding: 0;

    span {
      @include hide;
    }

    [class*="icon"] {
      fill: $theme-dark2;
      height: rem(10);
      width: rem(10);
    }
  }

  .map-info {
    margin: 0 pct(-10/300);
  }

  .list-intro {
    background: #f9fcff;
    margin: 0 0 rem(1);
    padding: 0 pct(10/300);

    .inner {
      padding: rem(16) rem(12) rem(1);
      position: relative;
    }

    .close {
      position: absolute;
      right: 0;
      top: rem(6);
    }
  }

  .list-details {
    background: #f9fcff;
    overflow: hidden;
    position: relative;

    [class*="view-"] {
      background: #f9fcff;
      box-sizing: border-box;
      padding: rem(20) pct(10/300);
      width: 100%;
    }

    .inner {
      padding: 0 rem(12);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 rem(5);
    }

    button {
      background: none;
      border: 0 none;
      padding: 0;
      text-align: left;
      width: 100%;
    }

    .view-home {
      font-weight: 500;
      text-transform: uppercase;

      li {
        + li {
          border-top: 1px solid $grey-light;
        }
      }
      // img {
      //   margin: 0 rem( 10 ) 0 0;
      //   vertical-align: middle;
      // }
      span {
        display: inline-block;
        vertical-align: middle;
      }

      button {
        padding: rem(5) rem(5) rem(5) rem(55);
        position: relative;
        text-transform: uppercase;

        &:before {
          @include valign;
          height: rem(40);
        }

        img {
          display: block;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: rem(41);
        }
      }
    }
  }

  .view-cat {
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 3;

    ul {
      padding: 0;
    }

    .cat-button {
      background: $white;
      font-weight: 500;
      padding: rem(5) rem(5) rem(5) rem(88);
      position: relative;
      text-transform: uppercase;

      &:before {
        @include valign;
        height: rem(40);
      }

      .icon-prev-light {
        height: rem(30);
        width: rem(17);
      }

      .arrow {
        height: rem(30);
        left: rem(6);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      img {
        display: block;
        left: rem(35);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: rem(41);
      }
    }

    .address-list {
      > li {
        + li {
          border-top: 1px solid $grey-light;
        }
      }
    }

    .address-button {
      font-size: rem(15);
      padding: rem(8) rem(16);
      text-align: left;
      transition: $transition;
      white-space: nowrap;

      &:before {
        @include valign;
        height: rem(38);
      }

      span {
        display: inline-block;
        white-space: normal;
        vertical-align: middle;
      }
    }

    .address-details {
      display: none;
      padding: rem(20) rem(16);

      ul {
        padding: rem(16) 0 0;
      }

      li {
        position: relative;
        padding: 0 0 0 rem(30);
      }

      [class*="icon-"] {
        position: absolute;
        left: 0;
        top: 0;
      }

      .icon-place {
        height: rem(24);
        width: rem(17);
      }

      .icon-clock-2 {
        height: rem(18);
        width: rem(18);
      }

      .icon-phone-2 {
        height: rem(21);
        width: rem(17);
      }
    }
  }

  .scroll {
    background: $white;
    position: relative;

    &:before,
    &:after {
      content: "";
      height: rem(10);
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    &:before {
      background: linear-gradient(to bottom, rgba(#2c444b, 0.2), rgba(#2c444b, 0));
      top: 0;
    }

    &:after {
      background: linear-gradient(to top, rgba(#2c444b, 0.2), rgba(#2c444b, 0));
      bottom: 0;
    }
  }
}

// Colors
// .directory-section .view-cat .address-button[aria-expended="true"] {
//   background: #f61d6c;
//   color: #ffffff;
// }
// .directory-section .view-cat .address-details [class*="icon-"] {
//   fill: #f61d6c;
// }

// sitemap section
.sitemap-section {
  background-color: $theme3;
  padding: 1rem 0;

  ul {
    list-style-type: none;
  }

  .sitemap-row {
    background-color: rgba($white, 0.05);
    padding: rem(12) rem(10) rem(28);
  }

  .tt-sitemap {
    color: $white;
    font-family: $font-roboto;
    font-size: rem(15);
    margin-bottom: rem(9);
    text-transform: none;
  }

  .cat-title {
    margin-bottom: rem(15);

    a {
      text-decoration: none;
    }
  }

  .cat-list {
    a {
      color: $white;
      text-decoration: none;
    }
  }

  .cart {
    border-bottom: 1px solid $theme;
    border-top: 1px solid $theme;
    box-sizing: border-box;
    color: $white;
    margin: rem(30) 0 rem(58);
    max-width: rem(490);
    padding: rem(15) 0 rem(15) rem(50);
    position: relative;

    [class*="icon-"] {
      fill: $white;
      height: rem(27);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: rem(29);
    }
  }

  .guadeloupedia {
    margin-bottom: rem(30);

    .category-details {
      background: url("#{$url-img}desktop/2x/bg-guadeloupedia.jpg") no-repeat 100% 0;
      background-size: auto 100%;
      max-width: 100%;
      text-align: center;
      width: rem(490);

      a {
        color: #233c44;
        display: block;
        height: rem(164);
        text-align: center;
        text-decoration: none;

        &:before {
          @include valign;
        }

        p {
          display: inline-block;
          border-bottom: 1px solid #233c44;
          border-top: 1px solid #233c44;
          font-size: rem(13);
          max-width: rem(230);
          padding: rem(2) 0;

          &:before {
            content: "> ";
          }
        }
      }
    }
  }
}

// Simple Header
.header-simple {
  display: none;
}

// Interactive movie
.interactive-movie {
  display: none;
}

// Interactive mobile
.interactive-mobile {
  background: url("#{$url-img}mobile/2x/bg-interactive.jpg") no-repeat 50% 50%;
  background-size: cover;
  color: $white;
  font-size: rem(16);
  padding: rem(20) 0 rem(4);
  position: relative;
  text-align: center;
  white-space: nowrap;

  &:before {
    background: rgba($black, 0.5);
    box-shadow: inset 0 0 60px 10px rgba($black, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:after {
    @include valign;
    height: rem(346);
  }

  .inner {
    display: inline-block;
    max-width: rem(540);
    margin: 0 auto;
    vertical-align: middle;
    position: relative;
    white-space: normal;
    width: pct(300 / 320);
  }

  .tt {
    font-family: $font-advent-pro;
    font-size: rem(30);
    text-transform: uppercase;
    font-weight: normal;
    margin: 0 0 rem(40);
  }

  .pict {
    margin: 0 auto rem(40);
    // transform: rotate( -5.5deg );
    width: rem(122);

    .cssgradients & {
      transform: rotate(-5.5deg);
      transform-origin: 50% 50%;
    }
  }

  [class*="icon"] {
    display: block;
    fill: $white;
    height: rem(122);
    width: rem(122);
  }
}

// interactive share
.interactive-share {
  color: $interactive;
  padding: rem(12) 0 0;
  text-align: right;

  p,
  ul {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }

  p {
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 0 0 rem(2);
    vertical-align: middle;
    width: rem(21);
  }

  img {
    display: block;
    width: 100%;
  }
}

// interactive section
.interactive-section {
  font-size: rem(14);
  color: $theme-dark2;
  padding: rem(30) 0 rem(30);
  text-align: center;

  [class*="tt-block"] {
    text-align: left;
  }

  .tt-interactive {
    display: none;
  }

  .button {
    padding-top: rem(10);
  }

  .choices {
    list-style-type: none;
    margin: 0 auto;
    max-width: rem(300);
    padding: 0;

    @include clearfix;

    li {
      box-sizing: border-box;
      float: left;
      margin: 0 0 rem(26);
      padding: 0 rem(10);
      width: 50%;

      &:nth-child(odd) {
        clear: left;

        div {
          margin: 0;
        }
      }

      &:nth-child(even) {
        div {
          margin: 0 0 0 auto;
        }
      }
    }

    div {
      width: rem(116);
    }

    p {
      margin-bottom: rem(5);
      text-align: center;
    }

    img {
      width: 100%;
    }
  }
}

// variant hotel
.variant-hotel .inner {
  padding: 0.625rem;
}

.variant-hotel .sticker {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  position: static;
  margin-bottom: 1rem;
}

.variant-hotel .sticker:before,
.variant-hotel .sticker:after {
  content: none;
}

.variant-hotel .sticker picture img {
  width: 100%;
  height: auto;
}

.variant-hotel .inner > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.variant-hotel .inner > .sticker {
  padding-left: 0rem;
  padding-right: 0rem;
}

.new-island-main {
  [class*="header-main"] {
    height: 400px;

    .wrap {
      height: 400px;
    }

    .bg-wrapper {
      height: 400px;
    }

    .text {
      font-size: rem(16);
      font-family: $font-roboto;
      @include center(horizontal);
      top: calc(2 * #{$baseline});
      bottom: $baseline;
      width: auto;
      min-width: 240px;

      .desc {
        display: block;
        max-width: 100%;

        &.dark {
          color: black;
        }
      }
    }

    .breadcrumb {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  .ct-header {
    /*font-family: $advent-pro, $font-sans-serif;*/
    font-family: $font-roboto;
    text-transform: uppercase;
    text-align: center;
    /*opacity: 0.7;*/
    &.dark {
      span {
        color: $theme-dark;
      }
    }

    &.light {
      span {
        color: $theme-light;
      }
    }

    span {
      font-size: rem(40);
      font-weight: 100;
    }
  }
}

.block-island-map {
  background-color: $theme;

  .bg-wrapper {
    overflow: visible;
    max-width: unset;
    margin: 0;
    background-color: $theme;
    background-size: cover;
  }

  .text {
    padding: calc(2 * #{$baseline}) calc(2 * #{$baseline}) calc(4 * #{$baseline});
    font-size: $baseline;

    &.light {
      color: $white;
    }

    h2 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      font-family: $roboto-condensed-regular, $font-sans-serif;
      font-size: calc(2 * #{$baseline});
    }

    .desc {
      font-family: $roboto, $font-sans-serif;
      margin: calc(2 * #{$baseline}) 0 0;
      background-color: transparent;
      width: 85%;
    }

    .link_cta{
      padding-top: 1rem;
      color: $white;
      font-family: "Roboto Condensed Regular";
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: none;
      overflow: hidden;
      margin: auto;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge $white;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    .link-light {
      margin-top: calc(1 * #{$baseline});
    }

    .cta-dark {
      display: block;
      margin-top: calc(2 * #{$baseline});
      width: 160px;
    }
  }
}

.block-island-encyclopedia {
  .bg-wrapper {
    margin: 0;
    background-color: $theme;
    background-size: cover;
  }

  .text {
    padding: calc(4 * #{$baseline}) calc(2 * #{$baseline}) calc(4 * #{$baseline});
    font-size: $baseline;

    &.light {
      color: $white;
    }

    h2 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      font-family: $advent-pro, $font-sans-serif;
      font-size: calc(2 * #{$baseline});
    }

    .desc {
      font-family: $roboto, $font-sans-serif;
      margin-top: calc(4 * #{$baseline});
      background-color: transparent;
    }

    .link-light {
      margin-top: calc(1 * #{$baseline});
    }
  }
}

.block-island-events {
  padding: rem(40) calc(2 * #{$baseline}) rem(4);

  .link_cta{
    padding-top: 1rem;
    color: #182883;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    overflow: hidden;
    margin: auto;
    display: block;

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  .block-agenda {
    padding: calc(4 * #{$baseline}) 0;

    h2 {
      display: none;
    }
  }

  .wrap {
    & > div {
      display: block;

      &:first-of-type {
        width: 100%;
        padding-right: calc(2 * #{$baseline});
      }

      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.new-island-main {
  .offers-section {
    padding-top: rem(40);
  }

  .block-offer {
    /*border: $grey 1px solid;*/
    &.activity {
      border-color: $activity;
    }

    &.accommodation {
      border-color: $accommodation;
    }

    &.accommodation2 {
      border-color: $accommodation2;
    }

    &.restaurant {
      border-color: $restaurant;
    }

    &.restaurant2 {
      border-color: $restaurant2;
    }

    &.sea {
      border-color: $sea;
    }

    &.sun {
      border-color: $sun;
    }

    &.interactive {
      border-color: $interactive;
    }

    &.koudke {
      border-color: $koudke;
    }
  }
}

.new-island-main {
  .block-island-slider {
    padding: 0;
    margin: 0;

    .slide {
      padding: 0;
      margin: 0;
      // only the first slide has a title
      &:not([data-slick-index="0"]) {
        figcaption {
          display: none;
        }
      }
    }

    picture {
      display: block;
      position: relative;
      z-index: 1;
    }

    figcaption {
      z-index: 2;
      @include center(both);
      padding: calc(0.5 * #{$baseline}) rem(5);
      max-width: 200px;
      background-color: $theme;
      font-size: rem(20);
      font-family: $advent-pro;
      text-transform: uppercase;

      span {
        @include middle();
      }
    }

    &.push-slider {
      overflow: hidden;

      .slider {
        width: 100%;
        width: 100vw;
        margin: unset;
        padding-bottom: 0;
      }

      img {
        width: 100%;
        width: 100vw;
        margin: unset;
      }
    }

    .slick-arrow {
      [class*="icon-"] {
        height: rem(45);
        width: rem(35);
      }

      &.slick-prev {
        left: 5%;

        [class*="icon-"] {
          @include drop-shadow(1px 1px 5px #000000);
        }
      }

      &.slick-next {
        left: 85%;

        [class*="icon-"] {
          @include rotate(180);
          @include drop-shadow(1px -1px 5px #000000);
        }
      }
    }

    .slick-dots {
      bottom: calc(2 * #{$baseline});

      .slick-active {
        button {
          background-color: $theme;
        }
      }

      button {
        width: rem(20);
        height: rem(20);
      }
    }
  }
}

// Sub navigation islands pages
.block-island-nav {
  position: relative;
  background-color: $light;

  .sub-navigation {
    position: static;
    background-color: $light;

    a {
      font-size: rem(16);
      font-weight: normal;
      color: $theme-legend;
    }

    ul {
      text-transform: none;

      li {
        & + li {
          a {
            &::before {
              background-color: $theme-dark2;
            }
          }
        }
      }
    }
  }
}

.anchor-nav {
  display: none;

  &.sticky {
    @include center(horizontal);
    position: fixed;
    z-index: 3;
    width: 100%;

    .sub-navigation {
      .select-list {
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: baseline;
        align-content: stretch;
      }
    }
  }
}
// section Inspiration //
.inspiration-section {
  width: 100%;
  font-family: "Roboto Condensed Regular";
  .wrap {
    background-image: url(../../B2C/img/Traits-inspiration.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: -210px;
    width: 1000px;
  }
  a {
    text-decoration: none;
    color: initial;
  }

  .inspiration-desktop-offers-target {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .bloc-list {
    display: flex;
    padding-bottom: 50px;
  }

  .activity-filter {
    height: 180px;
    width: 100%;
    padding-top: 50px;
  }

  .titre_inspiration {
    padding-top: 50px;
    padding-left: 50px;
    text-align: center;

    .span1 {
      color: #69b5c2;
      font-size: 30px;
      text-transform: none;
      font-weight: bold;
    }

    .span2 {
      font-family: $homemadeApple;
      font-weight: 40;
      color: #182883;
      font-size: 25px;
      text-transform: none;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;

    a {
      text-decoration: none;
    }

    .first-block {
      display: block;
    }

    .second-block {
      display: block;
      margin-top: 45px;
    }

    .third-block {
      display: block;
    }

    .block-inspiration-activity {
      width: 320px;
      max-height: 550px;
      position: relative;
      margin-bottom: 20px;

      .text {
        position: absolute;
        bottom: 60px;
        width: 240px;
        margin-left: 30px;
        overflow: hidden;
        word-wrap: break-word;
      }

      .text-blue {
        position: absolute;
        bottom: 60px;
        width: 240px;
        overflow: hidden;
        word-wrap: break-word;
      }

      .title-blue {
        color: #182883;
        font-size: 28px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
      }

      .title {
        color: #ffffff;
        font-size: 28px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
      }
    }
  }

  .block-inspiration-offer {
    width: 320px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .text {
      padding-top: 35px;
    }

    .label-activity {
      padding-top: 20px;

      .title {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
      }

      .promo {
        border-radius: 3.5px;
        background: #ffdd00;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        padding: 4px;
        float: right;
      }

      .on-site {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        padding: 4px 4px 4px 6px;
        border: 1px solid #979797;
        border-radius: 5px;
      }

      .price {
        color: #182883;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        float: right;
        padding-right: 5px;
      }
    }

    .location {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
      text-transform: uppercase;
      color: #000;
    }

    .city {
      font-family: "Roboto";
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
      color: #000;
    }

    .title {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      color: #000;
    }
  }

  .list-activity {
    padding-bottom: 50px;
    list-style: none;
    display: flex;
    padding-top: 50px;

    li {
      color: #dadadbe3;
      font-size: 20px;
      font-stretch: condensed;
      line-height: 24px;
      text-align: center;

      a {
        margin: 0 0 0 20px;

        .underline {
          opacity: 0.3;
        }

        .underline.active {
          color: #182883;
          font-weight: 700;
          text-align: center;
          padding-bottom: 1rem;
          border-bottom: #182883 solid 3px;
          opacity: 1;
        }

        .underline:hover {
          cursor: pointer;
          text-decoration: none;
          color: #182883;
          font-weight: 700;
          text-align: center;
          padding-bottom: 1rem;
          border-bottom: #182883 solid 3px;
          opacity: 1;
        }
      }
    }
  }

  .link-inspiration {
    color: #182883;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    padding: 40px 0 40px 0px;
    margin: auto;
    display: block;

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  .inspiration-mobileoffers-target {
    display: none;

    .slick-slider {
      display: none;
    }
  }
}
/*Barre de reservation */
.barre_reservation {
  a {
    text-decoration: none;
    cursor: pointer;
  }
  .titre_resa {
    color: #12415f;
    text-align: center;
    padding-bottom: 25px;
    margin-bottom: 30px;

    &:after {
      background: transparent;
    }

    .span1 {
      font-family: $roboto;
      color: #69b5c2;
      font-size: 30px;
      text-transform: initial;
      font-weight: bold;
    }
    .span2 {
      font-family: $homemadeApple;
      font-weight: 40;
      color: #182883;
      font-size: 25px;
      text-transform: lowercase;
    }
  }
  .sticky {
    position: fixed;
    top: 60px;
    z-index: 2;
    padding-bottom: 0;
    width: 100%;
  }

  .s002 form {
    width: 1000px;
    margin: auto;
    margin-bottom: 80px;
    padding-bottom: 50px;

    .inner-form {
      border-radius: 50px;
      background: #ffffff;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
      display: -ms-flexbox;
      display: flex;
      width: 1000px;
      height: 95px;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      font-family: "Roboto Condensed regular";

      input {
        // width: auto;
        // height: 30px;
        // line-height: 30px;
        // opacity: 0.3;
        // text-align: left;
        border-radius: 0;
        border: 0;
        // font-weight: 400;
        // padding: 7px;
        // width: 40px;
        // text-align: center;
        // vertical-align: middle;
        // display: table-cell;
        font-family: "Roboto Condensed regular";
        font-size: 13px;
      }

      .first-wrap {
        width: 100%;
        line-height: 30px;
        border-bottom: solid 1px #e7e7e7;
        height: 30px;
      }
      .first-span {
        //display: inline-block;
        width: 100%;
        color: rgba($black, 0.5);
        height: auto;
        #type-activity {
          height: auto;
          width: 65%;
          padding: 0;
        }
      }

      img {
        margin: 0 5px;
        vertical-align: initial;
      }

      .bi {
        float: right;
        cursor: pointer;
      }

      .dropdown {
        background-color: white;
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
        padding: 10px 10px 10px 10px;
        border-radius: 0px 0px 15px 15px;
        z-index: 111;
        position: absolute;
        min-width: 180px;
        img {
          margin: 0 5px 0 0;
        }
        div {
          //margin: 10px;
          cursor: pointer;
        }
      }

      .hide {
        display: none;
      }

      .second-wrap {
        width: 100%;
        height: 30px;
        margin-left: 10px;
        border-bottom: solid 1px #e7e7e7;
        line-height: 30px;
        .pos-abs {
          position: absolute;
        }
        .no-mrg {
          margin: 0;
        }
        .autocomplete {
          width: 100%;
          background: url('/Statics/B2C/img/desktop/2x/icon-pin.png');
          background-repeat: no-repeat;
          padding-left: 20px;
          background-position-y: center;
        }
        #span-locations-menu {
          padding: 0;
          color: $black;
          opacity: 1;
          height: auto;
        }
        #span-locations-menu::placeholder {
          color: rgba($black, 0.5);
        }

        #span-locations-menuautocomplete-list {
          font-size: 14px;
          padding-bottom: 0px;
          margin-left: -20px;
        }
        .location_input {
          width: auto;
          height: 30px;
          line-height: 30px;
          opacity: 0.3;
          text-align: left;
        }

        #locations-menu {
          display: flex;
          label {
            margin: 0;
          }
        }
      }

      .thirth-wrap {
        margin-left: 10px;
        width: 100%;
        border-bottom: solid 0.1px #e7e7e7;
        height: 30px;
        line-height: 30px;
      }

      .fourth-wrap {
        margin-right: 10px;
        margin-left: 10px;
        width: 100%;
        border-bottom: solid 0.1px #e7e7e7;
        height: 30px;
        line-height: 30px;
        display: flex;

        .dropdown {
          margin-top: 30px;
        }

        input {
          border: none;
          height: auto;
          width: 100px;
        }

        label {
          margin-top: 10px;
          color: #b1afaf;
          margin: unset;
        }

        .date-wrap {
          padding-right: 10px;
          padding-left: 10px;
          display: inline-flex;
          height: 100%;
          width: 100%;

          img {
            float: right;
            height: 18px;
            width: 18px;
          }

          span {
            margin-top: 15px;
          }

          input {
            opacity: 1;
            background: url(/Statics/B2C/img/desktop/2x/icon-calendar.png);
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: center;
          }
        }

        #span-typeFood-menu {
          display: inline-flex;

          input {
            width: 100%;
          }
        }

        #span-typeFoodPrice-menu {
          display: inline-flex;

          input {
            width: 100%;
          }
        }
      }

      .type-food-price-wrap {
        margin-right: 10px;
        margin-left: 10px;
        width: 100%;
        border-bottom: solid 0.1px #e7e7e7;
        height: 30px;
        line-height: 30px;
        display: flex;
      }

      .type_activity,
      .type_typeFood,
      .type_typeFoodPrice {
        font-size: 14px;
        line-height: 17px;
        display: flex;

        label {
          color: unset;
          margin-bottom: .5rem;
        }
      }

      #btn-activity {
        font-size: 14px;
        width: auto;
        position: absolute;
        margin-left: 20px;
      }

      #btn-passengers {
        outline: none;
        border: none;
      }

      #btn-passengers,
      #btn-typeFood,
      #btn-typeFoodPrice {
        outline: 0;
      }

      .btn:focus {
        box-shadow: none;
      }

      .type_passenger {
        font-size: 14px;
        line-height: 17px;
        display: flex;
      }

      #total-passengers {
        font-size: 14px;
        color: black;
        float: right;
        padding-right: 5px;
      }

      .type {
        padding: 5px 0px;
        width: 50px;
      }
      .age {
        opacity: 0.3;
        font-size: 11px;
        font-weight: 400;
        padding: 5px;
        width: 72px;
      }
      #number1,
      #number2,
      #number3 {
        text-align: center;
        color: black;
        opacity: 1;
        border-radius: 0;
        border: 0;
        width: 40px;
        height: auto;
      }
      input[type="text"].number {
        width: 30px;
      }
      .buttons-more-less {
        width: 20px;
        height: 20px;
        border: 1px solid;
        text-align: center;
        opacity: 0.3;
        font-size: 24px;
        cursor: pointer;
        border-radius: 5px;
        margin: 5px 0 0 0;
      }
      .minus {
        margin-left: 20px;
      }
      .fifth-wrap .btn-search {
        background: #fff;
        white-space: nowrap;
        color: #fff;
        transition: all 0.2s ease-out, color 0.2s ease-out;
        border: solid 1px #69b5c2;
        border-radius: 30px;
        color: #69b5c2;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        width: 90px;
        height: 28px;
        cursor: pointer;
        margin-left: 10px;
      }
      .btn-search:active {
        outline: none;
        border: none;
      }

      .btn-search:focus {
        outline: 0;
      }
    }

    input[type="radio"].activity,
    input[type="radio"].food,
    input[type="radio"].foodPrice {
      display: none;
    }
    input[type="radio"].activity + label,
    input[type="radio"].food + label,
    input[type="radio"].foodPrice + label {
      position: relative;
      padding-left: 1.7rem;
    }
    input[type="radio"].activity + label::before,
    input[type="radio"].activity + label::after,
    input[type="radio"].food + label::before,
    input[type="radio"].food + label::after,
    input[type="radio"].foodPrice + label::before,
    input[type="radio"].foodPrice + label::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 1rem;
      height: 1rem;
      border-style: solid;
      border-width: 1px;
      border-color: #dadfdf;
      border-radius: 3px;
      line-height: 30px;
      //margin-left: 5px;
      padding-right: 5px;
    }

    input[type="radio"].activity:checked + label::after,
    input[type="radio"].food:checked + label::after,
    input[type="radio"].foodPrice:checked + label::after {
      background-color: #69b5c2;
      box-shadow: inset 0 0 0 2px #fff;
    }

    .affinerRecherche {
      position: absolute;
      top: 140px;
      right: 260px;
    }

    #moreCriteria {
      //width: 100%;
      //height: 30px;
      //margin-left: 10px;
      border-bottom: solid 1px #e7e7e7;
      line-height: 17px;

      display: flex;
      background-color: white;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
      border-radius: 0px 0px 15px 15px;
      z-index: 111;
      justify-content: space-between;
      padding: 20px;
      width: 780px;
      margin: 0px auto;

      .vertical-bar {
        height: initial;
      }

      .panCrieteria {
        display: flex;
        flex-wrap: wrap;
        width: 510px;
        justify-content: space-between;

        .dropdown {
          padding-top: 10px;
        }

        .titre {
          font-weight: bold;
        }

        #divSearchBarEstablishmentType {
          padding-top: 20px;

          #establishmentType-span {
            font-size: 12px;
          }
        }
      }
      .slide-radio {
        display: flex;
        span {
          width: 100%;
          line-height: 30px;
          margin-left: 8px;
        }
        .custom-switch {
          float: right;

          .custom-control-label::before {
            width: 2.2rem;
            height: 20px;
            border-radius: 0.6rem;
            top: calc(0.15rem + 2px);
            left: calc(-2rem);
          }
          .custom-control-label::after {
            width: 20px;
            height: 20px;
            top: calc(0.15rem + 2px);
            left: calc(-2rem + 2px);
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          color: #69b5c2;
          background-color: #69b5c2;
          border-color: #69b5c2;
        }
      }

      .title {
        margin-top: 5px;
      }

      .filter {
        min-width: 160px;

        .optionSort {
          display: flex;
          justify-content: space-between;


        }
      }
    }
  }
}
.reservation-bar-mobile {
  display: none;
}


.datepicker-reservation-bar {
  border-radius: 0 0px 7px 7px;

  .pika-single {
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
  }
  .pika-title {
    background: initial;
    color: $black;
    background: $white;
    select {
      opacity: initial;
      color: $black;
      border: none;
      background: $white;
    }
  }
  .pika-prev,
  .pika-next {
    background-color: initial;
    opacity: initial;

    &:hover {
      opacity: initial;
    }

    &.is-disabled {
      opacity: initial;
    }
  }

  .pika-table {
    th {
      background: $white;
      color: #69b5c2;
    }
    td {
      border: 0;
      border-bottom: 1px solid $white;
    }

    abbr {
      border-bottom: none;
      cursor: help;
    }

    .is-startrange {
      background-color: #69b5c2;
      background: linear-gradient(to left, #deedf0, white);
    }

    .is-endrange {
      background-color: #69b5c2;
      background: linear-gradient(to right, #deedf0, white);
      border: 50%;
    }

    .pika-button {
      background: $white;
      text-align: center;

      .pika-button:hover {
        color: initial;
        background: initial;
      }

      .is-today & {
        color: #69b5c2;
      }

      .is-selected & {
        background: #69b5c2;
      }

      .is-startrange {
        background: #69b5c2;
        border-radius: 50%;
        width: 25px;
      }

      .is-endrange {
        background: #69b5c2;
        border-radius: 50%;
        width: 25px;
      }

      .is-inrange .pika-button {
        background: #69b5c2;
      }

      .is-startrange {
        background-color: #69b5c2;
        background: linear-gradient(to left, #deedf0, white);
      }

      .is-endrange {
        background-color: #69b5c2;
        background: linear-gradient(to right, #deedf0, white);
        border: 50%;
      }
      &:hover {
        color: inherit;
      }
    }
  }

  .pika-table {
    background: $white;
  }

  .is-inrange .pika-button {
    background: #deedf0;
  }

  .is-startrange .pika-button {
    background: #69b5c2;
    border-radius: 50%;
    color: #fff;
    width: 25px;
  }

  .is-endrange .pika-button {
    background-color: #69b5c2;
    border-radius: 50%;
    color: #fff;
    width: 25px;
  }
}
.barre_reservation {
  .barre_recherche {
    margin-bottom: 0;
  }
}
.container.searchEngine {
  max-width: 1280px;

  .search-type-container {
    margin: 0 auto;
    margin-bottom: 15px;
    border-bottom: 2px solid grey;
    padding-top: 20px;
    padding-bottom: 70px;

    .heading-type {
      margin-bottom:30px;
      text-align: center;
      color: #2bb7da;

    }

    .search-item.hidden{
      display: none;

    }
    .search-item {
      width: 100%;
      display: block;
      clear: both;
      margin: 80px 0 80px 0;

      .heading{
        font-size: 1.5rem;
        
      }
      .illustration {
        width: 200px;
        height: 200px;
        float: left;
        margin: 0 30px 0 0;

        img{
          max-width: 200px;
          max-height: 150px;

        }
      }
      a {
        text-decoration: none;
      }
      .link-item {
        color: #182883;
        font-family: $font-roboto;
        font-size: 20px;
        font-weight: 700;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;

        &::before {
          content: "";
          border-bottom: 1px ridge #182883;
          width: 70px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
        }
      }
    }
    .loadmore {
      color: #182883;
      font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: none;
      overflow: hidden;
      padding-top: 10px;
      padding-bottom: 10px;
      display: block;
      background: none;
      border: none;
      margin: 0 auto;
    }
    .loadmore::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }
  .autopromo {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    color: white;
    font-size: 25px;
    font-family: "Roboto Condensed Regular";
    .bloc {
      width: 50%;
      float: left;
      .push-search {
        text-decoration: none;
        .picture {
          padding: 10px;
        }
        .text {
          position: absolute;
          //width: 480px;
          overflow: hidden;
          word-wrap: break-word;
          margin: 50px 10px 0 10px;
          padding: 20px;
        }
      }
    }
  }
}
[class*="header-main"]  {
  .bg-wrapper {
    .tt-block {
      text-align: left;
    }
  }

  .tt-header-dark {
    color: red;
  }
}

.wrap {
  .text {
    .ct-header {
      span {
        font-family: "Roboto Condensed Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 35px;
        font-weight: 700;
        line-height: 48px;
        padding-top: 15px;
        display: inline;
        color: #16207f;
      }

    }
  }
}
#carrousel-section {
  width: 1280px;
  margin: auto;
  font-family: "Roboto Condensed Regular";

  .slider {
    width: 1280px;
    margin: auto;
  }
}

#guadeloupedia-section {
  width: 1280px;
  margin: auto;

  h2 {
    font-family: "Roboto Condensed Regular";
  }
}

.block-island-nav {
  background-color: unset;

  .sub-navigation {
    width: 1280px;
    margin: auto;
  }
}
.new-island-main {
  width: 1280px;
  margin: auto;

  h2 {
    font-family: "Roboto Condensed Regular";
  }

  .slider {
    width: 1280px;
    margin: auto;
  }
}

//error404
.page_404{
  width: 1280px;
  margin: auto;
  .text{
    P {
      padding-left: 0px;
    }
  }
}

.tt-block {
  text-align: center;
  //margin: 50px 0 50px 0;
  //padding-left: 50px;

  &:after {
    background: transparent;
  }

  .span1 {
    font-family: $font-roboto;
    color: #69b5c2;
    font-size: 30px;
    text-transform: none;
    font-weight: bold;
  }

  .span2 {
    font-family: $homemadeApple;
    font-weight: 40;
    color: #182883;
    font-size: 25px;
    text-transform: uppercase;
  }
}

.block-form{
  .font25{
    font-size: 25px;
  }
}

.video-youtube {
  width: 1280px;
  .youtube{
    height: 720px;
  }
}
