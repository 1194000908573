//------------------------------------
// #LAYOUT #HEADER
//------------------------------------

// Main Logo
#logo {
  left: 0;
  right: auto;
  width: rem( 124 );

  .full {
    display: block;
  }
  .small {
    display: none;
  }
}


// Banner
header[role="banner"] {
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
  .banner {
    line-height: 0;
    height: 0;
    padding: 0;

    .wrap {
      min-height: rem( 86 );
    }
  }

  // Burger button
  .bt-nav {
    display: none;
  }

  .temperatures {
    font-size: rem( 40 );
    margin-left: rem( 230 );
    margin-top: rem( 8 );
    padding: 0;
    vertical-align: middle;

    img {
      width: rem( 40 );
    }
    p {
      padding: 0 rem( 5 );
    }
  }

  // Homes navigation
  .home-nav {
    font-size: rem( 12 );
    margin: rem( 14 ) 0 0;
    position: relative;
    display: none;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      height: rem( 60 );
      margin: rem( -30 ) 0 0;
      position: absolute;
      top: 50%;
      vertical-align: middle;
      width: rem( 6 );
    }

    &:before {
      background-image: radial-gradient( farthest-side at 100% center, rgba( 0, 0, 0, 0.13 ), rgba( 0, 0, 0, 0 ));
      left: rem( -6 );
    }
    &:after {
      background-image: radial-gradient( farthest-side at -3px center, rgba( 0, 0, 0, 0.13 ), rgba( 0, 0, 0, 0 ));
      right: rem( -6 );
    }

    > ul {
      align-content: stretch;
      align-items: center;
      display: flex;
      height: rem( 60 );
      list-style-type: none;
      width: rem( 470 );

      > li {
        flex-grow: 1;
        text-align: center;

        div {
          padding: 0 rem( 15 );
          position: relative;
        }

        &:not(.lang){
          display: inline-block;
          font-weight: 500;
        }

        &.lang {
          div {
            padding-left: rem( 5 );
            padding-right: rem( 5 );
          }
        }

        + li {
          div {
            &:before {
              background: rgba( $theme-secondary-dark, 0.5);
              content: "";
              height: rem( 15 );
              left : 0;
              margin-top: rem( -7 );
              position: absolute;
              top: 50%;
              width: rem( 1 );
            }
          }
        }
      }

      .no-cssgradients & {
        display: table;

        > li {
          display: table-cell !important;
          vertical-align: middle;

          &:not(.lang){
            zoom: 1;
          }

          .select {
            zoom: 1;
            width: rem( 55 );
          }
        }
      }

      a {
        color: $theme-secondary-dark;
        text-decoration: none;
      }
    }
  }
}

// Nav
#nav {
  bottom: 0;

  .js &,
  & {
    display: block
  }

  .wrap {
    position: static;
  }

  &.sticky {
    bottom: auto;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    .sticky-logo{
      display: block;
      margin: rem( 5 ) rem( 25 ) 0 0;
      float: left;
      width: rem( 30 );
    }

    .categories-nav {
      float: left;

      > li:first-child{
        padding-left: rem( 1 );

        .category-tab:before {
          display: block;
        }
      }
    }
  }

  .icon-cart,
  .icon-account {
    fill: $white;
    height: rem( 25 );
    margin-top: rem( 7 );
    position: relative;
    top: 0;
    width: rem( 27 );

    +span {
      @include hide;
    }
  }

  .site-nav {
    display: none;
  }

  .categories-nav {
    align-content: stretch;
    background: transparent;
    flex-wrap: nowrap;
    height: rem( 40 );
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;   
    height: 60px;
    
    .lang,.static-icon,.account,.basket {
      display: block;
  }
  
  .account,.basket {
    padding-top: 15px;
}

    > li {
      flex-grow: 1;
      order: 1;
      width: auto;
      padding-top: 10px;

      .category-tab {
        margin-left: -1px;
        position: relative;

          span:hover {
            /*border-bottom: solid #182883 5px;
            height: 45px;*/
            text-decoration: underline #182883 solid 5px;
            text-underline-offset: 1rem;
          }

          img {
            width: 30px;
          }
      }

      .category-tab:after,
      &:first-child .category-tab:before {
       /* background: rgba( $white, 0.5 );
        content: "";
        height: rem( 20 );
        right: rem( 0 );
        margin: rem( -10 ) 0 0 0;
        position: absolute;
        top: 50%;
        width: rem( 1 );
        z-index: 1;*/
      }

      &:first-child .category-tab:before {
        left: 0;
        right: auto;
      }

      &:last-child {
        padding-right: 0;
      }
      &:last-child .category-tab:after,
      &:first-child .category-tab:before {
        display: none;
      }

      + li {
        border-top: 0 none;
      }

      .category-tab,
      & {
        > a,
        > button {
          box-sizing: border-box;
          display: block;
          font-weight: normal;
          height: rem( 40 );
          padding: 0 rem( 10 );
          position: relative;
          transition: none;
          white-space: nowrap;
          width: 100%;
          z-index: 2;
          padding-left: 0px;

          .icon-prev {
            display: none;
          }

          span {
            display: inline-block;
            white-space: normal;
            vertical-align: middle;
            font-weight: bold;
          }

          &:before {
            @include valign;
            height: rem( 38 );
            display: initial;
          }

          &:hover,
          &[aria-expanded="true"],
          &.on {
            /*background: $theme-secondary-grey;*/

          }
        }
      }

      // Guadeloupedia
      &.guadeloupedia {
        flex-basis: rem( 165 );
        flex-grow: 0;
        order: 1;
        text-align: left;
        padding-bottom: 0;
        position: relative;
        width: rem( 165 );
        height: 50px;

        .category-tab {
          margin-left: -1px;
          height: 100%;

          &:after {
            display: none;
          }

          > a,
          > button {
            background: none;    
            color: inherit;
            height: 100%;

            &:hover,
            &[aria-expanded="true"],
            &.on {
              //ackground: #79eae3 url( "#{$url-img}desktop/2x/bg-guadeloupedia-tab-on.jpg" ) no-repeat 100% 0;
              //background-size: auto 100%;
              border: none;
            }
            > span:hover {
              text-decoration: none;
            }
          }
        }

        .category-details {
          background: url( "#{$url-img}desktop/2x/bg-guadeloupedia.jpg" ) no-repeat 100% 0;
          background-size: auto 100%;
          left: auto;
          min-height: 0;
          padding: 0;
          right: rem( -162 );
          text-align: center;
          width: rem( 490 );
          margin-top: 0px;

          a {
            color: #233c44;
            display: block;
            height: rem( 164 );
            text-align: center;

            &:before {
              @include valign;
            }
          }

          p {
            display: inline-block;
            border-bottom: 1px solid #233c44;
            border-top: 1px solid #233c44;
            font-size: rem( 13 );
            max-width: rem( 230 );
            padding: rem( 2 ) 0;

            &:before {
              content: "> ";
            }
          }
        }

      }
      li:hover {
        color: red;
      }
      
      &.button {
        flex-basis: rem( 80 );
        flex-grow: 0;
        width: rem( 80 );
      }
    }

    // IE9
    .no-cssgradients & {
      display: table;

      > li {
        display: table-cell;
        vertical-align: middle;

        + li {

          // &:before {
          //   margin: rem( 10 ) 0 0 0;
          //   top: 0;
          // }
        }
      }
    }
  }

  .category-details {
    background: $white;
    left: 0;
    min-height: rem( 150 );
    padding: rem( 30 ) 0;
    position: absolute;
    width: 100%;
    margin-top: 10px;
  }
}
