//------------------------------------
// #HEADINGS
//------------------------------------


[class*="tt-header"] {
  font-size: rem( 45 );
  line-height: ( 47/45 );

  span {
    padding: rem( 10 ) rem( 16 );
  }
}

[class*="tt-block"] {
  font-size: rem( 35 );

  &.small {
    font-size: rem( 35 );
  }
}

[class*="tt-raw"] {
  font-size: rem( 35 );
}
