//------------------------------------
// #SELECTION
//------------------------------------

.selection-block {

  // Picture
  .picture {
    box-sizing: border-box;
    float: left;
    max-width: rem( 250 );
    padding: 0 rem( 20 ) 0 0;
    width: 50%;
  }

  // Place details
  .place {
    box-sizing: border-box;
    float: left;
    width: 50%;
  }

  // Details
  .details {
    align-items: stretch;
    border-top: 1px solid rgba( $theme3, 0.4 );
    display: flex;
    clear: both;
    justify-content: flex-end;
    padding: rem( 10 ) 0;
    width: 100%;
  }

  // Timing details
  .timing {
    border-top: 0 none;
    flex-grow: 1;
    padding: 0 rem( 20 );
  }

  // State
  .state {
    border-left: 1px solid rgba( $theme3, 0.4 );
    border-top: 0 none;
    flex-grow: 1;
    padding: rem( 20 ) rem( 10 ) 0 rem( 20 );
  }

  // Total
  .total {
    border-left: 1px solid rgba( $theme3, 0.4 );
    border-top: 0 none;
    flex-grow: 1;
    padding: 0 rem( 30 );
    text-align: center;

    > p {
      float: none;
      margin: 0 0 rem( 16 );
    }
    .price {
      float: none;
      text-align: center;
    }
  }

  // Delete button
  .delete {
    border-left: 1px solid rgba( $theme3, 0.4 );
    position: relative;
    right: auto;
    top: auto;
    width: rem( 30 );

    button {
      margin-top: rem( -22 );
      padding: 0;
      position: absolute;
      top: 50%;
    }
  }

  // Options
  .options {
    border-top: 0 none;
    min-height: rem( 30 );
    padding: 0 0 rem( 10 );

    [aria-expanded]{
      max-width: rem( 230 );
      padding-left: rem( 10 );
      width: 50%;
    }

    .options-details {
      margin-top: rem( -30 );

      ul {
        columns: 2;
        column-gap: rem( 30 );
        padding: rem( 35 ) rem( 10 ) rem( 10 );
      }

      li {
        padding-bottom: rem( 2 );
      }
    }
  }
}

// Total price
.selection-total {
  .sub-total,
  .total {
    padding: rem( 5 ) rem( 5 ) rem( 5 ) rem( 40 );

    p {
      margin: 0;
    }

    .txt {
      padding-top: rem( 5 );
      width: rem( 200 );
    }
    .price {
      float: left;
      padding-top: rem( 5 );
      text-align: right;
      width: rem( 150 );
    }
    .button {
      clear: none;
      float: right;
      padding: 0;
      text-align: right;
    }
  }
}

