//------------------------------------
// #GIMMICKS
//------------------------------------


// Date sticker
.date-sticker {
  background: $theme-light;
}

// Number sticker
.num-sticker {
  color: $theme2;
}

// Date Time
[class*="date-time"] {
  .day {
    background: $grey-light2;
    color: $theme2;
  }

  .month {
    background: $theme2;
  }

  &.activity {
    .day {
      color: $activity;
    }
    .month {
      background: $activity;
    }
  }

  &.accommodation {
    .day {
      color: $accommodation2;
    }
    .month {
      background: $accommodation2;
    }
  }

  &.koudke {
    .day {
      color: $koudke;
    }
    .month {
      background: $koudke;
    }
  }

  &.restaurant {
    .day {
      color: $restaurant;
    }
    .month {
      background: $restaurant;
    }
  }
}

// Hour
.time-hour {
  background: $theme2;

  &.activity {
    background: $activity;
  }

  &.accommodation {
    background: $accommodation;
  }

  &.koudke {
    background: $koudke;
  }
  &.restaurant {
    background: $restaurant;
  }
}

/*JJK 1.2.1.0 */
// Discount label
.discount-label {
  &.promo{
    float: left;
  }
  > span {
    background: $red;
  }

  display: flex;
  justify-content: flex-end;

  &.activity {
    > span {
      background: transparent;
      color: #182883;
    }
  }

  &.accommodation {
    > span {
      background: transparent;
      color: #182883;
    }
  }

  &.koudke {
    > span {
      background: $koudke;
    }
  }
  &.restaurant {
    > span {
      background: $restaurant;
      color: $black;
    }
  }

  &.commission {
    > span {
      background: $red2;
      color: $white;
    }
  }

  &.promo {
    > span {
      background: $yellow;
      color: $black;
    }
  }
}

.offer-list {.activity {
  > span {
    background:#ff9e21;
    color: #2e3d40;
  }
}
}

// Sticker label
.sticker-label {
  &.activity {
    span {
      background: $activity;
      color: $theme-secondary;
    }
  }

  &.accommodation {
    span {
      background: 'transparent';
      color: '#182883';
    }
  }

  &.koudke {
    span {
      background: $koudke;
    }
  }
  &.restaurant {
    span {
      background: $restaurant;
      color: $black;
    }
  }
}

// Info Label
.info-label {
  span {
    background: $theme3;
  }
}

// Tags
.tag-list {

  &.filter {
    a,
    button {
      color: $white;
      background: #c0c7c9;
    }
  }

  &.activity {
    a,
    button {
      color: $activity;
    }
  }

  &.accommodation {
    a,
    button {
      color: $accommodation2;
    }
  }

  &.koudke {
    a,
    button {
      color: $koudke;
    }
  }
  &.restaurant {
    a,
    button {
      color: $restaurant;
    }
  }

  a,
  button {
    &.on,
    &:hover {
      background: $theme-secondary-grey;
      color: $white;
    }
  }
}


// Option pict
.option-pict {

  &.accommodation {
    color: $accommodation2;

    .badge {
      background: $accommodation2;
    }
  }
  &.activity {
    color: $activity;

    .badge {
      background: $activity;
    }
  }
  &.restaurant {
    color: $restaurant;

    .badge {
      background: $restaurant;
    }
  }
  &.koudke {
    color: $koudke;

    .badge {
      background: $koudke;
    }
  }
}
