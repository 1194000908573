//------------------------------------
// $HELPERS
//------------------------------------


// Clearfix: A popular way to contain floats
// http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix {
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}
