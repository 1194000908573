//------------------------------------
// #PAGER
//------------------------------------

.pager {
  text-align: center;
}
.pager-list {
  margin: 0 0 rem( 16 );

  [class*="bt"] {
    background: $grey-light4;
    border-radius: 100%;
    color: $theme3;
    display: inline-block;
    font-size: rem( 15 );
    height: rem( 34 );
    line-height: rem( 32 );
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: rem( 34 );

    span {
      @include hide;
    }

    [class*="icon-prev"] {
      display: block;
      fill: $theme3;
      height: rem( 34 );
      margin: 0 auto;
      width: rem( 10 );
    }

    &:hover,
    &:focus,
    &.on {
      background: $theme2;
      color: $white;

      [class*="icon-prev"] {
        fill: $white;
      }
    }
  }
  .bt-next {
    [class*="icon-prev"] {
      transform:  matrix( -1, 0, -0, -1, 0, 0 );
    }
  }
  ul {
    list-style-type: none;
    margin: 0 rem( 3 );
  }
  ul,
  li {
    display: inline-block;
    vertical-align: middle;
  }
}
