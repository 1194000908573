//------------------------------------
// #Sections
//------------------------------------

// Generic
.bg-wrapper {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  box-sizing: border-box;
  margin: 0 auto;
  /*max-width: $max-width;*/
  position: relative;
}
.pict-wrapper {
  margin: 0 auto;
  max-width: $max-width;
  position: relative;
}

.pdg-btm-10 {
  padding-bottom: 10px;
}

// Colors
.grey {
  background: $white;
}
.grey-light {
  background: $grey-light2;
}
.theme {
  background: $theme2;
  // color: $white;
}
.theme2 {
  background: $theme5;
  // color: $white;
}
.theme3 {
  background: $theme6;
  color: $white;
}

// Home top section
.home-section {
  position: relative;

  .aside {
    .inner-wrap {
      background: $white;
    }
    .inner {
      padding: rem(20);
    }
  }
}

// Home hero
.home-hero {
  .bg-wrapper {
    padding: rem(120) 0 0;
  }

  .title {
    display: none;
  }
  .push {
    background: rgba($theme-secondary-dark, 0.4);
    margin: 0 pct(-10/300);
    padding: rem(10) pct(10/300);
    width: 100%;

    .tt-block {
      color: $white;
      font-size: rem(20);
      margin: 0 0 rem(5);

      &:after {
        background: $white;
      }
    }

    .button {
      text-align: center;
    }
  }
}

// Block push
.home-push {
  background: $theme2;
  color: $white;
  padding: rem(20) 0 rem(4);

  .tt-block {
    color: $white;
    font-size: rem(20);

    &:after {
      background: $white;
    }
  }

  .button {
    padding: rem(4) 0 rem(16);
    text-align: center;
  }

  [class*="icon-"] {
    display: none;
  }
}
// .tt-block {
//   text-align: center;
//   //margin: 50px 0 50px 0;
//   padding-left: 50px;

//   &:after {
//     background: transparent;
//   }

//   .span1 {
//     font-family: $font-roboto;
//     color: #69b5c2;
//     font-size: 30px;
//     text-transform: none;
//     font-weight: bold;
//   }

//   .span2 {
//     font-family: $homemadeApple;
//     font-weight: 40;
//     color: #182883;
//     font-size: 25px;
//     text-transform: uppercase;
//   }
// }
.title {
  padding-left: 0;
  text-align: left;
  .span1 {
    font-family: "Roboto Condensed Regular";
    color: #69b5c2;
    font-size: 30px;
    font-weight: bold;
  }
}

.titleSearch {
  margin-left: 95px;

  .span_1 {
    font-family: $roboto-condensed-regular;
    color: #ffffff;
    font-size: 38px;
    font-weight: 900;
    line-height: 42px;
  }

  .span_2 {
    font-family: $homemadeApple;
    font-size: 30px;
    color: #ffdd00;
    white-space: break-spaces;
    word-break: break-all;
  }
}

.link-subanner {
  color: $white;
  font-family: "Roboto Condensed Regular";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: auto;
  display: block;
  .link-more::before {
    content: "";
    border-bottom: 1px ridge $white;
    width: 70px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
  }
}

.link_ile {
  color: #182883;
  font-family: "Roboto Condensed Regular";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  padding: 40px 0 40px 0;
  margin: auto;
  display: block;

  &::before {
    content: "";
    border-bottom: 1px ridge #182883;
    width: 70px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
  }
}

.title {
  font-weight: 300;
  font-family: "Roboto Condensed Regular";
  color: #000;
  text-transform: uppercase;
  font-size: 23px;
}

// Content Header
[class*="header-main"] {
  position: relative;

  .icon-arrow-curv {
    display: none;
  }

  .bg-wrapper {
    height: rem(195);
    width: 1280px;
  }

  .HarmonisationUnset {
    height: rem(195);
    width: initial;
  }

  // Hero slider
  [data-slider="simple"] {
    height: 100%;
    overflow: hidden;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .slick-list {
      z-index: 1;
    }
    .slide {
      height: rem(195);
    }

    [class*="img-hero"] {
      background-size: cover;
      background-position: 50% 50%;
      height: 100%;
    }

    .slick-arrow {
      display: block;
      margin: rem(-10) 0 0;
      position: absolute;
      top: 50%;
      z-index: 2;

      [class*="icon-"] {
        fill: $white;
        height: rem(20);
        width: rem(13);
      }
    }
    .slick-prev {
      left: pct(10 / 320);
    }
    .slick-next {
      right: pct(10 / 320);
    }
  }

  .wrap {
    height: 100%;
  }
  .text {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 2;

    .desc {
      display: none;
    }

    &.center {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .slider-text {
    bottom: rem(60);
  }

  // &.header-islands {
  //   .bg-wrapper{
  //     background-color: #3e9fb2;
  //     background-size: auto 100%;
  //   }
  // }
  .map-labels {
    font-family: $font-roboto-condensed-regular;
    font-size: rem(13);
    height: 100%;
    list-style-type: none;
    margin: 0 auto;
    position: relative;
    text-transform: uppercase;
    width: rem(320);
    z-index: 1;

    li {
      position: absolute;

      // Grande-terre
      &:nth-child(1) {
        bottom: pct(180/216);
        left: pct(120/320);
      }
      // Basse-terre
      &:nth-child(2) {
        left: pct(80/320);
        top: pct(110/216);
      }
      // Les saintes
      &:nth-child(3) {
        left: pct(110/320);
        top: pct(190/216);
      }
      // La désirade
      &:nth-child(4) {
        left: pct(230/320);
        top: pct(35/216);
      }
      // Marie Galante
      &:nth-child(5) {
        left: pct(200/320);
        top: pct(160/216);
      }
    }

    a {
      background: rgba($black, 0.3);
      border: 1px solid rgba($white, 0.3);
      color: $white;
      display: inline-block;
      padding: rem(1) rem(5);
      text-decoration: none;

      [class*="icon-"] {
        display: none;
      }
    }
  }

  .header-slider {
    background: rgba(#616767, 0.5);
    bottom: 0;
    height: rem(60);
    left: 0;
    position: absolute;
    width: 100%;

    // .slider {
    //   margin: 0 rem( 40 );
    //   text-align: center;
    // }

    // img {
    //   margin: 0 auto;
    // }
  }
}
.block-filters {
  .filter-switch {
    .on {
      padding-right: 0 !important;
      text-align: center !important;
    }
  }
}
// Header video
.header-main-video {
  .tt-raw {
    color: $white;
    margin-top: rem(20);
    text-align: center;
  }
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .youtube-cover {
    display: none;
  }
}

// Carousel
.carousel_section {
  .carousel-item {
    position: relative;
    height: 600px;
    margin: 0px 230;

    .carousel {
      margin: auto;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
    .dechirure {
      margin: auto;
      position: absolute;
      z-index: 3;
      top: 74%;
      width: 100%;
      height: 130px;
    }
    .texte_slide {
      width: 360px;
      height: 193px;
      z-index: 3;
      position: absolute;
      left: 10%;
      right: 0;
      top: 38%;

      .hashtag {
        color: #ffdd00;
        font-family: "Homemade Apple";
        padding: 18px;
        font-style: italic;
        font-size: 30px;
        font-weight: normal;
        padding: 18px;
        display: inline;
      }
    }
    .fond_diving {
      background: #69b5c2;
      border-radius: 17.5px;
      text-align: center;
      padding: 10px;
    }
    .fond_diving_blue {
      background: #16207f;
    }
    .diving {
      color: #ffffff;
      font-family: $font-roboto-condensed-regular;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
    }
    .diving_blue {
      color: white;
    }
    .blue-heading {
      color: #16207f;
      background-color: none;
    }

    h1 {
      color: #ffffff;
      font-family: $font-roboto-condensed-regular;
      font-size: 35px;
      font-weight: 700;
      line-height: 48px;
      padding-top: 15px;
      display: inline;
    }
  }
  .carousel-indicators {
    z-index: 4;
    bottom: 75px;
  }
  .carousel-indicators li {
    width: 7px;
    height: 7px;
    border: solid 1.5px #69b5c2;
    border-radius: 100%;
  }
  .carousel-indicators li.active,
  .carousel-indicators li:hover {
    background-color: #69b5c2;
  }

  .pastille_jaune {
    position: absolute;
    z-index: 4;
    bottom: 10px;
    right: 60px;
  }
  .pastille {
    position: relative;
  }
  .titre_pastille {
    position: absolute;
    z-index: 1;
    top: 50px;
    color: #69b5c2;
    font-family: "Homemade Apple";
    font-size: 29px;
    font-weight: 400;
    line-height: 27.9px;
    text-align: center;
    margin: auto;
    padding-top: 60px;
    width: 100%;
    transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
    -khtml-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
  }
  .texte_pastille {
    position: absolute;
    z-index: 1;
    top: 150px;
    left: 76px;
    color: black;
    font-family: "Roboto";
    font-size: 16px;
    font-stretch: condensed;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
    margin: auto;
    width: 152px;
    padding-top: 10px;
    line-height: normal;
    transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
    -khtml-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
  }
}
// section video Youtube
.video-youtube {
  width: 980px;
  margin: auto;
  position: relative;
  margin-bottom: 90px;

  .youtube {
    height: 550px;

    .dechirure {
      margin: auto;
      position: relative;
      //top: 87%;
      margin-top: -50px;
    }
    .buttons {
      position: absolute;
      bottom: 35px;
      padding-left: 15px;
      cursor: pointer;
      margin: auto;

      .button {
        width: 35px;
        height: 35px;

        cursor: pointer;
      }

      #play-button {
        fill: #ffffff;
      }

      #pause-button {
        fill: #ffffff;
      }
    }

    .buttons.animationOut {
      -webkit-animation: cssAnimation 5s forwards;
      animation: cssAnimation 5s forwards;

      @keyframes cssAnimation {
        0% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @-webkit-keyframes cssAnimation {
        0% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
}
// Sub navigation
.sub-navigation {
  background: rgba($black, 0.3);
  color: $white;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;

  .select-list {
    color: $theme-secondary-grey;

    .js & {
      display: none;
    }
  }
  .select {
    width: 50%;

    .label {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  ul {
    font-weight: bold;
    text-transform: uppercase;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  a {
    text-decoration: none;
  }

  [class*="icon"] {
    height: rem(16);
    fill: $theme-secondary-grey;
    margin: 0 rem(8) 0 0;
    vertical-align: middle;
    transition: $transition;
    vertical-align: middle;
    width: rem(24);
  }
}

// Main section
.main-section {
  .content {
    padding: rem(26) 0 0;
  }
  .aside {
    .inner {
      background: $white;
      box-sizing: border-box;
      padding: rem(20) pct(10 / 300) rem(4);
    }

    .alt-green {
      background: $theme2;
    }
    .alt-grey {
      background: #838383;
    }
  }

  .wrap {
    .aside {
      margin-left: pct(-10/300);
      margin-right: pct(-10/300);
    }
  }
}

// Section
.section {
  padding: rem(26) 0 rem(10);

  .button {
    padding: 0 0 rem(16);
  }

  &.pushes {
    padding: rem(12) 0 0;
    .push-discover [class*="tt-header"]{
      width: 60%;
    }
  }

  .text {
    .title {
      font-weight: 300;
      font-family: "Roboto Condensed Regular";
      font-size: 22px;
      font-weight: bold;
      font-weight: 700;
      line-height: 26px;
      color: #000;
      text-transform: unset;

    }
  }


  .tt-block {
    padding-left: 0;
  }

  .tt-block-dark {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #69b5c2;
    font-size: 30px;
    text-transform: none;
    font-weight: bold;
    text-align: center;

    &:after {
      content: none;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    border-color: unset;
    background-color: unset;
    margin-bottom: 20px;
    height: 45px;
    font-size: 25px;
    background: unset;
    border-color: unset;
    font-weight: 400;
  }

  input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: black 1px solid;
  }

  .input {
    background-color: unset;
    display: grid;
    color: #000000;
    //font-weight: bold;
    white-space: nowrap;

    label {
      position: absolute;
      z-index: 0;
      font-size: 25px;
    }
    .line-link {
      text-align: right;
    }

    .error-msg {
      font-size: 13px;
      font-weight: 300;
      margin-top: -20px;
    }

    .warning {
      margin-top: 0;
    }

    .link {
      color: #182883;
      font-size: 20px;
      font-stretch: condensed;
      line-height: 24px;
      text-decoration: none;
      vertical-align: unset;
      font-style: unset;
      margin-top: 10px;

      &:hover {
        color: #182883;
        border-bottom: #182883 solid 3px;
      }

      &:before {
        content: "";
      }
    }
  }

  .link_cta {
    color: #182883;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    margin: auto;
    display: block;

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  #btnCta {
    color: white;
    background-color: transparent;
    font-size: 19px;

    &::before {
      border-color: white;
    }
  }

  button {
    background-color: white;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .line {
    label {
      z-index: 0;
      font-size: 25px;
      //font-weight: bold;
    }
  }

  .form-block {
    #address_form {
      #address_button {
        color: #182883;
        font-family: "Roboto Condensed Regular";
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        text-decoration: none;
        overflow: hidden;
        padding: 40px 0;
        margin: auto;
        display: block;

        ::before {
          content: "";
          border-bottom: 1px ridge #182883;
          width: 70px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
        }
      }

      .line {
        input {
          padding: 0;
        }

        .check {
          margin-top: -3rem;
        }
      }
    }
  }
}

.interactive-section {
  .wrap {
    .tt-block-dark {
      font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #69b5c2;
      font-size: 30px;
      text-transform: none;
      font-weight: bold;
      text-align: center;

      &:after {
        content: none;
      }
    }
  }
}
.block-form {
  background-color: #ffffff;
  .link_cta {
    color: #182883;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    margin: auto;
    display: block;
    width: auto;

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  button {
    background-color: white;
    border: none;

    &:focus {
      outline: none;
    }
  }
  .line {
    label {
      z-index: 0;
      font-size: 25px;
      font-weight: 400;
    }

    .placeholder {
      padding: 0;
    }

    .fake-label {
      font-size: 30px;
      font-weight: 400;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="message"],
  textarea {
    border-color: unset;
    background-color: unset;
    margin-bottom: 20px;
    height: 45px;
    font-size: 25px;
    background: unset;
    border-color: unset;
    border: none;
    border-bottom: black 1px solid;
    position: unset;
  }
  .form .custom-radio {
    float: left;
    margin-bottom: 0;
    height: 2.3125rem;
    width: 48%;
    height: 45px;
    margin-bottom: 20px;
  }
  input[type="radio"]#msg-female {
    display: none;
  }
  input[type="radio"]#msg-female + label {
    position: relative;
    padding-left: 1.7rem;
  }
  input[type="radio"]#msg-female + label::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 1rem;
    height: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: #dadfdf;
    line-height: 30px;
    //margin-left: 5px;
    padding-right: 5px;
    // margin-bottom: 5px;
    border-radius: initial;
  }

  input[type="radio"]#msg-female:checked + label::after {
    background-color: #69b5c2;
    box-shadow: inset 0 0 0 2px #fff;
  }

  input[type="radio"]#msg-male {
    display: none;
  }
  input[type="radio"]#msg-male + label {
    position: relative;
    padding-left: 1.7rem;
  }

  input[type="radio"]#msg-male + label::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 1rem;
    height: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: #dadfdf;
    line-height: 30px;
    //margin-left: 5px;
    padding-right: 5px;
    // margin-bottom: 5px;
    border-radius: initial;
  }

  input[type="radio"]#msg-male:checked + label::after {
    background-color: #69b5c2;
    box-shadow: inset 0 0 0 2px #fff;
  }

  .select {
    margin-bottom: 20px;

    .label {
      padding: 0;
    }
  }

  .cta {
    color: #182883;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    margin: auto;
    display: block;
    width: auto;

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      height: 100%;
    }
  }

  #title_block-form {
    margin-left: 0;
    margin-bottom: 25px;

    .span_1 {
      color: unset;
      font-weight: 400;
    }
  }
}

// Push section
.push-section {
  padding-top: rem(26);

  [class*="feature-push"] {
    padding-top: 0;
  }
}

.push-feature-section {
  [class*="feature-push"] {
    margin-bottom: 0;
  }
}

// Push section
.tour-section {
  padding: rem(26) 0 rem(14);

  .button {
    @include baseline;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  li + li {
    padding-top: rem(36);
    position: relative;

    &:before {
      background: linear-gradient(to bottom, $theme-secondary2 0%, $theme-secondary2 50%, transparent 50%);
      background-size: rem(2) rem(4);
      content: "";
      height: rem(45);
      left: rem(46);
      position: absolute;
      top: rem(-6);
      width: rem(2);
    }
  }
  .push-tour {
    padding-bottom: 0;
  }
}

/* JJK 1.2.1.0 */
// Section
.offers-section {
  overflow: hidden;
  padding: rem(26) 0 rem(4);

  .offers-target {
    position: relative;

    .block-offer {
      border: none;

      .text {
        p {
          width: 100%;
        }
        .location {
          font-family: "Roboto";
          font-size: 13px;
        }

        .title {
          font-family: "Roboto Condensed Regular";
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
  }
  .loader {
    background: rgba($white, 0.5);
    height: 100%;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
  }
  &.grey .loader {
    background: rgba($grey-light3, 0.5);
  }

  .tag-list {
    display: none;
  }

  [class*="tt-raw"] {
    margin-bottom: rem(32);
    text-align: center;

    .span1 {
      font-family: $font-roboto-condensed-regular;
      color: #69b5c2;
      font-size: 30px;
      text-transform: none;
      font-weight: bold;
    }
    .span2 {
      font-family: $homemadeApple;
      font-weight: 40;
      color: #182883;
      font-size: 25px;
    }
    span {
      text-transform: none;
    }
  }

  .button {
    padding: 0 0 rem(16);
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .sk-Rectangle {
      background-image: url(../../images/Rectangle.png);
      left: 0px;
      width: 80px;
      height: 1px;
      font-family: $font-roboto;
      font-weight: bold;
    }
    .sk-Plus {
      color: #182883;
      font-family: $font-roboto-condensed-regular;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
      overflow: hidden;
      padding: 40px 0 40px 0;
      margin: auto;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }

  &.offers-choices {
    [class*="tt-block"] {
      margin-bottom: rem(16);
      text-align: center;

      &:after {
        display: none;
      }
    }
  }
}

// Contributions
.contrib-head {
  [class*="tt-block"],
  [class*="cta"] {
    margin-bottom: rem(15);
  }
}
.contrib-sort {
  padding: 0 0 rem(15);

  p {
    display: inline-block;
    font-weight: 500;
    margin: 0 rem(10) rem(10) 0;
    vertical-align: middle;
  }
  .tag-list {
    display: inline-block;
    margin-bottom: rem(4);
    vertical-align: middle;
  }
}
.contrib-list {
  list-style-type: none;
}
.contrib-block {
  margin-bottom: rem(1);

  a {
    text-decoration: none;
  }

  .inner {
    background: $contrib;
    color: $theme-secondary-grey;
  }
  time {
    display: block;
    padding: rem(20) rem(20) rem(5) rem(20);

    img {
      display: none;
    }
  }
  .text {
    padding: rem(0) rem(20) rem(10);

    p {
      margin-bottom: rem(10);
    }
  }
  .tt {
    font-size: rem(20);
    font-weight: 500;
    margin: 0 0 rem(2);
    text-transform: uppercase;
  }
  .actions {
    background: $contrib-action;
    color: $white;

    &.disabled {
      background: $contrib-disabled;
    }

    ul {
      display: table;
      height: rem(100);
      list-style-type: none;
      margin: 0;
      table-layout: fixed;
      width: 100%;
    }
    li {
      display: table-cell;
      vertical-align: middle;

      &:first-child {
        a,
        button,
        div {
          border-right: 1px solid rgba($white, 0.6);
        }
      }
    }
    .state {
      border-left: 1px solid $white;

      button,
      a,
      div {
        height: 100%;

        &:before {
          @include valign;
        }
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      &.online {
        background: $state-online;
      }
      &.pending {
        background: $state-pending;
      }
      &.denied {
        background: $state-denied;
      }
      &.draft {
        background: $state-draft;
      }
    }

    [class*="icon"] {
      display: block;
      fill: $white;
      height: rem(24);
      margin: 0 auto rem(10);
    }
    .icon-edit {
      width: rem(20);
    }
    .icon-delete {
      width: rem(25);
    }
    div,
    button,
    a {
      background: none;
      border: 0 none;
      box-sizing: border-box;
      display: block;
      font-weight: 500;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &.disabled {
        cursor: auto;
      }
    }
  }

  .details {
    padding: rem(10) 0 0 0;

    .js & {
      display: none;

      &.on {
        display: block;
      }
    }
  }
}
//Section Instagram //
.instagram {
  .container {
    max-width: 1000px;
    padding-left: 0;
    padding-right: 0;

    .row-cols-1 {
      width: 1000px;
      margin: auto;
      display: flex;

      .col {
        padding: 0;
      }

      img {
        width: 235px;
        height: 230px;
        padding: 10px 0px 10px 0px;
        max-width: 235px;
      }
    }
    .row-cols-2 {
      width: 1000px !important;
      margin-bottom: 40px;
      margin: auto;
      display: flex;

      .col {
        padding: 0;;
      }

      img {
        width: 235px;
        height: 230px;
        padding: 10px 0px 10px 0px;
        max-width: 235px;
      }
    }

    .tt-block {
      text-align: center;
      margin: 50px 0 50px 0;
      &:after {
        background: transparent;
      }
      .span1 {
        font-family: $font-roboto-condensed-regular;
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }
      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 25px;
        text-transform: none;
      }
    }

    .instagagram_more {
      color: #182883;
      font-family: $font-roboto-condensed-regular;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
      overflow: hidden;
      padding: 40px 0 40px 0;
      margin: auto;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }
}
//L'archipel//
.explorez_archipel {
  .container_desktop {
    width: 1000px;
    height: auto;
    margin: auto;
    margin-bottom: 50px;
    background-image: url(../../B2C/img/Traits-archipel.png);
    background-repeat: no-repeat;
    background-size: contain;

    #la_desirade:hover {
      fill: #e30870;
    }

    #basse_terre:hover {
      fill: #71c52a;
    }

    #grande_terre:hover {
      fill: #ed6411;
      position: relative;
    }

    #les_saintes:hover {
      fill: #009bc3;
    }

    #marie_galante:hover {
      fill: #d7c007;
    }
    .bloc_image {
      display: flex;

      .title {
        width: 40%;
        margin: auto;
        margin-left: 50px;
        text-align: center;
      }
      .image {
        width: 75%;
      }
      .images_archipel {
        width: 491px;
        height: 479px;
      }
    }

    .tt-block {
      text-align: center;
      margin: 50px 0 50px 0;
      &:after {
        background: transparent;
      }
      .span1 {
        font-family: $font-roboto-condensed-regular;
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }
      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 25px;
        text-transform: none;
      }
    }
    .bloc_map {
      height: 537px;
      margin: auto;
      top: 1020px;
      margin-top: -100px;

      .map {
        width: 621px;
        float: left;
        cursor: pointer;
      }
      .islandDescriptionSection {
        width: 360px;
        height: 540px;
        margin: auto;
        display: table-cell;
        vertical-align: bottom;
        .title {
          font-weight: 300;
          font-family: "Roboto Condensed Regular";
        }
      }
      .descriptif_marie-galante {
        width: 360px;
        height: 540px;
        margin: auto;
        display: table-cell;
        vertical-align: bottom;
      }
      .descriptif_grande_terre {
        width: 359px;
        height: 539px;
        margin: auto;
        display: table-cell;
        vertical-align: bottom;
      }
      .descriptif_la_desirade {
        width: 359px;
        height: 539px;
        margin: auto;
        display: table-cell;
        vertical-align: bottom;
      }
      .descriptif_les_saintes {
        width: 359px;
        height: 539px;
        margin: auto;
        display: table-cell;
        vertical-align: bottom;
      }
      .descriptif_basse-terre {
        width: 359px;
        height: 539px;
        margin: auto;
        display: table-cell;
        vertical-align: bottom;
      }
      .title_descriptif {
        font-family: "Roboto Condensed";
        font-size: 32px;
        font-weight: 400;
        line-height: 32px;
      }
      p {
        font-family: $font-roboto;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
      .link_ile {
        color: #182883;
        font-family: "Roboto Condensed Regular";
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        text-decoration: none;
        overflow: hidden;
        padding: 40px 0 40px 0;
        margin: auto;
        display: block;

        &::before {
          content: "";
          border-bottom: 1px ridge #182883;
          width: 70px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
        }
      }
      .span1,
      .span2 {
        color: unset;
        text-transform: initial;
        font-weight: unset;
        font-size: 28px;
      }
    }
  }
}
.container_no_desktop {
  display: none;
}
//End L'archipel//
// Agenda

.agenda {
  background: rgba(119, 135, 83, 0.05);
  height: 100%;
  position: relative;

  .dechirure-top {
    height: 100px;
    width: 100%;
  }
  .dechirure-bottom {
    width: 100%;
    bottom: 0;
  }
  .container {
    width: 1000px;
    display: flex;
    margin: auto;
    vertical-align: middle;

    a {
      text-decoration: none;
    }

    .desc {
      width: 100%;
    }

    .agenda_descriptif {
      padding-top: 50px;
      float: left;
      width: 350px;

      .descriptif {
        margin-top: 60px;
        width: 350px;
      }
      .titre_descriptif_first {
        color: #182883;
        font-family: "Roboto Condensed Regular";
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        padding-top: 50px;
      }
      .titre_descriptif_second {
        font-family: "Roboto Condensed Regular";
        font-size: 30px;
        font-weight: 400;
        line-height: 32px;
        color: black;
      }
      .para-descriptif {
        font-size: 16px;
        font-weight: 300;
        line-height: 23px;
        width: 300px;
        padding-top: 15px;
        overflow: hidden;
        color: black;
        word-break: break-word;
      }
    }
    .agenda_image {
      width: 250px;
      height: auto;
      transform: rotate(-5deg);
      position: relative;
      float: left;
      img {
        border: 5px solid #ffffff;
      }
    }

    .date {
      position: absolute;
      width: auto;
      z-index: 2;
      padding-left: 105px;
      font-size: 60px;
      line-height: 65px;
      font-family: $font-roboto;
      font-size: 64px;
      font-weight: 500;

      .date_start {
        color: #182883;
        font-family: $font-roboto;
      }
      .date_end {
        color: #69b5c2;
      }
      .icon_fleche {
        vertical-align: text-bottom;
        width: 4rem;
        border: 0;
        padding: 10px;
        margin: 5px;
        background-color: rgba(255, 255, 255, 0);
      }
    }
    .agenda_evenement {
      float: right;
      margin-left: 20px;
      width: 310px;
      .icon_fleche {
        vertical-align: middle;
        border: 0;
        background-color: rgba(255, 255, 255, 0);
        float: right;
        width: 24px;
      }
    }

    .titre_agenda {
      padding-bottom: 20px;

      .span1 {
        font-family: $font-roboto-condensed-regular;
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }
      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 25px;
        text-transform: none;
      }
    }
    .bloc_first_date {
      padding-bottom: 30px;
      border-bottom: solid 1px #979797;

      .date_first {
        color: #182883;
        font-family: $font-roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
      .evenement_first {
        font-family: "Roboto Condensed Regular";
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        color: black;
      }
    }
    .bloc_second_date {
      padding-top: 30px;
      .date_second {
        color: #182883;
        font-family: $font-roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
      .evenement_second {
        font-family: "Roboto Condensed Regular";
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        color: black;
      }
    }

    .link_aganda {
      color: #182883;
      font-family: "Roboto Condensed Regular";
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: right;
      text-decoration: none;
      overflow: hidden;
      padding: 40px 0 40px 22px;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }

  .container_mobile {
    display: none;
  }
}
// End Agenda

// Section circuit
.circuit {
  width: 1280px;
  margin: auto;
  font-family: $font-roboto-condensed-regular;
  position: relative;

  a {
    text-decoration: none;
  }

  .circuit_block {
    width: 100%;
  }

  .title_circuit {
    position: absolute;
    z-index: 1;
    top: 150px;
    left: 150px;
    color: #ffffff;
    text-align: center;

    .span_1 {
      color: #ffffff;
      font-family: $roboto-condensed-regular;
      font-size: 30px;
      font-weight: 900;
      line-height: 42px;
    }

    .span_2 {
      font-family: $homemadeApple;
      font-size: 25px;
    }
  }

  .cover {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }

  .dechirure_top {
    position: absolute;
    margin-top: -10px;
  }

  .dechirure_bottom {
    position: relative;
    margin-top: -45px;
  }

  .images_circuit {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 330px;
    left: 150px;
  }

  .text {
    padding-top: 15px;
    width: 175px;

    .first {
      font-family: $font-roboto;
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
      text-transform: uppercase;
      word-break: break-word;
    }

    .second {
      font-family: $font-roboto-condensed-regular;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      word-break: break-word;
    }
  }

  .link_circuit {
    color: #182883;
    //font-family: $roboto-condensed-regular;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    padding: 40px 0px 40px 22px;
    width: 100%;
    text-align: center;
    display: block;

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  .circuit-slider {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: -230px;

    .circuit-slides {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      width: 100%;

      > div {
        scroll-snap-align: start;
        flex-shrink: 0;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.5s;
        position: relative;
        display: flex;
        float: right;
      }

      img {
        width: 235px;
        height: 414px;
        object-fit: cover;
      }

      .bloc_circuit {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
      }

      .circuit-slides::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      .circuit-slides::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
}

// End section circuit

// Newsletter
.section_newsletter {
  padding-bottom: 20px;

  .container_desktop {
    width: 1280px;
    height: auto;
    margin: auto;
    background-image: url(../../B2C/img/Traits-newsletter.png);
    background-repeat: no-repeat;
    background-size: cover;

    .bloc-newsletter {
      display: flex;
      width: 100%;
      padding-top: 50px;

      .title {
        width: 350px;
      }
    }

    .tt-block {
      text-align: center;
      margin: 50px 0 50px 0;
      width: 100%;

      &:after {
        background: transparent;
      }

      .span1 {
        font-family: $font-roboto-condensed-regular;
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }

      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 25px;
        text-transform: none;
      }
    }

    .newsletter-slider {
      display: block;
      width: 100%;
      // margin-left: 30px;
      overflow: hidden;

      .newsletter-slides {
        display: flex;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        position: relative;
        width: 100%;
        // height: 400px;

        overflow: hidden;

        > div {
          scroll-snap-align: start;
          flex-shrink: 0;
          transform-origin: center center;
          transform: scale(1);
          transition: transform 0.5s;
          position: relative;
          display: flex;
          width: 80%;

          left: 0;
          top: 0;
          max-width: none;
          margin: 0 -20px 0 0;
        }

        .newsletter {
          border: #979797 1px solid;
          width: 750px;
          height: 450;
          min-height: 350px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 50px;
          padding-right: 50px;

          .logo {
            width: 100px;
            height: 100px;
          }

          .titre-article {
            color: #182883;
            font-family: "Roboto";
            font-size: 18px;
            font-weight: 400;
            line-height: 14px;
            padding-top: 20px;
          }

          .article {
            font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 23px;
            padding-top: 10px;
            overflow: hidden;
            color: black;

            position: relative;
            max-height: 80px;
            text-align: justify;
            margin-right: -1em;
            padding-right: 0.8em;
          }

          .article:before {
            content: "...";
            position: absolute;
            right: 0;
            bottom: 0;
          }
          .article:after {
            content: "";
            position: absolute;
            right: 0;
            width: 1em;
            height: 1em;
            margin-top: 0.2em;
            background: white;
          }

          .date {
            font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 11px;
            font-weight: bold;
            line-height: 23px;
            padding-top: 10px;
            overflow: hidden;
            color: black;
            text-transform: uppercase;
          }

          .link_article {
            color: #182883;
            font-family: $font-roboto;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-decoration: none;
            overflow: hidden;
            padding-top: 50px;
            padding-bottom: 10px;
            display: block;

            &::before {
              content: "";
              border-bottom: 1px ridge #182883;
              width: 70px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 7px;
            }
          }
        }

        .newsletter-slides::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }

        .newsletter-slides::-webkit-scrollbar-track {
          background: transparent;
        }
      }
    }
  }
}

// End Newsletter

// Section Hébergement
.hebergement-section {
  .container {
    max-width: 1000px;
    padding-left: 0;
    padding-right: 0;

    .titre_hebergement {
      text-align: center;
      margin: 50px 0 50px 0;
      &:after {
        background: transparent;
      }
      .span1 {
        font-family: $font-roboto-condensed-regular;
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }
      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 25px;
        text-transform: none;
      }
    }

    .hebergement-target-offer {
      display: flex;
      justify-content: space-between;

      .accommodation-offer:nth-child(2) {
        padding-top: 45px;
      }
    }

    .more {
      color: #182883;
      font-family: $font-roboto-condensed-regular;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
      overflow: hidden;
      padding: 15px 0 40px 0;
      margin: auto;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    .block-accommodation-offer {
      width: 320px;
      padding-bottom: 20px;
      margin-bottom: 20px;

      a {
        text-decoration: none;
        color: initial;
      }

      .image_accommodation {
        height: 340px;
        width: 100%;
        object-fit: cover;
      }

      .description {
        padding-top: 35px;

        .Isle {
          font-family: "Roboto";
          font-size: 13px;
          font-weight: 300;
          line-height: 16px;
          text-transform: uppercase;
          color: #000;
        }

        .title {
          font-size: 22px;
          font-weight: 700;
          line-height: 26px;
          color: #000;
        }

        .City,
        .Category {
          font-family: "Roboto";
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
          color: #000;
        }
      }

      .label-accommodation {
        padding-top: 5px;

        .promo {
          border-radius: 3.5px;
          background: #ffdd00;
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;
          padding: 4px;
          float: right;
          margin-top: 4px;
        }

        .price_label {
          color: #182883;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          float: right;
          padding-right: 5px;
        }

        .price_number {
          color: #182883;
          font-size: 22px;
          font-weight: bold;
          // line-height: 16px;
          float: right;
          padding-right: 5px;
        }
      }

      .divImage {
        position: relative;
        height: 340px;

        .on-site {
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          padding: 4px 4px 4px 4px;
          border: 1px solid #979797;
          border-radius: 3.5px;
          background-color: white;
          bottom: -12px;
          right: 12px;
          position: absolute;
          white-space: nowrap;
        }
      }
    }
  }
}

//barre reservation hebergement, activities, restaurants

#hebergements,
#activities,
#restaurants {
  a {
    text-decoration: none;
    cursor: pointer;
  }

  .s002 {
    form {
      margin-bottom: 0;
      position: absolute;
      z-index: 10;
      top: -325px;
      left: 0;
      right: 0;
      // padding-bottom: 0;
    }
  }
  .search_bar {
    // position: fixed;
    // top: 238px;
    // z-index: 10;
    .first-bloc {
      display: flex;
      background-color: white;
      width: 600px;
      height: 70px;
      margin: auto;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);

      span {
        color: #182883;
        font-family: "Roboto Condensed Regular";
        font-size: 18px;
      }

      .slide-radio {
        display: flex;
        span {
          width: 100%;
          line-height: 30px;
          margin-left: 8px;
        }
        .custom-switch {
          float: right;

          .custom-control-label::before {
            width: 2.2rem;
            height: 20px;
            border-radius: 0.6rem;
            top: calc(0.15rem + 2px);
            left: calc(-2rem);
          }
          .custom-control-label::after {
            width: 20px;
            height: 20px;
            top: calc(0.15rem + 2px);
            left: calc(-2rem + 2px);
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          color: #69b5c2;
          background-color: #69b5c2;
          border-color: #69b5c2;
        }
      }
    }

    .wrap-equipment,
    .wrap-filter {
      width: 100%;
      height: 30px;
      //margin-left: 10px;
      border-bottom: solid 1px #e7e7e7;
      line-height: 30px;
    }
    .dropdown {
      min-width: 150px;

      #filter-details-1 {
        margin: 0;
      }
    }

    .hide {
      display: none;
    }

    .raw-price-travelers-desk {
      width: 100%;
      height: 30px;
      margin-left: 10px;
      border-bottom: solid 1px #e7e7e7;
      line-height: 30px;

      .price {
        #range {
          margin-left: 25px;
        }
        .first-span > span {
          color: black;
          margin-left: 5px;
        }

        .range-desktop {
          width: 200px;
          .noUi-connect {
            background: #69b5c2;
          }
          .noUi-handle {
            background: #69b5c2;
          }
          .noUi-tooltip {
            color: #69b5c2;
            background: none;
          }
          .noUi-tooltip {
            color: black;
            font-weight: 500;
          }
        }
      }
    }

    .vertical-bar {
      height: 60px;
      border-left: 2px #e7e7e7 solid;
      margin-right: 10px;
      margin-left: 10px;
    }

    input[type="radio"].activity {
      display: none;
    }
    input[type="radio"].activity + label {
      position: relative;
      padding-left: 1.7rem;
    }
    input[type="radio"].activity + label::before,
    input[type="radio"].activity + label::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 1rem;
      height: 1rem;
      border-style: solid;
      border-width: 1px;
      border-color: #dadfdf;
      line-height: 30px;
      //margin-left: 5px;
      padding-right: 5px;
      // margin-bottom: 5px;
    }

    input[type="radio"].activity:checked + label::after {
      background-color: #69b5c2;
      box-shadow: inset 0 0 0 2px #fff;
    }

    input[type="checkbox"].activity {
      display: none;
    }
    input[type="checkbox"].activity + label,
    input[type="checkbox"].activity + input + label {
      position: relative;
      padding-left: 1.7rem;
    }
    input[type="checkbox"].activity + label::before,
    input[type="checkbox"].activity + label::after,
    input[type="checkbox"].activity + input + label::before,
    input[type="checkbox"].activity + input + label::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 1rem;
      height: 1rem;
      border-style: solid;
      border-width: 1px;
      border-color: #dadfdf;
      line-height: 30px;
      //margin-left: 5px;
      padding-right: 5px;
      // margin-bottom: 5px;
      border-radius: 3px;
    }

    input[type="checkbox"].activity:checked + label::after,
    input[type="checkbox"].activity:checked + input + label::after {
      background-color: #69b5c2;
      box-shadow: inset 0 0 0 2px #fff;
    }
    .sticky {
      position: fixed;
      top: 60px;
      z-index: 2;
      padding-bottom: 0;
    }
  }
}

//décalage de la barre reservation pour activities et restaurants
#activities,
#restaurants {
  .s002 {
    form {
      margin-bottom: 0;
      position: absolute;
      z-index: 10;
      top: -255px;
    }
  }
}
// Section Push Video Popin
.section_video_popin {
  margin: 30px 0px;

  .container {
    max-width: 980px;
    padding-left: 15px;
    padding-right: 15px;

    .content {
      display: flex;

      .bloc-video {
        width: 500px;

        image {
          width: 100%;
        }
      }

      .bloc-description {
        max-width: 50%;

        .bloc-titre {
          .titre {
            font-family: $font-roboto-condensed-regular;
            color: #69b5c2;
            font-size: 30px;
            text-transform: none;
            font-weight: bold;
          }

          .sousTitre {
            font-family: $homemadeApple;
            font-weight: 40;
            color: #182883;
            font-size: 25px;
            text-transform: none;
          }
        }

        .description {
          font-family: $font-roboto;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          margin-top: 15px;
        }
      }
    }
  }
}
.page_404 {
  width: 980px;
  margin: auto;

  .error-banner-content {
    .error-banner-text {
      font-family: "Roboto Condensed Regular";
    }
  }

  .text {
    font-family: "Roboto Condensed Regular";
    .tt-block {
      text-align: left;
    }

    P {
      padding-left: 90px;
    }

    .encyclo-header {
      font-family: "Roboto Condensed Regular";
    }
    .link-light {
      color: #182883;
      font-family: "Roboto Condensed Regular";
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      text-decoration: none;
      overflow: hidden;
      margin: auto;
      display: block;
      font-style: unset;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    .encyclo-header {
      padding-right: 25rem;
    }
  }

  .button {
    .link {
      color: #182883;
      font-family: "Roboto Condensed Regular";
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      text-decoration: none;
      overflow: hidden;
      margin: auto;
      display: block;
      font-style: unset;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }

  .block-search {
    background: #2bb7da;
    .wrap {
      .tab[aria-expanded="true"] {
        background: #2bb7da;
        &:before {
          background: unset !important;
          width: calc(980px);
        }
      }

      .tab {
        &:first-of-type,
        &:last-of-type {
          &:before {
            width: 34.7vw;
            right: unset;
          }
        }
        &:first-of-type {
          padding-left: revert;
        }
      }
    }
  }
}

///my account/profile encyclo

.section#myencyclo {
  font-family: "Roboto Condensed Regular";

  .user-avatar img {
    background: rgba(144, 145, 144, 0.6);
  }
}

///my account/profile optin

.section#myoptin {
  font-family: "Roboto Condensed Regular";
}
