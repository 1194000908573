//------------------------------------
// #Sections
//------------------------------------

// Content Header
[class*="header-main"] {

  .map-labels {

    li {

      // Grande-terre
      &:nth-child(1){
        bottom: pct( 315/390 );
        left: pct( 470/980 );
      }

      // Basse-terre
      &:nth-child(2){
        left: pct( 320/980 );
        top: pct( 220/390 );
      }
      // Les saintes
      &:nth-child(3){
        left: pct( 380/980 );
        top: pct( 355/390 );
      }
      // La désirade
      &:nth-child(4){
        left: pct( 735/980 );
        top: pct( 90/390 );
      }
      // Marie Galante
      &:nth-child(5){
        top: pct( 285/390 );
        left: pct( 600/980 );
      }
    }
  }

  .bg-wrapper {
    height: rem( 390 );
  }

  // Hero slider
  [data-slider="simple"]{
    .slide{
      height: rem( 390 );
    }

    .slick-arrow {
      margin: rem( -20 ) 0 0;


      [class*="icon-"]{
        height: rem( 40 );
        width: rem( 26 );
      }
    }
  }

  .slider-text {
    bottom: rem( 80 );
  }

  &.small {
    .bg-wrapper {
      height: rem( 195 );
    }
    .text {
      bottom: auto;
      top: 50%;
      transform: translateY( -50% );
    }
    [class^="tt-header"] {
      margin-bottom: 0;
    }
  }
}
// Main section
.main-section {
  .content {
    padding-right: rem( 20 );

    .block-documents-list {
      padding-right: rem( 20 );
    }
  }
  .aside {
    width: rem( 320 );
  }
}

// Push section
.tour-section {
  .tt-block {
    display: none;
  }

  ul {
    max-width: none;
  }

  li + li {
    padding-top: rem( 20 );

    &:nth-child(odd){
      &:before {
        background: url( "#{$url-img}bg-dotted-02.png" ) no-repeat 0 0;
        background-size: 100% auto;
        height: rem( 116 );
        left: rem( 80 );
        top: rem( -65 );
        width: rem( 625 );
      }
    }
    &:nth-child(even){
      &:before {
        background: url( "#{$url-img}bg-dotted-01.png" ) no-repeat 0 0;
        background-size: 100% auto;
        height: rem( 149 );
        left: auto;
        right: rem( 100 );
        top: rem( -98 );
        width: rem( 436 );
      }

      .push-tour {
        text-align: left;

        .date-sticker {
          left: auto;
          right: rem( 60 );
        }

        .picture {
          left: auto;
          right: 0;
        }
        .text {
          text-align: right;
        }
      }
    }
  }
}
/* JJK 1.2.1.0 */
// Section
.offers-section {

  .tag-list {
    display: block;
  }

 /* [class*="tt-raw"]{
    text-align: left;
  }*/

  .block-offer{
    margin-bottom: rem( 16 );
  }

  .button {
    text-align: left;
  }

  &.offers-choices {
    padding-bottom: rem( 20 );

    [class*="tt-block"]{
      margin-bottom: rem( 26 );
      text-align: left;

      &:after {
        display: block;
      }
    }
  }
}

// Contributions
.contrib-head {
  @include clearfix;

  [class*="cta"] {
    float: right;
    max-width: 50%;
  }
}
