//------------------------------------
// #PUSHES
//------------------------------------


// Feature push
[class*="feature-push"] {
  // margin: 0 0 rem( 20 );
  padding: rem( 26 ) 0 0;
  text-align: center;

  [class*="col-"] {
    box-sizing: border-box;
    padding: 0 0 rem( 10 );
    text-align: left;
  }

  .tt {
    color: $theme;
    font-size: rem( 16 );
    font-weight: 500;
    margin: 0;
  }

  .button {
    padding: 0 0 rem( 26 );
    text-align: center;
  }
  .slider {
    margin-bottom: rem( 6 );
  }

  [class*="picture"] {
    padding: 0 0 rem( 16 );
    position: relative;

    .discount {
      position: absolute;
      right: 0;
      text-align: right;
      top: rem( 16 );
    }

    img {
      display: block;
      margin: 0 0 0 auto;
    }
  }
  .revert [class*="picture"]{

    .discount {
      left: 0;
      right: auto;
      text-align: left;
    }
    img {
      margin: 0;
    }
  }

  .picture-centerd {
    img {
      margin: 0 auto;
    }
  }
}

// Push islands
.feature-push-island {
  .picture {
    padding-bottom: pct( 10/300 );
    margin-right: auto;
    margin-left: pct( -10/300 );
    width: pct( 277/300 );

    img {
      width: 100%;
    }
  }
  .text {
    box-sizing: border-box;
    width: pct( 267/300 )
  }

  .revert {
    .picture {
      margin-left: auto;
      margin-right: pct( -10/300 );
    }
    .text {
      margin-left: auto;
      margin-right: 0;
      padding-right: pct( 10/300 );
      text-align: right;
    }
  }
}

// Distinctive green push
.push-distinctive {
  background: $theme2;
  color: $white;

  .picture {
    margin: 0 pct( -10/300 );

    img {
      display: block;
      margin: 0 auto;
    }
  }
  .button {
    padding: 0 0 rem( 16 );
  }
  .text {
    padding: rem( 26 ) 0 rem( 10 );
  }
}


// Tiles push
.push-tiles {
  // margin: 0 0 rem( 20 );
  padding: rem( 26 ) 0 rem( 10 );

  .tiles-list {
    list-style-type: none;
    margin: 0 auto;
    padding: 0 0 rem( 4 );

    @include clearfix;

    li {
      padding: 0 0 rem( 16 );
    }

    img {
      display: block;
      margin: 0 auto;
      width: 100%
    }

    a {
      display: block;
      position: relative;
    }
  }

  .caption {
    background: rgba( $black, 0.5 );
    box-sizing: border-box;
    color: $white;
    padding: rem( 5 ) rem( 20 );
    // padding: 0;
    position: absolute;
    margin: 0;
    top: 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    white-space: nowrap;

    &:before {
      @include valign;
    }

    span {
      display: inline-block;
      white-space: normal;
      vertical-align: middle;
    }

    &.init {
      transition: all 0.25s ease;

      span {
        transition: font-size 0.25s ease;
      }
    }
  }
  a:hover,
  a:focus {
    .caption.init {
      height: 100% !important;
      width: 100% !important;

      span {
        font-size: rem( 20 );
      }
    }
  }

  .desc {
    font-size: initial;
  }
}


// Number push
.push-numbers {
  background: #3797a7;
  color: $white;
  padding: rem( 16 ) 0 0;

  .pictures {
    display: none;
  }
  .tt-block {
    color: $white;

    &:after {
      background: $white;
    }
  }
  ul {
    list-style-type: none;
    margin: rem( 14 ) auto;
    text-align: center;
    width: rem( 300 );
  }
  li {
    display: inline-block;
    padding: 0 rem( 15 ) rem( 16 );
    vertical-align: top;
    width: rem( 108 );
  }
  .pict {
    display: none;
  }
  .desc {
    border: 2px solid $white;
    box-sizing: border-box;
    border-radius: rem( 54 );
    background: #45c2d4;
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 16 );
    height: rem( 108 );
    margin: 0;
    width: rem( 108 );
    white-space: nowrap;
    text-transform: uppercase;

    &:before {
      @include valign();
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
    }
    strong {
      font-family: $font-roboto;
      font-size: rem( 16 );
      font-weight: bold;
      text-transform: none;
    }
  }
  .button {
    margin: 0 0 rem( 16 );
    text-align: center;
  }
  .cta {
    background: $theme-light;
    border-color: $theme-light;
    color: $theme-dark;

    &:hover {
      background: $theme;
      border-color: $white;
      color: $white;
    }
  }
}

// Map push
.push-map {
  margin: 0 0 rem( 20 );
  padding: rem( 20 ) 0 0;

  .text {
    padding: 0 0 rem( 4 );
  }
  .button {
    margin: 0 0 rem( 16 );
    text-align: center;
  }
  .picture {
    margin: 0 pct( -10/300 );
    position: relative;

    img {
      display: block;
      position: relative;
      z-index: 1;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
  }
  li {
    position: absolute;
    z-index: 2;

    &:nth-child(1){
      // bottom: pct( 170/216 );
      top: pct( 50/216 );
      left: pct( 150/320 );
      // right: pct( 140/320 );
    }
    &:nth-child(2){
      left: pct( 110/320 );
      top: pct( 120/216 );
    }
    &:nth-child(3){
      left: pct( 140/320 );
      top: pct( 180/216 );
    }
    &:nth-child(4){
      left: pct( 245/320 );
      top: pct( 55/216 );
    }
    &:nth-child(5){
      bottom: pct( 40/216 );
      left: pct( 220/320 );
    }
  }

}


// Tour push
.push-tour {
  padding: rem( 42 ) 0 rem( 20 );
  position: relative;

  .date-sticker {
    left: rem( 8 );
    position: absolute;
    top: 0;
  }

  .picture {
    margin: 0 pct( -10 / 300 );

    img {
      display: block;
      width: 100%;
    }
  }
  .text {
    background: $white;
    box-sizing: border-box;
    margin: rem( -36 ) auto 0;
    padding: rem( 15 ) rem( 15 ) 0;
    position: relative;
    width: pct( 280 / 300 );
  }
}


// Hero picture block
.push-picture {
  display: none;
}


// Hero push
.push-hero {
  background: $theme;
  color: $white;
  text-align: center;

  // a {
  //   display: block;
  //   text-decoration: none;
  // }

  .tt-hero {
    color: $white;
  }
  .button {
    @include baseline;
  }
  .block {
    margin: 0 auto;
    max-width: rem( 480 );
    padding: rem( 26 ) 0 rem( 10 );
  }
}


// Slider push
.push-slider {
  background: $theme2;
  color: $white;
  padding: rem( 26 ) 0;
  text-align: center;

  [class*="tt-"] {
    color: $white;
    margin-bottom: rem( 20 );
  }

  ul {
    list-style-type: none;
    margin: 0;
  }
}


// Google map
.push-google-map {
  // box-shadow: inset 0 20px 20px -20px #2c444b, inset  0 -20px 20px -20px #2c444b;
  height: rem( 300 );
  position: relative;

  .flexbox & {
    &:before,
    &:after {
      content: "";
      height: rem( 30 );
      left: 0;
      position: absolute;
      pointer-events: none;
      width: 100%;
      z-index: 2;
    }

    &:before {
      background: linear-gradient( to bottom, rgba( #2c444b, 0.3 ), rgba( #2c444b, 0 ));
      top: 0;
    }
    &:after {
      background: linear-gradient( to top, rgba( #2c444b, 0.3 ), rgba( #2c444b, 0 ));
      bottom: 0;
    }
  }

  .map {
    height: 100%;
    position: relative;
    z-index: 1;
  }
}


// Certification push - Advantages
.push-certification {
  background-color: $grey-light;
  padding: rem( 26 ) 0 rem( 34 );

  .program-register {
    margin-top: rem( 26 );
    text-align: center;

    ol {
      list-style-type: none;
      text-align: left;
    }

    li {
      color: $theme;
      font-family: $font-roboto-condensed-regular;
      font-size: rem( 14 );
      position: relative;

      + li {
        margin-top: rem( 13 );
      }
    }

    .icon {
      background-color: $purple;
      border-radius: 50%;
      height: rem( 20 );
      position: absolute;
      top: rem( -2 );
      width: rem( 20 );

      svg {
        fill: $white;
        height: rem( 10 );
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate( -45%, -50% );
        width: rem( 10 );
      }

      + span {
        display: block;
        padding-left: rem( 27 );
      }
    }

    .cta {
      margin-top: rem( 13 );
    }
  }
}


// Certification modules
.push-certification-modules {
  background-color: $theme2;
  color: $white;
  padding: rem( 26 ) 0 0;

  [class*="tt-header"] {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem( 26 );
  }

  .modules {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: rem( 15 ) 0 0;

    article {
      margin: rem( 20 ) 0;
      text-align: center;

      p {
        margin: 0 auto rem( 10 );
        width: pct( 270 / 320 );
      }
    }

    .icon {
      background-color: rgba( $theme-dark2, .5 );
      border-radius: 50%;
      height: rem( 126 );
      margin: 0 auto rem( -25 );
      position: relative;
      width: rem( 126 );

      img {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate( -50%, -50% );
      }
    }
  }

  .mod-elearning {
    order: 1;
  }

  #mod-elearning {
    order: 2;
  }

  .mod-seminaire {
    order: 3;
  }

  #mod-seminaire {
    order: 4;
  }

  .mod-eductour {
    order: 5;
  }

  #mod-eductour {
    order: 6;
  }

  .mod-challenge {
    order: 7;
  }

  #mod-challenge {
    order: 8;
  }

  [class*="tt-raw"] {
    position: relative;
  }

  [id^="mod-"] {
    background-color: #126a6e;
    display: none;
    padding: rem( 26 ) 0;
    position: relative;

    &.on {
      display: block;
    }
  }
}


// Push Explain
.push-highlight {
  background: $theme;
  color: $white;
  padding: rem( 26 ) 0 0;
  text-align: center;

  .tt-raw-yellow {
    font-size: rem( 20 );
  }

  .pict {
    height: rem( 100 );

    img {
      display: block;
      margin: 0 auto;
      max-height: rem( 90 );
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: rem( 26 ) 0 0;
    text-align: center;
  }
  li {
    display: inline-block;
    max-width: rem( 290 );
    padding-bottom: rem( 16 );
  }
}


// Push discover
.feature-push-discover {

  .tt-block {
    margin-bottom: rem( 12 );
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.push-discover {
  display: block;
  margin: 0 0 rem( 50 );
  text-align: left;

  [class*="tt-header"] {
    margin-bottom: rem( 12 );
    text-align: left;
    width: 41%;
    span:first-child {
      background: transparent;
      display: inline-block;
    float: none;
    letter-spacing: normal;
    vertical-align: top;
    }

    .font-orchide {
      background-color: transparent;
    border: 0px;
      font-family:$homemadeApple;
      text-transform: lowercase;
    }
  }

  &.small{
    [class*="tt-header"] {
    width: 100%;
    font-size: 12px;
    }
    .text {
      color: black;
      padding-top: 10px;
      padding-left: 0px;
      a{
        text-decoration: none;
      }
      .intro{
        font-weight: bold;
        font-size: 20px;
      }

    }

  }


  .inner {
    background: no-repeat 50% 50%;
    background-size: cover;
    display: block;
    font-size: 0;
    letter-spacing: 0;

    &:before {
      @include valign;
      height: rem( 180 );
      vertical-align: bottom;
    }
  }

  &.middle {
    .inner{
      &:before {
        height: rem( 280 );
      }
    }
  }

  // &:nth-of-type(1) {
  //   .inner {
  //     &:before {
  //       height: rem( 280 );
  //     }
  //   }
  // }

  .text {
    box-sizing: border-box;
    display: inline-block;
    font-size: rem( $fz );
    letter-spacing: normal;
    padding: rem( 12 ) pct( 10/300 ) 0;
    vertical-align: bottom;
    width: 100%;

    .intro {
      font-size: rem( 15 );
      margin: 0 0 rem( 6 ) 0;
      text-transform: uppercase;
    }

    p {
      margin: 0 0 rem( 12 );
    }
    [class*="link"]{
      margin: 0 0 rem( 12 );
    }
  }
  .highlight {
    margin: 0 pct( -10/280 );
    padding: rem( 12 ) pct( 10/300 ) rem( 1 );
    position: absolute;
    .button {
      padding: 0 0 1rem;
      text-align: left;
      display: -webkit-flex;
      display: -ms-flexbox;
      -ms-flex-align: center;
      align-items: center;
      justify-content: left;
      .sk-Rectangle{
        background-image: url(../../images/Rectangle.png);
        left: 0px;
        width: 30px;
        height: 1px;
      }
      .sk-Plus{
        font-family: $font-roboto;
        color:#182883;
        font-size: 20px;
        opacity: 1;
        text-align: left;
        text-decoration: none;
        top: 0px;
        padding-left: 20px;
        height: 24px;
        font-weight: bold;
      }
    }
  }
  .link-icon {
    color: #182883;
  }
}


// Push Facebook
.push-facebook {
  color: $black;
  overflow: hidden;
  margin-top: 50px;

  .wrap {
    padding: rem( 26 ) 0 0;
  }

  .inner {
    padding-bottom: rem( 250 );
    position: relative;
  }

  .tt-block {
    text-align: center;
    .span1{
      font-family: $font-roboto;
      color: #69B5C2;
      font-size: 30.0px;
      text-transform: none;
      font-weight:  bold;
    }
    .span2{
      font-family: $homemadeApple;
      font-weight: 40;
      color: #182883;
      font-size: 25px;
      text-transform: none;
    }

    &:after {
      background: $white;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .text {
    margin: 0 auto;
    width: rem( 258 );

    .intro {
      background: #d9f0f6;
      color: $black;
      line-height: ( 20/13 );
      margin: 0 0 rem( 1 );
      padding: rem( 16 ) rem( 26 ) rem( 6 );
      text-align: center;

      p {
        margin: 0 0 rem( 10 );
      }
    }
    .title {
      font-size: rem( 20 );
      font-weight: normal;
      margin: 0 0 rem( 10 );
    }
    .content {
      background: $white url("#{$url-img}bg-facebook.jpg") no-repeat rem( 12 ) rem( 12 );
      color: #3b5998;
      font-size: rem( 11 );
      min-height: rem( 31 );
      padding: rem( 12 ) rem( 12 ) rem( 12 ) rem( 50 );
      position: relative;

      p {
        margin: 0 0 rem( 4 );
      }
      iframe {
        border:none;
        overflow:hidden;
        width: rem( 190 );
        height: rem( 20 );
      }

      &:after {
        content: "";
        border: 0 none;
        border-top: 8px solid #ffffff;
        border-right: 14px solid transparent;
        bottom: rem( -8 );
        height: 0;
        left: rem( 120 );
        position: absolute;
        width: 0;
      }
    }
  }

  .pictures {
    list-style-type: none;

    li {
      border: 1px solid $white;
      box-shadow: -3px 3px 3px rgba( #010101, 0.2 );
      position: absolute;

      &:nth-child(1),
      &:nth-child(4) {
        height: rem( 133 );
        z-index: 3;
      }
      &:nth-child(2),
      &:nth-child(5) {
        height: rem( 97 );
        opacity: 0.5;
        z-index: 2;
      }
      &:nth-child(3),
      &:nth-child(6) {
        height: rem( 81 );
        opacity: 0.5;
        z-index: 1;
      }

      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }


      &:nth-child(1) {
        bottom: rem( 101 );
        margin: 0 rem( 44 ) 0 0;
        right: 50%;
        z-index: 4;
      }
      &:nth-child(4) {
        bottom: rem( 66 );
        left: 50%;
        transform: translateX( -50% );
      }
      &:nth-child(5) {
        bottom: rem( 20 );
        left: 50%;
        margin: 0 0 0 rem( 44 );
      }
      &:nth-child(6) {
        bottom: rem( 160 );
        left: 50%;
        margin: 0 0 0 rem( 30 );
      }
    }
    img {
      display: block;
      height: 100%;
      max-width: none;
      width: auto;
    }
  }
}


// Advatages
.push-advantages {
  background: $white;
  color: $white;
  overflow: hidden;
  padding: rem( 26 ) 0 0;

  .tt-block{
    color: $white;
    text-align: center;
    padding-left: 0;

    &:after {
      background: $white;
      margin-left: auto;
      margin-right: auto;
    }
  }

  img {
    display: block;
    margin: 0 auto rem( 12 );
  }

  ul {
    font-size: 0;
    letter-spacing: 0;
    list-style-type: none;
    margin: 0 rem( -15 );
    text-align: center;
  }

  li {
    display: inline-block;
    font-size: rem( $fz );
    letter-spacing: normal;
    margin: 0 auto rem( 26 );
    padding: 0 rem( 15 );
    width: rem( 250 );
    vertical-align: top;
  }
  li {
    .title {
      text-align: center;
    }

  }

  p {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: black;
    text-align: center;
  }
}


// Guadeloupedia
.push-guadeloupedia-empty {
  background: #3369a9;
  color: $white;
  margin: 0 0 rem( 12 );
  text-align: center;

  .inner {
    background: no-repeat 50% 50%;
    background-size: cover;
    box-sizing: border-box;
    display: block;
    height: rem( 280 );
    padding: rem( 20 );
    text-decoration: none;
  }
  .push-tt {
    color: #75e1f3;
    font-size: rem( 26 );
    font-weight: 500;
    margin: 0 0 rem( 15 );
    text-shadow: 0 0 5px rgba( $white, 0.2 );
  }
  .intro {
    border-bottom: 1px solid $white;
    border-top: 1px solid $white;
    margin: 0 auto rem( 16 );
    max-width: rem( 240 );
    padding: rem( 2 ) 0;

    &:before {
      content: "> ";
    }
  }
}

.push-guadeloupedia {
  background: #6bd8da;
  box-sizing: border-box;
  color: $white;
  margin: 0 0 rem( 12 );
  min-height: rem( 280 );
  padding: rem( 20 ) rem( 10 ) rem( 5 );
  text-align: center;

  .push-tt {
    color: #0590b3;
    font-size: rem( 26 );
    font-weight: 500;
    margin: 0 0 rem( 5 );
    text-shadow: 0 0 5px rgba( $white, 0.7 );
  }
  .intro {
    border-bottom: 1px solid $white;
    border-top: 1px solid $white;
    display: inline-block;
    font-size: rem( 20 );
    font-weight: 500;
    margin: 0 auto rem( 30 );
    padding: rem( 2 ) 0;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    margin: 0 0 rem( 15 );
    text-align: left;
  }
  li {
    padding: 0 0 1px;
  }
  .button {
    text-align: right;
  }
  .contrib {
    background: $white;
    color: $theme-secondary-grey;
    position: relative;

    a {
      text-decoration: none;
    }
    > a {
      display: block;
    }

    time {
      background: no-repeat 50% 50%;
      background-size: cover;
      box-sizing: border-box;
      color: $white;
      float: left;
      font-size: rem( 12 );
      line-height: 1;
      height: rem( 70 );
      overflow: hidden;
      padding: 0;
      position: relative;
      text-align: center;
      width: rem( 80 );

      img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        max-width: none;
        // width: 100%;
        z-index: 1;
      }

      span {
        background: rgba( #233c44, 0.4 );
        box-sizing: border-box;
        display: block;
        height: 100%;
        min-height: rem( 70 );
        padding: rem( 16 ) 0 0;
        position: relative;
        width: 100%;
        z-index: 2;
      }

      b {
        font-size: rem( 20 );
        display: block;
      }
    }

    .text {
      box-sizing: border-box;
      min-height: rem( 70 );
      padding: rem( 10 ) rem( 10 ) rem( 25 ) rem( 90 )
    }

    .tt {
      font-size: rem( 15 );
      font-weight: bold;
      line-height: ( 17 / 15 );
      margin: 0;
      text-transform: uppercase;
    }
    .state {
      background: $theme-secondary-grey;
      bottom: 0;
      box-sizing: border-box;
      color: $white;
      font-weight: bold;
      margin: 0;
      padding: rem( 2 ) rem( 10 );
      min-width: rem( 80 );
      position: absolute;
      right: 0;
      text-align: center;

      &.online {
        background: $state-online;
      }
      &.pending {
        background: $state-pending;
      }
      &.denied {
        background: $state-denied;
      }
      &.draft {
        background: $state-draft;
      }
    }
  }
}
