//------------------------------------
// #OFFERS PAGES
//------------------------------------


// Search Form
.offer-search-form {

  // Main Search form
  [class*="form-header"] {
    .kids-form {
      .select-block {
        width: 20%;

        &:nth-child(4n-3),
        &:nth-child(4n) {
          padding: 0 rem( 2 ) 0 rem( 2 );
        }

        &:nth-child(5n-4){
          padding: 0 rem( 2 ) 0 0;
        }
        &:nth-child(5n){
          padding: 0 0 0 rem( 2 );
        }
      }
    }
  }

  // Filters form
  .form-filters {
    padding: rem( 16 ) rem( 20 );

    .price-part,
    .grades-part {
      padding-right: rem( 20 );
    }
    .type-part {
      padding-left: rem( 20 );
    }

    .options-part{

      .form-fields {
        column-gap: rem( 40 );
      }
    }

    .buttons {
      box-sizing: border-box;
      margin: 0 rem( -6 ) 0 auto;
      padding: 0 0 0 rem( 6 );
      width: 50%;
    }
  }
}


// Popins
#popin {
  .options-list {
    margin: 0 0 rem( 26 );

    li {
      padding-bottom: rem( 5 );
      padding-right: 0;
      @include clearfix;

      > label {
        float: left;
        padding-top: rem( 3 );
        width: rem( 420 );
      }
    }
    .option {
      float: right;

      .icon-check {
        top: rem( 6 )
      }
    }
  }

  .offer-actions {
    padding-bottom: rem( 10 );
  }
}
