.form-layer.on .dropdown-location {
    margin-top: -9.3rem;
}

.dropdown-location {
    width: 97.8%;

    &__table {
        display: table;

        & tbody {
            display: table-row-group;
        }

        & tbody > tr {
            display: table-row;
        }

        & tbody > tr > td {
            width: auto !important;
            display: table-cell;
            text-align: center;
            box-sizing: unset;
        }

        & > tbody > tr > td:last-child {
            border-top: solid 0.2rem white;
        }

        & .region, &.city {
            border-top: solid 0.2rem white;
            border-bottom: solid 0.2rem white;
        }
    }
}

.dropdown-location, .form-layer.on .dropdown-location {
  width: 100%;
  margin-top: -0.9rem;

  &__icon {
    display: block;
  }
}
