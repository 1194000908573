//------------------------------------
// #STAR RATING
//------------------------------------

/*!
 * Rate it
 */

 .rateit {
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;

  .rateit-range {
    background: url( "#{$url-img}stars.svg" ) 0 0;
    display: inline-block;
    height: rem( 13 );
    outline: none;
    position: relative;

    * {
      display: block;
    }
  }

  .rateit-hover,
  .rateit-selected {
    height: rem( 13 );
    position: absolute;
    left: 0;
  }

  .rateit-hover-rtl,
  .rateit-selected-rtl {
    left: auto;
    right: 0;
  }

  .rateit-hover {
    background: url( "#{$url-img}stars.svg" ) 0 rem( -13 );
  }
  .rateit-hover-rtl {
    background-position: 100% rem( -13 );
  }

  .rateit-selected {
    background: url( "#{$url-img}stars.svg" ) 0 rem( -13 );
  }

  .rateit-selected-rtl {
      background-position: 100% rem( -13 );
  }

  .rateit-preset {
      background: url( "#{$url-img}stars.svg" ) 0 rem( -13 );
  }

  .rateit-preset-rtl {
      background-position: 100% rem( -13 );
  }

  button.rateit-reset {
    background: url( "#{$url-img}delete.gif" ) 0 0;
    border: none;
    display: inline-block;
    float: left;
    height: rem( 16 );
    outline: none;
    padding: 0;
    width: rem( 16 );

    &:hover,
    &:focus {
      background-position: 0 rem( -16 );
    }
  }

  // Big version
  &.big {
    .rateit-range,
    .rateit-hover,
    .rateit-selected,
    .rateit-preset {
      background-size: auto rem( 46 );
    }

    .rateit-hover {
      background-position: 0 rem( -23 );
    }
    .rateit-hover-rtl {
      background-position: 100% rem( -23 );
    }

    .rateit-selected {
      background-position: 0 rem( -23 );
    }

    .rateit-selected-rtl {
        background-position: 100% rem( -23 );
    }

    .rateit-preset {
        background-position: 0 rem( -23 );
    }

    .rateit-preset-rtl {
        background-position: 100% rem( -23 );
    }
  }

  &.light {
    .rateit-range,
    .rateit-hover,
    .rateit-selected,
    .rateit-preset {
      background-image: url( "#{$url-img}stars-light.svg" );
    }
  }
}
