//------------------------------------
// #FORMS
//------------------------------------

label {
  display: block;

  small {
    font-size: rem( 12 );
    font-style: italic;
  }
}

input[type="text"],
input[type="tel"],
input[type="url"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 0;
  border-image: none;
  box-shadow: none;
  box-sizing: border-box;
  color: $text-color;
  font-family: $font-family;
  margin: 0;
  outline: none;
  padding: 0 rem( 10 );
  width: 100%;
}

input[type="text"],
input[type="tel"],
input[type="url"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"] {
  height: rem( 35 );
}

.input {
  background-color: $white;
  display: inline-block;
  position: relative;

  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  textarea {
    background-color: transparent;
  }
}

textarea {
  min-height: rem( 100 );
  padding: rem( 10 );
  resize: vertical;
}

label.placeholder,
span.placeholder {
  color: $text-color;
  cursor: text;
  font-family: $font-family;
  line-height: rem( 35 );
  padding-left: rem( 10 );
  text-transform: none;

  .js & {
    position: absolute;
    z-index: 0;
  }

  + input,
  + textarea {
    position: relative;
  }
}


// Validation
// input:invalid {
//   transition: none;
//   color: $color-alert;
// }


// Form
.form {
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  textarea {
    background: $white;
    border-color: $white;
  }

  &.light {
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    textarea {
      background: $grey-light3;
      border-color: $grey-light3;
    }
    .select {
      background-color: $grey-light3;
    }
  }


  .line {
    @include baseline;
    @include clearfix;

    &.error{

      input[type="text"],
      input[type="tel"],
      input[type="url"],
      input[type="number"],
      input[type="email"],
      input[type="password"],
      input[type="search"],
      textarea,
      .select {
        border-color: $red;
        color: $red;
      }

      .checkbox {
        label {
          &:before {
            border-color: $red;
          }
        }
      }
    }

    &.inline {
      label,
      .fake-label,
      .radio-field,
      .field {
        display: inline-block;
      }
      .field {
        padding-left: rem( 20 );
        vertical-align: top;
      }
    }

    .line-link {
      display: block;
      padding-top: rem( 5 );
      text-align: right;
    }
    .link {
      font-size: rem( 12 );
    }
  }

  .select-block {
    &.error{

      .select {
        border-color: $red;
        color: $red;
      }
    }
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;

    &.checkout-form {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .error-msg {
    clear: both;
    color: $red;
    display: none;
    padding-top: rem( 5 );
  }

  &.error .error-msg {
    display: block;
  }

  .radio-field {
    @include clearfix;
  }

  .custom-radio {
    float: left;
    margin-bottom: 0;
    height: rem( 37 );
    width: 48%;

    & + .custom-radio {
      margin-left: 4%;
    }

    input {
      @include hide;

      &:checked + label:after {
        background-color: $theme-secondary-grey;
      }
    }

    label {
      background-color: $white;
      font-weight: normal;
      padding: rem( 10 ) rem( 10 ) rem( 10 ) rem( 13 );
      position: relative;

      &:after {
        background-color: $white;
        border: 1px solid $black;
        border-radius: 50%;
        content: "";
        height: rem( 12 );
        pointer-events: none;
        position: absolute;
        right: rem( 10 );
        top: 50%;
        transform: translateY( -50% );
        transition: background-color $transition;
        width: rem( 12 );
      }
    }

    &.simple {
      display: inline-block;
      float: none;
      width: auto;

      label {
        background-color: transparent;
        padding: 0 rem( 40 ) 0 rem( 30 );
      }
      [class*="theme"] & {
        input:checked + label:after {
          background-color: $white;
        }
        label:after {
          background-color: transparent;
          border: 1px solid $white;
        }
      }
    }
  }

  .custom-checkbox {
    margin-bottom: 0;

    input {
      @include hide;

      &:checked ~ label:before {
        background-color: $theme-secondary-grey;
        border-color: $theme-secondary-grey;
      }
    }

    label {
      padding: 0 0 0 rem( 34 );
      position: relative;

      &:before {
        border: 1px solid $theme-secondary-grey;
        content: "";
        height: rem( 15 );
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: background-color $transition;
        width: rem( 15 );
      }
    }
  }

  [class*="theme"] {
    .custom-checkbox {
      color: $white;

      label {
        &:before {
          border-color: $white;
        }
      }
    }
  }

  .block-checkbox {
    border: 1px solid rgba( $theme3, 0.3 );
    border-radius: rem( 8 );
    margin: 0 0 rem( 5 );
    white-space: nowrap;

    .icon-star {
      fill: $theme4;
      height: rem( 14 );
      width: rem( 15 );
    }
    [class*="icon-check"] {
      fill: $white;
      height: rem( 13 );
      margin-top: rem( -7 );
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: rem( 7 );
      top: 50%;
      transition: opacity $transition;
      width: rem( 14 );
      z-index: 1;
    }

    input {
      @include hide;

      &:checked + label, &:checked + input[type=hidden] + label {
        [class*="icon-check"] {
          opacity: 1;
        }
        &:after {
          background-color: $theme3;
        }
      }
    }

    &:after {
      @include valign;
      height: rem( 30 );
    }

    label {
      box-sizing: border-box;
      color: $theme-dark2;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      font-family: $font-roboto-condensed-regular;
      font-size: rem( 13 );
      // font-weight: bold;
      line-height: ( 14 / 13 );
      // line-height: ( 20 / 13 );
      // padding: rem( 2 ) rem( 2 ) rem( 2 ) rem( 20 );
      padding: rem( 1 ) rem( 32 ) rem( 1 ) rem( 20 );
      position: relative;
      text-transform: uppercase;
      width: 100%;
      white-space: normal;

      .txt {
        display: inline-block;
        width: rem( 100 );
      }
      .val {
        color: $theme;
        display: inline-block;
        font-family: $font-roboto;
        text-align: center;
        width: rem( 75 );
      }

      &:after {
        background: rgba( $theme3, 0.3 );
        border-radius: 6px;
        content: "";
        height: rem( 26 );
        margin-top: rem( -13 );
        pointer-events: none;
        position: absolute;
        right: rem( 2 );
        top: 50%;
        transition: background-color $transition;
        width: rem( 26 );
      }
    }
  }

  // Checkbox
  .checkbox {
    label {
      display: inline-block;
      font-size: rem( 15 );
      margin: 0;
      padding: 0 0 0 rem( 26 );
      position: relative;
      text-transform: none;

      &:before {
        background: #bfc7c9;
        border: 1px solid #bfc7c9;
        border-radius: rem( 3 );
        content: "";
        height: rem( 19 );
        left: 0;
        position: absolute;
        top: 0;
        transition: background $transition;
        width: rem( 19 );
      }

      [class*="icon-check"] {
        fill: $theme3;
        height: rem( 10 );
        left: rem( 5 );
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: rem( 5 );
        transition: opacity $transition;
        width: rem( 10 );
        z-index: 1;
      }
    }
    input {
      @include hide;

      &:checked, &:checked + input[type=hidden] {
        + label {
          &:before {
            background: $white;
          }

          [class*="icon-check"] {
            opacity: 1;
          }
        }
      }
    }
  }

  .field-icon {
    position: relative;

    label {
      background: $theme-light;
      height: rem( 40 );
      left: 0;
      position: absolute;
      top: 0;
      width: rem( 50 );
      z-index: 1;
    }
    [class*="icon"]{
      fill: $white;
      height: rem( 26 );
      margin: rem( 7 ) 0 0;
      width: rem( 50 );
    }

    input{
      padding-left: rem( 65 );

      &[readonly]{
        background: #bfc7c9;
        border-color: #bfc7c9;
      }
    }
  }

}
.checkout-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: rem( 370 );
  text-align: center;

  .warning {
    display: none;
  }
  .check {
    display: none;
  }

  label,
  .fake-label {
    text-align: left;
  }

  .fake-label {
    clear: both;
    display: block;
  }

  .select {
    text-align: left;
    width: 100%;
  }

  .cta {
    margin-top: rem( 10 );
    min-width: rem( 160 );
  }
  legend {
    color: $theme-legend;
    font-size: rem( 18 );
    margin: 0 0 rem( 30 );

    [class*="theme"] & {
      color: $white;
    }
  }
}

.address-form {
  .custom-radio {

    & + .custom-radio {
      float: right
    }
  }
}


// Select list
.select-list {
  background-color: $white;
  box-sizing: border-box;
  color: $text-color;
  display: inline-block;
  height: rem( 35 );
  list-style-type: none;
  line-height: rem( 35 );
  margin: 0;
  padding: 0 rem( 28 ) 0 rem( 10 );
  overflow: hidden;
  vertical-align: middle;

  li {

  }
  a {
    padding: 0;
    text-decoration: none;
  }
  a:not(.selected) {
    display: none;
  }
}

// Custom selects
.select {
  background-color: $white;
  box-sizing: border-box;
  color: $text-color;
  display: inline-block;
  line-height: rem( 33 );
  vertical-align: middle;

  span {
    display: none;
  }
  select {
    height: rem( 33 );
  }
  .js & {
    border: 1px solid $white;
    overflow: hidden;
    position: relative;

    span {
      box-sizing: border-box;
      display: block;
      overflow: hidden;
      padding: 0 rem( 28 ) 0 rem( 10 );
      position: relative;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      width: 100%;

      &:after {
        content: "";
        @include triangle( 9px, #2c444b, down );
        right: rem( 9 );
        margin-top: rem( -2 );
        position: absolute;
        top: 50%
      }
    }

    select {
      appearance: none;
      border-width: 0;
      bottom: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
}

// Input File
.file {
  background: $theme-legend;
  border: 1px solid $theme-legend;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: rem( 17 );
  overflow: hidden;
  padding: rem( 5 ) rem( 18 ) rem( 5 ) rem( 38 );
  position: relative;
  transition: $transition;
  z-index: 2;

  [class*="icon"]{
    fill: $white;
    height: rem( 16 );
    left: rem( 15 );
    margin: rem( -8 ) 0 0 0;
    position: absolute;
    top: 50%;
    transition: $transition;
    width: rem( 18 )
  }

  input[type="file"] {
    cursor: pointer;
    height: 100%;
    right: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 200%;
    z-index: 1;
  }

  &:hover {
    background: $white;
    border-color: $theme-legend;
    color: $theme-legend;

    [class*="icon"]{
      fill: $theme-legend;
    }
  }
}


// User Form
.user-form {

  .select {
    width: 100%;

    &.small {
      width: rem( 89 );

      + .small {
        margin-left: rem( 16 );
      }
    }
  }
  .custom-radio {
    width: rem( 142 );

    + .custom-radio {
      margin-left: rem( 16 );
    }
  }

  .button {
    clear: both;
    min-width: rem( 180 );
    padding-top: rem( 20 );
    text-align: center;

    .section & {
      padding-top: rem( 20 );
    }

    [class*="cta"] {
      min-width: rem( 180 );
    }
  }

  .mandatory-text {
    font-size: rem( 11 );
  }
}
