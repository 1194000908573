//------------------------------------
// #BLOCKS
//------------------------------------


// Agenda
[class*="block-agenda"] {

  [class*="tt-raw"]{
    text-align: left;
  }
}

// Card
.block-card {
  max-width: rem( 440 );

  &.agency {
    margin: 0 auto rem( 16 );
  }

  .pict{
    .sticker {
      display: block;
      left: rem( -8 );
      position: absolute;
      top: rem( 20 );

      img {
        box-shadow: 2px 0 4px rgba( $black, 0.4);
        display: block;
      }

      &:after {
        content: "";
        @include triangle( 8px, $black, up-right );
        left: 0;
        bottom: rem( -8 );
        position: absolute;
      }
    }
  }

  .quote {
    padding: rem( 20 ) rem( 16 ) rem( 0 );
  }
}


// Agency
.block-agency {

  .about {
    @include baseline;
  }
}


// Filters
.block-filters {
  .filters {
    margin: 0 rem( -5 );
    @include clearfix;
  }
  .field {
    box-sizing: border-box;
    float: left;
    padding: rem( 0 ) rem( 5 );
    width: pct( 1/3 );
  }

  .filter-switch {
    line-height: ( 18 / 16 );

    > span,
    > a {
      padding-bottom: rem( 3 );

      span {
        display: inline;
      }
      .separator {
        display: inline;
      }
    }
  }
}


// Island main blocks
[class*="block-island"] {
  .desc {
    font-size: rem( 15 );
  }
}


// Island city block
.block-island-city {

  .cols {
    @include clearfix();
  }

  .col-one-third,
  .col-two-third {
    box-sizing: border-box;
    float: left;
  }

  .col-one-third {
    width: 34%;
  }

  .col-two-third {
    width: 66%;
  }

  .infos {
    border-spacing: rem( 12 );

    .figure {
      font-size: rem( 25 );
      padding: rem( 20 ) rem( 13 ) rem( 8 );

      span {
        font-size: rem( 13 );
        margin-bottom: rem( 8 );
      }
    }

    .inhabitants {
      padding-top: rem( 3 );
    }

    button {
      &[aria-expanded="true"] {
        &:after {
          bottom: rem( -12 );
          height: rem( 12 );
        }
      }
    }
  }
}

// contact block
.block-contact {
  > .wrap {
    width: pct( 305 / 320 );
    @include clearfix();
  }

  article {
    float: left;
    width: 49%;

    &:nth-child( 2n+1 ) {
      clear: left;
      margin-right: 2%;
    }
  }
}


// Agency
.block-agency {
  .block-quote {
    margin-bottom: rem( 20 );
  }
}


// Check list
.block-check {
  padding-left: rem( 30 );

  ul {
    padding-left: 0;
  }
}

// Event article
.block-event {
  padding-bottom: rem( 10 );
}


// Block sorting
.block-sorting {
  padding-bottom: 0;
  width: 1280px;
  margin: auto;
  .form {
    p {
      margin: 0 0 rem( 12 );
    }
    label,
    .label {
      &:not(.check-label){
        display: inline-block;
        margin: 0 rem( 10 ) 0 0;
        vertical-align: middle;
      }
    }
    .input {
      width: rem( 270 );
    }
    .check-bt {
      display: inline-block;
      vertical-align: middle;

      + .check-bt {
        margin-left: rem( 10 );
      }
      label {
        margin: 0;
      }
    }
  }
}


// Tour operator
.tour-operator-list {
  font-size: 0;
  letter-spacing: 0;
  margin-left: rem( -13 );
  margin-right: rem( -13 );
  margin-top: rem( 0 );

  @include clearfix;

  .tour-operator {
    display: inline-block;
    font-size: rem( $fz );
    letter-spacing: normal;
    margin-top: rem( 10 );
    padding-left: rem( 13 );
    padding-right: rem( 13 );
    width: 50%;
    vertical-align: top;
  }

}

.tour-operator-highlight {
  float: left;
  padding-right: rem( 5 );
  width: 50%;

  + .tour-operator-highlight {
    padding-left: rem( 5 );
    padding-right: 0;
  }

  .inner {
    min-height: rem( 220 );
    padding-bottom: rem( 26 );
    padding-top: rem( 26 );
  }
}


// Booking
.block-no-booking {
  .pict {
    width: rem( 73 );
  }
  .text {
    padding-left: rem( 86 );

    &:before {
      height: rem( 73 );
    }
  }
}

// User
.block-user {
  padding: rem( 50 ) rem( 20 ) rem( 50 );
  text-align: center;

  .user-info {
    display: inline-block;
    margin-right: rem( 20 );
    vertical-align: middle;
    width: rem( 195 );
  }
  .name {
    margin-bottom: 0;
  }
  ul {
    display: inline-block;
    margin-left: rem( 20 );
    text-align: left;
    vertical-align: middle;
  }
}

//
.block-booking {
  .address {
    float: left;
    width: rem( 200 );
  }
  .info {
    border-left: 1px solid $grey-light;
    float: right;
    padding: 0 0 0 rem( 5 );
    text-align: center;
    width: rem( 160 );

    &:before {
      display: none;
    }

    [class*="date-time"],
    .time-hour {
      margin: 0 auto;
    }
  }
}
