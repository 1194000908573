//------------------------------------
// #SELECTION
//------------------------------------



// Block
.selection-block {
  background: $white;
  box-shadow: 0 rem( 2 ) rem( 3 ) rgba( $black, 0.1 );
  margin: rem( 10 ) 0 rem( 20 );
  padding: rem( 10 ) rem( 10 ) 0;
  position: relative;

  // Picture
  .picture {
    margin: 0 0 rem( 10 );
    position: relative;

    .label {
      left: rem( -10 );
      position: absolute;
      top: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  // Delete button
  .delete {
    position: absolute;
    right: 0;
    top: 0;

    button {
      background: $white;
      border: 0 none;
      height: rem( 40 );
      padding: rem( 4 ) rem( 2 ) 0 0;
      width: rem( 35 );

      [class*="icon-"]{
        display: block;
        fill: $theme3;
        height: rem( 22 );
        margin: 0 auto;
        width: rem( 18 );
      }
      span {
        @include hide;
      }
    }
  }

  // Place details
  .place {
    font-size: rem( 14 );
    padding: 0 0 rem( 10 ) rem( 30 );
    position: relative;

    [class*="icon-place"] {
      fill: $theme3;
      height: rem( 24 );
      left: rem( 2 );
      position: absolute;
      top: rem( 2 );
      width: rem( 18 );
    }

    p {
      font-weight: 500;
      margin: 0 0 rem( 6 );
    }

    address {
      font-size: rem( 13 );
      font-style: normal;
      margin: 0 0 rem( 10 );
      text-transform: uppercase;

      strong {
        font-weight: normal;
        font-size: rem( 17 );
      }
    }
  }

  // Timing details
  .timing {
    border-top: 1px solid rgba( $theme3, 0.4 );
    color: $theme3;
    padding: rem( 10 ) 0 rem( 10 );
    text-align: center;

    p {
      margin: 0 0 rem( 6 );
    }

    .time {
      display: inline-block;
      margin: 0 rem( 8 ) rem( 10 );
      vertical-align: top;

      p {
        font-size: rem( 15 );
        font-weight: 500;
        margin: 0;
      }
    }
  }

  // State
  .state {
    border-top: 1px solid rgba( $theme3, 0.4 );
    font-size: rem( 16 );
    font-weight: 500;
    padding: rem( 20 ) 0;
    text-align: center;

    .inprogress {
      color: $color-confirm;

      [class*="icon-"] {
        fill: $color-confirm;
      }
    }
    .validated {
      color: $theme2;

      [class*="icon-"] {
        fill: $theme2;
      }
    }
    .canceled {
      color: $red;

      [class*="icon-"] {
        fill: $red;
      }
    }
    [class*="icon-"] {
      display: inline-block;
      height: rem( 13 );
      margin: 0 rem( 5 ) 0 0;
      vertical-align: middle;
      width: rem( 13 );
    }
    .icon-close {
      height: rem( 11 );
    }
  }

  // Total
  .total {
    border-top: 1px solid rgba( $theme3, 0.4 );
    color: $theme3;
    font-size: rem( 15 );
    padding: rem( 12 ) 0 rem( 8 );

    @include clearfix;

    p {
      margin: 0 0 rem( 6 );
    }
    > p {
      float: left;
      font-weight: 500;
    }

    .price {
      float: right;
      text-align: right;

      strong {
        font-size: rem( 20 );
        font-weight: 500;
        line-height: 1;
      }
    }
  }

  // Payment details
  .payment {
    border-top: 1px solid rgba( $theme3, 0.4 );
    font-size: rem( 12 );
    padding: rem( 15 ) 0 rem( 10 );

    p {
      margin: 0 0 rem( 5 );
    }
  }

  .contact {
    background: $grey-light3;
    margin: 0 0 rem( 10 );
    padding: rem( 20 ) rem( 20 ) rem( 10 );

    p:last-child {
      margin-bottom: 0;
    }

    .js & {
      display: none;

      &.on {
        display: block;
      }
    }

    .user {
      &:after {
        background: $theme3;
        content: "";
        display: block;
        height: rem( 2 );
        margin: rem( 5 ) 0 0;
        width: rem( 30 );
      }
      b {
        font-size: rem( 15 );
      }
    }
  }

    .contact-user {
      padding: 0 0 rem( 10 );
    }

  // Options
  .options {
    border-top: 1px solid rgba( $theme3, 0.4 );
    padding: rem( 10 ) 0;

    [aria-expanded]{
      background: $grey-light2;
      border: 0 none;
      box-sizing: border-box;
      font-weight: 500;
      height: rem( 30 );
      padding: rem( 6 ) rem( 40 ) rem( 6 ) rem( 20 );
      position: relative;
      text-align: left;
      width: 100%;

      &:after {
        content: "";
        @include triangle( rem( 10 ), $theme3, right );
        margin: rem( -5 ) 0 0;
        position: absolute;
        right: rem( 18 );
        top: 50%;
        transition: $transition;
      }
    }
    [aria-expanded="true"]{
      &:after {
        transform: rotate( 90deg );
      }
    }
    .options-details {
      background: $grey-light2;
      font-size: rem( 12 );

      .inner {
        .js & {
          display: none;

          &.on {
            display: block;
          }
        }
      }

      ul {
        list-style-type: none;
        padding: rem( 10 ) rem( 15 );
        margin: 0;

        li {
          padding-left: rem( 7 );
          position: relative;

          &:before {
            content: "•";
            left: 0;
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }
}


// Actions
.selection-actions {

  .tt {
    color: $theme2;
    font-size: rem( 16 );
    font-weight: 500;
    margin: 0 0 rem( 16 );
    text-align: center;
  }

  .address {
    border-bottom: 1px solid rgba( $theme3, 0.4 );
    font-weight: 500;
    margin: 0 0 rem( 20 );
    padding: 0 rem( 10 ) rem( 4 );

    .link {
      font-weight: normal;
    }
  }

  .legals {
    padding: 0 rem( 10 ) rem( 10 );
  }

  .checkbox {
    margin: 0 0 rem( 20 );

    label {
      padding-left: rem( 28 );
      font-size: rem( 13 );

      &:before {
        background: $white;
        border: 1px solid $theme3;
        border-radius: rem( 2 );
        height: rem( 12 );
        top: rem( 2 );
        width: rem( 12 );
      }

      [class*="icon-check"] {
        height: rem( 8 );
        left: rem( 3 );
        top: rem( 5 );
        width: rem( 8 );
      }

      a {
        font-style: italic;
      }
    }
  }
}


// Total price
.selection-total {
  padding: 0 0 rem( 40 );

  .sub-total {
    background: $white;
    border: 1px solid rgba( $theme3, 0.4 );
    font-weight: 500;
    margin: 0 0 rem( 10 );
    padding: rem( 10 );

    @include clearfix;

    p {
      margin: 0;
    }

    .txt {
      float: left;
      font-size: rem( 17 );

      small {
        font-size: rem( 13 );
      }
    }
    .price {
      float: right;
      font-size: rem( 19 );
    }
  }

  .total {
    background: $theme3;
    color: $white;
    font-weight: 500;
    margin: 0 0 rem( 10 );
    padding: rem( 10 );

    @include clearfix;

    p {
      margin: 0 0 rem( 16 );
    }

    .txt {
      float: left;
      font-size: rem( 20 );

      small {
        font-size: rem( 13 );
      }
    }
    .price {
      float: right;
      font-size: rem( 22 );
    }
    .button {
      clear: both;
      padding: 0 0 rem( 10 );
      text-align: center;
    }
  }

  .disabled & {
    .cta {
      cursor: default;
      opacity: 0.5;

      &:hover {
        background: $cta;
        border: 1px solid $cta;
        color: $white;
      }
    }
  }
}

// totaldollar
.selection-total .totaldollar{
	background-color: #486f7a;
}
.selection-total .totaldollar .notabene{
	clear:left;
	font-weight: normal;
	padding: 12px 0;
}


// Confirmation
.selection-confirmation {
  background: $grey-light2 url( "#{$url-img}desktop/2x/bg-confirmation.png" ) no-repeat rem( 30 ) rem( 26 );
  background-size: rem( 27 ) auto;
  border: 1px solid rgba( $theme3, 0.4 );
  box-sizing: border-box;
  font-size: rem( 14 );
  margin: rem( 30 ) auto rem( 20 );
  max-width: rem( 570 );
  padding: rem( 26 ) rem( 20 ) rem( 26 ) rem( 76 );
  position: relative;

  &:before {
    content: "";
    @include triangle( rem( 18 ), rgba( $theme3, 0.4 ), down );
    bottom: rem( -9 );
    left: 50%;
    margin: 0 0 0 rem( -9 );
    position: absolute;
  }

  &:after {
    content: "";
    @include triangle( rem( 18 ), $grey-light2, down );
    bottom: rem( -8 );
    left: 50%;
    margin: 0 0 0 rem( -9 );
    position: absolute;
  }

  p {
    margin: 0;
  }

  .tt {
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 18 );
    font-weight: normal;
    margin: 0;
  }
}


// Info
.selection-info {
  margin: rem( 40 ) 0 rem( 40 );
  padding: 0 rem( 10 );
}
