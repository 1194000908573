//------------------------------------
// #PUSHES
//------------------------------------


// Feature push
[class*="feature-push"] {

  [class*="col-"] {
    .intro {
      padding-right: rem( 40 );

      img {
        display: block;
        margin-bottom: rem( 26 );
      }
    }
  }
  .revert [class*="col-"] {
    .intro {
      padding-left: rem( 40 );
      padding-right: 0;
    }
  }
  .col-one-quarter {
    width: 25%;
  }
  .col-three-quarter {
    width: 75%;
  }

  .cols-centered {
    .col-one-quarter {
      flex-basis: auto;
      width: 25%;
    }
    .col-three-quarter {
      flex-basis: auto;
      width: 75%;
    }
  }
}

// Map push
.push-map {
  padding: 0;

  .map {
    display: table;
    width: 100%;
  }
  .text,
  .picture {
    box-sizing: border-box;
    display: table-cell;
    vertical-align: middle;
  }
  .text {
    padding-right: rem( 20 );
  }
  .button {
    text-align: left;
  }
  .cta-label {
    font-size: rem( 11 );

    span {
      padding: rem( 4 ) rem( 8 ) rem( 4 ) rem( 8 );
      min-width: rem( 65 );

      &:after {
        background-size: rem( 65 ) auto;
        bottom: rem( -5 );
        height: rem( 5 );
        right: rem( 5 );
        width: rem( 65 );
      }
    }

    &.revert,
    .revert & {
      span {

        &:after {
          background-position: 0 rem( -6 );
          left: rem( 5 );
        }
      }
    }
  }
}

// Hero image block
.push-picture {
  display: block;
  height: 0;
  padding: pct( 335 / 1000 ) 0 0;
  overflow: hidden;
  position: relative;

  .pict-wrapper {
    height: 100%;
    position: static;
  }

  img {
    height: 100%;
    left: 50%;
    max-width: none;
    top: 0;
    position: absolute;
    transform: translateX( -50% );
  }
}


// Tour push
.push-tour {
  margin-bottom: rem( 30 );
  max-width: none;
  padding-top: rem( 32 );
  text-align: right;

  &:after {
    @include valign;
    height: rem( 331 );
  }

  .date-sticker {
    left: rem( 60 );
    z-index: 2;
  }

  .picture {
    left: 0;
    position: absolute;
    top: rem( 32 );
    width: rem( 540 );
    z-index: 1;
  }
  .text {
    box-sizing: border-box;
    display: inline-block;
    margin: 0 0 0 auto;
    padding: rem( 20 ) rem( 20 ) rem( 4 );
    position: relative;
    text-align: left;
    top: 50%;
    vertical-align: middle;
    width: rem( 480 );
    z-index: 2;
  }
}


// Hero push
.push-hero {
  color: $text-color;
  overflow: hidden;
  transition: $transition;

  .tt-hero {
    color: $theme;
    transition: $transition;
  }


  .no-touchevents &:hover {
    color: $white;

    .container:before {
      opacity: 1;
    }

    .tt-hero {
      color: $white;
    }
  }
  .container {
    text-align: left;
    position: relative;
    z-index: 3;

    &:before {
      background: rgba( $theme-dark4, 0.8 );
      content: "";
      height: 100%;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity $transition;
      transform: translateX( -51% );
      width: 102vw;
      z-index: 1;
    }

    &:after {
      @include valign;
      height: rem( 300 );
    }
  }
  .block {
    display: inline-block;
    margin: 0 0 0 pct( 100 / 1200 );
    text-align: center;
    width: pct( 420 / 1200 );
    vertical-align: middle;
    position: relative;
    z-index: 3;
  }

  &.condensed {
    .container {

      &:after {
        height: rem( 190 );
      }
    }
    .block {
      margin-left: 0;
      text-align: left;
      width: pct( 520 / 1200 );
    }
  }
}


// Google map
.push-google-map {
  width: 980px;
  height: rem( 425 );
  margin: auto;
}


// Certification modules
.push-certification-modules {
  text-align: center;

  [class*="tt-block"] {
    &:after {
      display: none;
    }
  }

  .modules {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    article {
      box-sizing: border-box;
      margin-bottom: rem( 26 );
      margin-top: 0;
      padding: 0 rem( 10 );
      width: 50%;

      &:nth-child( n + 3 ) {
        margin: rem( 26 ) 0;
      }
    }

    .icon {
      height: rem( 150 );
      width: rem( 150 );
    }

    #mod-elearning {
      order: 3;
    }

    .mod-seminaire {
      order: 2;
    }

    #mod-seminaire {
      order: 4;
    }

    .mod-eductour {
      order: 5;
    }

    #mod-eductour {
      order: 7;
    }

    .mod-challenge {
      order: 6;
    }
  }

  .no-flexbox & {
    .modules {
      article {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .mod-elearning .icon img {
    width: rem( 98 );
  }

  .mod-seminaire .icon img {
    width: rem( 66 );
  }

  .mod-eductour .icon img,
  .mod-challenge .icon img {
    width: rem( 73 );
  }
}


// Push Facebook
.push-facebook {

  .tt-block {
    margin: 0;
  }

  .inner {
    padding: rem( 50 ) 0 rem( 80 );
  }

  .pictures{
    li{
      &:nth-child(1) {
        bottom: auto;
        height: rem( 166 );
        margin: 0 rem( 138 ) 0 0;
        top: rem( 50 );
      }
      &:nth-child(2) {
        bottom: auto;
        display: block;
        height: rem( 122 );
        right: 50%;
        top: rem( 154 );
        margin: 0 rem( 320 ) 0 0;
      }
      &:nth-child(3) {
        bottom: auto;
        display: block;
        height: rem( 101 );
        margin: 0 rem( 295 ) 0 0;
        right: 50%;
        top: 0;
      }
      &:nth-child(4) {
        bottom: auto;
        height: rem( 166 );
        margin: 0 0 0 rem( 138 );
        top: rem( 50 );
        transform: none;
      }
      &:nth-child(5) {
        bottom: auto;
        height: rem( 122 );
        top: rem( 154 );
        margin: 0 0 0 rem( 320 );
      }
      &:nth-child(6) {
        bottom: auto;
        height: rem( 101 );
        margin: 0 0 0 rem( 295 );
        top: 0;
      }
    }
  }
}

