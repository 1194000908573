//------------------------------------
// #Additional
//------------------------------------


//Custom Css version 1.5

//For Accommodation_archive page

.reserve-type-part .block-checkbox label {
    padding: rem( 7 ) rem( 32 ) rem( 7 ) rem( 4 );
}

    .reserve-type-part .block-checkbox label:after {
        border-radius: 15px;
        border: rgba(44,68,75,.3) 1px solid;
        background-color: white;
    }

    .reserve-type-part .block-checkbox label.clicked:after {
        /*background-color: rgba(44,68,75,.3);*/
    }

.reserve-type-part .block-checkbox:after {
    vertical-align: -75%;
}

.reserve-type-part .block-checkbox [class*="icon-check"] {
    width: 1rem;
    height: 1rem;
    right: 0.5rem;
}

.reserve-type-part .block-checkbox:first-of-type label:before {
    background-image: url('../img/blue_icon.png');
    background-size: 25px 25px;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    vertical-align: -50%;
    margin-right: 5px;
    //position:absolute;
}

.reserve-type-part .block-checkbox:nth-of-type(2) label:before {
    background-image: url('../img/orange_icon.png');
    background-size: 25px 25px;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    vertical-align: -50%;
    margin-right: 5px;
    //position:absolute;
}


.date-part .block-checkbox {
    opacity: 0.5;
}

    .date-part .block-checkbox label:after {
        opacity: 0;
    }

    .date-part .block-checkbox input {
        left: 10px;
    }


    .block-offer .text p {
        width: 100%;
        float: left;
    }

.block-datepart input {
    border: 1px solid rgba(44, 68, 75, 0.3) !important;
    border-radius: 0.5rem;
    margin-bottom:5px;
}

.block-offer {

	.desc {
		position: relative;
	}

	.picture{
		.discount{
			z-index:1;
		}
	}
}


.icon_img {
    float:right;
}

.reserve-type-part {
	border-bottom:none !important;
}

.reserve-type-part .block-checkbox input:checked + label:after, .reserve-type-part .block-checkbox input:checked + input[type=hidden] + label:after{
background: white;
}


//For Accommodation Single Page

    #registerContactForm .line{
        width:100%;
    }



    .registercontactsubmit {
        background: #2c444b;
        color: #fff;
        font-family: "Advent Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.25rem;
        line-height: 1;
        border: 1px solid #2c444b;
        position: relative;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        width: 100%;
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
    }

    #registerContactForm .buttons > div{
        width:100% !important;
    }

    #registerContactForm .line p.custom-checkbox label {
        font-style: italic;
    }

    #registerContactForm .line p.custom-checkbox label:before {
        border-color: white !important;
        border-width: 1.5px !important;
    }

.hotel_external_cta {
  background: #2c444b;
  color: #fff;
  font-family: "Advent Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid #2c444b;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.partnerContact .buttons > div {
  width: 100%;
}

//Banner Resizing

@media screen and (max-width: 1250px) and (min-width: 1000px){

#sub-nav-2 .col{
	margin: 0 0.45rem;
}

#sub-nav-2 .col:last-of-type {
    margin: 0 0.45rem;
}
}


/* For Search Box Overlay in Activity, Accommodation and Restaurant*/

#form-filters-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 7;
}

.form-filters {
  z-index: 8 !important;
}


/*Encyclopedia Blade*/
.block-island-encyclopedia .bg-wrapper{
  position:relative !important;
  margin: 0 auto !important;
}

/*Calendar Z-index*/
.pika-single.is-bound{
  z-index:10;
}
