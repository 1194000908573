//------------------------------------
// #PUSHES
//------------------------------------


// Booking push
.feature-push-booking {
  &.big {
    padding: rem( 60 ) 0 rem( 50 );
  }
  &.middle {
    padding-bottom: rem( 30 );
  }
}
.push-booking {
  .inner {

    &:before {
      height: rem( 265 );
    }
  }

  [class*="tt-header"] {
    font-size: rem( 28 );
  }

  .icon-flight {
    height: rem( 100 );
    width: rem( 120 );
  }

  .icon-accommodation {
    height: rem( 100 );
  }

  .icon-car {
    height: rem( 100 );
    width: rem( 95 );
  }
}
