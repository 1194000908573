//------------------------------------
// #ISLAND MAIN PAGE
//------------------------------------

.island-main {

  .header-main {
    .link-alt-cta {
      display: none;
    }
  }

}
