//------------------------------------
// #HEADINGS
//------------------------------------


.tt-icon {

  .pict {
    display: inline-block;
    margin: rem( 29 ) 0 0;
    vertical-align: top;
  }
  .title {
    font-size: rem( 19 );
    height: rem( 130 );
    width: rem( 130 );

    strong {
      font-size: rem( 24 );
    }
  }
}

[class*="tt-header"] {
  margin-bottom: rem( 36 );
}

[class*="tt-block"] {

  &.small {
    font-size: rem( 25 );
  }
}
