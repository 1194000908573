//------------------------------------
// #OFFERS PAGES
//------------------------------------


// Search Form
.offer-search-form {

  // Filters form
  .form-filters {
    padding: rem( 10 ) rem( 12 );

    fieldset {
      box-sizing: border-box;
      border: 0 none;
      margin: 0;
      padding: 0;
    }

    .price-part,
    .grades-part {
      float: left;
      padding-right: rem( 12 );
      width: 50%;
    }
    .type-part {
      float: right;
      padding-left: rem( 12 );
      width: 50%;
    }

    .price-part,
    .grades-part,
    .type-part {
      border: none;
    }

    .grades-part {
      &:before {
        background: rgba( $theme3, 0.3 );
        content: "";
        display: block;
        height: rem( 1 );
      }
    }

    .options-part{
      border-top: 1px solid rgba( $theme3, 0.3 );
      clear: both;

      .form-fields {
        columns: 2;
        column-gap: rem( 24 );
      }
    }

    .form-fields {
      display: block;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;

      .js &[id] {
        display: block;
      }
    }

    .legend {
      cursor: auto;
      padding-left: 0;

      .toggle-bt {
        display: none;
      }
    }

    .buttons {
      padding: 0;
    }
  }
}


// Search Results
.offer-list {

  // Content
  .results-content {

    .results-list {
      font-size: 0;
      letter-spacing: 0;
      text-align: center;
      margin: 0 rem( -15 );
      padding: rem( 30 ) 0 rem( 4 );


      > li {
        box-sizing: border-box;
        display: inline-block;
        font-size: rem( $fz );
        letter-spacing: normal;
        max-width: rem( 330 );
        padding: 0 rem( 15 );
        text-align: left;
        vertical-align: top;
        width: 50%;
      }
    }
  }
}


/* Offer Page
-------------------*/

// Heading
.offer-heading {
  position: relative;

  .inner {
    position: relative;
  }

  .cols {
    display: table;
    width: 100%;
  }
  .col {
    box-sizing: border-box;
    display: table-cell;
    padding: 0 rem( 20 ) 0 0;
    vertical-align: top;

    + .col {
      border-left: 1px solid rgba( $theme3, 0.6 );
      padding: 0 rem( 20 ) 0 rem( 20 );
      width: rem( 200 );
    }

    .desc {
      padding-right: 0;
    }
    .rating {
      margin-bottom: rem( 30 );
      position: static;
      text-align: right;
    }
    .option-pict {
      margin-left: auto;
      margin-right: auto;
      max-width: rem( 140 );
    }
  }

  .desc {
    padding-right: rem( 190 );
  }

  .services {
    margin: 0 rem( -5 );
    text-align: left;

    li {
      display: inline-block;
      min-width: rem( 80 );
      padding: 0 rem( 5 );
    }
  }

  .rating {
    position: absolute;
    right: 0;
    top: 0;
  }
}


// Offer desc
.offer-description-block {
  position: relative;

  [data-zoom-slider-target] {
    .zoom-opacity {
      background: rgba( $theme-dark2, 0.7 );
      display: block;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 3;
    }

    .zoom{
      background: $white;
      box-sizing: border-box;
      display: block;
      max-width: rem( 650 );
      opacity: 0;
      padding: rem( 35 );
      position: absolute;
      transform: translateX( -50% );
      width: pct( 300 / 320 );
      z-index: 4;

      img {
        display: block;
        margin-bottom: rem( 10 );
      }
      p {
        font-size: rem( 14 );
        margin: 0;
      }
    }

    .slick-arrow {
      margin: rem( -14 ) 0 0;
      position: absolute;
      top: 50%;


      [class*="icon-"]{
        fill: $theme3;
        height: rem( 28 );
        width: rem( 15 );
      }
    }
    .slick-prev {
      left: rem( -24 );
    }
    .slick-next {
      right: rem( -24 );
    }

    .close {
      background: $theme6;
      border: 0 none;
      display: block;
      height: rem( 35 );
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: rem( 35 );

      [class*="icon"]{
        display: block;
        fill: $white;
        height: rem( 15 );
        margin: 0 auto;
        width: rem( 15 );
      }

      span {
        @include hide;
      }
    }
  }
}

.offer-description {
  padding-bottom: 0;

  .desc-pictures {
    margin-top: rem( 20 );
    max-width: none;
    padding: 0;
    position: relative;
    width: auto;

    &:after {
      bottom: 0;
      content: "";
      height: 50%;
      left: 0;
      position: absolute;
      width: 100%;

      
    }

    .pictures {
      box-sizing: border-box;
      padding: rem( 5 );
      margin: 0 auto;
      max-width: rem( 650 );
      position: relative;
      z-index: 2;
      width: 100%;

      @include clearfix;
    }

    .slide {
      border: 4px solid $white;
      box-sizing: border-box;
      float: left;
      width: 50%;

      &:nth-child(odd){
        clear: left;
      }
      &:nth-child(n+5){
        display: none;
      }
    }

    .button {
      display: block;

      &:focus {
        div {
          opacity: 1;
        }
      }
    }
    .no-touchevents & {
      .slide:hover {
        .button {
          div {
            opacity: 1;
          }
        }
      }
    }
  }
}


// Offer picture
.offer-picture {
  margin: 0 auto rem( 10 ) auto;
  max-width: rem( 650 );

  .text {
    float: left;
    margin: rem( -48 ) 0 rem( 16 );
    padding: rem( 20 ) rem( 20 ) rem( 10 ) rem( 30 );
    position: relative;
    width: rem( 320 );
    z-index: 2;
  }

  &:nth-of-type(even){
    .text {
      float: right;
    }
  }
}

// Offer services
.offer-services {
  padding-top: rem( 20 );
}

// Offer services
.offer-services-equipments {
  .cols {
    display: table;
    margin: 0 0 rem( 16 );
    width: 100%
  }
  .col {
    box-sizing: border-box;
    display: table-cell;
    padding-left: 0;
    padding-right: rem( 40 );
    width: 50%;

    + .col {
      border-left: 1px solid rgba( $white, 0.4 );
      padding-left: rem( 40 );
    }
  }

  ul {
    margin: 0;
  }
}

// Offer schedule
.offer-schedule {
  .schedule {
    display: table;
    width: 100%;
  }

  .table,
  .info {
    box-sizing: border-box;
    display: table-cell;
    vertical-align: middle;
    width: 50%;
  }

  .info {
    padding-bottom: rem( 20 );
  }
}

// Offer votes
.offer-votes {

  .vote-details {
    position: relative;

    &:before {
      background: $white;
      content: "";
      height: 80%;
      left: 50%;
      max-height: rem( 85 );
      position: absolute;
      top: 50%;
      transform: translateY( -48% );
      width: rem( 1 );

      .no-cssgradients & {
        display: none;
      }
    }
  }
  ul {
    columns: 2;

    li {
      padding: rem( 8 ) 0;
    }
  }

  &.full {
    .offer-cat-head {
      [class*="tt-block"] {
        display: inline-block;
        margin-right: rem( 10 );
        vertical-align: top;
        width: rem( 320 );
      }
      .rating {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .vote-desc {
    padding: 0;

    .vote-details {
      padding-left: rem( 110 );

      &:before {
        display: none;
      }
    }
    ul {
      columns: auto;

      p {
        max-width: none;
        width: rem( 210 );
      }
    }
  }

  .line {
    &.rate {
      label {
        display: inline-block;
        margin-right: rem( 10 );
        padding-top: rem( 4 );
        vertical-align: top;
        width: rem( 320 );
      }
      .field {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

// Offer Choices
.offer-choices {

  .choices-list {

    > li {
      padding-bottom: rem( 12 );
    }

    .head {
      background: transparent;
      height: auto;
      padding: 0;

      .pict {
        width: rem( 292 );
      }

      .desc {
        box-sizing: border-box;
        display: block;
        height: 100%;
        padding: rem( 55 ) rem( 50 ) 0 rem( 10 );
        position: relative;
        text-decoration: none;
        background: rgba(119, 135, 83, 0.05);

        p {
          margin: 0;
        }
        .link {
          font-weight: normal;
          margin-top: rem( 5 );
        }

        // &:after {
        //   background: rgba( $theme3, 0.4 );
        //   content: "";
        //   left: 50%;
        //   height: rem( 120 );
        //   position: absolute;
        //   top: rem( 12 );
        //   width: rem( 1 );
        // }

        // .col {
        //   float: left;
        //   width: 50%;
        // }

        // ul {
        //   color: $theme3;
        //   font-size: rem( 11 );
        //   font-weight: 500;
        //   list-style-type: none;
        //   margin: 0;
        //   padding: 0 rem( 14 );
        //   text-transform: uppercase;
        // }

        // li {
        //   padding: rem( 2 ) 0 rem( 2 ) rem( 35 );
        //   position: relative;
        //   white-space: nowrap;

        //   &:before {
        //     @include valign;
        //     height: rem( 28 );
        //   }
        //   span {
        //     display: inline-block;
        //     vertical-align: middle;
        //     white-space: normal;
        //   }
        // }

        [class*="icon"]{
          fill: $theme3;
          height: rem( 20 );
          left: 0;
          margin: rem( -10 ) 0 0;
          position: absolute;
          top: 50%;
          width: rem( 30 );
        }
      }

      .tt {
        color: black;
        font-size: rem( 15 );
        margin: 0 0 rem( 8 );

        &:before {
          display: none;
        }
      }

      .button {
        background: $theme6;
        bottom: 0;
        display: block;
        height: rem( 40 );
        right: 0;
        width: rem( 40 );

        &:before,
        &:after {
          background: $white;
          content: "";
          position: absolute;
        }

        &:before {
          height: rem( 2 );
          left: rem( 11 );
          margin: rem( -1 ) 0 0 0;
          top: 50%;
          width: rem( 18 );
        }

        &:after {
          height: rem( 18 );
          left: 50%;
          margin: 0 0 0 rem( -1 );
          top: rem( 11 );
          width: rem( 2 )
        }

        [class*="icon-prev"] {
          display: none;
        }
      }
    }

    .inner-head {
      background: $white;
      height: rem( 145 );
      padding: 0 0 0 rem( 292 );
      position: relative;
    }
  }

  .choice-select {
    bottom: rem( 107 );
    position: absolute;
    right: rem( 10 );
  }

  // Details
  .details-block {
    margin-left: rem( -8 );
    margin-bottom: rem( -145 );
    position: relative;
    transform: translateY( rem( -145 ));
  }
  .details {
    padding: rem( 40 ) rem( 40 ) rem( 30 );
    position: relative;

    .bt-minus {
      background: $theme6;
      border: 0 none;
      display: block;
      height: rem( 40 );
      position: absolute;
      right: 0;
      top: 0;
      width: rem( 40 );

      &:before {
        background: $white;
        content: "";
        height: rem( 2 );
        left: rem( 11 );
        margin: rem( -1 ) 0 0 0;
        position: absolute;
        top: 50%;
        width: rem( 18 );
      }

      span {
        @include hide;
      }

      &:last-of-type {
        bottom: 0;
        top: auto;
      }
    }
  }

  [data-slider] {
    margin: 0 auto rem( 30 );
    max-width: rem( 570 );
    width: 100%;

    .slick-arrow {
      margin: rem( -14 ) 0 0;


      [class*="icon-"]{
        height: rem( 28 );
        width: rem( 15 );
      }
    }
    .slick-prev {
      left: rem( -28 );
    }
    .slick-next {
      right: rem( -28 );
    }
  }
}

// Toggle
.offer-cat-head {
  padding: 0;

  .toggle-bt {
    display: none;
  }
}

.offer-padding{
  padding-left: 15px;
}

.offer-cat-block {
  .js & {
    display: block;
  }
}
