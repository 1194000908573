//------------------------------------
// #LAYOUT #HEADER
//------------------------------------

// Main Logo
#logo {
  margin: 0;
  position: relative;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: rem(30);
  padding-top: 40px;

  img {
    display: block;
    width: 100%;
  }

  .full {
    display: none;
  }
}

// Banner
header[role="banner"] {
  position: relative;
  z-index: 1000;

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  .banner {
    background-color: $white;
    min-height: rem(40);
    position: relative;

    .wrap {
      min-height: rem(40);
    }
  }

  // Burger button
  .bt-nav {
    background: none;
    border: 0 none;
    box-sizing: content-box;
    height: 100%;
    left: pct(-10/300);
    outline: none;
    padding: 0 pct(10/300);
    position: absolute;
    top: 0;
    width: rem(20);

    [class*="icon"] {
      fill: $theme-secondary-grey;
      height: rem(15);
      width: rem(20);
    }

    span {
      @include hide;
    }

    &[aria-expanded="true"] {
      background: $theme-secondary-grey;

      [class*="icon"] {
        fill: $white;
      }
    }
  }

  .home-nav {
    float: left;
    font-family: $font-advent-pro;
    font-size: rem(20);
    margin-left: pct(20/300);
    padding-left: rem(20);
    text-align: center;

    img {
      vertical-align: super;
      padding-top: 15px;
      //width: rem( 20 );
    }

    p {
      display: inline-block;
      margin: 0;
      padding: 0 rem(3);
      vertical-align: middle;
    }
    span {
      display: inline-block;
      line-height: rem(40);
      vertical-align: middle;
    }
  }

  // Homes navigation
  .home-nav {
    float: right;
    margin-right: rem(40);

    ul {
      list-style-type: none;
      margin: 0;

      .lang {
        .select {
          span:after {
            border-top: 4.5px solid #69b5c2;
          }
        }
      }
    }
    /*> ul {

      > li {
        &:not(.lang) {
          display: none;
        }

        &.lang {
          width: rem( 60 )
        }
      }
    }*/
  }
}

// Nav
#nav {
  font-size: rem(15);
  left: 0;
  position: absolute;
  transform: translate3d(0, 0, 0);
  width: 100%;
  background: white;

  .js & {
    display: none;

    &.open {
      display: block;
    }
  }

  .sticky-logo {
    display: none;
  }

  .icon-cart,
  .icon-account {
    fill: $white;
    height: rem(25);
    margin-top: rem(-12);
    position: absolute;
    top: 50%;
    width: rem(27);

    + span {
      padding-left: rem(40);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  a {
    text-decoration: none;
    font-family: $roboto;
    line-height: 35px;
    color: #182883;
  }

  .categories-nav,
  .site-nav {
    margin: 0 pct(-10/300);
    padding: rem(0) pct(10/300);

    > li {
      &.static-icon,
      .account,
      .basket {
        background: #182883;
        color: #ffffff;
        padding: 5px;
        padding-top: 5px;
      }
      .weather,
      .sea-weather,
      .clock {
        img {
          vertical-align: bottom;
        }
        span {
          vertical-align: top;
        }
      }

      // padding: rem( 2 ) 0;
      .weather,
      .sea-weather,
      .clock,
      .pro {
        float: left;

        img {
          width: 30px;
        }

        span {
          line-height: 2.5rem;
          vertical-align: bottom;
        }
      }

      .pro {
        margin-left: 25px;
      }

      .category-tab,
      & {
        > a,
        > button {
          display: block;
          font-size: rem(15);
          padding: rem(12) 0;
          position: relative;
          font-weight: 500;
          width: 100%;
          text-transform: uppercase;
          font-family: "Roboto Condensed Regular";
          line-height: 38px;
          font-weight: 700;

          .icon-prev {
            height: rem(11);
            fill: $white;
            position: absolute;
            margin: rem(-6) 0 0 0;
            right: 0;
            top: 50%;
            transform: matrix(-1, 0, -0, -1, 0, 0);
            width: rem(11);
          }
        }
        .select {
          margin: 0;
          color: #69b5c2;
        }
        [aria-expanded="true"] {
          .icon-prev {
            transform: matrix(-0, -1, 1, -0, 0, 0);
          }
        }
      }

      // Guadeloupedia
      &.guadeloupedia {
        text-align: left;
        // &,
        // &:hover {
        .category-tab {
          > a,
          > button {
            //background: #7cebe4 url( "#{$url-img}mobile/2x/bg-guadeloupedia-tab.jpg" ) no-repeat 100% 0;
            background-size: auto 100%;
            box-sizing: border-box;
            //color: #0590b3;
            padding-left: rem(14);
            padding-right: rem(14);
            text-shadow: 0 0 5px $white;
          }
        }
        // }
      }
    }
  }

  .categories-nav {
    background: $theme-secondary-grey;
    color: #182883;
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 100%;

      + li {
        border-top: 1px solid rgba($white, 0.5);
      }

      // Guadeloupedia
      &.guadeloupedia {
        order: 50;

        .flexbox & {
          border: 0 none;
          padding-bottom: rem(16);
          max-width: min-content;
          box-sizing: initial;
        }
      }
    }

    .lang {
      .select {
        span:after {
          border-top: 4.5px solid #69b5c2;
        }
      }
    }

    .category-details {
      font-family: "Roboto Condensed Regular";
      font-weight: 500;
      line-height: 38px;
      position: relative;
      .js & {
        display: none;

        &.open {
          display: block;
        }
      }
    }
    .cat-list {
      text-align: left;
      display: none;
      background-color: #fff;
      white-space: nowrap;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      padding-right: 10px;
    }

    li:hover > ul {
      display: block;
      position: absolute;
      left: 100%;
      top: 0;
    }

    svg {
      float: right;
      margin-left: 10px;
      margin-top: 12px;
    }
  }

  .site-nav {
    background: $white;
    color: $theme-secondary2;

    .select-list,
    .select {
      width: 100%;
    }

    > li {
      + li {
        border-top: 1px solid $theme-secondary2;
      }
    }
  }
}

// nav Fouzia
header[role="banner"] {
  .menu {
    display: flex;
    height: 60px;
    font-family: "Roboto Condensed Regular";
    font-size: 15px;
    background-color: white;
    position: fixed;
    top: 0;
    width: 1280px;
    position: absolute;
    &.sticky {
      position: fixed;
      z-index: 10;
  
      .sticky-logo{
        display: block;
        margin: rem( 5 ) rem( 25 ) 0 0;
        float: left;
        width: rem( 30 );
      }
    }  
    li {
      list-style: none;
    }
    .first-menu:hover {
      text-decoration: underline #182883 solid 5px;
      text-underline-offset: 0.4rem;
    }
    .dropdown {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-right: 25px;
      padding-top: 10px;
      color: #182883;

      .category-details {
        padding-left: 10px;
      }

      .span-menu {
        text-transform: uppercase;
        font-weight: 600;
        white-space: nowrap;
      }

      svg {
        float: right;
        margin-top: 15px;
        margin-right: 10px;
      }
    }

    .dropdown li {
      position: relative;
      list-style: none;
    }

    .dropdown li a {
      font-family: "Roboto Condensed Regular";
      font-size: 14px;
      color: #182883;
      text-align: center;
      text-decoration: none;
      display: block;
      line-height: 38px;
      padding-left: 20px;
    }

    .dropdown li ul {
      position: absolute;
      top: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      display: none;
      line-height: normal;
      background-color: #fff;
    }

    .second-menu:hover {
      color: #69b5c2;
    }

    .dropdown li ul li a {
      text-align: left;
      //padding: 10px;
      display: block;
      white-space: nowrap;
      line-height: 48px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /*  .dropdown li ul li a:hover {
       color: #ffffff;
     } */

    .dropdown li ul li ul {
      left: 100%;
      top: 0;

      a {
        color: #182883;
        font-weight: 500;
        padding-left: 20px;
        padding-right: 20px;
      }

      a:hover {
        color: #69b5c2;
      }
    }

    /*  ul li:hover>a {
       color: #ffffff !important;
     } */

    ul li:hover > ul {
      display: block;
    }
    .static-icon {
      background: #182883;
      color: #ffffff;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-evenly;   
      padding: 15px 10px 0 10px;

      picture {
        vertical-align: middle;
      }

      span {
        vertical-align: top;
      }

      .searchIcon {
        cursor: pointer;
      }
      .withopacity {
        opacity: 0.5;
      }
    }
    .static-icon-basket {

      background: #182883;
      color: #ffffff;
      display: flex;
      width: 125px;
      height: 100%;
      justify-content: space-between;
      padding: 15px 0px;

      .withopacity {
        opacity: 0.5;
      }
    }
    .lang {
      .select {
        margin: 0;
        color: #69b5c2;
        font-family: 300;
        span:after {
          border-top: 4.5px solid #69b5c2;
        }

        select {
          border: none;
        }
      }
      [aria-expanded="true"] {
        .icon-prev {
          transform: matrix(-0, -1, 1, -0, 0, 0);
        }
      }
    }

    .guadeloupedia a {
      font-family: "Roboto Condensed Regular";
      text-transform: uppercase;
      font-weight: 700;
      white-space: nowrap;
    }
  }
  .header-mobile {
    display: none;

    .sub-nav,
    .bottom-menu {
      display: flex;
    }
  }


    .search {
      border-radius: 50px;
      background: #ffffff;
      box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.14);
      
      input {
        border: none;
        height: 45px;
        padding-left: 15px;
        opacity: 0.7;
        margin-left : 18px;
        width: 200px;
      }

      button[type="submit"] {
        border: none;
        background-color: white;
        vertical-align: middle;
        opacity: 0.7;
        outline: none;

        :focus {
          outline: none;
          border: none;
        }
      }
  
      .bi {
          font-size: 25px;
      }
    }
    #searchbar-desk {
      width: 273px;
      margin-bottom: 15px;
      margin-top: 5px;
      position: absolute;
      top: 60px;
      right: 43px;
      z-index: 100;

      #searchbox-suggestion-desk.hide{
        display: none;

      }
      #searchbox-suggestion-desk.show
      {
        margin: auto;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        background: white;
        border-radius: 5px;
        box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.14);
        
        a.link-item{
          text-decoration: none;
          .suggestion-item:hover{
            background-color: #182883;
            .title{
              color: white;
            }
            .link{
              color: white;
            }
            
          }
          .suggestion-item { 
            text-align: center;
            margin-top: 1px;
            margin-bottom: 2px;
            border-bottom: 2px solid grey;
  
            .title {
              color: black;
              font-size: 13px;
  
            }
            .link {
              color: #69b5c2;
              font-style: italic;
              text-decoration: none;
              font-size: 10px;
            }
          }
        }
        
      }
      #suggestion-search-mobile.hide{
          display: none;
      }
      #suggestion-search-mobile.show{
          margin: auto;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: center;
          background: white;
          border-radius: 5px;
          box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.14);
          padding: 5px;

          a.link-item{
              text-decoration: none;
              .suggestion-item { 
                text-align: center;
                margin: 5px;
                border-bottom: 1px solid grey;
      
                .title {
                  color: black;
                  font-size: 14px;
      
                }
                .link {
                  color: gray;
                  font-style: italic;
                  text-decoration: none;
                  font-size: 13px;
                }
              }
            }
        }


  }


  #suggestion-search-mobile.hide{
    display: none;
  }
  #suggestion-search-mobile.show{
      margin: auto;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: center;
      background: white;
      border-radius: 5px;
      box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.14);

      a.link-item{
          text-decoration: none;

          .suggestion-item { 
            text-align: center;
            margin-top: 2px;
            margin-bottom: 2px;
            border-bottom: 1px solid grey;
  
            .title {
              color: black;
              font-size: 12px;
  
            }
            .link {
              color: #69b5c2;
              font-style: italic;
              text-decoration: none;
              font-size: 10px;
            }
          }
        }
    }
  
}
