//------------------------------------
// #HEADINGS
//------------------------------------


[class*="tt-block"] {
  font-size: rem( 35 );
}

[class*="tt-raw"] {
  font-size: rem( 35 );
}
