//------------------------------------
// #FORMS
//------------------------------------


// Form
.form {

  .line {
    &.half {
      width: 50%;
    }
  }
}
