//------------------------------------
// #TABS/ACCORDION
//------------------------------------

.tab-heading {
  background: #499394;
  color: $white;
  font-family: $font-advent-pro;
  -webkit-font-kerning: none;
  font-size: rem( 25 );
  text-align: left;

  button {
    border: 0 none;
    background: none;
    padding: rem( 8 ) rem( 60 ) rem( 8 ) rem( 10 );
    position: relative;
    text-align: left;
    width: 100%;
  }
  .icon-prev {
    fill: $white;
    height: rem( 30 );
    position: absolute;
    margin-top: rem( -15 );
    right: rem( 20 );
    top: 50%;
    transform:  matrix( 0, -1, 1, 0, 0, 0 );
    width: rem( 10 );
  }
  [aria-expanded="true"] {
    .icon-prev {
      transform:  matrix( 0, 1, -1, 0, 0, 0 );
    }
  }
}


.tab-content {
  .js & {
    display: none;

    &.on {
      display: block;
    }
  }
}
