//------------------------------------
// #POPINS
//------------------------------------

#popin {

  .popin-content {
    width: rem( 780 );
  }

  .block-form {
    padding-top: rem( 36 );
  }
}
