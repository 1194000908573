//------------------------------------
// #OFFERS PAGES
//------------------------------------

#offer-search {
  background: $grey-light3;
  padding: 0 0 0 0;
  position: relative;
}

// Search Form
.offer-search-form {

  // Search mobile layer
  .form-layer-container {
    min-height: rem( 100 );
    position: relative;
  }
    .form-layer {
      background: $theme3;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9;

      &.on {
        .js & {
          [class*="form-header"] .form-details {
            display: block;
          }
          .form-actions {
            display: block;
          }
        }
      }
    }

  // Main Search form
  [class*="form-header"] {
    background: $theme3;
    color: $white;
    min-height: rem( 86 );
    padding: rem( 14 ) 0 0;

    .wrap {
      box-sizing: border-box;
      padding-left: rem( 8 );
      padding-right: rem( 8 );
    }

    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="email"],
    input[type="password"],
    input[type="search"] {
      font-size: rem( 15 );
      font-weight: 500;
      height: rem( 40 );
    }

    .error-msg {
      color: $red;
      padding-top: 0;

      span {
        background: $white;
        display: inline-block;
        padding: rem( 1 ) rem( 5 );
      }
    }

    fieldset {
      position: relative;

      > .line label {
        padding-right: rem( 35 );
        -webkit-tap-highlight-color: rgba( $black, 0 );
        -webkit-touch-callout: none;
      }
    }

    .toggle-bt {
      background: none;
      border: 0 none;
      height: rem( 31 );
      outline: none;
      padding: rem( 10 );
      position: absolute;
      right: 0;
      top: rem( -2 );
      width: rem( 31 );

      span {
        @include hide;
      }
      [class*="icon-prev"] {
        display: block;
        fill: $white;
        height: rem( 11 );
        margin: 0 auto;
        transform: matrix(-0.000,-1,1,-0.000,0,0);
        width: rem( 7 );
      }
    }

    .form-details {
      .js & {
        display: none;
      }
    }

    .select {
      font-size: rem( 15 );
      font-weight: 500;
      line-height: rem( 38 );
      width: 100%;
      select {
        height: rem( 38 );
      }
    }

    label {
      font-family: $font-advent-pro;
      font-size: rem( 18 );
      margin: 0 0 rem( 6 );
      text-transform: uppercase;
    }

    .select-block {
      box-sizing: border-box;
      float: left;
      padding: 0 rem( 2 ) 0 0;
      width: 50%;

      .select {
        width: 100%;
      }

      + .select-block {
        padding: 0 0 0 rem( 2 );
      }
    }

    .buttons {
      padding: rem( 8 ) 0 rem( 16 );
      text-align: center;
    }


    .kids-form {
      label {
        font-family: $font-roboto;
        font-size: rem( $fz );
        text-transform: none;
      }
      .selects {
        @include clearfix;
        margin-bottom: rem( 16 );
      }

      .select-block {
        display: none;
        margin-bottom: rem( 4 );

        &.visible {
          display: block;
        }

        &:nth-child(odd){
          padding: 0 rem( 2 ) 0 0;
        }
        &:nth-child(even){
          padding: 0 0 0 rem( 2 );
        }
      }
    }
  }

  // Actions Form
  .form-actions {
    background: $theme3;
    color: $white;
    padding: rem( 12 ) rem( 8 ) rem( 22 );

    .js & {
      display: none;
    }
  }

  .form-filters-bt {
    display: none;
  }

  // Filters form
  .form-filters {
    background: $white;
    color: $text-color;
    // margin-bottom: rem( 30 );
    padding: rem( 10 ) rem( 16 );

    .legend-tt {
      font-family: $font-advent-pro;
      font-size: rem( 18 );
      margin: 0 0 rem( 6 );
      text-transform: uppercase;
    }

    fieldset {
      border-bottom: 1px solid rgba( $theme3, 0.3 );
    }

    .form-details {
      padding: 0 0 rem( 12 );
    }
    .form-fields {
      margin-left: rem( -15 );
      margin-right: rem( -15 );
      padding: 0 rem( 15 ) rem( 12 );

      .js &[id] {
        display: none;
        &.on {
          display: block;
        }
      }

    }
    .form-col {
      columns: 2;
      column-gap: rem( 12 );
    }

    .legend {
      cursor: pointer;
      font-size: rem( 15 );
      font-weight: 500;
      margin: 0;
      padding: rem( 12 ) 0 rem( 12 ) rem( 26 );
      position: relative;

      .toggle-bt {
        background: none;
        border: 0 none;
        left: 0;
        height: rem( 11 );
        margin: rem( -6 ) 0 0 0;
        outline: none;
        padding: 0;
        position: absolute;
        top: 50%;
        width: rem( 11 );

        span {
          @include hide;
        }

        [class*="icon-prev"] {
          display: block;
          height: rem( 11 );
          margin: 0 auto;
          transform: rotate( 180deg );
          transition: transform 0.2s $transition-timing-function;
          width: rem( 7 );

          .no-cssgradients & {
            transform: matrix(-1,0.000,-0.000,-1,0,0);
          }
        }

        &.on{
          [class*="icon-prev"] {
            transform: rotate( 270deg );
            .no-cssgradients & {
              transform: matrix(-0.000,-1,1,-0.000,0,0);
            }
          }
        }
      }
    }

    .label {
      color: $theme-dark2;
      font-family: $font-roboto-condensed-regular;
      margin: 0 0 rem( 20 );
      padding: rem( 5 ) 0 0 0;
      text-transform: uppercase;
    }

    .buttons {
      margin: 0 rem( -6 ) rem( 10 );
      padding: rem( 20 ) 0 0;
      @include clearfix;

      .button {
        box-sizing: border-box;
        float: left;
        padding: 0 rem( 6 );
        width: 50%
      }
      [class*="cta"]{
        width: 100%;
      }
    }

    .actions {
      padding: 0 0 rem( 8 );
      @include clearfix;

      div {
        box-sizing: border-box;
        float: left;
        padding-right: rem( 4 );
        width: 50%;

        + div {
          padding-left: rem( 4 );
          padding-right: 0;
        }
      }
      .bt {
        background: $grey-light4;
        border: 0 none;
        border-radius: rem( 8 );
        color: $theme-dark2;
        font-family: $font-roboto-condensed-regular;
        font-size: rem( 13 );
        padding: rem( 11 ) rem( 2 );
        text-align: center;
        text-transform: uppercase;
        transition: $transition;
        width: 100%;

        &:hover {
          background: $theme3;
          color: $white;
        }
      }
    }
  }

  // Mode switch
  .form-display {
    // background: $theme3;
    background: rgba( $theme3, 0.7 );
    bottom: 0;
    box-sizing: border-box;
    color: rgba( $white, 0.5 );
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;

    &.sticky {
      position: fixed;
    }
  }

  .switch {
    box-sizing: border-box;
    font-size: 0;
    height: rem( 62 );
    letter-spacing: 0;
    padding: rem( 16 ) 0 0;
    position: relative;

    [class*="icon"]{
      fill: rgba( $white, 0.5 );
      margin: 0 rem( 10 ) 0 0;
      transition: $transition;
      vertical-align: middle;
    }
    .icon-list {
      height: rem( 22 );
      width: rem( 22 );
    }
    .icon-list2 {
      height: rem( 19.2 );
      width: rem( 19.2 );
    }
    .icon-map {
      height: rem( 32 );
      width: rem( 32 );
    }
    .icon-map2 {
      height: rem( 19.2 );
      width: rem( 19.2 );
    }
    label {
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      font-size: rem( 20 );
      letter-spacing: normal;
      position: relative;
      -webkit-tap-highlight-color: rgba( $black, 0 );
      -webkit-touch-callout: none;
      transition: $transition;
      user-select: none;
      z-index: 3;

      &:nth-of-type(1){
        margin-right: rem( -32 );
        padding: 0 rem( 76 ) 0 0;
      }
      &:nth-of-type(2){
        margin-left: rem( -64 );
        padding: 0 0 0 rem( 76 );
      }
    }
    input {
      display: none;
      // @include hide;

      &:checked{
        &:nth-of-type(1) ~ .cursor:before{
          // transform: translateX( 0 )
          left: 0;
        }

        + label {
          color: $white;
          z-index: 2;

          [class*="icon"] {
            fill: $white;
          }
        }
      }
    }

    .cursor {
      background: #48595e;
      border-radius: rem( 14 );
      box-shadow: inset 0 2px 3px rgba( #344744, 0.4 );
      display: inline-block;
      height: rem( 28 );
      margin: rem( -14 ) 0 0 rem( -32 );
      position: relative;
      vertical-align: middle;
      z-index: 1;
      width: rem( 64 );

      &:before {
        background: #f7f7f7;
        background: linear-gradient( to bottom, #f7f7f7, #e8e8e8 );
        border: 1px solid $white;
        border-radius: rem( 14 );
        box-sizing: border-box;
        content: "";
        height: rem( 28 );
        left: rem( 26 );
        position: absolute;
        top: 0;
        // transform: translateX( rem( 26 ));
        transition: left $transition;
        width: rem( 38 );
      }
    }
  }
}

// Search Results
.offer-results {
  position: relative;
}

// Search List
.offer-list {
  padding-bottom: rem( 62 );

  // Header
  .results-header {
    display: none;
  }

  // Content
  .results-content {

    .results-list {
      list-style-type: none;
      margin: 0;
      padding: rem( 30 ) pct( 20/300 ) rem( 4 );

      li {
        margin: 0 auto;
        max-width: rem( 256 );
        .text {
          p {
            width: 100%;
            .location {
              font-family: 'Roboto';
              font-size: 13px;
            }

            .title {
              font-family: 'Roboto Condensed Regular';
              font-size: 22px;
              font-weight: bold
            }
          }
        }
      }

    }
  }
}

.offer-sort {
  padding: 0 0 rem( 4 );

  p {
    display: inline-block;
    font-size: rem( 15 );
    font-weight: 500;
    margin: 0 rem( 10 ) rem( 10 ) 0;
    vertical-align: middle;
  }
  .tag-list {
    display: inline-block;
    margin-bottom: rem( 4 );
    vertical-align: middle;
  }
}

// Search Map
.offer-map {
  display: none;

  .offer-map-block {
    height: 100%;
    width: 100%;
  }

  .map-info-window {
    margin-top: rem( 53 );
    position: absolute;
    width: rem( 254 );

    &:after {
      content: "";
      @include triangle( 14px, $white, down );

      bottom: rem( -7 );
      // box-shadow: 0 rem( 2 ) rem( 3 ) rgba(0, 0, 0, 0.1);
      left: 50%;
      margin: 0 0 0 rem( -7 );
      position: absolute;
    }

    .block-offer {
      margin: 0;
    }
  }

  .map-info-close {
    background: $theme3;
    border: none;
    height: rem( 28 );
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: rem( 28 );
    z-index: 1;

    [class*="icon"]{
      display: block;
      fill: $white;
      height: rem( 12 );
      margin: 0 auto;
      width: rem( 12 );
    }
    span {
      @include hide;
    }
  }

  [class*="map-label"] {
    cursor: pointer;
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 12 );
    padding: rem( 12 ) 0 0;
    text-align: center;
    width: rem( 40 );
  }
  .map-label-over {
    color: $white;
  }

  .white {
    color: $white;
  }
}


// SearchPage (Hébergements)
.SearchHeader {
  .container {
    max-width: 1280px;
    padding-left: 0;
    padding-right: 0;

    .coverSearch {
      position: relative;
      .cover {
        width: 100%;
        height: 385px;
        object-fit: cover;
      }

      .dechirure_bottom {
        position: relative;
        z-index: 1;
        bottom: 55px;
      }

      .titleSearch {
        position: absolute;
        z-index: 1;
        top: 0;
        margin-left: 95px;
        text-align: left;
        margin-top: 50px;

        .span_1 {
          font-family: $roboto-condensed-regular;
          color: #FFFFFF;
          font-size: 38px;
          font-weight: 900;
          line-height: 42px;
        }

        .span_2 {
          font-family: $homemadeApple;
          font-size: 30px;
          color: #FFDD00;
          white-space: break-spaces;
          word-break: break-all;
        }
      }
    }
  }
}

// New Search Page

#offer-search.new {
  background: initial;

  .offer-search-form {
    display: none;

    .form-display {
      border-radius: 45px;
      background: rgb(24, 40, 131);

      .separator {
        color: white;
        font-size: 24px;
        padding: 0 15px;
      }

      .switch {
        label {

          &:nth-of-type(1) {
            margin-right: unset;
            padding: unset;
          }
          &:nth-of-type(2) {
            margin-left: unset;
            padding: unset;
          }
        }
      }
    }
  }
}

// Labels Atout France
.EtoilesAtoutFrance {
  height: 9px;
  display: initial !important;
}

// Labels Cle Vacances
.CleVacances {
 height: 20px;
 display: initial !important;
}

// Labels GitesDeFrance
.GitesDeFrance {
  height: 22px;
  display: initial !important;
}



.offer-results {
  background: initial !important;

  .wrap {
    max-width: 1000px;
    padding-left: 0;
    padding-right: 0;
    background-image: url(../../B2C/img/Traits-hebergement.png);
    background-repeat: no-repeat;
    background-size: contain;

    .offer-list {
      display: block;
      position: relative;
      width: 50% !important;
      z-index: 1;
      border-top: inherit;
      box-shadow: unset !important;

      .inner-wrap {
        padding-left : inherit;

        .results-list {
          list-style-type: none;
          margin: 0 5px;
          padding: 0 0 rem( 4 );
          min-height: 700px;

          > li {
            box-sizing: border-box;
            display: inline-block;
            /* font-size: 0.8125rem; */
            letter-spacing: normal;
            max-width: 14.275rem;
            margin: 0 5px;
            padding: 0px 5px;
            /* text-align: left; */
            vertical-align: top;
            /* width: 50%;*/

            .block-offer-new {
              //max-width: 14.275rem;
              padding-bottom: 20px;
              margin-bottom: 20px;
              box-shadow: none;

              a {
                text-decoration: none;
                color: initial;
              }

              .description {
                padding-top: 30px;

                .offer-title {
                  font-size: 22px;
                  font-weight: 700;
                  line-height: 26px;
                  color: #000;
                }

                .City, .Category {
                  font-family: "Roboto";
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 16px;
                  color: #000;
                }
              }

              .divImage {
                height: 255px;

                .image_offer {
                 // width: 300px;
                  height: 100%;
                  object-fit: cover;
                  min-width: 100%;
                }

                .on-site {
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 12px;
                  padding: 4px 4px 4px 4px;
                  border: 1px solid #979797;
                  border-radius: 3.5px;
                  background-color: white;
                  bottom: 10px;
                  right: 10px;
                  position: relative;
                  white-space: nowrap;
                  float: right;
                }
              }
            }

            .label-offer {
              padding-top: 5px;

              .promo {
                border-radius: 3.5px;
                background: #ffdd00;
                font-size: 10px;
                font-weight: 700;
                line-height: 12px;
                padding: 4px;
                float: right;
                margin-top: 4px;
              }


              .price_label {
                color: #182883;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                float: right;
                padding-right: 5px;
              }

              .price_number {
                color: #182883;
                font-size: 22px;
                font-weight: bold;
                // line-height: 16px;
                float: right;
                padding-right: 5px;
              }
            }
          }
        }

        .more-places {
          color: #182883;
          font-family: $font-roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          text-decoration: none;
          overflow: hidden;
          padding-top: 10px;
          padding-bottom: 10px;
          display: block;
          text-align: center;

          &::before {
            content: '';
            border-bottom: 1px ridge #182883;
            width: 70px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
          }
        }
      }

      .results-content {
        margin-top: 61px;
      }

      .results-count {
        font-family: $font-roboto-condensed-regular;
        color: #69B5C2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
        text-align: center;
      }
    }

    .offer-map {
      width: 50% !important;
      max-height: 43.3rem !important;
      background-color: initial !important;

      .small-block-offers {
        display: none;
      }

      .title {
        width: 100%;
        text-align: center;

        .tt-block {
          text-align: center;
          width: 100%;
          margin-bottom: 0;

          &:after {
              margin: 0;
              background: unset;
          }

          .span1 {
              font-family: $font-roboto-condensed-regular;
              color: #69B5C2;
              font-size: 30px;
              text-transform: none;
              font-weight: bold;
          }

          .span2 {
              font-family: $homemadeApple;
              font-weight: 40;
              color: #182883;
              font-size: 25px;
              text-transform: none;
          }
        }
      }

      .offer-map-block {
        height: 100%;
        width: 100%;


      }

      .map-info-window {
        margin-top: rem( 53 );
        position: absolute;
        width: rem( 254 );

        &:after {
          content: "";
          @include triangle( 14px, $white, down );

          bottom: rem( -7 );
          // box-shadow: 0 rem( 2 ) rem( 3 ) rgba(0, 0, 0, 0.1);
          left: 50%;
          margin: 0 0 0 rem( -7 );
          position: absolute;
        }

        .block-offer {
          margin: 0;
        }
      }

      .map-info-close {
        background: $theme3;
        border: none;
        height: rem( 28 );
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: rem( 28 );
        z-index: 1;

        [class*="icon"]{
          display: block;
          fill: $white;
          height: rem( 12 );
          margin: 0 auto;
          width: rem( 12 );
        }
        span {
          @include hide;
        }
      }

      [class*="map-label"] {
        cursor: pointer;
        font-family: $font-roboto-condensed-regular;
        font-size: rem( 12 );
        padding: rem( 12 ) 0 0;
        text-align: center;
        width: rem( 40 );
      }
      .map-label-over {
        color: $white;
      }
    }


  }
}


/* MAP View
-------------------*/
#offer-search.map-view{

  // Search Results
  .offer-list {
    display: none;
  }

  // Search Map
  .offer-map {
    height: calc( 100vh - 140px );
    background: #b1d5fe;
    display: block;
  }
}


/* Offer Page
-------------------*/

// Heading
.offer-heading {
  padding: rem( 16 ) 0;

  [class*="tt-"] {
    .stars {
      display: inline-block;
      font-size: 0;
      letter-spacing: 0;
      margin: rem( 2 ) 0 0;
      padding-left: rem( 5 );
      vertical-align: top;
      white-space: nowrap;

      [class*="icon"]{
        fill: $theme-dark;
        height: rem( 10 );
        width: rem( 10 );
      }
    }
  }

  .address {
    color: $theme-dark2;
    font-size: rem( 15 );
    margin: 0 0 rem( 16 );
    padding: rem( 2 ) 0 0 rem( 25 );
    position: relative;

    [class*="icon"]{
      fill: $theme-dark3;
      height: rem( 23 );
      left: 0;
      position: absolute;
      top: 0;
      width: rem( 17 );
    }
    address {
      font-style: normal;
    }
  }

  .services {
    list-style-type: none;
    margin: 0 pct( -10 / 300 );
    padding: 0 0 rem( 5 );
    text-align: center;

    li {
      display: inline-block;
      min-width: rem( 68 );
      margin-bottom: rem( 10 );
      padding: 0 rem( 2 );
      vertical-align: top;
    }
  }

  .rating {
    text-align: center;
      > p {
      font-size: rem( 12 );
    }
  }

  .col .rating {
    margin-bottom: rem( 20 );
  }
}

// Offer desc
.offer-description-block {
  [data-zoom-slider-target] {
    .zoom-opacity {
      display: none;
    }
    .zoom{
      display: none;
    }
  }
}
.offer-description {
  padding: rem( 26 ) 0 rem( 4 );

  .desc-text {
    padding-bottom: rem( 4 );
  }

  .desc-pictures {

    .pictures {
      margin: 0 auto;
      max-width: rem( 312 );
      padding: rem( 4 ) 0 rem( 20 );
      width: pct( 270 / 300 );
    }

    .slick-arrow {
      margin: rem( -4 ) 0 0;
      position: absolute;
      top: 50%;


      [class*="icon-"]{
        fill: $theme3;
        height: rem( 13 );
        width: rem( 8 );
      }
    }
    .slick-prev {
      left: rem( -12 );
    }
    .slick-next {
      right: rem( -12 );
    }

    .slide {
      position: relative;

      img {
        display: block;
      }
    }

    .button {
      background: none;
      border: 0 none;
      display: none;
      height: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      width: 100%;

      div {
        background: rgba( $theme-dark2, 0.7 );
        color: $white;
        height: 100%;
        opacity: 0;
        transition: $transition;
        width: 100%;
        white-space: nowrap;

        &:before {
          @include valign();
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;

        &:after {
          background: $white;
          content: "";
          display: block;
          margin: rem( 10 ) auto 0;
          height: rem( 2 );
          width: rem( 42 );
        }
      }
    }
  }
}

// Offer picture
.offer-picture {
  margin: 0 0 0;

  [class*="picture"] {
    background: no-repeat 50% 50%;
    background-size: cover;
    height: 0;
    padding: pct( 332 / 650 ) 0 0;
    position: relative;
  }

  img {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .text {
    background: $white;
    box-sizing: border-box;
    padding: rem( 20 ) rem( 20 ) rem( 4 );
    position: relative;
    text-align: left;
    z-index: 2;
  }

  [class*="tt-raw"]{
    font-size: rem( 18 );
    margin: 0 0 rem( 10 );
    text-align: left;
  }
}

// Offer services
.offer-services {
  background: white;
  color: $black;
  padding: rem( 20 ) 0 rem( 4 );

  .accommodation & {
    background: $accommodation;
  }

  .activity & {
    background: $activity;
  }

  .offer-cat-head {
    .toggle-bt {
      [class*="icon-prev"] {
        fill: $black;
      }
    }
  }

  ul {
    columns: 2;
    column-gap: ( 28vw / 320 * 100 );
    column-rule: 1px solid $black;
    font-size: rem( 11 );
    list-style-type: none;
    margin: 0 0 rem( 16 );
    padding: 0;
  }

  .list-wide {
    ul {
      columns: auto;
    }

    &::before {
      display: none;
    }
  }

  li {
    padding: rem( 5 ) 0;
    @include clearfix;

    strong {
      color: $theme-dark;
    }
  }

  .pict {
    display: inline-block;
    width: rem( 38 );
    vertical-align: middle;

    [class*="icon"]{
      height: rem( 25 );
      fill: $white;
      width: 100%;
    }

    .icon-take-away {
      height: rem( 28 );
    }
    .icon-paycheck {
      height: rem( 21 );
    }
    .icon-baby-seat {
      height: rem( 35 );
    }
    .icon-delivery {
      height: rem( 20 );
    }
  }

  p {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    width: calc( 100% - 43px );
  }

  .more-services {
    background: rgba( $theme-secondary-grey, 0.15 );
    border: 0 none;
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 15 );
    margin: 0 0 rem( 16 );
    padding: rem( 5 ) rem( 40 ) rem( 5 ) rem( 16 );
    position: relative;
    text-align: left;
    width: 100%;

    &:before,
    &:after {
      background: $white;
      content: "";
      position: absolute;
      top: 50%;
    }

    &:before {
      height: rem( 2 );
      margin-top: rem( -1 );
      right: rem( 12 );
      width: rem( 14 )
    }

    &:after {
      height: rem( 14 );
      margin-top: rem( -7 );
      right: rem( 18 );
      width: rem( 2 );
    }
  }
}


// Offer services equipments
.offer-services-equipments {
  color: $light;
  padding: rem( 20 ) 0 rem( 4 );

  .offer-cat-head {
    .toggle-bt {
      [class*="icon-prev"] {
        fill: $white;
      }
    }
  }

  [class*="tt-raw"]{
    font-size: rem( 18 );
  }

  ul {
    list-style-type: none;

    li .activity {
      color: $activity;
    }
  }
}

// Offer schedule
.offer-schedule {
  color: $white;
  padding: rem( 20 ) 0 rem( 4 );

  &.accommodation {
    background: $accommodation;
  }

  &.restaurant {
    background: $restaurant2;
  }

  &.activity {
    color: $black;
  }

  .offer-cat-head {
    .toggle-bt {
      [class*="icon-prev"] {
        fill: $white;
      }
    }
  }

  table {
    margin: 0 0 rem( 6 );

    th {
      font-weight: normal;
      padding: 0 rem( 20 ) rem( 10 ) 0;
      text-align: left;
    }
    td {
      padding-bottom: rem( 10 );
    }
  }

  .info {
    box-sizing: border-box;
    margin: rem( 16 ) auto;
    max-width: rem( 300 );
    text-align: center;

    &:before {
      @include valign;

      background: url( "#{$url-img}desktop/2x/bg-schedule.png" ) no-repeat 0 50%;
      background-size: rem( 77 ) auto;
      height: rem( 106 );
      width: rem( 100 );
    }
    .inner {
      border-bottom: 1px solid rgba( $white, 0.5 );
      border-top: 1px solid rgba( $white, 0.5 );
      display: inline-block;
      font-size: rem( 14 );
      max-width: rem( 190 );
      padding: rem( 22 ) 0;
      vertical-align: middle;
    }

    p {
      margin: 0;
    }
    .tt {
      font-family: $font-advent-pro;
      font-size: rem(18 );
      text-transform: uppercase;
      margin: 0 0 rem( 5 );
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        padding: 0 rem( 15 ) 0 rem( 9 );
        position: relative;
        vertical-align: middle;

        &:not(:last-child){
          &:after {
            background: $white;
            content: "";
            height: rem( 12 );
            margin: rem( -6 ) 0 0 0;
            position: absolute;
            right: 0;
            top: 50%;
            width: rem( 2 );
          }
        }
      }
    }
  }
}

// Offer info
.offer-info {
  padding: rem( 20 ) 0 rem( 4 );

  .info {
    padding: 0 0 rem( 40 );

    ul {
      font-size: rem( 15 );
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0 0 rem( 5 );
      }

      b {
        display: inline-block;
        font-weight: 500;
        min-width: rem( 70 );
        padding: 0 rem( 10 ) 0 0;
      }

      ul {
        margin: 0 rem( -5 );
        padding: rem( 15 ) 0 0;

        li {
          display: inline-block;
          padding: 0 rem( 5 );
          vertical-align: middle;
        }
      }
      p {
        font-size: rem( 13 );
        margin: 0;
      }
    }

    .info-list{
      li {

        + li {
          padding-top: rem( 20 );
        }
      }
      ul {
        margin: 0;
        padding: 0;

        li {
          display: block;
          padding: 0 0 0 rem( 10 );
          position: relative;

          &:before {
            content: "•";
            font-size: rem( 16 );
            left: 0;
            position: absolute;
            top: rem( 1 );
          }

          + li {
            padding-top: 0;
          }
        }
      }
    }
  }

  .activity-map {
    background: $grey-light;
    height: rem( 440 );
    margin: 0 pct( -10 / 300 ) rem( -4 );
    position: relative;

    .mask {
      display: none;
    }

    .map {
      height: 100%;
      width: 100%;
    }

    .legend {
      font-size: rem( 11 );
      left: pct( 10 / 320 );
      position: absolute;
      top: rem( 15 );
      z-index: 2;

      p {
        background: $white;
        box-shadow: 0 2px 3px rgba( $black, 0.1 );
        display: inline-block;
        margin: 0;
        padding: rem( 5 ) rem( 6 ) rem( 5 ) rem( 30 );
        position: relative;

        + p {
          margin-left: rem( 5 );
        }

        [class*="icon"] {
          fill: $theme-secondary-grey;
          left: rem( 8 );
          height: rem( 18 );
          margin: rem( -10 ) 0 0;
          position: absolute;
          top: 50%;
          width: rem( 14 );
        }

        &.activity {
          [class*="icon"] {
            fill: $activity;
          }
        }

        &.restaurant {
          [class*="icon"] {
            fill: $restaurant;
          }
        }

        &.accommodation {
          [class*="icon"] {
            fill: $accommodation;
          }
        }
      }
    }

    .activity-list {
      display: none;
    }

    // Info window
    .map-info-window {
      margin: rem( 64 ) 0 0;
      position: absolute;
      width: rem( 300 );
    }
    .map-info-close {
      background: $theme3;
      border: none;
      height: rem( 28 );
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: rem( 28 );
      z-index: 1;

      [class*="icon"]{
        display: block;
        fill: $white;
        height: rem( 12 );
        margin: 0 auto;
        width: rem( 12 );
      }
      span {
        @include hide;
      }
    }
    .block-offer {
      margin: 0 0 rem( 32 );
      width: rem( 300 );

      .text {
        p {
          margin: 0;
        }
        > p {
          text-transform: uppercase;
        }
      }
      .rating {
        margin: rem( -5 ) 0 rem( 5 );
        text-align: right;

        > p {
          font-size: rem( 11 );
          padding: 0;
          display: block;
        }
      }
      .desc {
        position: relative;
      }
      .marker {
        background: url( "#{$url-img}desktop/2x/bg-marker-infowindow.png" ) no-repeat 50% 100%;
        background-size: 100% auto;
        bottom: rem( -32 );
        height: rem( 70 );
        left: 50%;
        margin: 0 0 0 rem( -28 );
        position: absolute;
        width: rem( 57 );

        img {
          display: block;
          margin: 0 auto;
          width: rem( 47 );
        }
      }
    }
  }
}

// Offer votes
.offer-votes {
  background: #40d0f4;
  color: $white;
  padding: rem( 20 ) 0 rem( 4 );

  [class*="tt-block"]{
    margin-bottom: rem( 10 );
  }

  .offer-cat-head {
    .toggle-bt {
      top: rem( 18 );

      [class*="icon-prev"] {
        fill: $white;
      }
    }
  }

  .tt {
    background: #40d0f4;
    border: 2px solid $white;
    display: inline-block;
    font-family: $font-advent-pro;
    font-size: rem( 20 );
    font-weight: normal;
    margin: 0 0 rem( 24 );
    padding: rem( 2 ) rem( 10 );
    position: relative;
    text-transform: uppercase;

    b {
      font-size: rem( 42 );
      padding: 0 rem( 5 ) 0 0;
    }

    &:before {
      border-style: solid;
      border-width: 0 25px 15px 0;
      border-color: transparent $white transparent transparent;
      bottom: rem( -15 );
      content: "";
      height: 0;
      position: absolute;
      right: rem( 18 );
      width: 0;
    }

    &:after {
      border-style: solid;
      border-width: 0 25px 15px 0;
      border-color: transparent #40d0f4 transparent transparent;
      bottom: rem( -11 );
      content: "";
      height: 0;
      position: absolute;
      right: rem( 20 );
      width: 0;
    }

  }

  ul {
    font-size: rem( 15 );
    font-weight: 500;
    list-style-type: none;
    margin: 0 0 rem( 20 );
    padding: 0;
    text-align: center;
    text-transform: uppercase;

    li {
      padding: rem( 12 ) 0;
    }

    p {
      display: inline-block;
      margin: 0;
      padding-right: rem( 10 );
      text-align: left;
      vertical-align: middle;
      width: rem( 140 )
    }

    .rating {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
  }

  .vote-desc {
    > div {
      @include clearfix;
    }

    .vote-details {
      padding-bottom: rem( 10 );
      padding-left: rem( 100 );
    }
    .tt {
      margin-top: rem( 10 );
      padding-bottom: rem( 5 );
      text-align: center;
      float: left;

      b {
        display: block;
      }
    }

    ul {
      font-size: rem( 13 );
      text-align: left;

      li {
        padding: rem( 6 ) 0;
      }

      p {
        max-width: rem( 210 );
        min-width: rem( 80 );
        width: 50%;
      }
    }
  }

  label {
    color: $theme-dark2;
    font-size: rem( 15 );
    font-weight: 500;
  }

}


// Offer Choices
.offer-choices {
  background: white;
  padding: rem( 20 ) 0 rem( 16 );

  .choices-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      padding-bottom: rem( 20 );
      position: relative;
    }

    .head {
      background: rgba(119, 135, 83, 0.05);
      display: block;
      height: rem( 106 );
      padding: 0 rem( 14 ) 0 rem( 160 );
      position: relative;
      text-decoration: none;

      .pict {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: rem( 150 );

        img {
          width: 100%;
        }

        .discount {
          left: 0;
          position: absolute;
          top: 0;

          p {
            margin: 0 0 rem( 8 );
          }
        }
      }

      .desc {
        display: none;
      }

      .tt {
        color: $theme6;
        display: inline-block;
        font-size: rem( 13 );
        font-weight: 500;
        height: 100%;
        margin: 0;
        text-transform: uppercase;
        vertical-align: middle;

        &:before {
          @include valign;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .button {
        bottom: rem( 24 );
        height: rem( 7 );
        position: absolute;
        right: rem( 16 );
        width: rem( 11 );

        [class*="icon-prev"] {
          display: block;
          fill: $theme3;
          height: rem( 11 );
          margin: 0 auto;
          transform: rotate( 270deg );
          transition: transform $transition;
          width: rem( 7 );

          .no-cssgradients & {
            transform: matrix(-0.000,-1,1,-0.000,0,0);
          }
        }
      }

      &[aria-expanded="true"]{
        .button {
          [class*="icon-prev"] {
            transform: rotate( 90deg );

            .no-cssgradients & {
              transform: matrix(0.00,1.00,-1.00,0.00,0,0);
            }
          }
        }
      }
    }
  }

  // Details
  .details-block {
    .js & {
      display: none;

      &.on {
        display: block;
      }
    }
  }
  .details {
    background: rgba(248, 248,248, 1);
    padding: rem( 30 ) rem( 14 ) rem( 16 );

    [class*="tt-block"]{
      font-size: rem( 15 );
      font-weight: 500;
      margin: 0 0 rem( 12 );

      &:after {
        height: rem( 1 );
        margin-top: rem( 4 );
      }
    }

    .bt-minus {
      display: none;
    }
  }

  [data-slider] {
    margin: 0 auto rem( 30 );
    width: rem( 272 );

    .slick-arrow {
      margin: rem( -4 ) 0 0;
      position: absolute;
      top: 50%;


      [class*="icon-"]{
        fill: $theme3;
        height: rem( 13 );
        width: rem( 8 );
      }
    }
    .slick-prev {
      left: rem( -12 );
    }
    .slick-next {
      right: rem( -12 );
    }
  }

  .foot {
    text-align: center;
  }
  .price {
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 15 );
    margin: 0 0 rem( 10 );

    strong {
      font-size: rem( 25 );
      font-weight: normal;
    }
  }
}

// Toggle
.offer-cat-head {
  padding-right: rem( 30 );
  position: relative;

  .toggle-bt {
    background: none;
    border: 0 none;
    height: rem( 31 );
    outline: none;
    padding: rem( 10 );
    position: absolute;
    right: 0;
    top: rem( -2 );
    width: rem( 31 );

    span {
      @include hide;
    }
    [class*="icon-prev"] {
      display: block;
      fill: $theme3;
      height: rem( 11 );
      margin: 0 auto;
      transform: rotate( 270deg );
      transition: transform $transition;
      width: rem( 7 );

      .no-cssgradients & {
        transform: matrix(-0.000,-1,1,-0.000,0,0);
      }
    }

    &.on{
      [class*="icon-prev"] {
        transform: rotate( 90deg );

        .no-cssgradients & {
          transform: matrix(0.00,1.00,-1.00,0.00,0,0);
        }
      }
    }
  }
}
.offer-cat-block {
  .js & {
    display: none;

    &.on {
      display: block;
    }
  }
}


// offer recap
.offer-selection {
  ~ section:last-child {
    margin-bottom: rem( 40 );
  }
}
.offer-recap {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
  .logoPosition{
    position: relative;
    padding: 0 0 0 1.375rem;
  }
}
.offer-recap-block {
  bottom: 0;
  font-size: rem( 15 );
  left: 0;
  position: absolute;
  text-align: right;
  transform: translate3d( 0, 0, 0 );
  width: 100%;
  z-index: 5;

  &.fixed {
    position: fixed;
    right: auto;
  }

  .inner {
    background: #e3e3e3;
    padding: rem( 10 ) pct( 10 / 320 );
    text-align: left;

    .js & {
      display: none;

      &.on {
        display: block;
      }
    }
  }

  .tt {
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 20 );

    strong {
      font-size: rem( 32 );
      line-height: ( 32 / 32 );
    }
  }

  address {
    font-style: normal;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0 0 rem( 10 );

    [class*="icon"]{
      fill: $white;
      display: block;
      height: rem( 18 );
      left: 0;
      position: absolute;
      top: rem( 1 );
      width: rem( 14 )
    }
  }

  ul {
    list-style-type: none;
  }

  li {
    margin: 0 0 rem( 10 );
    padding: 0 0 0 rem( 22 );
    position: relative;

    [class*="icon"]{
      display: block;
      height: rem( 18 );
      left: 0;
      position: absolute;
      top: rem( 1 );
      width: rem( 14 )
    }
  }

  // Form
  .form {
    .line {
      margin-bottom: rem( 10 );
    }

    label {
      padding-bottom: rem( 5 );
    }

    .col{
      @include clearfix;

      .line {
        box-sizing: border-box;
        float: left;
        width: 50%;

        &:nth-child(odd){
          clear: left;
          padding-right: rem( 5 );
        }
        &:nth-child(even){
          padding-left: rem( 5 );
        }
      }
    }
    .select-block {
      width: 100%;

      .select {
        width: 100%
      }
    }
    .inline {
      text-align: right;

      label {
        display: inline-block;
        padding-bottom: 0;
        padding-right: rem( 10 );
        vertical-align: middle;
      }
    }

    .offer-recap-selection-title {
      display: none;
    }

    .offer-recap-selection {
      margin: rem( 25 ) 0;
      background-color: $white;
      position: relative;
      display: table;
      width: 100%;

      &.no-selection {
        font-family: $font-advent-pro;
        color: #41717f;
        background-color: transparent;
      }

      &::before, &::after {
        content: "";
        background: $white;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        top: rem( -8 );
      }
      &::after {
        top: auto;
        bottom: rem( -8 );
      }
    }

    .offer-recap-pict {
      width: rem( 100 );
      height: rem( 65 );
      background-size: cover;
      display: table-cell;
      margin-right: rem( 15 );
    }

    .offer-recap-accomodation-name {
      display: table-cell;
      vertical-align: middle;
      text-transform: uppercase;
      padding: rem( 5 ) rem( 15 );
      font-size: rem( 12 );
      background-color: #182883;
    }

    .price-unavailable {
      text-transform: uppercase;
      font-family: $font-family;
      color: white;
      font: 20px; 
      font-size: $font-root;
      line-height: $line-height;
    }
  }

  .select-inline {
    display: inline-block;
    vertical-align: middle;

    .span-select {
      background-color: #ffffff;
      box-sizing: border-box;
      color: #333333;
      display: inline-block;
      line-height: 2.0625rem;
      vertical-align: middle;
      border: 1px solid #ffffff;
      overflow: hidden;
      position: relative;

      select {
        -webkit-appearance: none;
        appearance: none;
        border-width: 0;
        bottom: 0;
        cursor: pointer;
        left: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
      }

      .custom-label {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        padding: 0 0.625rem 0 0.625rem;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  ._select {
    margin: 0 rem( 7 ) rem( 7 ) 0;
    display: inline-block;
  }

  .total {
    border-top: 1px solid $black;
    font-size: rem( 20 );
    margin: rem( 20 ) 0 0;
    padding: rem( 15 ) 0 rem( 20 );

    @include clearfix;

    p {
      margin: 0;
    }

    .text {
      float: left;
      line-height: 1;
      max-width: 50%;
      text-transform: uppercase;

      small {
        font-size: rem( 14 );
        text-transform: none;
      }
    }
    .price {
      float: right;
      max-width: 50%;
    }
  }
  .buttons {
    margin: 0 0 rem( 10 );

    @include clearfix;

    > div {
      box-sizing: border-box;
      float: left;
      padding-right: rem( 2 );
      width: 50%;

      + div {
        padding-left: rem( 3 );
        padding-right: 0;
      }

    }

    .cta-outline {
      background-color: transparent;

      &:hover,
      a:hover & {
        background: $theme3;

        [class*="icon"]{
          fill: $white;
        }
      }
    }

    [class*="cta"]{
      font-size: rem( 18 );
      width: 100%;

      &.shop {
        font-size: rem( 14 );
        line-height: 1;
        padding-left: rem( 7 );
        padding-right: rem( 34 );
        position: relative;
        text-align: left;

        [class*="icon"]{
          bottom: rem( 5 );
          fill: $theme3;
          height: rem( 22 );
          left: auto;
          position: absolute;
          right: rem( 7 );
          width: rem( 22 );
        }

        &:hover,
        a:hover & {

          [class*="icon"]{
            fill: $white;
          }
        }
      }
    }
  }

  .disabled {
    [class*="cta"]{
      opacity: 0.5;
    }
    .cta {
      &:hover {
        background: $cta;
        color: $white;
      }
    }
    .cta-outline {
      &:hover {
        background: inherit;
        color: inherit;

        [class*="icon"]{
          fill: inherit;
        }
      }

      &.shop {
        &:hover {
          [class*="icon"]{
            fill: $theme-secondary-grey;
          }
        }
      }
    }
  }
}


// Popins
#popin {
  .options-list {
    list-style-type: none;

    li {
      padding: 0 rem( 16 ) rem( 10 );
      position: relative;
    }

    .option {
      label {
        background: $theme2;
        box-sizing: border-box;
        color: $white;
        cursor: pointer;
        font-family: $font-roboto-condensed-regular;
        padding: rem( 4 ) rem( 28 ) rem( 4 ) rem( 16 );
        position: relative;
        text-transform: uppercase;
        width: rem( 135 );

        [class*="icon"]{
          fill: $white;
          height: rem( 14 );
          position: absolute;
          right: rem( 6 );
          top: 50%;
          transform: translateY( -50% );
          width: rem( 14 );
        }
        .icon-close {
          height: rem( 10 );
        }

        &.on {
          background: $theme3;
        }
      }
      input {
        display: none;
      }
      .icon-check {
        display: none;
        fill: $theme3;
        height: rem( 10 );
        left: 0;
        position: absolute;
        top: rem( 4 );
        width: rem( 10 );
      }
      .on {
        display: none;
      }
      input:checked{
        ~ .on {
          display: block;
        }
        ~.off {
          display: none;
        }
        ~ .icon-check {
          display: block;
        }
      }
    }
  }

  .offer-actions {
    margin: 0 auto;
    max-width: rem( 340 );

    .total {
      display: table;
      font-size: rem( 15 );
      font-weight: 500;
      margin: 0 0 rem( 10 );
      width: 100%;

      small {
        font-size: rem( 13 );
      }

      .txt,
      .price {
        box-sizing: border-box;
        display: table-cell;
        padding: rem( 10 );
      }
      .txt {
        text-align: right;
      }
      .price {
        padding-right: rem( 40 );
        text-align: right;
        width: 40%;
      }
    }
    .sub-total,
    .full-total {
      display: table-row;
    }

    .sub-total {

      .txt,
      .price {
        border: 1px solid $theme3;
      }
      .txt {
        border-right: 0 none;
      }
      .price {
        border-left: 0 none;
      }
    }
    .full-total {
      font-size: rem( 17 );
      .txt,
      .price {
        background: $theme2;
        border-top: rem( 6 ) solid $grey-light2;
        color: $white;
      }
    }

    .buttons {
      margin: 0 0 rem( 16 );

      @include clearfix;

      > div {
        box-sizing: border-box;
        float: left;
        padding-right: rem( 2 );
        width: 50%;

        + div {
          padding-left: rem( 3 );
          padding-right: 0;
        }

      }

      .cta-outline {
        background-color: transparent;

        &:hover,
        a:hover & {
          background: $theme3;

          [class*="icon"]{
            fill: $white;
          }
        }
      }

      [class*="cta"]{
        line-height: 1;
        // font-size: rem( 18 );
        width: 100%;

        &.shop {
          // font-size: rem( 14 );
          // line-height: 1;
          padding-left: rem( 7 );
          padding-right: rem( 34 );
          position: relative;
          text-align: left;

          [class*="icon"]{
            bottom: rem( 5 );
            fill: $theme3;
            height: rem( 22 );
            left: auto;
            position: absolute;
            right: rem( 7 );
            width: rem( 22 );
          }

          &:hover,
          a:hover & {

            [class*="icon"]{
              fill: $white;
            }
          }
        }
      }
    }
  }

  .offer-confirmation {
    text-align: center;
  }
}
