//------------------------------------
// #GIMMICKS
//------------------------------------


// Date sticker
.date-sticker {
  font-size: rem( 22 );
  height: rem( 85 );
  width: rem( 85 );
}

// Discount label
.discount-label {

  &:not(.small):not(.xsmall){
    > span {
      font-size: rem( 15 );
      padding: rem( 9 ) rem( 20 );

      strong {
        font-size: rem( 26 );
      }
    }
  }
}
