//------------------------------------
// #BLOCKS
//------------------------------------

// Event article
.block-event {
  .header {
    .tt {
      color: $theme-secondary;
    }
  }

  [class*="icon-"]{
    fill: $theme;
  }
}

// Event list
.block-events-list {
  .event-desc {
    span {
      color: $theme2;
    }
  }
}


// Calendar
.calendar {
  .calendar-title {
    background: $theme4;
  }

  .calendar-table {
    thead {
      th {
        background: $theme2;
      }
    }
    tbody {
      td {
        &.selected {
          background: $theme4;
        }
      }
    }
  }
}


// contact block
.block-contact {
  article {
    [class^=icon-] {
      stroke: $theme-secondary2;
    }
  }
}


// Filters
.block-filters {
  background: $theme4;
  width: 1280px;
  margin:auto;
  
  .filter-switch {
    .off {
      color: inherit;
      &:before {
        background: $theme2;
      }
    }
  }
}

// Documents
.block-document {
  .info-label {
    span {
      background-color: #258182;
    }
  }
  .text {
    background: $white;

    [class*="icon-"]{
      fill: #258182;
    }
  }
}


// Search
.block-search {
  background: $theme;
  color: $white;
  overflow: hidden;

  .wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .tab {
    background: $theme2;
    border: 0 none;
    font-family: $font-advent-pro;
    font-size: rem( 20 );
    margin: 0 pct( -10 / 300 );
    padding: rem( 10 ) pct( 10 / 300 );
    position: relative;
    text-align: left;
    text-transform: uppercase;
    transition: background 0.3s ease-out;
    width: pct( 320 / 300 );

    div {
      padding: 0 rem( 30 ) 0 rem( 38 );
      position: relative;
    }

    [class*="icon"]{
      fill: $white;
      height: rem( 30 );
      left: 0;
      margin: rem( -15 ) 0 0;
      position: absolute;
      top: 50%;
      width: rem( 22 );
    }

    .icon-prev{
      height: rem( 10 );
      left: auto;
      margin: rem( -5 ) 0 0;
      right: rem( 10 );
      transform:  matrix( -1, 0, -0, -1, 0, 0 );
      transition: transform 0.3s ease-out;
      width: rem( 15 );
    }

    ~ .tab {
      border-top: 1px solid $white;
    }

    &:nth-of-type(1){
      order: 1
    }
    &:nth-of-type(2){
      order: 3
    }
    &:nth-of-type(3){
      order: 5
    }
    &:nth-of-type(4){
      order: 7
    }
    &:nth-of-type(5){
      order: 9
    }
    &:nth-of-type(6){
      order: 11
    }

    &[aria-expanded="true"]{
      background: $theme;

      .icon-prev {
        transform:  matrix( 0, -1, 1, 0, 0, 0 );
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    width: 100%;

    &[aria-hidden="true"]{
      display: none;
    }

    .inner {
      padding: 0 0 rem( 5 );
    }

    &:nth-of-type(1){
      order: 2
    }
    &:nth-of-type(2){
      order: 4
    }
    &:nth-of-type(3){
      order: 6
    }
    &:nth-of-type(4){
      order: 8
    }
    &:nth-of-type(5){
      order: 10
    }
    &:nth-of-type(6){
      order: 12
    }
  }

  .form {
    input,
    textarea,
    .select {
      color: #414042;
    }
    input {
      &::placeholder {
        color: #414042;
      }
    }
    .line {
      margin-bottom: rem( 20 );

      .select {
        width: 100%;
      }
    }
    .button {
      margin: 0 0 rem( 20 );
      text-align: center;
    }
    .third,
    .half {
      @include clearfix;

      .line {
        box-sizing: border-box;
        float: left;
        padding-right: rem( 3 );
        width: 50%;

        + .line {
          padding-left: rem( 3 );
          padding-right: 0;
        }
      }
    }
    .third {
      .line:nth-of-type(3){
        clear:left;
        padding-left: 0;
        width: 100%;
      }
    }
    .selects {
      margin: 0 rem( -1 );

      .select-block {
        display: none;
        padding: 0 rem( 1 ) rem( 6 );
        width: rem( 79 );

        &.visible {
          display: inline-block;
        }
      }
    }
  }

  .kids-form {
    &[aria-hidden="true"]{
      display: none;
    }

    label {
      padding-bottom: rem( 2 );
    }
  }
}
