//------------------------------------
// #SLIDERS
//------------------------------------


// Logo Slider
[data-slider="logo"]{

  .js & {
    width: rem( 420 );
  }

  .slick-list {
    width: rem( 420 );
  }

  .slide {
    width: rem( 105 );
  }
}


// Partner slider
[data-slider="partners"] {
  width: rem( 936 );
}
