//------------------------------------
// #FUNCTIONS
//------------------------------------

$em-base:  13;
$rem-base: 16;





// Strips the unit from a number.
//
// @param {Number (With Unit)} $value
//
// @example scss - Usage
//   $dimension: strip-units(10em);
//
// @example css - CSS Output
//   $dimension: 10;
//
// @return {Number (Unitless)}

@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}




// Convert pixels to rems
// eg. for a relational value of 12px write rem(12)
// Assumes $em-base is the font-size of <html>
// https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_px-to-rem.scss

@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $rem-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}




// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)
// https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_px-to-em.scss

@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}

// Convert pixels to percentage
@function pct($args...) {
  @return percentage( $args... );
}
