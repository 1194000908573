.block-search {
    .wrap {
      display: block;
    }
  
    .completeHeader{
      background-color: transparent;
      height: 0;
    }

    .content {
      display: unset;
      justify-content: unset;
    }
}