//------------------------------------
// #BLOCKS
//------------------------------------


// Aside
.block-aside {
  [class*="tt-side"]{
    text-align: left;
  }
  .link {
    margin-bottom: rem( 16 );
  }
}

// Agenda
[class*="block-agenda"] {

  [class*="tt-"]{
    text-align: center;
  }

  [class*="tt-side"]{
    text-align: left;
  }

  .events-list {
    list-style-type: none;
    margin: 0;
    padding: rem( 10 ) 0;

    a {
      text-decoration: none;
    }

    li {
      @include clearfix;
    }
    p {
      margin: 0 0 rem( 10 );
    }
    .date-time {
      float: left;
      margin: 0 0 rem( 10 );
    }
    .txt {
      padding: rem( 6 ) 0 0 rem( 80 );
    }
  }
  .link {
    margin-bottom: rem( 16 );
  }
}
.block-agenda-light {
  color: $white;

  .tt-side {
    border-color: $white;
    color: $white;
  }

  .events-list {
    li {
      +li {
        border-top: 1px dotted $white;
        padding-top: rem( 10 );
      }
    }
  }

  .link {
    color: $white;
  }
}

// Calendar
.calendar {
  margin: 0 0 rem( 16 );

  .calendar-title {
    background: #c04ebf;
    color: $white;
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 22 );
    padding: rem( 8 ) rem( 40 );
    position: relative;
    text-align: center;

    button {
      background: none;
      border: 0 none;

      span {
        @include hide;
      }
    }
    [class*="icon-"] {
      fill: $white;
      height: rem( 15 );
      width: rem( 10 );
    }
    .calendar-prev,
    .calendar-next {
      position: absolute;
      top: 50%;
      transform: translateY( -50% );

      &.hidden {
        display: none;
      }
    }
    .calendar-prev {
      left: rem( 16 );
    }
    .calendar-next {
      right: rem( 16 );

      .icon-prev {
        transform:  matrix( -1, 0, -0, -1, 0, 0 );
      }
    }
  }
  .calendar-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;

    thead {
      text-transform: uppercase;

      th {
        background: #9b399a;
        color: $white;
        font-size: rem( 10 );
        font-weight: bold;
        padding: rem( 8 ) 0;
      }
    }
    tbody {
      font-size: rem( 18 );
      font-weight: 500;

      td {
        background: $grey-light2;
        border: 1px solid $white;
        color: #7e7e7e;
        padding: rem( 8 ) 0;

        &.not-current {
          color: $white;
        }
        &.selected {
          background: #c04ebf;
          color: $white;
        }
      }

    }
  }
}


// Quotes
.block-quote {
  background: $white;
  font-size: rem( 15 );
  margin: rem( 4 ) 0 0 0;
  padding: rem( 26 ) rem( 16 ) rem( 4 );
  quotes: "\201C""\201D""\2018""\2019";
  text-align: center;

  p {
    color: $text-color;
    display: inline;
    margin: 0;
  }

  &:before,
  &:after {
    color: inherit;
    font-family: Lucida Bright, Georgia, serif;
    font-size: 5em;
    line-height: 0;
    vertical-align: -0.4em;
  }
  &:after {
    vertical-align: -0.7em;
  }
  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
}

// Card
.block-card {
  margin: 0 auto rem( 16 );

  &.agency {
    margin-bottom: 0;

    .contact {
      box-shadow: 1px 1px 2px rgba( $black, 0.2 );
    }
  }

  .pict {
    margin: 0;
    position: relative;

    .sticker {
      display: none;
    }

    img {
      display: block;
    }
  }
  .contact {
    background: $white;
    // font-size: rem( 11 );
    min-height: rem( 40 );
    padding: rem( 10 ) rem( 16 ) rem( 10 ) rem( 122 );
    position: relative;

    b {
      font-size: rem( 15 );
    }
    p {
      margin: 0;
    }
    span {
      font-style: italic;
    }

    .thumb {
      border: 2px solid $white;
      border-radius: 100%;
      height: rem( 98 );
      left: rem( 10 );
      overflow: hidden;
      position: absolute;
      top: rem( -52 );
      width: rem( 98 );

      img {
        display: block;
        width: rem( 98 );
      }
    }
  }
}


// Agency
.block-agency {
  margin: 0 auto;
  max-width: rem( 420 );

  .block-quote {
    background-color: transparent;
  }

  .about {
    display: table;
    font-size: rem( 14 );
    line-height: ( 16 / 14 );
    margin: 0 auto rem( 10 );
    text-transform: uppercase;
    width: pct( 280 / 300 );

    .pict {
      display: table-cell;
      padding: 0 rem( 12 ) 0 0;
      text-align: right;
      vertical-align: bottom;
    }

    .pict + .desc {
      text-align: left;
    }

    .desc {
      display: table-cell;
      padding: 0 0 rem( 5 ) rem( 12 );
      text-align: center;
      vertical-align: bottom;
    }
    p {
      margin: 0;
    }
    strong {
      font-size: rem( 40 );
      font-weight: bold;
      line-height: ( 42 / 40 );
    }
  }
}


// Filters
.block-filters {
  background: #0e5e62;
  color: $white;
  padding: rem( 10 ) 0;

  .title {
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 16 );
    margin: 0 0 rem( 10 );
    text-transform: uppercase;
  }
  .field {
    margin: 0 0 rem( 10 );
  }
  .select {
    width: 100%;
  }

  .filter-switch {
    font-size: rem( 16 );
    line-height: ( 18 / 16 );
    text-align: center;

    > span,
    > a {
      display: inline-block;
      vertical-align: middle;
      width: pct( 85 / 300 );

      &:nth-child(1) {
        padding-right: rem( 28 );
        text-align: right;

        &:before,
        &:after {
          right: 0;
        }
      }
      &:nth-child(2) {
        padding-left: rem( 28 );
        text-align: left;

        &:before,
        &:after {
          left: 0;
        }
      }

      span {
        display: block;
      }
      .separator {
        display: none;
      }
    }

    a {
      text-decoration: none;
    }

    .off {
      color: rgba( $white, 0.5 );
      outline: none;
      position: relative;

      &:hover {
        color: $white;
      }

      &:nth-child(1) {
        padding-right: rem( 88 );
      }
      &:nth-child(2) {
        padding-left: rem( 88 );
      }

      &:before {
        background: $theme-dark3;
        border-radius: rem( 13 );
        box-shadow: inset 0 3px 2px rgba( $black, 0.3 );
        content: '';
        display: block;
        height: rem( 26 );
        margin-top: rem( -13 );
        position: absolute;
        top: 50%;
        width: rem( 60 );
      }
      &:after {
        background: #f7f7f7;
        background: linear-gradient( to top, #e6e6e6, #f7f7f7 );
        border: 1px solid rgba( $white, 0.8 );
        border-radius: rem( 13 );
        box-sizing: border-box;
        // box-shadow: inset 0 3px 2px rgba( $black, 0.3 );
        content: '';
        height: rem( 26 );
        margin-top: rem( -13 );
        top: 50%;
        position: absolute;
        width: rem( 36 );
      }
    }
  }
}


// City picker (island page)
.block-picker {
  background-color: $theme-dark;
  color: $white;
  padding: rem( 14 ) 0 rem( 10 );

  p {
    display: inline-block;
    margin-bottom: 0;
    padding-right: rem( 3 );
    vertical-align: middle;
  }

  .select {
    background-color: rgba( $theme4, .3 );
    border-color: rgba( $white, .3 );
    color: $white;
    line-height: ( 22 / 14 );
    text-transform: uppercase;

    span:after {
      border-top-color: $white;
    }
  }
}


// Island main blocks
[class*="block-island"] {
  .desc {
    background-color: $theme2;
    font-family: $font-roboto-condensed-regular;

    p {
      color: $white;
      margin: 0 auto;
      max-width: pct( 300 / 320 );
      padding: rem( 20 ) 0;
    }
  }
}

.block-island-main {
  padding-top: rem( 20 );

  .link-alt {
    margin-bottom: rem( 20 );
  }

  img {
    display: block;
    width: 100%;
  }

  .tt-raw {
    margin: rem( 15 ) 0;
  }

  .cols {
    background-color: $light;
    padding: rem( 20 ) 0;
  }

  .col-one-third {
    display: none;
  }
}


// Island city block
.block-island-city {
  margin-top: rem( 35 );

  .cols .back-top {
    display: none;
  }

  .tt-block {
    margin-bottom: rem( 12 );
  }

  img {
    display: block;
    width: 100%;
  }

  .desc {
    margin-bottom: rem( 20 );
  }

  .infos {
    border-collapse: separate;
    border-spacing: rem( 5 );
    display: table;
    margin: 0 auto;
    max-width: rem( 470 );
    width: pct( 310 / 320 );

    > * {
      display: table-cell;
      height: rem( 67 );
      text-align: center;
      vertical-align: middle;
      width: 33%;
    }

    .figure {
      -webkit-font-kerning: none;
      background-color: $theme2;
      color: $yellow;
      font-family: $font-title;
      font-size: rem( 18 );
      padding: 0 rem( 10 );
      text-transform: uppercase;

      span {
        border-bottom: 1px solid rgba( $white, .58 );
        color: $white;
        display: block;
        font-family: $font-family;
        font-size: rem( 12 );
        margin-bottom: rem( 3 );
        padding-bottom: rem( 4 );
      }
    }

    button {
      -webkit-font-kerning: none;
      background-color: $light;
      border-width: 0;
      color: $theme2;
      font-family: $font-title;
      font-size: rem( 20 );
      height: 100%;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      width: 100%;

      [class^="icon-"] {
        display: block;
        fill: $theme;
        height: rem( 25 );
        margin: rem( 2 ) auto 0;
        width: rem( 25 );
      }

      .icon-minus {
        display: none;
      }

      &[aria-expanded="true"] {
        &:after {
          background-color: $light;
          bottom: rem( -5 );
          content: "";
          height: rem( 5 );
          left: 0;
          position: absolute;
          width: 100%;
        }

        .icon-plus {
          display: none;
        }

        .icon-minus {
          display: block;
        }
      }
    }
  }

  .block-contact {
    background-color: $light;
    display: none;

    &.on {
      display: block;
    }
  }
}


// contact block
.block-contact {
  padding-top: rem( 22 );
  @include clearfix();

  article {
    margin-bottom: rem( 28 );
    position: relative;

    .sticker-label {
      left: 0;
      max-width: rem( 234 );
      position: absolute;
      top: rem( 19 );

      span {
        font-size: rem( 13 );
        text-transform: none;
      }
    }

    img {
      margin-bottom: rem( 18 );
      width: 100%;
    }

    [class^=icon-] {
      stroke: $theme;
    }

    .icon-phone-2 {
      height: rem( 19 );
      margin: 0 rem( 15 ) 0 rem( 2 );
      width: rem( 16 );
    }

    .icon-mail-2 {
      height: rem( 13 );
      margin-right: rem( 13 );
      width: rem( 19 );
    }

    .place-info {
      margin-bottom: 0;

      > span {
        display: block;
      }

      a {
        display: inline-block;
        margin-top: rem( 14 );
        text-decoration: none;

        span,
        svg {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}


// Support block - person contact card
[class*="block-support"] {

  [class*="tt-side"] {
    margin-bottom: rem( 20 );
    text-align: left;
  }

  .contact {
    display: table;
    width: 100%;

    .picture {
      display: table-cell;
      padding: 0 rem( 16 ) 0 0;
      vertical-align: top;
      width: rem( 82 );
    }
    .thumb {
      border: 2px solid $white;
      border-radius: 100%;
      height: rem( 82 );
      overflow: hidden;
      width: rem( 82 );

      img {
        display: block;
        width: rem( 82 );
      }
    }
    .text {
      display: table-cell;
      padding-top: rem( 4 );
      vertical-align: top;
    }

    + .contact {
      margin-top: rem( 10 );
    }
  }

  .icon-phone {
    height: rem( 20 );
    width: rem( 10 );
  }
  .icon-mail {
    height: rem( 12 );
    width: rem( 16 );
  }
  .contact-link {
    display: inline-block;
    padding: rem( 4 ) 0 rem( 2 ) rem( 22 );
    position: relative;
    text-decoration: none;

    [class*="icon"] {
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY( -50% );
    }

    .icon-phone {
      left: rem( 4 );
    }
  }
}

.block-support-light {
  color: $white;

  [class*="tt-side"] {
    border-color: $white;
    color: $white;
  }

  [class*="icon-"] {
    fill: $white;
  }
}


// Check list
.block-check {

  ul {
    padding-left: rem( 30 );
    list-style-type: none;
  }

  li {
    position: relative;
  }
  .icon-check {
    fill: $theme;
    left: rem( -30 );
    height: rem( 16 );
    position: absolute;
    top: rem( 2 );
    width: rem( 16 );
  }
}


// Event list
.block-events-list {
  list-style-type: none;

  > li {
    padding: 0 0 rem( 10 );
    @include clearfix;
  }
  .date-time {
    float: left;
    margin: 0 0 rem( 16 );
  }
  .event-desc {
    font-size: rem( 14 );
    padding: 0 0 0 rem( 85 );

    span {
      color: $purple;
    }

    li {
      position: relative;

      &:not(:only-of-type):before {
        background: rgba( $text-color, 0.5 );
        content: "";
        height: 100%;
        left: rem( -10 );
        min-width: 1px;
        position: absolute;
        top: 0;
        width: rem( 1 );
      }
    }
  }
  p {
    margin: 0;
    padding: rem( 16 ) 0;
  }

  ul {
    list-style-type: none;
    position: relative;
  }
}

// form block
.block-form {
  background-color: $grey-light2;
  padding: rem( 26 ) 0 rem( 34 );

  &.center {
    text-align: center;

    [class*="tt-block"]{
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .line {
      text-align: left;
    }
  }

  legend {
    @include hide();
  }

  label,
  .fake-label {
    font-weight: 500;
    margin-bottom: rem( 4 );
  }

  .fake-label {
    clear: both;
    display: block;
  }

  .select,
  .cta {
    width: 100%;
  }

  .cta {
    margin-top: rem( 20 );
  }
}

// Event article
.block-event {

  .header {
    @include clearfix

    [class*="date-time"] {
      float: left;
      margin-bottom: rem( 10 );
    }
    > div {
      padding: rem( 10 ) 0 rem( 16 ) rem( 102 );
    }

    .tt {
      color: $purple;
      font-size: rem( 16 );
      margin: 0 0 rem( 10 );
      text-decoration: underline;
    }
  }

  [class*="icon-"]{
    fill: $purple;
    height: rem( 15 );
    left: 0;
    position: absolute;
    top: 0;
    width: rem( 15 );
  }
  ul {
    list-style-type: none;
    margin: 0;
  }
  li {
    margin: 0 0 rem( 10 );
    padding: 0 0 0 rem( 22 );
    position: relative;
  }

}


// Documents list
.block-documents-list {
  list-style-type: none;
  margin: 0 0 rem( 10 );
  max-width: rem( 835 );

  .tab-content & {
    padding-left: rem( 10 );
    padding-right: rem( 10 );
  }

  .date-time,
  .num-sticker {
    float: left;
  }
  .num-sticker {
    margin-bottom: rem( 10 );
    + .text {
      margin-bottom: rem( 10 );
    }
  }
  .date-time{
    + .text {
      &:before {
        height: rem( 60 );
      }
    }
  }
  .text {
    padding: 0 0 0 rem( 75 );
    margin-bottom: rem( 16 );
    position: relative;
    white-space: nowrap;

    * {
      white-space: normal;
    }

    p {
      margin: 0;
    }

    &:before {
      @include valign;
      height: rem( 40 );
      white-space: nowrap;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
    }
    [class*="icon-"] {
      fill: $purple;
      height: rem( 30 );
      margin: rem( -15 ) 0 0 0;
      position: absolute;
      right: 0;
      top: 50%;
      width: rem( 30 );

      + p {
        padding-right: rem( 40 );
      }
    }
  }

  li {
    @include clearfix;
  }
}


// Block form
[class*="block-subscribe"] {
  [class*="tt-side"]{
    text-align: left;
  }

  // Form
  .form {
    padding-top: rem( 20 );
  }
  .line {
    align-items: flex-start;
    display: flex;
    max-width: rem( 320 );
  }
  .field {
    flex-grow: 1;
    margin-bottom: 0;
    margin-right: rem( 8 );
    vertical-align: top;

    .input {
      width: 100%;
    }
  }
  [class*="cta"] {
    text-align: center;
    vertical-align: top;
    width: rem( 64 );

    &:before {
      height: rem( 23 );
    }
  }

  .no-flexbox & {
    .field {
      display: inline-block;
      margin-right: rem( 5 );
      width: rem( 180 );
    }
  }
}
.block-subscribe-light{
  color: $white;

  .tt-side {
    border-color: $white;
    color: $white;
  }
}


// Documents
.block-document {
  box-shadow: 0 rem( 2 ) rem( 3 ) rgba( $black, 0.3 );
  // max-width: rem( 280 );

  a {
    text-decoration: none;

    .no-touchevents &:hover {
      .picture .layer {
        opacity: 1;
      }
    }
  }

  .picture {
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    .layer {
      background: rgba( $black, 0.4 );
      height: 100%;
      left: 0;
      position: absolute;
      opacity: 0;
      top: 0;
      transition: $transition;
      width: 100%;

      [class*="icon-"]{
        fill: $white;
        height: rem( 94 );
        left: 50%;
        margin: rem( -47 ) 0 0 rem( -47 );
        position: absolute;
        top: 50%;
        width: rem( 94 );
      }
    }

    .info {
      position: absolute;
      right: 0;
      text-align: right;
      top: rem( 8 );
    }

    img {
      display: block;
      margin: 0 0 0 auto;
    }
  }
  &.revert .picture{

    .info {
      left: 0;
      right: auto;
      text-align: left;
    }
    img {
      margin: 0;
    }
  }

  .text {
    font-size: rem( 15 );
    margin: 0;
    padding: rem( 10 ) rem( 5 ) rem( 10 ) rem( 42 );
    position: relative;

    [class*="icon-"]{
      fill: $theme;
      height: rem( 28 );
      left: rem( 5 );
      margin: rem( -14 ) 0 0 0;
      position: absolute;
      top: 50%;
      width: rem( 28 );
    }
  }
  [class*="cta"]{
    display: block;
    width: 100%;
  }
}
.documents-list {
  list-style-type: none;
  margin: 0;
  padding-top: rem( 26 );
  text-align: center;

  [class*="tt-"] + & {
    padding-top: 0;
  }

  li {
    text-align: left;
    margin: 0 0 rem( 36 );
    vertical-align: top;
  }
}

.documents-list-intro {
  font-size: rem( 14 );
  padding-top: rem( 30 );
}


// Block sorting
.block-sorting {
  background: $theme2;
  color: $white;
  padding: rem( 12 ) 0 rem( 6 );

  .form {
    p {
      margin: 0 0 rem( 6 );
      padding-left: 5px;
    }
    label,
    .label {
      display: block;
      font-family: $font-roboto-condensed-regular;
      font-size: rem( 18 );
      margin: 0 0 rem( 6 );
      text-transform: uppercase;
    }
    .input {
      box-sizing: border-box;
      padding-right: rem( 36 );
      width: 100%;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="search"] {
      height: rem( 28 );

      &::placeholder {
        color: $text-color;
      }
    }
    .bt-search {
      background: none;
      border: 0 none;
      padding: 0;
      position: absolute;
      right: rem( 8 );
      top: rem( 5 );

      [class*="icon"]{
        fill: #9f9f9f;
        height: rem( 18 );
        width: rem( 20 );
      }
      span {
        @include hide;
      }
    }

    .check-bt {
      display: block;
      position: relative;
      vertical-align: middle;

      input {
        @include hide();

        &:checked {
          + label {
            background:#182883;
            color: #ffdd00;
          }
        }
      }

      label {
        background: #FFDD00;
        border-radius: rem( 10 );
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        font-family: $font-roboto;
        font-size: rem( 14 );
        font-weight: bold;
        margin: 0 0 rem( 8 );
        min-width: rem( 190 );
        padding: rem( 6 ) rem( 12 );
        text-align: center;
        transition: $transition;
        color : #182883;

        &:hover {
          background: #FFDD00;
        }
      }
    }
  }
}


// Tour operator
.tour-operator-list {
  margin-top: rem( -26 );

  .tour-operator {
    margin-top: rem( 26 );
  }
}
.tour-operator {
  box-sizing: border-box;
  margin: 0 0 rem( 16 );

  .js &.hide {
    display: none;
  }

  .inner {
    background: $white;
    box-shadow: 0 rem( 2 ) rem( 3 ) rgba( $black, 0.1 );
    padding: rem( 85 ) rem( 26 ) rem( 10 ) rem( 26 );
    position: relative;
  }

  .sticker {
    box-shadow: 0 rem( 3 ) rem( 3 ) rgba( $black, 0.1 );
    left: rem( -8 );
    margin: 0;
    position: absolute;
    top: rem( 10 );

    img {
      display: block;
    }

    &:after {
      content: "";
      @include triangle( 8px, $black, up-right );

      bottom: rem( -8 );
      left: 0;
      position: absolute;
    }

    &:before {
      background: url( "#{$url-img}bg-label-shadow.png" ) no-repeat 0 rem( -13 );
      background-size: rem( 136 ) auto;
      bottom: rem( -13 );
      content: "";
      height: rem( 13 );
      left: rem( 8 );
      position: absolute;
      text-align: right;
      width: rem( 136 );
    }
  }
  .title {
    color: $theme4;
    font-size: rem( 20 );
    margin: 0 0 rem( 20 );
    font-weight: 500;
    text-transform: uppercase;
  }

  .button {
    &,
    .section & {
      padding: rem( 30 ) 0 rem( 16 );
      text-align: center;
    }
  }


}

.tour-operator-details {
  padding-bottom :rem( 6 );

  .tour-operator {
    margin-bottom: rem( 10 );

    .inner {
      padding-bottom: rem( 16 );
    }
  }
}

.tour-operator-highlight {
  box-sizing: border-box;
  color: $white;
  font-weight: 500;
  margin-bottom: rem( 16 );
  text-align: center;

  .inner {
    background: $theme4;
    box-sizing: border-box;
    margin: 0;
    padding: rem( 16 );
  }

  strong,
  span {
    display: block;
  }
  strong {
    color: $theme-dark4;
    font-family: $font-advent-pro;
    font-size: rem( 40 );
    line-height: ( 42/40 );
    padding: 0 0 rem( 12 );
  }
  span {
    border-top: 1px solid rgba( $white, 0.6 );
    padding: rem( 16 ) 0 0;
    text-transform: uppercase;
  }
}



// Offers
.block-offer{
  background: $white;
  box-shadow: 0 rem( 2 ) rem( 3 ) rgba( $black, 0.1 );
  margin: 0 0 rem( 26 );

  > a {
    display: block;
    text-decoration: none;
  }

  .picture {
    position: relative;

    .discount {
      position: absolute;
      right: 0;
      text-align: right;
      top: rem( 16 );
    }

    img {
      display: block;
      width: 100%;
    }

    .title {
      background: rgba( $black, 0.6 );
      bottom: 0;
      box-sizing: border-box;
      color: $white;
      font-weight: bold;
      left: 0;
      margin: 0;
      padding: rem( 4 ) rem( 14 );
      position: absolute;
      text-transform: uppercase;
      width: 100%;
    }
  }
  .desc {
    padding: rem( 8 ) rem( 10 ) rem( 2 );

    @include clearfix;

    .pict {
      float: left;

      + .text {
        padding-left: rem( 75 );
      }
    }
  }
  p {
    margin: 0 0 rem( 8 );
  }
  .rating p{
    margin-bottom: 0;
  }
}


// Forms
.form-block {
  padding-bottom: rem( 16 );
  text-align: center;

  [class*="tt-block"]{
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .form-tt {
    text-align: left;

    &:after {
      margin-left: 0;
    }
  }

  .intro {
    margin-bottom: rem( 26 );
  }
}

.forms-area {
  .form-block {
    + .form-block {
      margin-top: rem( 10 );

      &:before {
        background: rgba( $theme-secondary-grey, 0.5 );
        content: "";
        display: block;
        height: rem( 1 );
        margin: 0 auto rem( 26 );
        max-width: rem( 460 );
        width: 80%;
      }
    }
  }
}

// Booking
.booking-list {
  list-style-type: none;
  margin: 0;
}
.booking-link {
  margin: 0 0 rem( 16 ) 0;
  text-align: right;
}

.block-no-booking {
  background: $white;
  box-shadow: 0px 3px 3px rgba( $black, 0.1 );
  margin: 0 0 rem( 16 ) 0;
  padding: rem( 10 );

  @include clearfix;

  .pict {
    float: left;
    width: rem( 60 );

    img {
      display: block;
      max-width: 100%;
    }
  }

  .tt {
    color: $theme-secondary-grey;
    font-size: rem( 15 );
    margin: 0 0 rem( 10 );
    text-transform: uppercase;
  }

  .text {
    padding: 0 0 rem( 10 ) rem( 70 );
    white-space: nowrap;

    &:before {
      @include valign;

      height: rem( 60 );
      vertical-align: bottom;
    }
    .ct {
      display: inline-block;
      vertical-align: bottom;
      width: 100%;
      white-space: normal;
    }

    p {
      margin: 0;
    }
  }

  .bt {
    clear: both;
    text-align: right;
  }

  [class*="cta"]{
    padding-left: rem( 5 );
    padding-right: rem( 5 );
    width: rem( 195 );
  }
}

//
.block-booking {
  background: $white;
  box-shadow: 0px 3px 3px rgba( $black, 0.1 );
  // color: #0a1f25;
  margin: 0 0 rem( 12 ) 0;
  padding: rem( 8 ) rem( 10 );
  position: relative;

  @include clearfix;

  .sticker-label {
    left: 0;
    position: absolute;
    top: rem( 8 );
    z-index: 2;
  }

  .pict {
    float: left;
    margin: 0 0 rem( 10 );

    img {
      display: block;
      width: rem( 195 );
    }
  }
  .address {
    box-sizing: border-box;
    clear: left;
    float: left;
    padding: 0 0 0 rem( 26 );
    position: relative;
    width: rem( 195 );

    [class*="icon"] {
      height: rem( 24 );
      left: 0;
      position: absolute;
      top: rem( 2 );
      width: rem( 18 );
    }

    span {
      font-size: rem( 17 );
    }
  }
  .info {
    padding-left: rem( 202 );
    padding-top: rem( 5 );
    text-align: center;

    div {
      display: inline-block;
      padding: 0 rem( 5 );
      vertical-align: top;
    }

    .tt {
      font-size: rem( 15 );
      font-weight: 500;
      margin: 0 0 rem( 2 );
    }
    [class*="date-time"],
    .time-hour {
      margin: 0 auto rem( 10 );
    }
  }
}


// User
.user-intro {
  font-size: rem( 16 );

  strong {
    font-size: rem( 18 );
  }
}
.block-user {
  color: $white;
  margin: 0 0 rem( 16 );
  padding: rem( 60 ) rem( 20 ) rem( 50 );

  .pict {
    border: 2px solid $white;
    display: block;
    margin: 0 auto rem( 16 );
    width: rem( 167 );
  }
  .name {
    font-size: rem( 16 );
    margin: 0 0 rem( 40 );
    text-align: center;

    &:after {
      background: $white;
      content: "";
      display: block;
      height: rem( 2 );
      margin: rem( 12 ) auto 0;
      width: rem( 50 );
    }
  }
  ul {
    font-weight: 500;
    list-style-type: none;
    margin: 0 auto;
    max-width: 14rem;

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:before {
        content: "> ";
      }
    }
  }
  li {
    border-bottom: 1px solid rgba( $white, 0.4 );
    padding: rem( 6 ) 0;
  }
}

// User form
.user-credentials {
  text-align: center;

  > div {
    display: inline-block;
    text-align: left;
  }
  .link {
    font-size: rem( 12 );
  }
}
.block-user-form {
  padding-bottom: rem( 16 );

  .user-avatar {
    margin: 0 0 rem( 10 );
    width: rem( 148 );

    img {
      display: block;
      max-width: 100%
    }
  }

  .form-intro {
    font-size: rem( 14 );
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }
}

.area-user-form {
  padding-bottom: rem( 16 );

  .block-user-form {
    padding-bottom: 0;
  }

  + .button {
    padding-top: rem( 4 );

    .section & {
      padding-top: rem( 4 );
    }
  }

  &.separator {
    .block-user-form {
      padding-bottom: rem( 4 );

      + .block-user-form {
        border-top: 1px solid $grey;
        padding-bottom: 0;
        padding-top: rem( 20 );
      }
    }
  }
}

.area-profil {
  .block-user-form {
    padding-bottom: 0;
  }
}


// Edito
.edito {
  .wrap {
    max-width: rem( 730 );
  }
}
.edito-header{
  text-align: center;

  .picture{
    margin: 0 0 rem( 16 );

    img {
      display: block;
      margin: 0 auto;
      max-width: rem( 185 );
    }
  }

  [class*="tt-block"]{
    small {
      font-family: $font-roboto;
      font-size: rem( 14 );
      font-weight: 500;
      text-transform: none;
    }
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}


// Cookies
.block-cookies {
  background: $theme3;
  color: $white;
  position: sticky;
  top: 0;
  z-index: 1000;

  .wrap {
    padding-bottom: rem( 16 );
    padding-top: rem( 16 );
  }

  &[aria-hidden="true"]{
    display: none;
  }

  .link {
    color: $white;
    font-weight: normal;
    font-style: normal;
    font-size: 1em;

    &:hover {
      color: $white;
    }
  }
}
