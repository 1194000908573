//------------------------------------
// #BOOKING PAGES
//------------------------------------

#booking-search {
  background: $grey-light2;
  padding: 0 0 0 0;
  position: relative;
}

// Title
.booking-tt {
  // background: $theme3;
  color: $white;
  font-family: $font-advent-pro;
  font-size: rem( 19 );
  font-weight: normal;
  margin: 0;
  padding: rem( 20 ) 0 rem( 16 );
  text-transform: uppercase;
}


// Search Form
.offer-search-form {

  .bg-wrapper {
    background-color: #41575d;
    background-position: 0 0;
    background-size: 100% auto;
  }

  .form-header-booking {
    background: rgba( $theme3, 0.5 );
    padding-bottom: 0;
    padding-top: 0;

    .checkbox {
      clear: left;

      input {
        margin-left: rem( 10 );
        margin-top: rem( 20 );
      }

      label {
        font-size: rem( 15 );
        margin: 0;
        text-transform: none;
      }
    }

    .select-block {
      padding: 0;

      + .select-block {
        padding: 0 0 0 rem( 1 );
      }
    }

    .field {
      float: left;
      width: 50%;

      &:nth-child(odd){
        clear: left;
      }

      &:nth-child(n+3){
        padding-top: rem( 12 );
      }
    }

    .associated-line {
      button {
        background-color: transparent;
        border: 0 none;
        display: block;
        padding: 0;
        margin: 0 0 rem( -16 ) auto;

        [class*="icon"]{
          fill: $white;
          height: rem( 26 );
          transform:  matrix( 0, 1, -1, 0, 0, 0 );
          width: rem( 24 );
        }
      }
    }
  }

  .buttons {
    .cta {
      display: block;
      width: 100%;
    }
  }
}

// Form
.booking-form {
  padding-bottom: rem( 12 );
  padding-top: rem( 12 );

  .js &.off {
    display: none;
  }
}

// Actions
.booking-action {
  background: $white;
  box-shadow: 0 2px 3px rgba( $black, 0.1 );
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;

  .js & {
    display: none;

    &.on {
      display: block;
    }
  }

  .action-head {
    background: $theme3;
    color: $white;
    padding: rem( 12 ) 0;

    .wrap {
      box-sizing: border-box;
      padding: 0 rem( 8 );
    }

    .tt {
      color: $white;
      font-family: $font-roboto-condensed-regular;
      float: left;
      font-size: rem( 15 );
      font-weight: normal;
      margin: rem( 12 ) 0 0 0;
      text-transform: uppercase;
    }
    [class*="cta"]{
      float: right;
    }
  }

  .form-filters {
    padding-left: rem( 8 );
    padding-right: rem( 8 );

    fieldset {
      border: 0;
    }

    .form-details {
      padding: 0;
    }

    .form-fields {
      padding-top: rem( 10 );
    }

    .legend {
      background: #bfc7c9;
      box-sizing: border-box;
      color: $theme3;
      padding: rem( 8 ) pct( 10/284 );
      margin: 0 calc( #{pct( -10/284 )} - #{rem( 8 )} ) rem( 6 );

      span {
        display: block;
        padding: 0 rem( 28 ) 0 rem( 8 );
        position: relative;
      }

      .toggle-bt {
        left: auto;
        right: rem( 8 );

        &:before {
          content: "";
          display: block;
          @include triangle( 9px, $theme3, up );
        }
      }
    }
    .buttons {
      padding-top: rem( 12 );
    }
  }
  .filters-tt {
    font-family: $font-advent-pro;
    font-size: rem( 15 );
    margin: 0 0 rem( 10 );
  }
}

// Recap
.booking-recap {

  .js &.off {
    display: none;
  }

  .wrap {
    padding-bottom: rem( 12 );
    padding-top: 0;
  }

  p {
    margin: 0 0 rem( 5 );
  }
  .icon-arrow-next {
    fill: $white;
    height: rem( 10 );
    margin: 0 rem( 8 );
    width: rem( 16 );
  }
  fieldset {
    padding: rem( 15 ) 0 rem( 5 );
  }
  .col {
    margin: 0 calc( #{pct( -10 / 300 )} - #{rem( 8 )} );

    .line {
      box-sizing: border-box;
      padding: 0 rem( 1 ) 0 0;
      float: left;
      width: 50%;

      + .line {
        padding: 0 0 0 rem( 1 );
      }
    }
  }
  [class*="cta"]{
    display: block;
    width: 100%;
  }
  .banner {
    display: none;
  }

  .rental-booking & {
    p {
      margin: 0 0 rem( 14 );

      > span {
        display: inline-block;
        vertical-align: top;

        span {
          display: block;
        }
      }
    }
    [class*="cta"]{
      margin-bottom: rem( 8 );
    }
  }
}

// Search Results
.booking-results {
  padding: rem( 20 ) 0 0;
  position: relative;

  .results-actions {
    padding: 0 rem( 8 ) rem( 16 );

    @include clearfix;

    div {
      box-sizing: border-box;
      float: left;
      padding: 0 rem( 1 ) 0 0;
      width: 50%;

      + div {
        padding: 0 0 0 rem( 1 );
      }

      &.full {
        float: none;
        padding: 0;
        width: auto;
      }
    }

    button {
      background: $white;
      border: 1px solid #b7bdbd;
      border-radius: rem( 8 );
      color: $theme-dark2;
      display: block;
      font-family: $font-roboto-condensed-regular;
      font-weight: bold;
      padding: rem( 10 ) rem( 5 );
      text-align: center;
      text-transform: uppercase;
      transition: $transition;
      width: 100%;

      &:hover,
      &:focus {
        background: $theme3;
        border-color: $theme3;
        color: $white;
      }
    }
  }

  .sort {
    display: none;
  }
}
.booking-list {
  list-style-type: none;
  margin: 0;
}

// Blocks
.booking-block {
  background: $white;
  box-shadow: 0 2px 3px rgba( $black, 0.1 );
  margin: 0 0 rem( 25 );
  padding: 0 0 rem( 30 );
  position: relative;

  // Airport details
  .airport {
    background: $theme2;
    border-radius: rem( 8 );
    box-sizing: border-box;
    color: $white;
    align-content: space-between;
    font-size: rem( 13 );
    line-height: 1;
    margin: 0;
    padding: rem( 4 );
    text-align: center;
    width: rem( 48 );

    b {
      display: block;
      font-weight: 500;
      padding: rem( 3 ) 0;
    }

    span {
      border-top: 1px solid rgba( $white, 0.7 );
      font-family: $font-roboto-condensed-regular;
      display: inline-block;
      padding: rem( 3 ) 0;
    }
  }

  // Flight details
  .flight {
    + .flight {
      border-top: 1px solid rgba( $theme3, 0.4 );
    }
  }

  // logo
  .logo {
    border-bottom: 1px solid rgba( $theme3, 0.4 );
    padding: rem( 10 ) rem( 6 );

    img {
      display: block;
      max-width: rem( 170 );
    }
  }

  //schedule
  .schedule {
    box-sizing: border-box;
    display: table;
    padding: rem( 14 ) rem( 6 );
    width: 100%;

    .time,
    .info {
      display: table-cell;
      vertical-align: top;
    }

    .time {
      width: rem( 48 );

      &:last-of-type {
        width: rem( 80 );
      }
    }
    .info {
      padding: rem( 3 ) rem( 14 ) 0;
      line-height: 1;
      text-align: center;

      p {
        margin: 0;
      }
    }

    .travel {
      font-weight: bold;
      padding: rem( 16 ) 0 0;
      position: relative;

      &:before {
        content: "";
        background: #bfc7c9;
        height: rem( 4 );
        left: 0;
        position: absolute;
        top: rem( 6 );
        width: calc( 100% - 5px );
      }
      &:after {
        content: "";
        @include triangle( 16px, #bfc7c9, right );

        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }

      &.stopover {

        p:first-child {
          &:before {
            background: #204865;
            border-radius: 100%;
            content: "";
            height: rem( 6 );
            left: 50%;
            margin: 0 0 0 rem( -3 );
            position: absolute;
            top: rem( 5 );
            width: rem( 6 );
          }
        }
      }
    }

    .airport {
      display: inline-block;
      vertical-align: bottom;
    }
    .delay {
      display: inline-block;
      font-family: $font-roboto-condensed-regular;
      margin: 0 0 0 rem( 2 );
      vertical-align: bottom;
    }
  }

  // link
  .action {
    background: $grey-light2;
    box-sizing: border-box;
    bottom: 0;
    font-family: $font-roboto-condensed-regular;
    height: rem( 30 );
    left: 0;
    padding: rem( 6 ) rem( 26 ) 0 rem( 6 );
    position: absolute;
    text-decoration: none;
    width: 100%;

    &:after,
    &:before {
      content: "";
      background: $theme3;
      position: absolute;
      right: rem( 6 );
      top: 50%;
    }

    &:before {
      height: rem( 11 );
      margin: rem( -5 ) rem( 5 ) 0 0;
      width: rem( 1 );
    }
    &:after {
      height: rem( 1 );
      width: rem( 11 );
    }
  }

  // price
  .price {
    background: $theme3;
    color: $white;
    padding: rem( 12 ) rem( 6 ) rem( 12 ) rem( 36 );

    @include clearfix;

    div {
      box-sizing: border-box;
      float: left;
      width: 50%;

      + div {
        text-align: right;
      }
    }

    p {
      font-size: rem( 14 );
      margin: 0;
      padding: rem( 5 ) 0 0 0;
      text-transform: uppercase;

      b {
        font-size: rem( 22 );
        font-weight: 500;
      }
    }
  }
}

// Rental
.rental-block {
  background: $white;
  box-shadow: 0 2px 3px rgba( $black, 0.1 );
  margin: 0 0 rem( 25 );
  position: relative;

  .details {
    @include clearfix;
  }

  .sticker-label {
    margin-bottom: rem( 20 );

    span {
      min-width: rem( 210 );
      text-align: right;
    }

    &.revert span {
      text-align: left;
    }
  }

  .type {
    float: right;
    width: 50%;

    img {
      display: block;
      margin: 0 auto;
      max-width: 90%;
    }

    p {
      font-family: $font-roboto-condensed-regular;
      font-size: rem( 11 );
      margin: 0 0 rem( 10 );
      text-align: center;
    }
  }

  .desc {
    box-sizing: border-box;
    float: left;
    padding: 0 0 0 rem( 15 );
    width: 50%;

    .badge {
      background: $theme2;
      border-radius: rem( 8 );
      box-sizing: border-box;
      color: $white;
      display: inline-block;
      font-size: rem( 14 );
      font-weight: 500;
      height: rem( 50 );
      margin: 0 rem( 12 ) rem( 10 ) 0;
      padding: rem( 6 ) 0 0;
      text-align: center;
      width: rem( 50 );

      span {
        @include hide;
      }

      [class*="icon"] {
        display: block;
        fill: $white;
        height: rem( 18 );
        margin: 0 auto rem( 3 );
        width: rem( 30 );
      }
    }

    ul {
      font-size: rem( 12 );
      list-style-type: none;
      margin: 0 0 rem( 10 );

      li {
        @include clearfix;
      }

      p {
        font-weight: 500;
        margin: 0;
        padding: rem( 6 ) 0 0 rem( 26 );
        text-transform: uppercase;
      }

      small {
        display: none;
      }

      [class*="icon"]{
        float: left;
        height: rem( 26 );
        width: rem( 20 );
      }
    }
  }

  // price
  .price {
    background: $theme3;
    color: $white;
    padding: rem( 12 ) rem( 6 ) rem( 12 ) rem( 36 );

    @include clearfix;

    .logo {
      display: none;
    }

    div {
      box-sizing: border-box;
      float: left;
      width: 50%;

      + div {
        text-align: right;
      }
    }

    p {
      font-size: rem( 14 );
      margin: 0;
      padding: rem( 5 ) 0 0 0;

      b {
        font-size: rem( 22 );
        font-weight: 500;
      }
    }
  }
}

.booking-selection {
  display: none;
}


// Popins
.popin-booking {

  #popin & .wrap {
    width: pct( 300 / 320 );
  }

  .close {
    background: $theme3;
    border: 0 none;
    border-radius: rem( 8 );
    height: rem( 40 );
    padding: 0;
    position: absolute;
    right: 0;
    top: rem( 10 );
    width: rem( 40 );

    [class*="icon"]{
      display: block;
      height:rem( 40 );
      fill: $white;
      margin: 0 auto;
      width: rem( 20 );
    }

    span {
      @include hide;
    }
  }

  .popin-content {
    padding: rem( 60 ) 0 0;

    .inner {
      background: $grey-light2;
      padding: rem( 16 ) 0 0;
    }
  }

  [class*="tt-block"]{
    margin-bottom: rem( 16 );
    text-align: center;

    &:after {
      margin-left: auto;
      margin-right: auto;
    }

    small {
      display: block;
      font-family: $font-roboto;
      font-size: rem( 11 );
      font-weight: 500;
      text-transform: none;
    }
  }

  // Booking recap
  .booking {
    margin: 0 auto rem( 18 );
    max-width: rem( 450 );

    .details {
      background: $theme3;
      color: $white;
      padding: rem( 14 ) 0 rem( 30 );

      @include clearfix;

      .intro {
        font-weight: 500;
        padding: 0 rem( 10 );
        text-align: center;

        p {
          margin: 0;
        }
      }
    }

    // Recap
    .recap {
      box-sizing: border-box;
      float: left;
      font-size: rem( 15 );
      padding: 0 rem( 18 ) 0 0;
      margin: rem( 10 ) 0 0 0;
      position: relative;
      text-align: right;
      width: 50%;

      p {
        margin: 0;
      }

      b {
        font-size: rem( 25 );
      }

      &:before {
        background: $white;
        content: "";
        height: calc( 100% - #{rem(8)} );
        position: absolute;
        right: 0;
        top: rem( 6 );
        width: rem( 1 );
      }
    }

    // logo button
    .action {
      box-sizing: border-box;
      float: left;
      padding: rem( 16 ) rem( 16 ) 0;
      width: 50%;

      div {
        max-width: rem( 140 );
      }

      [class*="cta"]{
        display: block;
        width: 100%;
      }

      img {
        display: block;
        margin: 0 0 rem( 3 );
        max-width: 100%;
      }
    }

    // Waring
    .warning {
      background: $grey-light4;
      padding: rem( 5 ) rem( 16 );
      text-align: right;

      .info {
        background: $theme3;
        border-radius: 100%;
        display: inline-block;
        height: rem( 12 );
        margin: 0 rem( 6 ) 0 0;
        vertical-align: middle;
        width: rem( 12 );

        [class*="icon"]{
          display: block;
          fill: $white;
          height: rem( 8 );
          margin: rem( 2 ) auto 0;
          width: rem( 10 );
        }
      }

      a {
        color: $theme-dark2;
        font-family: $font-roboto-condensed-regular;
        font-size: rem( 10 );
        text-decoration: none;
        text-transform: uppercase;
        padding: 0 rem( 10 ) 0 0;
        position: relative;

        &:after {
          content: "";
          @include triangle( 6px, $theme3, down );
          margin: rem( -3px ) 0 0;
          position: absolute;
          right: 0;
          top: 50%;
        }
      }
    }
  }

  .flight {
    background: $white;
    box-shadow: 0 rem( 2 ) rem( 3 ) rgba( $black, 0.1 );
    margin: 0 0 rem( 16 );

    .head {
      background: $theme3;
      color: $white;
      padding: rem( 18 ) 50% rem( 18 ) rem( 10 );
      text-align: right;

      p {
        display: inline-block;
        font-family: $font-advent-pro;
        vertical-align: middle;
        // padding-right: rem( 16 );
        margin: 0 rem( 16 ) 0 0;
        text-align: center;
        text-transform: uppercase;

        [class*="icon-arrow"] {
          display: block;
          fill: $white;
          height: rem( 18 );
          margin: rem( 6 ) auto 0;
          width: rem( 30 );
        }
      }
      .date-time {
        display: inline-block;
        margin: 0 rem( -30 ) 0 0;
        vertical-align: middle;
      }
    }

    &.back {
      .head {
        p {
          [class*="icon-arrow"] {
            transform:matrix(-1.00,0.00,-0.00,-1.00,0,0);
          }
        }
      }
    }

    .desc {
      font-size: rem( 11 );
      padding: rem( 4 ) rem( 22 ) 0 rem( 8 );
    }

    .name {

      p {
        margin: 0 0 rem( 5 );
      }
      small {
        font-style: italic;
      }
    }

    .travel {
      padding: rem( 6 ) rem( 40 ) rem( 10 ) 0;
      position: relative;

      .total {
        border-left: rem( 3 ) solid #bfc7c9;
        bottom: 0;
        font-family: $font-roboto-condensed-regular;
        height: 100%;
        margin: 0;
        padding: 0 0 0 rem( 5 );
        position: absolute;
        right: 0;
        width: rem( 37 );

        &:before {
          content: "";
          @include valign;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      &:first-of-type {
        .total {
          height: calc( 100% - #{rem( 48 )} );
        }
      }

      &:last-of-type {
        .total {
          bottom: auto;
          height: calc( 100% - #{rem( 14 )} );
          top: 0;

          &:after {
            content: "";
            @include triangle( 12px, #bfc7c9, down );

            left: rem( -7 );
            bottom: rem( -2 );
            position: absolute;
          }
        }

        &:first-of-type {
          .total {
            height: calc( 100% - #{rem( 62 )} );
            top: rem( 48 );
          }
        }
      }
    }

    .connection {
      background: $grey-light2;
      color: rgba( $theme3, 0.8 );
      padding: rem( 4 ) rem( 40 ) rem( 4 ) rem( 50 );
      position: relative;

      b {
        font-weight: 500;
        text-transform: uppercase;
      }

      p {
        margin: 0;
      }

      .total {
        border-left: rem( 3 ) solid $white;
        bottom: 0;
        font-family: $font-roboto-condensed-regular;
        height: 100%;
        margin: 0;
        padding: 0 0 0 rem( 5 );
        position: absolute;
        right: 0;
        width: rem( 29 );

        &:before {
          content: "";
          @include valign;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .time {
      background: $grey-light2;
      border-radius: rem( 6 );
      display: inline-block;
      font-family: $font-roboto-condensed-regular;
      height: rem( 40 );
      margin: 0;
      text-align: center;
      vertical-align: middle;
      width: rem( 40 );

      &:before {
        content: "";
        @include valign;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
      small {
        display: block;
        font-size: 1em;
      }

      &.start {
        background: $theme2;
        color: $white;
      }
    }

    .line {
      display: table;
      width: 100%;

      + .line {
        margin: rem( 6 ) 0 0;
      }

      .time,
      .text {
        display: table-cell;
        margin: 0;
        vertical-align: middle;
      }
      .text {
        font-weight: 500;
        padding: 0 rem( 10 );
      }
    }

    .recap {
      background: $theme2;
      color: $theme3;
      padding: rem( 10 );
      text-align: center;

      p {
        margin: 0;
      }
      b {
        font-weight: 500;
      }
    }
  }

  .offers-section {
    background: $white;
  }


  // Redirection
  .redirect {
    padding: rem( 10 ) 0;

    // from
    .from {
      display: table;
      margin: 0 auto rem( 26 );
      max-width: rem( 290 );
      width: 100%;

      .logo {
        background: $white;
        box-shadow: 0 2px 3px rgba( $black, 0.1 );
        box-sizing: border-box;
        display: table-cell;
        padding: rem( 10 ) rem( 18 ) rem( 12 ) rem( 15 );
        position: relative;
        width: rem( 150 );
        vertical-align: middle;

        &:after {
          content: "";
          @include triangle( 14px, $white, right );
          margin: rem( -7 ) 0 0;
          position: absolute;
          right: rem( -7 );
          top: 50%;
        }

        img {
          display: block;
          max-width: 100%
        }
      }

      p {
        display: table-cell;
        margin: 0;
        padding: 0 0 0 rem( 15 );
        vertical-align: middle;
      }
    }

    // pending
    .pending {
      color: $theme2;
      display: table;
      margin: 0 auto rem( 26 );
      max-width: rem( 290 );
      width: 100%;

      .pict {
        display: table-cell;
        height: rem( 64 );
        vertical-align: middle;
        width: rem( 165 );

        &:before {
          background: url( "#{$url-img}desktop/2x/bg-search.png" ) no-repeat 0 0;
          background-size: 100% auto;
          content: "";
          display: block;
          height: rem( 43 );
          margin: 0 auto;
          transform: rotate( 90deg );
          width: rem( 64 );
        }
      }


      p {
        display: table-cell;
        margin: 0;
        vertical-align: middle;
      }
    }
  }

  // to
  .to {
    display: table;
    margin: 0 auto rem( 26 );
    max-width: rem( 290 );
    width: 100%;

    .logo {
      display: table-cell;
      width: rem( 150 );
      vertical-align: middle;

      img {
        display: block;
        max-width: 100%
      }
    }

    p {
      display: table-cell;
      margin: 0;
      padding: 0 0 0 rem( 15 );
      vertical-align: middle;
    }
  }

  .recap-details {
    background: $white;
    box-shadow: 0 2px 3px rgba( $black, 0.1 );
    font-size: rem( 15 );
    font-weight: 500;
    margin: 0 0 rem( 16 );
    padding: rem( 16 ) rem( 12 );

    [class*="tt-block"] {
      font-size: rem( 17 );
      margin-bottom: rem( 20 );
    }
    p {
      margin-bottom: rem( 6 );
    }

    .line {
      display: table;
      margin: 0 0 rem( 6 );
      text-align: left;
      width: 100%;

      > div,
      > p {
        display: table-cell;
      }
      > div {
        p {
          display: inline-block;
          margin: 0;
          vertical-align: middle;
        }

        .icon-arrow-next {
          display: inline-block;
          fill: #bfc7c9;
          height: rem( 10 );
          vertical-align: middle;
          width: rem( 22 );
        }
      }
      > p {
        margin: 0;
        text-align: right;
      }
    }

    .recap-block {
      text-align: right;
    }

    .logo,
    .price {
      display: inline-block;
      margin: rem( 16 ) 0 rem( 10 );
      vertical-align: middle;
    }

    .logo {
      padding: 0 rem( 6 ) 0 0;

      img {
        display: block;
        max-width: rem( 90 );
      }
    }

    .price {
      border-left: 1px solid #bfc7c9;
      font-size: rem( 16 );
      padding: 0 0 0 rem( 8 );
      text-align: left;

      b {
        display: block;
        font-size: rem( 22 );
        font-weight: 500;
      }
    }
  }

  .rental-block {
    margin-bottom: 0;
  }

  .rental-recap {
    background: #6ac7dd;
    box-shadow: 0 2px 3px rgba( $black, 0.1 );
    margin: 0 0 rem( 16 );
    padding: rem( 16 ) rem( 26 ) rem( 6 );
    position: relative;
    z-index: 1;

    ul {
      list-style-type: none;
      margin: 0;
    }

    li {
      padding: 0 0 rem( 10 );
    }
  }
}
