.feature-push-discover {
    .tt-block {
        .span1 {
            font-size: 24px;
        }
        .span2 {
            font-size: 18px;
        }
    }
}

.tt-html{
    font-size: 1.5625rem;
}