//------------------------------------
// #PUSHES
//------------------------------------


// Feature push
[class*="feature-push"] {

  [class*="cols"] {
    margin: 0 rem( -10 );
  }
  [class*="col-"] {
    float: left;
    padding-left: rem( 10 );
    padding-right: rem( 10 );
  }
  .revert  {
    float: right;
  }
  .col-one-quarter,
  .col-three-quarter {
    width: 100%;
  }
  .col-one-third {
    width: pct( 1/3 );
  }
  .col-two-third {
    width: pct( 2/3 );
  }
  .col-half {
    width: 50%;
  }
  .button {
    padding-top: rem( 14 );
    text-align: left;
  }
  .slider {
    margin: rem( 10 ) 0 rem( 26 );
  }

  .revert {
    [class*="col-"] {
      float: right;
    }
  }

  .cols-centered {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .col-one-quarter,
    .col-three-quarter,
    .col-half,
    .col-one-third,
    .col-two-third {
      flex-basis: auto;
    }

    &.revert {
      flex-direction: row-reverse;
    }
  }
}


// Push islands
.feature-push-island {
  .picture {
    margin-right: 0;
    margin-left: 0;
    padding: 0 0 rem( 16 );
    width: auto;

    img {
      width: 100%;
    }
  }
  .text {
    width: auto;
  }

  .revert {
    .picture {
      margin-right: 0;
      margin-left: 0;
    }
    .text {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}


// Distinctive green push
.push-distinctive {
  overflow: hidden;

  .cols {
    display: table;
    margin: 0 pct( -10 / 300 );
    table-layout: fixed;
    width: pct( 320 / 300 );
  }
  [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
  }
  .text {
    box-sizing: border-box;
    max-width: rem( 400 );
  }
  .col-one-third {
    width: 66%;

    .text {
      padding-left: pct( 10 / 66 / 300 * 100 );
      padding-right: pct( 10 / 66 / 300 * 100 );
    }
  }
  .col-two-third {
    width: 34%;

    .text {
      padding-left: pct( 10 / 34 / 300 * 100 );
      padding-right: pct( 10 / 34 / 300 * 100 );
    }
  }
  .col-half {
    width: 50%;

    .text {
      padding-left: pct( 10 / 50 / 300 * 100 );
      padding-right: pct( 10 / 50 / 300 * 100 );
    }
  }
  .picture {
    float: right;
    margin: 0;
    max-width: rem( 600 );
    width: 50vw;

    img {
      margin: 0 0 0 auto;
    }

  }
  [class*="col-"] {
    + [class*="col-"] {
      .picture {
        float: left;

        img {
          margin: 0 auto 0 0;
        }
      }
    }
  }
}


// Tiles push
.push-tiles {

  .tiles-list {
    margin: 0 rem( -5 );
    padding-top: rem( 10 );

    li {
      box-sizing: border-box;
      float: left;
      padding-left: rem( 5 );
      padding-right: rem( 5 );
      width: pct( 1/3 );
    }
  }

  .caption {
    width: auto;
  }
}


// Number push
.push-numbers {
  ul {
    width: auto;
  }
}


// Map push
.push-map {
  margin: 0;

  .picture {
    margin: 0 auto;
    max-width: rem( 625 );
  }
  li {
    &:nth-child(1){
      left: pct( 130/320 );
    }
    // &:nth-child(2){
    //   right: pct( 240/320 );
    //   top: pct( 100/216 );
    // }
    &:nth-child(4){
      left: pct( 255/320 );
      top: pct( 50/216 );
    }
  }
}


// Tour push
.push-tour {
  margin-left: auto;
  margin-right: auto;
  max-width: rem( 660 );

  .date-sticker {
    left: rem( 40 );
  }

  .picture {
    margin: 0;
  }
  .text {
    box-sizing: border-box;
    margin-left: rem( 40 );
    margin-right: 0;
    width: rem( 480 );
  }
}


// Slider push
.push-slider {
  .slider {
    margin-left: auto;
    margin-right: auto;
  }
  .slick-arrow {
    &.slick-prev {
    }
    &.slick-next {
    }
  }
}



// Push Explain
.push-highlight {
  padding: rem( 26 ) 0;

  ul {
    margin: 0 rem( -20 );
  }
  li {
    display: inline-block;
    margin-left: rem( 20 );
    margin-right: rem( 20 );
    max-width: rem( 280 );
    vertical-align: top;
  }
}


// Push discover
.feature-push-discover {

  .tt-block {
    margin-bottom: rem( 16 );
    text-align: left;

    &:after {
      margin-left: 0;
    }
  }
}

.push-discover {

  .inner{
    &:before {
      height: rem( 280 );
    }
  }

  .text {
    padding-left: rem( 26 );
    padding-right: rem( 26 );
  }
  
  .highlight {
    margin-left: rem( -26 );
    margin-right: rem( -26 );
    padding-left: rem( 26 );
    padding-right: rem( 26 );
  }

  &.small {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-left: 0;
    padding-right: rem( 6 );
  }

  &.small:nth-of-type(even) {
    padding-right: 0;
    padding-left: rem( 6 );
  }

}