//------------------------------------
// #LAYOUT #FOOTER
//------------------------------------

footer[role="contentinfo"] {
  background: #ffdd00;
  color: $black;
  line-height: (19 / 13);
  padding: rem(10) 0 0;
  margin-top: 50px;

  .wrap {
    width: 1280px;
  }

  a {
    text-decoration: none;
  }

  .dechirure-top {
    width: 100%;
    margin-top: -44px;
  }

  .cols {
    ul {
      list-style-type: none;
      margin-bottom: 5px;
    }
    /*     p {
      margin-bottom: rem( 15 );
    } */
  }

  a:not(.link-light) {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  .block {
    padding-bottom: rem(10);
    padding-top: rem(26);
  }
  .col {
    width: 50%;
    height: 100%;
    float: right;
    display: contents;
    padding-left: 0px;
    padding-right: 0px;

    .image_footer {
      width: 320px;
      height: 400px;
      position: relative;
      margin-left: 80px;
      margin-top: -100px;
    }

    .footer_image_bird {
      position: absolute;
      z-index: 1;
      top: 186px;
      margin-left: 5px;
    }
  }
  .col-form {
    flex-direction: column;

    .line {
      border-bottom: 1px solid #000000;
    }
    .social {
      display: flex;
      padding-bottom: 30px;
      .social_span {
        font-size: 22px;
        padding-top: 55px;
        width: 100%;
        font-weight: bold;
      }

      .icons_social {
        padding-top: 55px;
        display: flex;
        img {
          height: 24px;
          max-width: initial;
          margin-left: 30px;
        }
      }

      svg {
        margin-left: 30px;
      }
    }

    .button-top {
      position: absolute;
      top: -90px;
      right: 0;
      margin-right: -20px;
    }
  }

  .sk-EncartNewsletter {
    border-radius: 25px;
    background-color: white;
    font-family: "Roboto Condensed Regular";
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    padding: 7px 20px;
  }
  .desc-nl {
    font-family: $roboto-condensed-regular;
    font-size: 28px;
    padding-top: 20px;
    padding-bottom: 20px;
    strong {
      font-weight: bold;
    }
  }

  .contact {
    border-top: 0;
    padding-bottom: rem(20);
    //text-align: center;

    p {
      display: inline-block;
      margin-bottom: rem(10);
      padding-top: rem(20);
      text-align: left;
    }
    .link {
      color: $black;

      &:hover {
        color: $black;
      }
    }
  }

  [class*="icon"] {
    fill: $white;
  }

  // Form
  .field {
    display: inline-block;
    margin-bottom: 0;
    margin-right: rem(5);
    vertical-align: top;
    white-space: nowrap;
    .input {
      vertical-align: top;
      width: 255px;
      background: #ffdd00;
      border: none;
      margin-left: -10px;

      .placeholder {
        font-family: "Roboto Condensed Regular";
        color: #000000;
        font-size: 30px;
        font-weight: bold;
        white-space: nowrap;
      }
      input {
        background: transparent;
        border: 0px;
        height: 45px;
        margin-left: 10px;
      }
    }
  }
  .cols .field {
    margin-bottom: 0;
  }

  .cta-submit {
    text-align: center;
    vertical-align: top;
    //width: rem( 64 );
    background: url(../img/Path2.png) no-repeat;
    color: #000000;
    border: none;
    margin-top: 10px;
    float: right;

    &:before {
      height: rem(23);
    }
  }

  .cta-fb {
    > span {
      + span {
        width: rem(251);
      }
    }
  }
}

.footer-links {
  /*display: flex;
    flex-wrap: wrap;
    justify-content: space-around;*/
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  .col {
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 15px;
    font-family: "roboto light";
    font-size: 13px;

    #section-left,
    #section-right {
      width: 25%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      display: flex;
      font-family: $roboto;
      font-weight: 200;
    }

    #section-middle {
      width: 100%;
      display: flex;
      padding-top: 15px;
      font-family: $roboto;
      font-weight: 200;
      justify-content: center;
      // padding-left: 23%;
      //white-space: nowrap;
/* 
      div:first-child {
        margin-left: auto;
      } */

      .second-colonne {
        padding-left: 40px;
      }

      [class*="icon"] {
        left: 0;
        position: absolute;
        top: 15%;
        transform: translateY(-50%);
      }
      .icon-phone {
        height: rem(20);
        left: rem(3);
        width: rem(12);
        margin-top: 6px;
      }
      .icon-mail {
        height: rem(12);
        width: rem(18);
        margin-top: 6px;
      }
      .contact-link {
        display: inline-block;
        margin: 0 0 rem(10);
        padding: 0 0 0 rem(26);
        position: relative;
        margin-right: 10px;
      }
    }

    .block {
      padding-left: 15px;

      
      .tt-raw-light > span {
        color: black;
        font-family: "Roboto";
        font-size: 13px;
        font-weight: bold;
        margin-right: 5px;
        margin-top: 3px;
        padding-left: 10px;
        vertical-align: top;

      }
      ul {
        list-style: none;
        padding-left: 10px;
        line-height: 23px;
      }
    }
  }
}

// Disclaimer links
.disclaimer {
  font-size: rem(12);
  padding: rem(12) 0;
  text-align: center;
  font-weight: bold;

  .menu-disclaimer {
    list-style-type: none;
    justify-content: space-between;
    display: flex;
    margin: 0px 50px;
    flex-flow: wrap;
  }
  li {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    //padding-right: 50px;

    a {
      vertical-align: middle;
    }

    + li {
      padding-left: 0;

      // &:before {
      //   content: "";
      //   display: inline-block;
      //   height: rem( 10 );
      //   margin-right: rem( 15 );
      //   vertical-align: middle;
      //   width: rem( 1 );
      // }
    }
  }
}
.footer-links-mobile {
  display: none;
}
.dropDown-link {
  display: none;
}
