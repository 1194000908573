.dropdown-location {
    max-width: 61.25rem;
    width: 94.8%;
    background-color: white;
    margin-top: -0.7rem; //-0.9rem;
    position: absolute;
    z-index: 9;
    padding: 1rem;
    padding-right: 1.5rem;
    box-sizing: border-box;
    box-shadow: 20px 20px 50px 10px rgba(0,0,0,0.3);

    font-family: $font-advent-pro;
    font-size: 1rem;

    display: none;
    opacity: 0;
    transition: opacity 0.2s;

    &--visible {
        display: block;
        opacity: 1;

    }

    #content {
        overflow-y: scroll;
        height: 400px;
        -ms-overflow-style: none; /* hide browser scrollbar on IE10+ */

        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    &__all {
        color: $theme-legend;
        background-color: $grey-light2;
        padding: 1rem;
        border: solid 0.2rem white;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: bold;

        &:hover {
            background-color: #d8d8d8;
            cursor: pointer;
        }
    }

    &__table {
        display: block;
        width: 100%;
        font-family: $font-roboto-condensed-regular;
    }

    & tbody {
        display: inline-block;
        width: 100%;
    }

    & tbody > tr > td {
        width: 100% !important;
        display: inline-block;
        text-align: left;
        box-sizing: border-box;
    }

    & td {
        color: $theme-legend;
        border: solid 0.2rem white;
        background-color: $grey-light2;
        padding: 0.5rem 1rem;
        font-weight: bold;

        &:hover {
           background-color: #d8d8d8;
           cursor: pointer;
        }
    }

    .island {
        text-transform: uppercase;
        font-family: $font-advent-pro;
        font-size: 1.25rem;
    }

    .region {
        color: black;
    }

    & .region, &.city {
        border-top: none;
        border-bottom: none;
    }

    & td:last-child {
        color: #b0b0b0;
        text-align: left;
        padding: 0;
        border-top: none;

        &:hover {
            background-color: $grey-light2;
        }
    }

    .last-col {
        margin: 0.5rem 0;
        width: 100%;
    }

    .last-col td {
        padding: 0.5rem 0;
        font-weight: normal;
        border: none;
        padding-left: 1rem;

        &:hover {
            background-color: #d8d8d8;
            cursor: pointer;
        }
    }

    &__icon {
        display: none;
        fill: #C8D501;
        width: 30%;
        margin: 0 auto;
        margin-top: 1rem;
    }

    & td:hover > &__icon {
        fill: white;
    }
}

.form-layer.on .dropdown-location {
    margin-top: -10rem; //-9.3rem;
}