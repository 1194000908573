//------------------------------------
// #BLOCKS
//------------------------------------

// Island city block
.block-island-city {

  .col-one-third,
  .col-two-third {
    margin-bottom: rem( 20 );
  }

  .desc {
    margin-top: rem( 20 );
    transform: translateX( rem( -105 ) );
  }

  .infos {
    clear: both;
  }
}


// Event list
.block-events-list {

  .event-desc {
    padding: 0 0 0 rem( 100 );

    li {
      &:not(:only-of-type):before {
        left: rem( -15 );
      }
    }
  }
}


// Documents list
.block-documents-list {
  .text {
    [class*="icon-"] {
      height: rem( 44 );
      margin: rem( -22 ) 0 0 0;
      width: rem( 44 );

      + p {
        padding-right: rem( 100 );
      }
    }
  }
}


// Block sorting
.block-sorting {
  .form {
    display: flex;

    p {
      flex-grow: 1;

      + p {
        border-left: 1px solid rgba( $white, 0.4 );
        padding-left: rem( 12 );
      }
    }

    .no-flexbox & {
      display: table;

      p {
        display: table-cell;
        margin: 0;
        padding-bottom: rem( 6 );
        padding-right: rem( 12 );

        + p {
          padding-right: 0;
        }
      }
    }

    label,
    .label {
      &:not(.check-label){
        display: block;
        margin: 0 0 rem( 6 ) 0;
      }
    }
  }
}

.tour-operator-details {
  border-collapse: separate;
  border-spacing: rem( 10 );
  display: table;
  margin-bottom: rem( 20 );
  //margin-left: rem( -10 );
  //margin-right: rem( -10 );
  padding-bottom: 0;
  table-layout: fixed;
  width: 100%;

  .tour-operator {
    background: $white;
    box-shadow: 0 rem( 2 ) rem( 3 ) rgba( $black, 0.1 );
    display: table-cell;
    vertical-align: top;

    .inner {
      box-shadow: none;
    }
  }

  .tour-operator-highlight {
    background: $theme4;
    display: table-cell;
    float: none;
    padding: 0;
    width: rem( 160 );
    vertical-align: top;

    .inner {
      padding-top: rem( 46 );
    }
  }
}


.forms-area {
  display: table;
  margin-top: rem( 10 );
  margin-bottom: rem( 26 );
  width: 100%;

  .form-block {
    box-sizing: border-box;
    display: table-cell;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: rem( 25 );
    padding-right: rem( 25 );
    position: relative;
    table-layout: fixed;
    width: 55%;

    + .form-block {
      border-left: 1px solid rgba( $theme-secondary-grey, 0.5 );
      margin: 0;
      width: 45%;

      &:before {
        display: none;
      }
    }
  }
}


// Booking
.block-no-booking {
  position: relative;

  .text {
    padding-bottom: 0;
    padding-right: rem( 225 );
  }
  .bt {
    bottom: rem( 10 );
    position: absolute;
    right: rem( 10 );
  }
}


//
.block-booking {
  .address {
    width: rem( 250 );
  }
  .info {
    width: rem( 160 );
  }
}


// User form
.area-user-form {
  display: table;
  table-layout: fixed;
  width: 100%;

  .block-user-form {
    box-sizing: border-box;
    display: table-cell;
    padding-right: rem( 28 );
    vertical-align: top;
    width: 50%;

    + .block-user-form {
      padding-left: rem( 27 );
      padding-right: rem( 0 );
    }
  }

  &.separator {
    .block-user-form {
      padding-bottom: 0;

      + .block-user-form {
        border-top: 0 none;
        border-left: 1px solid $grey;
        padding-top: 0;
      }

      p:last-child,
      .line:last-child,
      .button:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.area-profil {
  display: table;
  // table-layout: fixed;
  margin: 0 0 rem( 16 );
  width: 100%;

  .block-user-form {
    box-sizing: border-box;
    display: table-cell;
    padding-bottom: 0;
    padding-right: rem( 65 );
    width: pct( 530 / 980 );

    + .block-user-form {
      border-left: 1px solid $grey;
      padding-left: rem( 36 );
      padding-right: rem( 0 );
      width: pct( 450 / 980 );

      [class*="theme"] & {
        border-color: $white;
      }
    }

    p:last-child,
    .line:last-child,
    .button:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  + .mandatory-text {
    box-sizing: border-box;
    padding-right: rem( 65 );
    width: pct( 530 / 980 );
  }
}


// Edito
.edito-header{
  padding-bottom: rem( 20 );
  padding-top: rem( 4 );
  text-align: left;
  @include clearfix;

  .picture{
    float: left;
    margin-right: rem( 30 );
  }

  [class*="tt-block"]{
    float: left;
    max-width: rem( 500 );

    &:after {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
