//------------------------------------
// #LAYOUT #PAGE
//------------------------------------


main {
  position: relative;

  @include clearfix;
}

.wrap {
  max-width: $page-width;
  margin: 0 auto;
  position: relative;
  width: pct( 300 / 320 );

  @include clearfix;
}

.grey-background {
  background: rgba(119, 135, 83, 0.05);
}

.dechirure-offer-div{

  .dechirure-offer-img{
    width: 1280px;
    background: rgba(119, 135, 83, 0.05);
  }
}
