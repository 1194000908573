//------------------------------------
// #SECTIONS
//------------------------------------


// sitemap section
.sitemap-section {
  .sitemap-row {
    padding: rem( 12 ) rem( 37 ) rem( 28 );
  }

  .site-map-cols {
    @include clearfix;
  }

  .col {
    float: left;
    width: 48%;

    &:nth-of-type( 2n + 2 ) {
      margin-left: 4%;
    }

    &:nth-of-type( 2n + 3 ) {
      clear: left;
    }
  }
}


// interactive section
.interactive-section{

  .choices {
    max-width: none;
    width: rem( 620 );

    li {
      margin: 0 0 rem( 26 );
      padding: 0 rem( 10 );
      width: 25%;

      &:nth-child(odd){
        clear: none;

        div {
          margin: 0 auto;
        }
      }
      &:nth-child(even){

        div {
          margin: 0 auto;
        }
      }
    }

    div {
      margin: 0 auto;
    }
  }
}

// New Island Page
.new-island-main {
  [class*="header-main"] {

    .bg-wrapper {
      height: 400px;
    }

    .bg-wrapper.expand{
      height: 500px !important;
    }

    .text {
      top: calc(3 * #{$baseline});
      min-width: 600px;
      font-size: calc(1.2 * #{$baseline});
    }

  }

  .ct-header {
    span {
      font-size: calc(3 * #{$baseline});
    }
  }
}

.new-island-main {
  .block-island-slider {

    figcaption {
      padding: calc(0.5*#{$baseline}) rem( 5 );
      max-width: 300px;
      font-size: rem( 24 );
    }

    .slick-arrow {
      [class*="icon-"] {
        height: rem( 50 );
        width: rem( 40 );
      }
    }

    .slick-dots {
      button {
        width: rem( 20 );
        height: rem( 20 );
      }
    }
  }
}

.block-island-map {
  .bg-wrapper {
    min-height: 450px;
  }
  .text {
    padding: calc(3 * #{$baseline}) calc(4 * #{$baseline}) calc(4 * #{$baseline});
    h2 {
      text-align: left;
    }
  }
  .wrap {
    @include center(both);
  }
}

.block-island-encyclopedia {
  .text {
    h2 {
      text-align: left;
    }
  }
}

.block-island-events {
  .block-agenda {
    padding: calc(4 * #{$baseline}) calc(8 * #{$baseline});
  }
}
// l'inspiration //
.inspiration-section {
  [data-slider="offers"] {
    display: none;
  }
  .inspiration-mobileoffers-target .slider .inspiration-slides > div {
    width: 45%;
  }
  .container {
    display: flex;
  }
  .list-activity {
    margin: auto;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: end;
  }
  .second-bloc {
    margin-top: 0;
  }
  .third-bloc {
    display: none;
  }
}

// End l'inspiration //


  // Agenda

  .agenda {
    .container_mobile {
      .date_agenda {
        text-align: center;
        display: block;
      }
    
      .date {
      display: inline-block;
      }
      .agenda_image {
        display: inline-block;
        width: 244px;
        }
  
      .descriptif {
        margin-top: 50px;
      }
    
    }
  }
  //Section Instagram //
.instagram {
	.container{ 
	  .row-cols-2 {
    width: 640px;
  
	  }
  
  
  }
  
}
	//End section Instagram //
