.offers-section {
  margin-top: 50px;

  .tt-raw-dark {
    .span1 {
      font-size: 30px;
    }

    .span2 {
      font-size: 20px;
    }
  }

  .sk-Rectangle {
    width: 30px !important;
  }
}

.section {
  .input {
    label {
      font-size: 20px;
    }
  }
  .line {
    label {
      font-size: 20px;
    }
  }

  .title {
    font-size: 24px;
  }
}
.push-advantages {
  .title {
    font-size: 24px;
  }
}

.push-facebook {
  margin-top: 100px;

  .tt-block {
    .span1 {
      font-size: 24px;
    }

    .span2 {
      font-size: 18px;
    }
  }
}

.tt-block {
  text-align: center;
  //margin: 50px 0 50px 0;
  padding-bottom: 0;
  padding-left: 0;

  .span1 {
    font-size: 25px;
  }

  .span2 {
    font-size: 20px;
  }
}

.titleSearch {
  margin-left: 0;
  margin-top: 50px;
  margin-bottom: 15px;
}

.link-subanner {
  margin-left: 15px;
  text-align: left;
}

// Carousel

.carousel_section .wrap {
  width: 100%;

  .carousel-item {
    height: 400px;

    .carousel {
      height: 300px;
      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
    }

    .dechirure {
      height: 100px;
      top: 64%;
      margin: 0px;
      padding: 0px;
    }

    p {
      color: #ffffff;
      font-family: $font-roboto-condensed-regular;
      font-size: 30px;
      font-weight: 700;
      line-height: 28px;
      padding-top: 15px;
    }

    .texte_slide {
      width: 250px;
      height: 91px;
      left: 5%;
      top: 20%;
    }

    .hashtag {
      font-size: 20px;
    }
  }

  .pastille_jaune {
    visibility: hidden;
    top: 1%;
  }

  .carousel-indicators {
    margin-right: 0;
    margin-left: 0;
  }
}
.video-youtube {
  width: 100%;
  height: auto;

  .youtube {
    height: 100%;

    .dechirure {
      margin: 0px;
      padding: 0px;
      //top: 88%;
      margin-top: -34px;
    }
    .buttons {
      bottom: 20px;
    }
    .buttons.animationOut {
      -webkit-animation: unset;
      animation: unset;
    }
  }
}

//Explorer l'archipel //
.explorez_archipel {
  .container_desktop {
    display: none;
  }
  .container_no_desktop {
    display: block;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-image: url(../../B2C/img/Traits-archipel-mobile.png);
    background-size: contain;
    background-repeat: no-repeat;
    a {
      text-decoration: none;
    }

    .tt-block {
      text-align: center;
      //margin: 50px 0 50px 0;

      &:after {
        background: transparent;
      }

      .span1 {
        font-family: $font-roboto;
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }

      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 20px;
        text-transform: none;
      }
    }

    .bloc_map {
      top: auto;
      display: contents;
    }

    .map {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .title_descriptif {
      font-family: "Roboto Condensed Regular";
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      color: black;
    }

    .islandDescriptionSection p {
      font-family: $font-roboto;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      color: black;
    }

    .link_ile {
      color: #182883;
      font-family: "Roboto Condensed Regular";
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
      overflow: hidden;
      padding: 40px 0 40px 0;
      margin: auto;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    #la_desirade_mobile:hover {
      fill: #e30870;
    }

    #basse_terre_mobile:hover {
      fill: #71c52a;
    }

    #grande_terre_mobile:hover {
      fill: #ed6411;
      position: relative;
    }

    #les_saintes_mobile:hover {
      fill: #009bc3;
    }

    #marie_galante_mobile:hover {
      fill: #d7c007;
    }
  }
}

//End explorer l'archipel //

// l'inspiration //

.inspiration-section {
  font-family: "Roboto Condensed Regular";

  .wrap {
    background-image: url(../../B2C/img/Traits-inspiration-mobile.png);
    background-position-y: -327px;
    width: unset;
  }

  .inspiration-desktop-offers-target {
    display: none;
  }
  .container {
    display: none;
    padding-left: 15px;
  }

  .bloc-list {
    display: block;
    padding-bottom: 0;
  }

  .titre_inspiration {
    padding-top: 50px;
    padding-left: 0;
    text-align: center;

    .span1 {
      font-size: 30px;
    }

    .span2 {
      font-size: 20px;
    }
  }

  .list-activity {
    padding-top: 25px;
    justify-content: center;
    float: inherit;
  }

  .activity-filter {
    padding-top: 0;
    margin-top: 20px;
    height: auto;
  }

  .link-inspiration {
    padding: 0;
  }

  .inspiration-mobileoffers-target {
    display: block;
    .inpiration-slider {
      display: block;
      width: 100%;
      overflow: hidden;

      .inspiration-slides {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        > div {
          scroll-snap-align: start;
          flex-shrink: 0;
          transform-origin: center center;
          transform: scale(1);
          transition: transform 0.5s;
          position: relative;
          display: flex;
          width: 80%;
        }
      }
      .inspiration-slides::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      .inspiration-slides::-webkit-scrollbar-track {
        background: transparent;
      }

      .slick-prev {
        opacity: 0;
      }

      .slick-next {
        opacity: 0;
      }

      .slick-list {
        padding: 0px 60px 0 0;
      }

      .block-inspiration-offer {
        max-height: 480px;
        box-shadow: none;
        width: 100%;

        .desc {
          width: 90%;
        }

        .activity {
          width: 255px;
          max-width: 90%;
        }

        .text {
          padding-top: 30px;
          overflow: hidden;
          word-wrap: break-word;

          p {
            width: 100%;
          }
        }

        .location {
          font-family: "Roboto";
          font-size: 13px;
          font-weight: 300;
          line-height: 16px;
          text-transform: uppercase;
          color: #000;
        }
        .city {
          font-family: "Roboto";
          font-size: 13px;
          font-weight: 300;
          line-height: 16px;
          color: #000;
        }
        .title {
          font-size: 22px;
          font-weight: 700;
          line-height: 26px;
          color: #000;
        }
      }

      .block-inspiration-activity {
        width: 100%;

        .picture {
          position: relative;

          img {
            max-width: 90%;
          }
        }

        .text {
          position: absolute;
          bottom: 60px;
          overflow: hidden;
          word-wrap: break-word;
          margin-left: 10px;
          width: 80%;

          p {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
          }
          p.dark {
            color: #182883;
          }
          p.clear {
            color: #ffffff;
          }
          .title-blue {
            font-size: 22px;
          }
        }
      }
    }
  }
}
// End l'inspiration //

// Agenda
.agenda {
  height: 100%;
  .container {
    display: none;
  }
  a {
    text-decoration: none;
  }
  .dechirure-top {
    height: 0px;
  }
  .dechirure-bottom {
    width: 100%;
    position: relative;
    bottom: 0;
    height: 65px;
  }

  .container_mobile {
    display: block;
    .date_agenda {
      display: flex;
      text-align: center;
    }
    .icon_fleche {
      vertical-align: text-bottom;
      width: 28px;
      height: 26px;
      border: 0;
      margin: 5px;
      background-color: rgba(255, 255, 255, 0);
    }

    .titre_agenda {
      padding-bottom: 20px;
      text-align: center;

      .span1 {
        font-family: "Roboto Condensed Regular";
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }
      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 20px;
        text-transform: none;
      }
    }
    .date {
      position: relative;
      z-index: 1;
      left: 20px;
      padding-top: 100px;
      display: inline-block;
      .date_start {
        color: #182883;
        font-family: $font-roboto;
        font-size: 30px;
        display: block;
      }
      .date_end {
        color: #69b5c2;
        font-family: $font-roboto;
        font-size: 30px;
      }
    }
    .agenda_image {
      width: 150px;
      height: auto;
      transform: rotate(-5deg);
      display: inline-block;
    }
    .descriptif {
      padding-top: 20px;
      padding-bottom: 20px;
      padding: 20px 25px 20px 25px;
      border-bottom: solid 1px #979797;
      .titre_descriptif_first {
        color: #182883;
        font-family: "Roboto Condensed Regular";
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-transform: uppercase;
        margin: 0;
      }
      .titre_descriptif_second {
        font-family: "Roboto Condensed Regular";
        font-size: 35px;
        font-weight: 400;
        margin: 0;
      }
    }

    .bloc_first_date {
      padding-bottom: 30px;
      border-bottom: solid 1px #979797;
      padding: 25px 0 25px 25px;

      .icon_fleche {
        float: right;
        margin-right: 20px;
        width: 24px;
      }
      .date_first {
        color: #182883;
        font-family: $font-roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
      .evenement_first {
        font-family: "Roboto Condensed Regular";
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
      }
    }
    .bloc_second_date {
      padding: 25px 0 25px 25px;

      .icon_fleche {
        float: right;
        margin-right: 20px;
        width: 24px;
      }
      .date_second {
        color: #182883;
        font-family: $font-roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
      .evenement_second {
        font-family: "Roboto Condensed Regular";
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        padding-right: 30px;
      }
    }
    .link_aganda {
      color: #182883;
      font-family: "Roboto Condensed Regular";
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
      overflow: hidden;
      padding: 40px 0 40px 22px;
      display: block;

      &::before {
        content: "";
        border-bottom: 1px ridge #182883;
        width: 70px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }
}

// Section circuit
.circuit {
  width: 100%;
  height: 660px;

  .images_circuit {
    display: none;
  }

  .cover {
    height: 380px;
    width: 100%;
    object-fit: cover;
  }

  .title_circuit {
    top: 65px;
    left: unset;
    text-align: center;
    width: 100%;

    .span_1 {
      font-size: 30px;
      line-height: 30px;
    }
    .span_2 {
      font-size: 20px;
    }
  }

  .circuit-slider {
    display: block;
    width: 100%;
    overflow: hidden;

    .circuit-slides {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      margin-left: 0px;

      > div {
        scroll-snap-align: start;
        flex-shrink: 0;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.5s;
        position: relative;
        display: flex;
        // width: 80%;
      }

      .bloc_circuit {
        // width: 550px;
        // height: 350px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
      }

      img {
        width: 210px;
        height: 320px;
        object-fit: cover;
      }

      .circuit-slides::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      .circuit-slides::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
}

//Section Instagram //
.instagram {
  .container {
    width: 100%;
    margin: auto;

    .row-cols-2 {
      display: none;
    }

    .row-cols-1 {
      width: 312px;
      flex-wrap: wrap;
      margin: auto;

      .col {
        padding: 0;
        text-align: center;
        flex: 50%;
        max-width: 50%;
      }

      img {
        width: 153px;
        height: 153px;
        padding: 5px 3px 5px 3px;
      }
    }

    .tt-block {
      text-align: center;
      margin: 15px;

      .span1 {
        font-size: 30px;
      }
      .span2 {
        font-size: 20px;
      }
    }
    .instagagram_more {
      padding: 20px 0 20px 0;
    }
  }
}
//End section Instagram //

// Newsletter

.section_newsletter {
  .container_desktop {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-image: url(../../B2C/img/Traits-newsletter.png);
    background-repeat: no-repeat;
    background-size: cover;

    .bloc-newsletter {
      display: block;
      width: 100%;
      padding-top: 50px;

      .title {
        width: unset;
      }
    }

    .tt-block {
      text-align: center;
      margin: 50px 0 50px 0;
      width: 100%;
      &:after {
        background: transparent;
      }

      .span1 {
        font-family: "Roboto Condensed Regular";
        color: #69b5c2;
        font-size: 30px;
        text-transform: none;
        font-weight: bold;
      }

      .span2 {
        font-family: $homemadeApple;
        font-weight: 40;
        color: #182883;
        font-size: 20px;
        text-transform: none;
      }
    }

    .newsletter-slider {
      .newsletter-slides {
        position: unset;
        width: unset;
        height: unset;
        overflow-x: auto;

        > div {
          padding: 0px 10px 0px 10px;
          animation: unset;
          margin: unset;
        }

        .newsletter {
          padding-left: 20px;
          padding-right: 20px;

          .logo {
            width: 60px;
            height: 60px;
          }

          .titre-article {
            font-size: 0.95em;
          }

          .article {
            font-size: 0.85em;
          }

          .link_article {
            font-size: 1em;
            padding-top: 10px;

            &::before {
              width: 70px;
            }
          }
        }
      }
    }
  }
}

// End Newsletter

// Hébergement
.hebergement-section {
  .container {
    display: block;
    width: 100%;

    .hebergement-target-offer {
      overflow-x: auto;

      .accommodation-offer {
        width: 80%;

        .block-accommodation-offer {
          max-width: 90%;

          .image_accommodation {
            height: 280px;
          }
        }
      }

      .accommodation-offer:nth-child(2) {
        padding-top: initial;
      }
    }
  }
}
/*Barre de reservation */
.barre_reservation {
  .titre_resa {
    margin-bottom: 0px;
  }
}

.search_bar {
  display: none;
}

.s002 {
  display: none;
}

.reservation-bar-mobile {
  display: block;
  font-family: "Roboto Condensed Regular";

  a {
    text-decoration: none;
    cursor: pointer;
  }

  input {
    font-family: "Roboto Condensed Regular";
  }
  span {
    color: #182883;
    font-size: 15px;
    font-weight: 700;
  }

  .barre_recherche {
    display: block;
    border-radius: 50px;
    background: #ffffff;
    width: 300px;
    height: 60px;
    margin: auto;
    margin-bottom: 70px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
    text-align: center;
    line-height: 60px;
    left: 0;
    right: 0;
    z-index: 10;

    .span-search {
      color: black;
      font-weight: 400;
    }
  }

  .form-active {
    display: block;
    position: fixed;
    height: calc(100vh - 59px);
    width: 100%;
    z-index: 100;
    top: 59px;
    overflow-y: auto;
    background-color: white;
  }

  .dropdown-mobile {
    background-color: white;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
    width: 100%;
    padding: 20px 20px 10px 20px;
    border-radius: 0 0px 25px 25px;
    position: absolute;
    left: 0;
    z-index: 2;
  }

  .close {
    margin-top: 10px;
    padding-right: 15px;

    span {
      font-size: 20px;
    }
  }
  .first-bloc {
    padding: 20px 20px 20px 30px;
    margin-top: 40px;

    .slide-radio {
      display: flex;
      span {
        width: 100%;
        line-height: 30px;
      }
      .custom-switch {
        float: right;
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        color: #69b5c2;
        background-color: #69b5c2;
        border-color: #69b5c2;
      }
    }
  }
  .second-bloc {
    margin-top: 40px;
    padding: 0px 20px 10px 20px;
    box-shadow: 0 -8px 8px -6px rgba(0, 0, 0, 0.24);

    span {
      color: #b1afaf;
    }

    .bi {
      float: right;
    }

    .first-span,
    .second-span {
      display: inline-block;
      width: 100%;
      border-bottom: solid 0.1px #b1afaf;
    }

    .place {
      position: relative;
      padding-top: 40px;
      .autocomplete {
        width: 100%;
        display: block;
        .location_input {
          border: none;
          text-align: left;
          margin: 0;
          padding: 0;
          width: 100%;
          background: url(/Statics/B2C/img/desktop/2x/icon-pin.png);
          background-repeat: no-repeat;
          padding-left: 25px;
          //background-position-y: center;
          border-bottom: solid 0.1px #b1afaf;
        }
        .dropdown {
          width: 100%;
          background-color: white;
          box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24);
          padding: 10px 10px 10px 10px;
          border-radius: 0 0px 7px 7px;
        }
      }
      .location_input {
        //opacity: 0.3;
        height: 23px;
      }
      img {
        height: 18px;
        margin: 0 10px 0 0;
      }
      .dropdown-mobile {
        top: 55px;

        img {
          border: 1px solid;
          padding: 3px;
          height: 25px;
          border-radius: 5px;
          opacity: 0.5;
        }
      }
    }
    .raw-price-travelers {
      display: flex;
      position: relative;
      padding-top: 40px;
      span {
        font-size: 13px;
        font-weight: 400;
      }
      .price {
        width: 45%;
        padding-right: 10px;

        .first-span > span {
          color: black;
          //margin-left: 10px;
          float: right;
          margin-right: 5px;
        }
        .range-mobile {
          .noUi-connect {
            background: #69b5c2;
          }
          .noUi-handle {
            background: #69b5c2;
          }
          .noUi-tooltip {
            color: black;
            font-weight: 500;
          }
        }
      }
      .passengers {
        width: 55%;

        input {
          border: none;
          width: 40px;
          text-align: center;
        }

        .plus,
        .minus {
          border: solid 0.1px #b1afaf;
          width: 23px;
          height: 23px;
          display: inline-block;
          border-radius: 5px;
          text-align: center;
        }

        .type {
          color: #000;
          font-weight: bold;
          width: 50%;
        }

        .age {
          font-size: 12px;
          width: 50%;
        }

        span {
          display: inline-block;
        }

        .type_passenger {
          display: flex;

          .bloc-left {
            width: 50%;
            padding-bottom: 10px;

            .age {
              font-size: 15px;
              white-space: nowrap;
            }
          }

          .bloc-right {
            width: 50%;
            text-align: right;
          }
        }

        #total-passengers_1 {
          margin-left: 60px;
          color: black;
        }
      }
    }
    .date {
      display: flex;
      padding-top: 40px;

      label {
        margin-top: 10px;
        color: #b1afaf;
        margin: unset;
      }

      .date-wrap {
        border-bottom: solid 0.1px #b1afaf;
        display: inline-flex;
        height: 100%;
        width: 100%;
        align-items: flex-end;

        img {
          float: right;
          //height: 18px;
          //width: 18px;
          // margin-top: 15px;
        }

        span {
          margin-top: 15px;
        }

        input {
          border: none !important;
          height: auto;
          margin-bottom: 0px;
          padding: 0px;
          background: url(/Statics/B2C/img/desktop/2x/icon-calendar.png);
          background-repeat: no-repeat;
          background-position-x: right;
          border-radius: 0;
          width: 100%;
        }
      }
      .date-wrap:first-child {
        padding-right: 10px;
      }
    }
    .equipments {
      display: flex;
      padding-top: 40px;
      position: relative;

      .select-equipments {
        width: 60%;
        padding-right: 10px;
      }
      .bi-chevron-down::before {
        color: #b1afaf;
      }

      .filter-equipments {
        width: 40%;

        span {
          color: black;
        }
      }

      .type_activity {
        display: flex;
      }

      input[type="checkbox"].chck-equipment {
        display: none;
      }
      input[type="checkbox"].chck-equipment + label,
      input[type="checkbox"].chck-equipment + input + label {
        position: relative;
        padding-left: 1.7rem;
        font-weight: 600;
      }
      input[type="radio"].activity {
        display: none;
      }
      input[type="radio"].activity + label {
        position: relative;
        padding-left: 1.7rem;
        font-weight: 600;
      }
      input[type="radio"].activity + label::before,
      input[type="radio"].activity + label::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 1rem;
        height: 1rem;
        border-style: solid;
        border-width: 1px;
        border-color: #dadfdf;
        border-radius: 3px;
        line-height: 30px;
        //margin-left: 5px;
        padding-right: 5px;
        border-radius: 3px;
      }

      input[type="radio"].activity:checked + label::after {
        background-color: #69b5c2;
        box-shadow: inset 0 0 0 2px #fff;
      }
      input[type="checkbox"].chck-equipment + label::before,
      input[type="checkbox"].chck-equipment + label::after,
      input[type="checkbox"].chck-equipment + input + label::before,
      input[type="checkbox"].chck-equipment + input + label::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 1rem;
        height: 1rem;
        border-style: solid;
        border-width: 1px;
        border-color: #dadfdf;
        border-radius: 3px;
        line-height: 30px;
        //margin-left: 5px;
        padding-right: 5px;
        border-radius: 3px;
      }

      input[type="checkbox"].chck-equipment:checked + label::after,
      input[type="checkbox"].chck-equipment:checked + input + label::after {
        background-color: #69b5c2;
        box-shadow: inset 0 0 0 2px #fff;
      }
      /*       :first-child {
        margin-right: 15px;
      } */

      .first-span {
        font-size: 13px;
        font-weight: 400;
        color: black;
      }
    }

    .affinerRecherche {
      margin-top: 20px;
      position: relative;

      .first-span {
        border: none;
      }

      .slide-radio {
        display: flex;
        span {
          width: 100%;
          line-height: 30px;
          margin-left: 8px;
          color: initial;
          font-size: unset;
          font-weight: unset;
        }
        .custom-switch {
          float: right;

          .custom-control-label::before {
            width: 2.2rem;
            height: 20px;
            border-radius: 0.6rem;
            top: calc(0.15rem + 2px);
            left: calc(-2rem);
          }
          .custom-control-label::after {
            width: 20px;
            height: 20px;
            top: calc(0.15rem + 2px);
            left: calc(-2rem + 2px);
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          color: #69b5c2;
          background-color: #69b5c2;
          border-color: #69b5c2;
        }
      }

      .title {
        margin-top: 5px;
      }

      .optionSort {
        display: flex;
        justify-content: space-around;

        input[type="radio"].activity {
          display: none;
        }
        input[type="radio"].activity + label {
          position: relative;
          padding-left: 1.7rem;
        }
        input[type="radio"].activity + label::before,
        input[type="radio"].activity + label::after {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 1rem;
          height: 1rem;
          border-style: solid;
          border-width: 1px;
          border-color: #dadfdf;
          line-height: 30px;
          margin-left: 5px;
          padding-right: 5px;
          border-radius: 3px;
        }

        input[type="radio"].activity:checked + label::after {
          background-color: #69b5c2;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }
    }
  }
  .btn-search {
    color: #182883;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 30px 0 40px 0px;
    display: block;
    text-decoration: none;
    background-color: white;
    border: none;
    margin: auto;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &::before {
      content: "";
      border-bottom: 1px ridge #182883;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  .modal {
    height: calc(100vh);
    overflow-y: auto;
    //top: 214px;
    padding-top: 59px;
    z-index: 100;

    .modal-dialog {
      margin: 0;
      .modal-content {
        border-radius: initial;
        border: none;
        height: calc(100vh - 59px);
        overflow-y: auto;
      }
      .modal-body {
        // height: 100vh;
        // overflow-y: auto;
        padding: inherit;

        .first-bloc {
          margin-top: 0;
        }
      }

      .modal-header {
        border: none;
      }
    }

    .close {
      margin-top: 0;
      padding-right: inherit;
    }
  }
}

#hebergements-mobile {
  .barre_recherche {
    margin-bottom: 40px;
    position: absolute;
    z-index: 10;
    bottom: 0;
  }
}

.pika-single.is-bound.datepicker-reservation-bar {
  z-index: 100;
  .is-selected .pika-button {
    background: #69b5c2;
    border-radius: 50%;
    color: #fff;
    width: 25px;
  }
}

.pika-single.is-bound.force-datepicker-fixedposition {
  position: fixed !important;
}

.container.searchEngine {
  .search-type-container {
    width: auto !important;
    .illustration {
      width: auto !important;
      height: auto !important;
      margin: 0 0 30px 0 !important;
      float: unset !important;
      text-align: center !important;
    }
  }

  .autopromo {
    display: inline-block;
    .bloc {
      width: 100%;
      margin-bottom: 20px;
      .push-search {
        .picture {
          padding: 0px;
        }
        .text {
          margin: 0;
          width: 100%;
          padding: 10px 40px 0 10px;
        }
      }
    }
  }
}

// Section Push Video Popin
.section_video_popin {
  .container {
    .content {
      flex-direction: column-reverse;

      .bloc-video {
        width: 100%;
      }

      .bloc-description {
        max-width: 100%;

        .bloc-titre {
          text-align: center;
        }
      }
    }
  }
}
#wrapBookingBar {
  width: 100%;
}
.page_404 {
  width: 100%;

  .bg-wrapper {
    background-color: #2bb7da;
  }

  .error-main-banner {
    width: 100%;
  }

  .block-search {
    width: 100%;
  }

  .error-header-islands {
    .bg-wrapper {
      width: 100%;
    }
  }

  .wrap {
    & > div {
      &:first-of-type {
        padding-right: 0;
      }
    }

    .tt-block {
      padding-left: 0;
    }
  }
}
[class*="header-main"] {
  .bg-wrapper {
    width: 100%;
  }
  .block-filters {
    .wrap {
      .filter-switch {
        .on {
          padding-right: 0;
          text-align: 0;
        }
      }
    }
  }
}

.new-island-main {
  width: 100%;

  h2 {
    font-family: "Roboto Condensed Regular";
  }
}

#guadeloupedia-section {
  width: 100%;
}

#carrousel-section {
  width: 100%;

  .slider {
    width: 100%;
  }
}

#guadeloupedia-section {
  width: 100%;
}

.block-island-nav {
  .sub-navigation {
    width: 100%;
  }
}
.new-island-main {
  width: 100%;

  .slider {
    width: 100%;
  }
}

[class*="header-main"] .text {
  width: 100%;
  bottom: 25px;
  left: 0;
  position: absolute;
  z-index: 2;
}

.block-filters {
  width: 100%;
}
.pushes {
  .push-discover {
    margin: 0 0 5rem;
  }
}

.block-form{
  .form{
    .custom-radio{
      width: auto;
    }
  }
}

.new-island-main{
  .block-island-slider {
    .slick-dots{
      bottom: 0.5rem;
    }
  }
}

.block-island-map{
  height: unset;
  position: relative;
  .bg-wrapper{
    height: unset;
    width: 100%;
    overflow: unset;
    max-width: unset;
    margin: 0 auto;

    .wrap{
      height: 400px;
      top: 0px !important;
      left: 0px !important;
      margin: 0 auto !important;
      position: relative !important;
      transform: none !important;

      .text{
        padding-bottom: 0px !important;
        font-size: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(2*1rem);
        bottom: 1rem;
        width: auto;
        min-width: 240px;
        padding: unset;

        .desc{
          width: 100%;
        }
      }
    }
  }
}

.marginTop{
  margin-top: 3.5rem;
}

.offer-selection {
  ~ section:last-child {
    margin-bottom: 0;
  }
}
