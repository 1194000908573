//------------------------------------
// #DATE PICKERS
//------------------------------------

/*!
 * Pikaday
 */

 .pika-single {
  background: $white;
  border: 1px solid $grey-light2;
  // border-bottom-color: $pd-picker-border-bottom;
  // color: $pd-text-color;
  display: block;
  position: relative;
  z-index: 8;

  &.is-hidden {
    display: none;
  }

  &.is-bound {
    position: absolute;
    box-shadow: 0 rem( 5 ) rem( 15 ) rem( -5 ) rgba( $black, 0.1 );
  }
}

.pika-single {
  @include clearfix;
}

.pika-lendar {
  float: left;
  margin: rem( 8 );
  width: rem( 240 );
}

.pika-title {
  background: $theme4;
  color: $white;
  position: relative;
  text-align: center;

  select {
    cursor: pointer;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: rem( 5 );
    z-index: 7;
  }
}

.pika-label {
  display: inline-block;
  font-size: rem( 14 );
  font-weight: bold;
  line-height: ( 20 / 14 );
  margin: 0;
  overflow: hidden;
  padding: rem( 5 ) rem( 3 );
  position: relative;
  z-index: 8;
}

.pika-prev,
.pika-next {
  cursor: pointer;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  border: 0;
  display: block;
  height: rem( 30 );
  opacity: .5;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: rem( 20 );
  white-space: nowrap;
  width: rem( 20 );

  &:hover {
    opacity: 1;
  }

  &.is-disabled {
    cursor: default;
    opacity: .2;
  }
}

.pika-prev,
.is-rtl .pika-next {
  float: left;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
}

.pika-next,
.is-rtl .pika-prev {
  float: right;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
}

.pika-select {
  display: inline-block;
}

.pika-table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%;

  th,
  td {
    padding: 0;
    width: 14.285714285714286%;
  }

  th {
    background: $theme2;
    color: $white;
    font-size: rem( 12 );
    font-weight: bold;
    line-height: rem( 25 );
    text-align: center;
  }
  td {
    border: 1px solid $white;
  }

  abbr {
    border-bottom: none;
    cursor: help;
  }
}

.pika-button {
  // background: $pd-day-bg;
  background: $grey-light2;
  border: 0;
  box-sizing: border-box;
  // color: $pd-day-color;
  cursor: pointer;
  display: block;
  font-size: rem( 12 );
  line-height: ( 15 / 12);
  margin: 0;
  outline: none;
  padding: rem( 5 );
  text-align: center;
  width: 100%;

  .is-today & {
    color: $theme4;
    font-weight: bold;
  }

  .is-selected & {
    background: $theme4;
    color: $white;
    font-weight: bold;
  }

  .is-disabled &,
  .is-outside-current-month & {
    // color: $pd-day-disabled-color;
    cursor: default;
    opacity: .3;
    pointer-events: none;
  }

  &:hover {
    background: $theme4;
    box-shadow: none;
    color: $white;
  }
}

.pika-week {
  // color: $pd-week-color;
  font-size: rem( 11 );
}

.is-inrange .pika-button {
  background: #D5E9F7;
}

.is-startrange .pika-button {
  background: #6CB31D;
  border-radius: 3px;
  box-shadow: none;
  color: #fff;
}

.is-endrange .pika-button {
  background: #33aaff;
  border-radius: 3px;
  box-shadow: none;
  color: #fff;
}
