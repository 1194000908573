//------------------------------------
// #PUSHES
//------------------------------------


// Booking push
.feature-push-booking {
  @include clearfix;
}
.push-booking {
  box-sizing: border-box;
  float: left;
  width: 50%;

  &:nth-of-type(odd){
    clear: left;
    padding-right: rem( 5 );
  }
  &:nth-of-type(even){
    padding-left: rem( 5 );
  }

  .inner {
    &:before {
      height: rem( 200 );
    }
  }

  .icon-car {
    height: rem( 60 );
  }

  .titleSearch {
    margin-left: 0;
  }
}
