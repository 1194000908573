//------------------------------------
// #SLIDERS
//------------------------------------


// Offers
[data-slider="offers"],
[data-slider="small-offers"]{
  // margin-left: rem( -15 );
  // margin-right: rem( -15 );
  width: auto;

  .slide{
    box-sizing: border-box;
    float: left;
    width: pct( 336/1010 );
  }
}
