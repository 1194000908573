//------------------------------------
// #HEADINGS
//------------------------------------

.tt-raw-block {
  color: $theme;
  font-weight: 300;
  font-family: "Roboto Condensed Regular";
}

.tt-label {
  span {
    background: $theme2;
  }
}

[class*="tt-raw"] {
  &.activity {
    color: $activity;
  }
}

.tt-raw-light {
  color: $theme2;
}

.tt-raw-dark {
  color: $theme-dark;
}

.tt-block-dark {
  color: $theme-dark2;

  &:after {
    background: $theme-dark2;
  }
}
