//------------------------------------
// #LAYOUT #FOOTER
//------------------------------------


// Main footer
footer[role="contentinfo"] {

  .block {
    border-top: 0;
    padding-top: 0;
  }

  [class*="tt-raw"]{
    font-size: rem( 25 );
  }

  .cols {
    /*align-items: stretch;
    display: flex;*/
    //margin: 0 0 rem( 50 );
    display: table;
    width: 100%;

    .no-cssgradients & {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    .block:last-child {
      padding-bottom: 0;
      padding-left: 0;

      p:last-of-type,
      ul:last-of-type {
        margin-bottom: 0;
        width: 495px;
      }
    }
  }
  .col {
    flex-basis: pct( 1/3 );
    width: pct( 1/3 );
    display: table-column;
    // padding-left: rem( 30 );

   /* &:nth-child(2),
    &:nth-child(3) {
      border-left: 1px solid rgba( $white, 0.3 );
    }*/

    .no-cssgradients & {
      box-sizing: border-box;
      display: table-cell;
      vertical-align: top;
    }

    .flexbox & {
      &:nth-child(3) {
        padding-bottom: rem( 48 );
        position: relative;

        .button {
          bottom: 0;
          position: absolute;
        }
      }
    }

  }
  .footer-links {
    .col {
      display: contents;
      .block{
        float: left;
      }
    }
  }

  // Form
  .field {
    width: rem( 210 );
  }

  .cta-fb {

    > span {
      + span {
        width: rem( 231 );
      }
    }
  }
}
