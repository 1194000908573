//------------------------------------
// #BUTTONS
//------------------------------------

// Link
.link {
  background: none;
  border: 0 none;
  color: $theme;
  display: inline-block;
  font-size: rem( 14 );
  font-style: italic;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;
  transition: $transition;
  vertical-align: top;

  &:before {
    content: ">";
  }

  &:hover {
    color: $purple;
  }
}

.link-revert {
  @extend .link;
  color: $purple;

  &:hover {
    color: $theme;
  }
}

.link-light {
  @extend .link;
  color: $white;

  &:hover {
    color: $white;
  }
}

.link-alt {
  @extend .link;
}
.link-revert-alt {
  @extend .link-revert;
}


.link-alt,
.link-revert-alt {
  font-size: rem( 13 );
  font-style: normal;

  &:before {
    content: "< ";
  }
}

.link-alt-cta {
  background: rgba( $black, .2 );
  border: 1px solid rgba( $white, .4 );
  color: $white;
  display: inline-block;
  font-size: rem( 13 );
  padding: rem( 4 ) rem( 13 ) rem( 4 ) rem( 9 );
  text-decoration: none;
  transition: $transition;
  vertical-align: top;

  &:before {
    content: "< ";
  }

  &:hover {
    background: rgba( $black, .25 );
  }
}

// CTA
.cta {
  background: $cta;
  border: 1px solid $cta;
  box-sizing: border-box;
  color: $white;
  display: inline-block;
  font-family: $font-roboto-condensed-regular;
  font-size: rem( 14 );
  padding: rem( 5 ) rem( 20 );
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: $transition;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  a:hover & {
    background: $white;
    border-color: $cta;
    color: $cta;

    [class*="icon-"] {
      color: $cta;
    }
  }

  &:before {
    @include valign;
    height: rem( 28 );
  }

  span {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  [class*="icon-"] {
    fill: $white;
    height: rem( 26 );
    width: rem( 26 );
    margin: rem( -13 ) 0 0 0;
    left: rem( 8 );
    position: absolute;
    transition: $transition;
    top: 50%;

    + span {
      padding-left: rem( 20 );
    }
  }
}

.cta-push {
  @extend .cta;
  background: $theme-light;
  border-color: $theme-light;
  color: $theme-dark;

  [class*="icon-"] {
    fill: $theme-dark;
  }

  &:hover,
  a:hover & {
    background: $theme;
    border-color: $white;
    color: $white;

    [class*="icon-"] {
      fill: $white;
    }
  }
}

.cta-submit {
  @extend .cta;
  background: $theme3;
  border-color: $theme3;
  color: $white;

  [class*="icon-"] {
    fill: $white;
  }

  &:hover {
    background: $white;
    border-color: $theme3;
    color: $theme3;

    [class*="icon-"] {
      fill: $theme3;
    }
  }
}

.cta-dark {
  @extend .cta;
  background: $theme-secondary-grey;
  border-color: $theme-secondary-grey;
  color: $white;

  [class*="icon-"] {
    fill: $white;
  }

  &:hover,
  a:hover & {
    background: $white;
    border-color: $theme-secondary-grey;
    color: $theme-secondary-grey;

    [class*="icon-"] {
      fill: $theme-secondary-grey;
    }
  }
}

.cta-block {
  @extend .cta;
  background: #8f8f8f;
  border-color: #8f8f8f;
  color: $white;

  &:hover,
  a:hover & {
    background: $white;
    border-color: #8f8f8f;
    color: #8f8f8f;
  }

  &:before {
    height: rem( 20 );
  }
}

.cta-toggle {
  @extend .cta;
  background: #41717f;
  border-color: #41717f;
  color: $white;

  &:hover,
  a:hover & {
    background: $white;
    border-color: #41717f;
    color: #41717f;
  }
}

.cta-tour {
  background: #17a8cc;
  background: #17a8cc linear-gradient( to top, #17a8cc, #4ac6e5 );
  border: 0 none;
  box-sizing: border-box;
  color: $white;
  display: inline-block;
  font-size: rem( 13 );
  font-weight: 500;
  padding: 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  transition: $transition;

  > span {
    border: 1px solid transparent;
    display: inline-block;
    padding: rem( 4 ) rem( 14 ) rem( 4 ) rem( 32 );
    vertical-align: middle;
    white-space: nowrap;

    &:before {
      @include valign;
      height: rem( 35 );
    }
    span {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
    }
  }

  [class*="icon-"] {
    fill: $white;
    height: rem( 18 );
    width: rem( 15 );
    margin: rem( -10 ) 0 0 0;
    left: rem( 10 );
    position: absolute;
    transition: $transition;
    top: 50%
  }

  &:hover {
    background: $white;
    color: #4ac6e5;

    > span {
      border: 1px solid #4ac6e5;
    }

    [class*="icon-"] {
      fill: #4ac6e5;
    }
  }
}

// CTA labels
.cta-label {
  background: none;
  border: 0;
  display: inline-block;
  font-family: $font-roboto-condensed-regular;
  font-size: rem( 11 );
  padding: 0;
  text-transform: uppercase;

  span {
    background: $theme;
    box-sizing: border-box;
    color: $white;
    display: inline-block;
    padding: rem( 4 ) rem( 8 ) rem( 4 ) rem( 8 );
    position: relative;
    min-width: rem( 65 );

    &:after {
      background: url( "#{$url-img}bg-label-shadow.png" ) no-repeat 0 0;
      background-size: rem( 65 ) auto;
      bottom: rem( -5 );
      content: "";
      height: rem( 5 );
      position: absolute;
      right: rem( 5 );
      text-align: left;
      width: rem( 65 );
    }
  }

  &.revert,
  .revert & {
    span {

      &:after {
        background-position: 0 rem( -6 );
        left: rem( 5 );
        right: auto;
        text-align: right;
      }
    }
  }
}


// Back to top button
.back-top {
  color: $white;
  display: table;
  margin: rem( 13 ) auto rem( 34 );
  min-width: rem( 90 ); // fixes some repaint problems

  .icon-back {
    display: block;
    fill: $theme3;
    height: rem( 13 );
    margin: 0 auto rem( 2 );
    transition: fill $transition;
    width: rem( 27 );
  }

  span {
    background-color: $theme3;
    display: inline-block;
    padding: rem( 5 ) rem( 6 ) rem( 4 );
    transition: background-color $transition;
  }

  &:hover,
  &:focus {
    .icon-back {
      fill: $theme-dark2;
    }

    span {
      background-color: $theme-dark2;
    }
  }
}


// Facebook button
.cta-fb {
  // background: $fb-blue;
  color: $white;
  display: inline-block;
  position: relative;
  white-space: nowrap;

  .icon-facebook {
    height: rem( 24 );
    width: rem( 12 );
  }

  > span {
    background: $fb-blue;
    box-sizing: border-box;
    height: rem( 48 );
    display: inline-block;
    vertical-align: top;

    &:first-child {
      padding: rem( 11 ) 0 0;
      text-align: center;
      white-space: normal;
      width: rem( 48 );
    }

    + span {
      margin-left: rem( 1 );
      padding: 0 rem( 20 );

      &:before {
        @include valign;
      }
      span {
        white-space: normal;
      }
    }
  }
}


.cta-article {
  border: 1px solid $theme-secondary-grey;
  background: none;
  color: $theme-secondary-grey;
  display: inline-block;
  font-family: $roboto-condensed-regular;
  padding: rem( 5 ) rem( 20 ) rem( 5 ) rem( 55 );
  position: relative;
  font-size: rem( 18 );
  text-decoration: none;
  text-transform: uppercase;
  transition: $transition;
  white-space: nowrap;

  [class*="icon"] {
    fill: $theme-secondary-grey;
    height: rem( 32 );
    left: rem( 15 );
    margin: rem( -17 ) 0 0;
    position: absolute;
    top: 50%;
    transition: $transition;
    width: rem( 26 );
  }

  &:before {
    @include valign;
    height: rem( 28 );
  }

  span {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  &:hover,
  a:hover & {
    background: $theme-secondary-grey;
    border-color: $theme-secondary-grey;
    color: $white;

    [class*="icon"] {
      fill: $white;
    }
  }
}

// CTA Outline
.cta-outline {
  @extend .cta;
  background: $white;
  border-color: $theme-secondary-grey;
  color: $theme-secondary-grey;

  &:hover,
  a:hover & {
    background: $theme-secondary-grey;
    border-color: $theme-secondary-grey;
    color: $white;
  }
}
