//------------------------------------
// #BLOCKS
//------------------------------------


// Filters
.block-filters {
  .filters {
    align-content: stretch;
    align-items: center;
    display: flex;
    margin: 0;
  }
  .title,
  .field {
    float: none;
    margin: 0;
    width: auto;
  }
  .title {
    margin: 0;
    padding: 0 rem( 10 );
  }
  .field {
    flex-grow: 1;
    padding: 0 0 0 rem( 10 );
  }

  .no-flexbox & {
    .filters {
      margin: 0 rem( -5 );
      text-align: center;
    }

    .title,
    .field {
      display: inline-block;
      vertical-align: middle;
    }
    .title {
      box-sizing: border-box;
      width: rem( 220 );
    }
    .field {
      text-align: left;
      width: rem( 251 );
    }
  }
  .filter-switch {
    font-size: rem( 20 );
  }
}


// City picker (island page)
.block-picker {
  display: none;
}

// Island main block
.block-island-main {
  margin: rem( 20 ) auto 0;
  max-width: rem( 980 );
  width: pct( 300/320 );
  @include clearfix();

  .container {
    position: relative;
  }

  .link-alt {
    display: none;
  }

  img {
    width: rem( 650 );
  }

  .subcontainer {
    bottom: 0;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    width: rem( 680 );

    .wrap {
      width: auto;
    }
  }

  .tt-raw {
    background-color: $white;
    margin: 0;
    padding: rem( 18 ) rem( 23 ) rem( 20 );
    z-index: 1;
  }

  .cols {
    clear: both;
    padding: 0;
    position: relative;

    &:before {
      background: $light;
      content: "";
      height: 100%;
      left: -25vw;
      position: absolute;
      top: 0;
      width: 50vw;
    }

    .wrap {
      max-width: 100%;
    }
  }

  .col-one-third {
    display: block;
    float: left;
    width: 34%;
  }
  .col-two-third {
    box-sizing: border-box;
    float: left;
    padding: rem( 40 ) rem( 20 ) rem( 20 ) 0;
    width: 66%;
  }


}

// Island city block
.block-island-city {
  margin-bottom: rem( 70 );
  padding-top: rem( 44 );

  .tt-block {
    margin-bottom: rem( 24 );
  }

  .cols {
    margin: 0 auto;
    max-width: rem( 980 );
    position: relative;
    width: pct( 300/320 );

    .back-top {
      bottom: 0;
      display: table;
      margin: 0;
      position: absolute;
      right: 0;
    }
  }

  .col-one-third,
  .col-two-third {
    margin-bottom: 0;
  }

  .col-two-third {
    margin-bottom: 0;
    padding: 0 rem( 20 ) 0 rem( 5 );

    .wrap {
      width: auto;
    }
  }

  img {
    max-width: rem( 320 );
  }

  .desc {
    max-width: rem( 525 );
  }

  .infos {
    bottom: rem( -12 );
    clear: none;
    float: right;
    right: 15%;
    margin: 0 0 0 rem( -7 );
    position: relative;
    display:inline-block;

    > * {
      box-sizing: border-box;
      height: rem( 95 );
    }

    button {
      font-size: rem( 25 );

      span,
      [class^="icon-"] {
        vertical-align: middle;
      }

      .icon-plus {
        display: inline-block;
      }

      &[aria-expanded="true"] {
        .icon-plus {
          display: none;
        }

        .icon-minus {
          display: inline-block;
        }
      }
    }

  }

  > .back-top {
    display: none;
  }

  .block-contact {
    .wrap {
      width: rem( 980 );
    }
  }
}

// contact block
.block-contact {
  article {
    width: rem( 313 );

    &:nth-child( 2n+1 ) {
      clear: none;
      margin-right: 0;
    }

    &:nth-child( 2n + 2 ) {
      margin: 0 rem( 20 );
    }
  }
}


// Agency
.block-agency {
  padding-top: rem( 20 );

  .block-quote {
    margin-bottom: rem( 30 );
  }

  .about {
    font-size: rem( 16 );
    line-height: ( 18 / 16 );

    strong {
      font-size: rem( 50 );
      line-height: ( 52 / 50 );
    }
  }
}


// form block
.block-form {
  .form {
    @include clearfix;
  }

  fieldset {
    float: left;
    width: rem( 335 );

    + fieldset {
      float: right;
      width: rem( 600 );
    }
  }

  .cta {
    display: block;
    margin: rem( 36 ) auto 0;
    max-width: rem( 224 );
  }
  .buttons {
    text-align: center;

    .cta {
      display: inline-block;
      margin-left: rem( 5 );
      margin-right: rem( 5 );
    }
  }
}


// Documents
.documents-list {
  justify-content: flex-start;
  text-align: left;

  li {
    width: rem( 330 );
  }

  &.centered {
    justify-content: center;
    text-align: center;
  }

  .main-section & {
    margin-left: pct( -25/640 );
    margin-right: pct( -25/640 );

    li {
      padding: 0 pct( 25/690 );
    }
  }
}

.documents-list-intro {
  padding: rem( 20 ) rem( 20 ) rem( 14 );
}


// Block sorting
.block-sorting {
  .form {
    label,
    .label {
      &:not(.check-label){
        display: inline-block;
        margin: 0 rem( 10 ) 0 0;
        vertical-align: middle;
      }
    }
  }
}

// Tour operator
.tour-operator-list {

  .tour-operator {
    width: pct( 1/3);
  }
}

// Forms
.form-block {
  padding-bottom: rem( 26 );
  padding-top: rem( 10 );

  [class*="tt-block"]{
    font-size: rem( 35 );
  }
}

.forms-area {

  .form-block {
    padding-left: rem( 25 );
    padding-right: rem( 25 );
  }
}


// User
.user-content-col {
  float: left;
  width: 58rem;
  margin-left: 25px;
}
.user-aside-col {
  float: right;
  width: rem( 320 );
}
.block-user {
  padding: rem( 60 ) rem( 20 ) rem( 50 );
  text-align: left;

  .user-info {
    display: block;
    margin-right: 0;
    width: auto;
  }
  .name {
    margin-bottom: rem( 46 );
  }
  ul {
    display: block;
    margin: 0 auto;
  }
}


// Edito
.edito {
  padding-bottom: rem( 40 );
}


// Cookies
.block-cookies {
  font-size: rem( 14 );
}
