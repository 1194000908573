//------------------------------------
// #AUTOCOMPLETE
//------------------------------------

.autocomplete-suggestions {
  background: #FFF;
  border: 1px solid #999;
  overflow: auto;

  strong {
    color: #3399FF;
    font-weight: normal;
  }
}
.autocomplete-suggestion {
  overflow: hidden;
  padding: rem( 2 ) rem( 5 );
  white-space: nowrap;

  &.level-1 {
    padding-left: rem( 15 );
  }
  &.level-2 {
    padding-left: rem( 25 );
  }
}
.autocomplete-selected {
  background: #F0F0F0;
}
.autocomplete-group {
  padding: rem( 2 ) rem( 5 );

  strong {
    border-bottom: 1px solid #000;
    display: block;
  }
}
