//------------------------------------
// #BLOCKS
//------------------------------------


// Documents
.documents-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: pct( -25/980 );
  margin-right: pct( -25/980 );


  li {
    box-sizing: border-box;
    padding: 0 pct( 25/1030 );
    max-width: rem( 330 );
    text-align: left;
    width: 50%;
  }

  .no-flexbox & {
    font-size: 0;
    letter-spacing: 0;

    li {
      display: inline-block;
      font-size: rem( $fz );
      letter-spacing: normal;
    }
  }
}

//
.block-booking {
  .text {
    padding: rem( 10 ) 0 0 rem( 210 );
  }
  .pict {
    margin: 0;
  }
  .address {
    clear: none;
    float: none;
    width: auto;
  }
  .info {
    padding: 0;
    text-align: left;

    &:before {
      background: $grey-light;
      content: "";
      display: block;
      height: rem( 1 );
      margin: 0 0 rem( 16 );
      // width: rem( 160 );
    }
  }
}


// Cookies
.block-cookies {

  .wrap {
    padding-bottom: rem( 6 );
    padding-top: rem( 20 );
  }

  .inner {
    display: table;
    width: 100%;
  }
  .text {
    display: table-cell;
    vertical-align: top;
  }
  .button {
    display: table-cell;
    padding: 0 0 rem( 16 ) rem( 20 );
    vertical-align: top;
  }
}
