//------------------------------------
// #SLIDERS
//------------------------------------

// Calendar slider
[data-calendar-slider] {
  &.accommodation {
    .calendar .calendar-table tbody td.selected {
      background: $accommodation;
    }
  }
  &.activity {
    .calendar .calendar-table tbody td.selected {
      background: $activity;
    }
  }
  &.restaurant {
    .calendar .calendar-table tbody td.selected {
      background: $restaurant;
    }
  }
  &.koudke {
    .calendar .calendar-table tbody td.selected {
      background: $koudke;
    }
  }
}
