//------------------------------------
// #TABS/ACCORDION
//------------------------------------

.tab-heading {

  button {
    padding: rem( 10 ) rem( 80 ) rem( 10 ) rem( 10 );
  }
  .icon-prev {
    right: rem( 30 );
  }
}
