//------------------------------------
// #FORMS
//------------------------------------


// Form
.checkout-form {
  max-width: rem( 520 );
  padding-left: rem( 45 );
  padding-right: rem( 45 );

  .custom-radio {
    max-width: rem( 180 );
  }

  .civiity-radio .custom-radio {
    max-width: rem( 140 );
  }

  .line {
    position: relative;
  }

  .check,
  .warning {
    background: $red;
    border-radius: 50%;
    height: rem( 30 );
    margin-top: rem( -32 );
    position: absolute;
    right: rem( -45 );
    // top: rem( 20 );
    width: rem( 30 );

    [class*="icon"]{
      fill: $white;
      height: rem( 12 );
      margin: rem( 9 ) 0 0;
      width: rem( 12 );
    }
  }

  .check {
    background: $theme2;
    display: block;

    .theme & {
      background: $white;

      [class*="icon"]{
        fill: $theme2;
      }
    }
  }

  &.error,
  .error & {
    .warning {
      display: block;
    }
  }
}

// Form
.address-form {
  max-width: rem( 370 );

  .custom-radio {
    max-width: rem( 140 );
  }
}
