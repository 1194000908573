//------------------------------------
// #BOOKING PAGES
//------------------------------------

// Recap
.booking-recap {
  .col {
    margin: 0;

    .line {
      box-sizing: border-box;
      padding: 0 rem( 1 ) 0 0;
      float: left;
      width: 50%;

      + .line {
        padding: 0 0 0 rem( 1 );
      }
    }
  }
  [class*="cta"]{
    display: block;
    width: 100%;
  }
}
