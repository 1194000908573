//------------------------------------
// #SLIDERS
//------------------------------------


// Logo Slider
[data-slider="logo"]{
  width: auto;
  text-align: left;

  .js & {
    width: rem( 252 );

    &.slick-initialized {
      width: auto;
    }
  }

  .slick-list {
    display: inline-block;
    vertical-align: middle;
    width: rem( 252 );
  }

  .slide {
    width: rem( 84 );
  }

  .slick-arrow {
    display: inline-block !important;
    margin: 0;
    position: relative;
    top: auto;
    vertical-align: middle;

    &.slick-prev {
      left: auto;
      margin: 0 rem( 5 ) rem( 5 ) 0;
    }
    &.slick-next {
      margin: 0 0 rem( 5 ) rem( 5 );
      right: auto;
    }
  }
}


// Partner slider
[data-slider="partners"] {
  margin: 0 auto rem( 10 );
  width: rem( 468 );

  .slide {
    padding-left: rem( 14 );
    padding-right: rem( 14 );
  }

  .slick-arrow {
    margin-top: rem( -16 );
    position: absolute;
    top: 50%;

    [class*="icon-"] {
      height: rem( 32 );
      width: rem( 26 );
    }
  }
  .slick-prev {
    left: rem( -40 );
  }
  .slick-next{
    right: rem( -40 );
  }
}


// Offers
[data-slider="offers"],
[data-slider="small-offers"]{
  width: rem( 572 );
}


// Calendar slider
[data-calendar-slider] {
  max-width: rem( 570 );

  .slick-arrow {
    margin: rem( -14 ) 0 0;

    [class*="icon-"]{
      display: block;
      height: rem( 28 );
      width: rem( 18 );
    }
  }
  .slick-prev {
    left: rem( -28 );
  }
  .slick-next {
    right: rem( -28 );
  }
}
