.scrollbar {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    margin: 0.2rem auto 0.4rem auto;
    width: 0.2rem;
    background: #c2c7c7;
}

#scrollbar-container {
    position: absolute;
    border: 0.05rem solid #cccdcd;
    right: 0.75rem;
    bottom: 1rem;
    top: 1rem;
    width: 0.6rem;
    box-sizing: border-box;
}