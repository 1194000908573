//------------------------------------
// #Range Slider
//------------------------------------


/* Styling;
 */
.noUi-background {
  background: #bfc7c9;
}
.noUi-connect {
  background: $theme2;
}

/* Handles and cursors;
 */
.noUi-handle {
  background: $theme2;
}

/* Tooltip;
 */
.noUi-tooltip {
  color: $theme2;
}

.activity {
  .noUi-connect {
    background: $activity;
  }
  .noUi-handle {
    background: $activity;
  }
  .noUi-tooltip {
    color: $activity;
  }
}
.accommodation {
  .noUi-connect {
    background: $accommodation;
  }
  .noUi-handle {
    background: $accommodation;
  }
  .noUi-tooltip {
    color: $accommodation2;
  }
}
.koudke {
  .noUi-connect {
    background: $koudke;
  }
  .noUi-handle {
    background: $koudke;
  }
  .noUi-tooltip {
    color: $koudke;
  }
}
.restaurant {
  .noUi-connect {
    background: $restaurant;
  }
  .noUi-handle {
    background: $restaurant;
  }
  .noUi-tooltip {
    color: $restaurant;
  }
}
