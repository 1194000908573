//------------------------------------
// #POPINS
//------------------------------------

// Layer opened
.layer-open {
  &,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    overflow: hidden;
  }
}

#popin {
  background: rgba( $theme-secondary-grey, 0.8 );
  height: 100%;
  left: 0;
  overflow: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;

  article {
    font-size: 0;
    letter-spacing: 0;
    height: 100%;
    overflow: auto;
    position: relative;
    text-align: center;

    .ios & {
      -webkit-overflow-scrolling: touch;
    }

    &:before {
      @include valign();
    }
  }

  .popin-content {
    display: inline-block;
    font-size: rem( $fz );
    height: auto;
    letter-spacing: normal;
    position: relative;
    text-align: left;
    vertical-align: middle;
    width: 100%;
  }
  .wrap {
    width: pct( 660 / 780 );
  }
  .block-form .wrap {
    max-width: rem( 560 );
  }
}
