//------------------------------------
// #HEADINGS
//------------------------------------


// Global
h1, h2, h3, h4, h5, h6 {
  line-height: 1;
  // font-weight: $base-font-weight;

  @include baseline;
}


// Specific
[class*="tt-"] {
  -webkit-font-kerning: none;
  font-family: $font-title;
  font-weight: 300;
  text-transform: uppercase;
}

[class*="tt-header"] {
  font-size: rem( 20 );
  line-height: ( 22/20 );
  margin-bottom: rem( 20 );

  @include clearfix;

  span {
    box-sizing: border-box;
    color: $white;
    padding: rem( 7 ) rem( 8 );
    float: left;
  }

  span:first-child {
    background: rgba( $theme-secondary, .9 );
    border: 2px solid transparent;

    ~ span {
      background-color: rgba( $theme-secondary-dark, 0.3 );
      border: 2px solid $white;
      color: $white;
    }
  }
}
.tt-header-dark {
  span:first-child {
    background: #303b3d;
  }
}
.tt-header-hero {
  span:first-child {
    background: $theme;
  }
}
.tt-header-green {
  span:first-child {
    background: rgba( $theme-dark, .8 );
  }
}

.tt-header-cyan {
  span:first-child {
    background: #0e5f6c;
  }
}
[class*="tt-block"] {
  color: $theme;
  font-size: rem( 25 );
  margin-bottom: rem( 26 );

  &:after {
    background: $theme;
    content: "";
    display: block;
    height: rem( 2 );
    margin: rem( 8 ) 0 0 0;
    width: rem( 30 );
  }

  &.small {
    font-size: rem( 18 );
    font-weight: 500;

    &:after {
      width: rem( 60 );
    }
  }
}
.tt-block-light {
  color: $white;

  &:after {
    background: $white;
  }
}

[class*="tt-side"] {
  border: 2px solid $theme;
  border-width: 2px 0 2px 0;
  color: $theme;
  font-size: rem( 22 );
  margin: 0 0 rem( 10 );
  padding: rem( 2 ) rem( 5 );
  text-align: center;
}

.tt-side-light {
  border-color: $white;
  color: $white;
}

[class*="tt-raw"] {
  color: $theme;
  font-size: rem( 25 );
  margin: 0 0 rem( 10 );
}
.tt-raw-light {
  color: $white;
}
.tt-raw-block {
  color: $purple;
}

.tt-raw-yellow {
  color: $yellow;
}

.tt-label {
  font-size: rem( 22 );
  margin-bottom :rem( 20 );

  span {
    background: $theme;
    box-sizing: border-box;
    color: $yellow;
    display: inline-block;
    padding: rem( 10 ) rem( 20 );
    position: relative;
    min-width: rem( 144 );

    &:after {
      background: url( "#{$url-img}bg-label-shadow.png" ) no-repeat 0 0;
      background-size: rem( 136 ) auto;
      bottom: rem( -13 );
      content: "";
      height: rem( 13 );
      position: absolute;
      right: rem( 8 );
      text-align: left;
      width: rem( 136 );
    }
  }

  &.revert,
  .revert & {
    span {

      &:after {
        background-position: 0 rem( -13 );
        left: rem( 8 );
        right: auto;
        text-align: right;
      }
    }
  }
}


.tt-icon {
  margin: 0 0 rem( 16 );

  .pict {
    display: inline-block;
    margin: rem( 29 ) 0 0;
    vertical-align: top;
    // width: rem( 35 );
  }
  .title {
    background: #33aabb;
    border-radius: rem( 100% );
    box-sizing: border-box;
    color: $white;
    display: inline-block;
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 16 );
    font-weight: normal;
    height: rem( 100 );
    margin: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    vertical-align: top;
    white-space: nowrap;
    width: rem( 100 );

    &:before {
      @include valign();
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
    }

    &:after {
      background: #33aabb;
      content: "";
      height: rem( 1 );
      left: rem( -50 );
      position: absolute;
      top: rem( 98 );
      width: rem( 90 );
    }

    strong {
      font-family: $font-roboto;
      font-size: rem( 20 );
      font-weight: bold;
      text-transform: none;
    }
  }
}


.tt-text {
  color: $theme;
  font-family: $font-roboto;
  font-size: rem( 18 );
  font-weight: 500;
  margin: 0 0 rem( 10 );
}


.tt-desc {
  color: $theme;
  font-family: $font-roboto;
  font-size: rem( 16 );
  font-weight: bold;
  margin: 0;
  text-transform: none;
}

[class*="tt-hero"] {
  color: $theme;
  font-family: $font-roboto;
  font-size: rem( 30 );
  font-weight: bold;
}
.tt-hero-light {
  color: $white;
}
