

.section {

  #btnCta {
      color: #182883;
      background-color: transparent;
      
      &::before {
        border-color: #182883;
      }
  }
}