//------------------------------------
// #BOOKING PAGES
//------------------------------------

// Actions
.booking-action {
  .form-filters {
    padding-left: rem( 8 );
    padding-right: rem( 8 );
  }
}

// Blocks
.booking-block {

  // Airport details
  .airport {
    font-size: rem( 15 );
    padding: rem( 5 );
    width: rem( 60 );

    b {
      font-size: rem( 16 );
      padding: rem( 4 ) 0;
    }
    span {
      padding: rem( 4 ) 0;
    }
  }

  // Flight details
  .logo {
    width: rem( 170 );
  }
  // schedule
  .schedule {
    width: calc( 100% - #{rem( 170 )} );
  }

  .no-cssgradients & {
    .logo,
    .schedule {
      width: 100%;
    }
  }

  // link
  .action {
    font-size: rem( 18 );
    padding: rem( 6 ) rem( 45 ) rem( 6 ) rem( 22 );

    &:after,
    &:before {
      right: rem( 15 );
    }

    &:before {
      height: rem( 18 );
      margin: rem( -9 ) rem( 8 ) 0 0;
      width: rem( 2 );
    }
    &:after {
      height: rem( 2 );
      margin: rem( -1 ) 0 0;
      width: rem( 18 );
    }
  }

  // price
  .price {
    padding: rem( 14 ) rem( 12 ) rem( 10 );

    p {
      font-size: rem( 16 );
      margin: 0 0 rem( 34 );

      b {
        font-size: rem( 22 );
      }
    }
  }
}


// Rental
.rental-block {

  .desc {
    .badge {
      border-radius: rem( 10 );
      font-size: rem( 17 );
      height: rem( 60 );
      padding: rem( 8 ) 0 0;
      width: rem( 60 );

      [class*="icon"] {
        height: rem( 22 );
        margin: 0 auto rem( 3 );
        width: rem( 36 );
      }
    }
  }
}
