//------------------------------------
// #THEME
//------------------------------------

$black:                #000000;
$white:                #ffffff;
$light:                #e8e8e8;
$grey:                 #333333;
$grey-light:           #e0e0e0;
$grey-light2:          #f2f2f2;
$grey-light3:          #ebebeb;
$grey-light4:          #e2e4e5;
$theme-legend:         #2c444b;
$theme-secondary:      #2e3d40;
$theme-secondary2:     #287e7f;
$theme-secondary-dark: #4d9899;
$theme-secondary-grey: #2c444b;

$theme:             #2bb7da;
$theme2:            #0ca5cb;
$theme3:            #2c444b;
$theme4:            #27aece;
$theme5:            #7bced4;
$theme6:            #41565d;
$theme-dark:        #0e5e62;
$theme-dark2:       #011e26;
$theme-dark3:       #2c444b;
$theme-dark4:       #31434a;
$theme-light:       #45b9d5;
$cta:               #e11c4a;
$purple:            #b24bb1;
$yellow:            #fffc27;
$red:               #e6315b;
$red2:              #ee0016;

$koudke:            #ff406d;
$accommodation:     #b5dd31;
$accommodation2:    #94b720;
$activity:          #ff9e21;
$restaurant:        #ff614d;
$restaurant2:       #f4824b;
$sea:               #3bd8e2;
$sun:               #f4dc13;
$interactive:       #6baf00;

$color-confirm:     #4CAF50; // green
$color-alert:       #F44336; // red

$text-color:        $grey;
$bg-color:          $white;

$color-ui:          #ccc;    // Default colour for objects’ borders
$color-affordance:  inherit; // Link color

$radius:            4px;

//------------------------------------
// #LAYOUT
//------------------------------------

// Content width
$page-width-B2C: 80rem;
