//------------------------------------
// #BASE
//------------------------------------

html {
  background: $bg-color;
}

body {
  background: $bg-color;
  color: $text-color;
  font-family: $font-family;
  -moz-font-feature-settings: "kern" off; // Fix Advent Pro display issue in FF.
  font-size: $font-root;
  -webkit-font-smoothing: antialiased;
  line-height: $line-height;
}


// Links
a {
  color: $color-affordance;
  transition: $transition;

  // &:hover {}
}


// Lists
ul, ol {
  list-style-position: inside;
  padding: 0;
  @include baseline;
}


// Paragraphs
p {
  @include baseline;
}


// Quotes
q, blockquote {
  quotes: "\00AB" "\00BB" "\2018" "\2019";
}

blockquote {
  @include baseline;
}


// Misc
b,
strong {
  font-weight: 500;
}

figure {
  margin: 0;
}

iframe {
  border: 0 none;
}

img {
  max-width: 100%;
}

.strike {
  text-decoration: line-through;
}
