//------------------------------------
// #Sections
//------------------------------------

// New Island Page
.new-island-main {
	[class*="header-main"] {
		height: 400px;

		.wrap {
			height: 400px;
		}

		.bg-wrapper {
			height: 400px;
		}

		.text {
			font-size: calc(1.1 * #{$baseline});
			width: auto;
			min-width: 420px;
		}

	}

	.ct-header {
		span {
			font-size: calc(2.5 * #{$baseline});
		}
	}
}

.block-island-map {
	.text {
		h2 {
			font-size: calc(2.5 * #{$baseline});
		}
		.desc, .link-light {
			font-size: calc(1.2 * #{$baseline});
		}
		.link-light {
			margin-top: calc(2 * #{$baseline});
		}
	}
}

.block-island-encyclopedia {
  height: 400px;

  .bg-wrapper {
    margin: 0;
    height: 400px;
  }

  .text {
    font-size: $baseline;

    &.light {
      color: $white;
    }

    h2 {
      font-size: calc(2.5 * #{$baseline});
    }

    .desc {
      /*font-size: calc(1.2 * #{$baseline});*/
    }
  }
}

.block-island-events {
	.block-agenda {
		padding: calc(4 * #{$baseline}) calc(2 * #{$baseline});
	}
}


