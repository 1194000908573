//------------------------------------
// #OFFERS PAGES
//------------------------------------


// Search Form
.offer-search-form {

  // Main Search form
  [class*="form-header"] {

    .form-details {

      .line {
        box-sizing: border-box;

        &:nth-child(1),
        &:nth-child(2) {
          float: left;
          width: 50%;
        }
        &:nth-child(1) {
          padding-right: rem( 2 );
        }
        &:nth-child(2) {
          padding-left: rem( 2 );
        }

        &:nth-child(3) {
          clear: both;
        }
      }

    }

    .kids-form {
      .select-block {
        padding: 0 rem( 2 ) 0 rem( 2 );
        width: 25%;

        &:nth-child(odd),
        &:nth-child(even){
          padding: 0 rem( 2 ) 0 rem( 2 );
        }

        &:nth-child(4n-3){
          padding: 0 rem( 2 ) 0 0;
        }
        &:nth-child(4n){
          padding: 0 0 0 rem( 2 );
        }
      }
    }
  }
}
