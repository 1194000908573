//------------------------------------
// #BOOKING PAGES
//------------------------------------

// Title
.booking-tt {
  display: none;
}

// Search Form
.offer-search-form {

  .form-filters {
    #booking-search & {
      margin-top: rem( -30 );
    }
  }

  .form-header-booking {

    .checkbox {

      &.big {
        label {
          font-size: rem( 22 );
        }
      }
    }

    .field {
      padding-right: rem( 35 );

      &:nth-child(even){
        padding-left: rem( 35 );
        padding-right: 0;
      }
    }

    .associated-line {
      .line {
        padding-right: rem( 35 );

        &:nth-of-type(even){
          padding-left: rem( 35 );
          padding-right: 0;
        }
      }
    }

    .cols {
      .col {
        padding-right: rem( 35 );

        &:nth-of-type(even){
          padding-left: rem( 35 );
          padding-right: 0;
        }
      }

      .half {
        // padding-right: rem( 25 );

        &:nth-of-type(even){
          // padding-left: rem( 25 );
          padding-right: 0;
        }
      }

      .large,
      .small {
        box-sizing: border-box;
        float: left;

        &:nth-of-type(even){
          padding-left: rem( 1 );
        }
      }
      .large {
        width: pct( 340 / 455 );
      }
      .small {
        width: pct( 115 / 455 );
      }
    }
  }
}

// Rental
.rental-block {

  // price
  .price {
    width: rem( 200 );
  }
}

// Form
.booking-form {
  padding: rem( 20 ) rem( 230 ) rem( 54 ) 0;
  position: relative;

  .buttons {
    bottom: rem( 89 );
    position: absolute;
    right: 0;
    width: rem( 180 );
  }

  .rental-booking & {
    padding-right: 0;

    .buttons {
      bottom: auto;
      clear: both;
      position: static;
      padding: rem( 10 ) 0 0 0;
      text-align: right;
      width: auto;

      .error-msg {
        float: left;
        margin-top: rem( 5 );
        max-width: rem( 230 );
      }
    }
  }
}

.booking-action {
  left: auto;
  overflow: visible;
  position: relative;
  top: auto;

  .js &:not(.sorting-action){
    display: block;
  }
  .js &.sorting-action {
    &,
    &.on {
      display: none;
    }
  }

  .action-head {
    display: none;
  }

  > .wrap {
    &:after,
    &:before {
      display: none;
    }
  }

  .form-filters {

    .legend {
      background-color: transparent;
      margin: 0;
      padding: 0;

      span {
        padding: 0;
      }
    }
  }
  .filters-tt {
    display: none;
  }
}

// Recap
.booking-recap {
  .wrap {
    padding-bottom: 0;
    padding-top: rem( 4 );
  }

  .recap {
    float: left;
    margin: 0 rem( 10 ) rem( 26 ) 0;
    width: rem( 320 );
  }
  fieldset {
    float: left;
    margin: 0 0 rem( 10 );
    max-width: rem( 650 );
    padding: 0;
  }
  .banner {
    .wrap {
      padding-left: rem( 345 );
    }
  }

  .rental-booking & {
    padding-bottom: rem( 24 );

    .recap {
      float: none;
      margin: 0;
      width: auto;
    }
    p {
      font-size: rem( 15 );
    }
  }
}

// Search Results
.booking-results {
  padding: rem( 25 ) 0 0;
  position: relative;

  .results-actions {
    display: none;
  }

  .tt-block {
    margin-top: 0;
  }

  .results-content {
    padding-bottom: rem( 14 );
    padding-left: rem( 345 );
  }

  .sort {
    display: block;
    float: right;
    padding: rem( 5 ) 0 0;

    .label {
      display: inline-block;
      margin: 0 rem( 5 ) 0 0;
      vertical-align: middle;
    }
    .select-list{
      background: $grey-light;
      border: 1px solid $grey-light;
      border-radius: rem( 6 );
      height: rem( 28 );
      line-height: rem( 28 );
    }
    .select {
      background: $grey-light;
      border-color: $grey-light;
      border-radius: rem( 6 );
      line-height: rem( 28 );

      select {
        height: rem( 28 );
      }

      .label {
        display: block;
        margin: 0;
        vertical-align: top;
      }

      .no-cssgradients & {
        width: rem( 200 );
        zoom: 1;

        select,
        span {
          width: rem( 200 );
          zoom: 1;
        }
      }
    }
  }
}

.booking-selection {
  display: table;
  font-family: $font-roboto-condensed-regular;
  font-size: rem( 15 );
  line-height: ( 17 / 15 );
  list-style-type: none;
  margin: 0 0 rem( 30 );
  table-layout: fixed;
  width: 100%;

  li {
    display: table-cell;
    text-align: center;
    // width: pct( 1/3 );

    ~li {
      padding-left: rem( 3 );
    }
  }

  a {
    background: $grey-light;
    border-radius: rem( 6 );
    color: $theme3;
    display: block;
    padding: rem( 6 );
    position: relative;
    text-decoration: none;

    &.on,
    &:hover,
    &:focus {
      background: $theme3;
      color: $white
    }
    &.on {
      &:after {
        content: "";
        @include triangle( 14px, $theme3, down );

        bottom: rem( -7 );
        left: 50%;
        margin: 0 0 0 rem( -7 );
        position: absolute;
      }
    }
  }
  small {
    font-size: rem( 13 );
  }
  b {
    font-family: $font-roboto;
    font-size: rem( 30 );
    font-weight: 500;
    line-height: 1;
  }
}


// Popins
.popin-booking {

  #popin & .wrap {
    width: rem( 725 );
  }

  #popin & .popin-content {
    width: rem( 870 );
  }

  #popin &.search .popin-content {
    width: rem( 780 );

    .wrap {
      width: rem( 650 );
    }
  }

  .popin-content {
    .inner {
      padding: rem( 28 ) 0 0;
    }
  }

  [class*="tt-block"]{
    margin-bottom: rem( 22 );

    small {
      font-size: rem( 15 );
    }
  }

  // Booking recap
  .booking {
    margin: 0 auto rem( 42 );

    .details {
      background: $theme3;
      color: $white;
      // padding: rem( 30 ) 0;

      @include clearfix;
    }

    // Recap
    .recap {
      font-size: rem( 16 );

      b {
        font-size: rem( 30 );
      }
    }

    // Waring
    .warning {
      padding: rem( 12 ) rem( 8 );

      .info {
        height: rem( 18 );
        margin: 0 rem( 10 ) 0 0;
        width: rem( 18 );

        [class*="icon"]{
          height: rem( 12 );
          margin: rem( 3 ) auto 0;
          width: rem( 14 );
        }
      }

      a {
        font-size: rem( 13 );
        padding: 0 rem( 14 ) 0 0;

        &:after {
          @include triangle( 9px, $theme3, down );
          margin: rem( -3px ) 0 0;
        }
      }
    }
  }

  .flight {

    .head {

      p {
        font-size: rem( 18 );
      }
      small {
        font-size: rem( 14 );
      }
    }

    .desc {
      font-size: rem( 15 );
      padding: rem( 10 ) rem( 24 ) 0 rem( 24 );
    }

    .timing {
      padding: 0 0 rem( 8 );
    }

    .travel {
      @include clearfix;

      .name {
        float: left;
        width: rem( 160 );
      }
      .data {
        padding-left: rem( 175 );
      }
    }

    .travel {
      padding-bottom: rem( 12 );
      padding-top: rem( 12 );
      padding-right: rem( 66 );

      .total {
        font-size: rem( 14 );
        padding-left: rem( 10 );
        width: rem( 44 );
      }

      &:first-of-type {
        .total {
          height: calc( 100% - #{rem( 30 )} );
        }
      }

      &:last-of-type {
        .total {
          height: calc( 100% - #{rem( 22 )} );

          &:after {
            @include triangle( 16px, #bfc7c9, down );
            left: rem( -11 );
          }
        }

        &:first-of-type {
          .total {
            height: calc( 100% - #{rem( 52 )} );
            top: rem( 30 );
          }
        }
      }
    }

    .connection {
      margin-left: rem( 175 );
      padding-right: rem( 66 );
      padding-bottom: rem( 6 );
      padding-top: rem( 6 );

      .total {
        font-size: rem( 14 );
        padding-left: rem( 10 );
        width: rem( 44 );
      }
    }

    .time {
      border-radius: rem( 8 );
      height: rem( 60 );
      width: rem( 60 );
    }

    .line {
      + .line {
        margin: rem( 12 ) 0 0;
      }
    }

    .recap {
      font-size: rem( 17 );
      padding: rem( 16 );

      p {
        display: inline-block;
        padding: 0 rem( 12 );
        vertical-align: middle;
      }
    }
  }

  .offers-section {
    padding-bottom: rem( 14 );
  }

  // Redirection
  .redirect {
    display: table;
    font-size: rem( 15 );
    padding: rem( 32 ) 0 rem( 48 );

    .from,
    .pending,
    .to {
      display: table-cell;
      vertical-align: middle;
    }

    .from {
      width: rem( 220 );

      .logo {
        display: block;
        height: rem( 88 );
        padding: rem( 12 ) rem( 12 ) 0;
        margin: 0 0 rem( 18 );
        width: 100%;

        img {
          height: rem( 56 );
          margin: 0 auto;
          max-width: none;
        }

        &:after {
          content: "";
          @include triangle( 20px, $white, down );
          bottom: rem( -10 );
          left: 50%;
          margin: 0 0 0 rem( -10 );
          right: auto;
          top: auto;
        }
      }
      p {
        display: block;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        width: rem( 140 );
      }
    }

    .pending {
      margin: 0;
      width: rem( 220 );

      .pict {
        display: block;
        height: rem( 76 );
        margin: 0 0 rem( 18 );
        padding: rem( 12 ) 0 0;
        width: auto;

        &:before {
          background: url( "#{$url-img}desktop/2x/bg-search.png" ) no-repeat 0 0;
          background-size: 100% auto;
          height: rem( 57 );
          transform: none;
          width: rem( 85 );
        }
      }

      p {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: rem( 170 );
      }
    }

    .to {
      width: rem( 210 );

      .logo {
        box-sizing: border-box;
        display: block;
        height: rem( 88 );
        padding: rem( 24 ) 0 0 0;
        margin: 0 0 rem( 18 );
        width: 100%;

        img {
          margin: 0 auto;
          max-width: rem( 140 );
        }
      }
      p {
        display: block;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        width: rem( 210 );
      }
    }
  }
  .recap-details {
    background: $white;
    box-shadow: 0 2px 3px rgba( $black, 0.1 );
    font-size: rem( 15 );
    margin: 0 0 rem( 50 );

    .head {
      @include clearfix;

      p {
        float: right;
        max-width: 50%;
        padding: rem( 4 ) 0 0;
      }
    }
    [class*="tt-block"] {
      float: left;
      font-size: rem( 25 );
      margin-bottom: rem( 20 );
      text-align: right;
      max-width: 50%;

      &:after {
        margin-left: 0;
      }
    }

    .recap-block {
      display: table;
      margin: 0 0 rem( 10 );
      width: 100%;

      .schedule {
        display: table-cell;
        padding: 0 rem( 30 ) 0 0;
        width: rem( 292 );
      }
      .logo {
        border-left: 1px solid #bfc7c9;
        display: table-cell;
        margin: 0;
        padding: 0;
        text-align: center;

        img {
          margin: 0 auto;
          max-width: rem( 100 );
        }
      }
      .price {
        display: table-cell;
        margin: 0;
        padding: 0 0 0 rem( 10 );
        text-align: center;
        width: rem( 110 );
      }
    }
  }

  .rental-recap {
    font-size: rem( 15 );
    margin-bottom: rem( 30 );
  }
}
