//------------------------------------
//  #MIXINS
//------------------------------------


// To align vertically elements

@mixin middle {
    display: inline-block;
 	vertical-align: middle;
}

@mixin valign {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}



// Baseline
// Use it as a property
// ex: .foo { @include baseline(3); }

@mixin baseline($time: 1) {
    margin-top: 0;
    //margin-bottom: $baseline*$time;
    margin-bottom: 8px;
}

// Media query from a defined named width
// @size Width size small | medium | large
// ex: .foo { @include mq(medium) {...} }
// ex: .foo { @include mq(320) {...} }
// ex: .foo { @include mq(50rem) {...} }
// ex: .foo { @include mq(45em) {...} }
// ex: .foo { @include mq(320px) {...} }

@mixin mq($media-query){
    @each $size, $breakpoint in $breakpoints {
        @if $media-query == $size {
            @if (unitless($breakpoint)) or (unit($breakpoint) == 'px') {
                @media (max-width: rem(strip-units($breakpoint))) { @content; }
            }  @else if (unit($breakpoint) == 'rem') {
                @media (max-width: $breakpoint) { @content; }
            }
        }
    }
    @if type-of($media-query) == number {
        @if (unit($media-query) == 'px') or unitless($media-query) {
            @media (max-width: rem($media-query)) { @content; }
        }

        @if (unit($media-query) == 'em') or (unit($media-query) == 'rem'){
            @media (max-width: $media-query) { @content; }
        }
    }
}

/**
** To create '...' ellipsis only with CSS for multiline
** Requierment : text-align: justify
** See : http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/?ref=hackingui
**/
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  //margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}


// Hide only visually, but have it available for screenreaders
@mixin hide{
  border: 0;
  clip: rect( 0 0 0 0 );
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  pointer-events: none;
}

@mixin hide-reset{
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}


@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  border: 0 none;
  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}


// generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
   @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}

@mixin horizontal-center () {
  position: absolute;
  z-index: 1;
  @include transform(translate(-50%, 0));
  left: 50%;
}


@mixin center($position) {
  position: absolute;
  @if $position == 'vertical' {
    top: 50%;
    @include transform(translateY(-50%));
  }
  @else if $position == 'horizontal' {
    left: 50%;
    @include transform(translateX(-50%));
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    @include transform(translate(-50%,-50%));
  }
}

@mixin box-shadow($params) {
  -webkit-box-shadow : #{$params};
     -moz-box-shadow : #{$params};
          box-shadow : #{$params};
}
//SVG prop only
@mixin drop-shadow($params) {
  -webkit-filter: drop-shadow(#{$params});
     -moz-filter: drop-shadow(#{$params});
          filter: drop-shadow(#{$params});
}