//------------------------------------
// #BLOCKS
//------------------------------------

// Documents
.documents-list {
  padding-bottom: rem( 20 );
}

// Search
.block-search {
  .wrap {
    display: block;
  }

  .completeHeader{
    background-color: $theme2;
    height: 2.9rem;
  }

  .tab {
    float: left;
    margin: 0;
    padding: rem( 10 );
    width: auto;

    &:first-of-type{
      padding-left: 0;
    }

    div {
      padding-left: rem( 15 );
      padding-right: rem( 15 );
      position: relative;
      z-index: 1;
    }
    span{
      display: inline-block;
    }
    [ class*="icon" ] + span {
      padding-left: rem( 23 );
    }

    .icon-prev{
      display: none;
    }

    ~ .tab {
      border-top: 0 none;
    }

    &:first-of-type,
    &:last-of-type{
      &:before {
        content: "";
        top: 0;
        transition: background 0.3s ease-out;
        position: absolute;
      }
    }

    &:first-of-type {
      &:before {
        right: 0;
      }
    }
    &:last-of-type {
      &:before {
        left: 0;
      }
    }

    &[aria-expanded="true"]{
    &:before {
        background: $theme;
      }
    }
  }

  .content {
    clear: both;

    .inner {
      padding-bottom: rem( 10 );
      padding-top: rem( 20 );
    }
  }

  .form{
    @include clearfix;

    .line {
      margin-bottom: rem( 10 );
    }

    .fields {
      float: left;
      width: rem( 820 );
    }
    .button {
      box-sizing: border-box;
      float: left;
      padding: 0 0 0 rem( 1 );
      margin: 0 0 rem( 10 );
      width: rem( 160 );

      [class*="cta"] {
        width: 100%;

        &:before {
          height: rem( 23 );
        }
      }
    }
  }
  .main-form {
    display: flex;
    justify-content: flex-end;

    .line {
      flex-grow: 1;
    }

    .half {
      flex-grow: 2;
      width: rem( 320 );

      &.small {
        width: rem( 240 );
      }
    }

    .third {
      flex-grow: 3;

      .line,
      .line:nth-of-type(3) {
        clear: none;
        float: left;
      }
      .line {
        width: 33%;

        &:nth-of-type(3){
          width: 34%;
        }
      }
    }

    &,
    & .half,
    & .third {
      .line,
      .half,
      .third {
        box-sizing: border-box;
        padding-right: 0;

        + .line,
        + .half,
        + .third {
          padding-left: rem( 1 );
        }
      }
    }
  }

  .kids-form {
    text-align: right;

    label {
      display: inline-block;
      padding-top: rem( 8 );
      vertical-align: top;
    }

    .selects {
      display: inline-block;
      margin: 0;
      max-width: rem( 415 );
      vertical-align: top;

      .select-block {
        float: right;
        padding: 0 0 rem( 4 ) rem( 4 );
        text-align: left;
      }
    }
  }
}
