
footer[role="contentinfo"] {

  .cols {

    .button-top {
      top: -60px;
      margin-right: 0px;
      svg {
        width: 80px;
        height: 80px;
      }

    }
    .line {
      //display: flex;
      .field {
        margin-right: 0px;
        width: 100%;
        .input {
          .placeholder{
            font-size: 20px;
          }
        }
      }
    }

    .dflex {
      display: flex;
    }

    .sk-EncartNewsletter{
      font-size: 13px;
      line-height: 22px;

    }
    .desc-nl{
      font-size: 20px;
      padding-bottom: 20px;
    }

    .social {
      border-bottom: 1px solid rgba(0, 0, 0, .3);
      .social_span {
        font-size: 13px;
      }

      .icons_social {
        img {
          height: 18px;
          max-width: initial;
          margin-left: 15px;
        }
      }
      svg {
        width: 18px;
        height: 18px;
        margin-left: 15px;
      }
    }


  }

  .col{
    .image_footer {
      display: none;
    }

    .footer_image_bird {
      display: none;
    }

  }

  .block {
    padding: 0;
    padding-top: 15px;
    width: 100%;

    .tt-raw-light{
     display: block;
     line-height: 0.8;

     span {
      padding-left: 0px;
     }

    }
      .contact-link {
        padding-left: 20px;
        margin-right: 0px;
        padding-right: 5px;

      }

    ul{
    margin: 0px;
    padding-left: 0;
    }


  }

  .disclaimer {
    .menu-disclaimer {
      margin: initial;
    }
  }

  .disclaimer li{
  padding: 0px;
  margin-right: 0px;
  }

  .footer-links {
    #section-middle
    {
      padding : 0;
      margin: 20px 0;
      justify-content: space-around;

      .links{
    position: absolute;
    left: 0px;
    margin: 0px;
      }
      a {
        text-decoration: none;
        font-size: 11px;
        font-family: 'Roboto Condensed Regular';
        font-weight: 400;
        color: initial;vertical-align: super;
      }
    }
  }


//   .footer-links-mobile {
//     display: flex;
//     border-bottom: 1px solid #000000;

//     ul {
//       li {
//         list-style: none;
//       }
//       a {
//         font-family: 'roboto';
//         line-height: 25px;
//       }

//     }

//     .block {
//       padding-top: 10px;
//     }

//   }

//   .dropDown-link-second {
//     display: flex;
//     width: 100%;
//     justify-content: space-between;
//     margin-right: 30px;
//   }

// }

.dropDown-link {
  display: flex;
  list-style: none;
  justify-content: space-between;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 15px;
}

// .footer-links {
//   // display: none;
//   .block {
//     ul{
//       padding-left: 0px;
//       display: contents;
//     }
// }
//   .contact{
//     .tt-raw-light{
//       margin-right: 0px;
//     }
//   }
// }
}


.footer-links {
  .col {
    #section-middle {
      div:first-child {
        margin-left: initial;
      }
    }
    .block .tt-raw-light > span {
      padding-left: initial;
    }
  }
}
