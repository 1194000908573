//------------------------------------
// #SELECTION
//------------------------------------


.selection-section {
  padding: rem( 20 ) 0 0;
}


// Block
.selection-block {
  margin: rem( 20 ) 0;

  // Offer Part
  .offer {
    @include clearfix;
  }

  // Picture
  .picture {
    width: rem( 250 );
  }

  // Place details
  .place {
    margin-top: rem( 2 );
    width: rem( 250 );
  }

  // Details
  .details {
    border: 0 none;
    clear: none;
    float: right;
    min-height: rem( 110 );
    padding: 0;
    width: auto;
  }

  // Timing details
  .timing {
    border-left: 1px solid rgba( $theme3, 0.4 );
    border-top: 0 none;
    padding-top: rem( 10 );
  }

  .total {
    padding-top: rem( 10 );
    padding-left: rem( 10 );
    padding-right: rem( 10 );
    width: rem( 120 );
  }

  // State
  .state {
    text-align: left;
    width: rem( 140 );

    p {
      margin-bottom: rem( 30 );
    }

    [class*="cta"]{
      display: block;
      width: 100%;
    }
  }

  .contact {
    margin-top: rem( 10 );
    padding: rem( 20 ) rem( 40 );

    .inner {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    .contact-user {
      display: table-cell;
      border-right: 1px solid $theme3;
      margin: 0;
      padding: 0 rem( 40 ) 0 rem( 60 );
      vertical-align: middle;
      width: rem( 200 );
    }
    .contact-form {
      display: table-cell;
      padding-left: rem( 40 );
    }
    .button {
      padding: 0;
    }
  }

  // Payment details
  .payment {
    border-top: 0 none;
    padding: rem( 2 ) 0 rem( 10 );
  }

  // Options
  .options {

    .options-details {

      ul {
        width: rem( 490 );
      }
    }
  }
}


// Actions
.selection-actions {
  margin: rem( 40 ) 0 rem( 50 );
  position: relative;

  @include clearfix;

  .tt {
    margin-bottom: rem( 20 );
    text-align: left;
  }

  .address {
    border: 0;
    float: left;
    margin: 0;
    padding: 0;
    width: rem( 300 );

    p:last-child{
      margin-bottom: 0;
    }
  }

  .legals {
    float: right;
    padding: 0 0 0 rem( 30 );
    width: rem( 570 );

    &:before {
      background: rgba( $theme3, 0.4 );
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      right: rem( 600 );
      width: rem( 1 );
    }
  }

  .checkbox {
    margin: 0 0 rem( 16 );

    &:last-child{
      margin-bottom: 0;
    }
  }
}


// Total price
.selection-total {
  padding: 0 0 rem( 90 ) rem( 380 );

  .cta {
    font-size: rem( 18 );
    min-width: rem( 150 );
  }
}


// Confirmation
.selection-confirmation {
  font-size: rem( 17 );
  margin-bottom: rem( 26 );
  margin-top: rem( 10 );
  text-align: center;

  .tt {
    font-size: rem( 28 );
  }
}


// Info
.selection-info {
  margin: rem( 40 ) 0 rem( 50 );
  padding: 0;
}
