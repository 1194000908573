//------------------------------------
// #BUTTONS
//------------------------------------


// CTA labels
.cta-label {
  font-size: rem( 20 );

  span {
    padding: rem( 8 ) rem( 10 ) rem( 8 ) rem( 10 );
    min-width: rem( 108 );

    &:after {
      background-size: rem( 100 ) auto;
      bottom: rem( -9 );
      height: rem( 9 );
      right: rem( 8 );
      width: rem( 100 );
    }
  }

  &.revert,
  .revert & {
    span {

      &:after {
        background-position: 0 rem( -10 );
        left: rem( 8 );
      }
    }
  }
}
