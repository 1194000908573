/*header mobile + tab */


header[role="banner"] {
    .banner{
        background-color: #182883;
        .wrap{
            width: 100%;
            button{
                padding: 0;
                padding-left: 20px;
                left: 0px;
            }
        }
    }
    img, picture {
    color: $white;
    vertical-align: initial;
    width: 30px;
    }
    .bt-nav[aria-expanded="true"]{
        background: transparent;
    }
    .home-nav
    {
    color: white;
    margin-right: 0px;
    margin-left: 0px;
    > ul
        > li
        {
            display: flex;
            float: left;
            p{
                margin-bottom: 0px;
                padding: 1px;
                padding-top: 0px;

                span{
                    vertical-align: top;
                    font-size: 13px;
                }
            }
            .pro{
                margin-left: 10px;
                padding-top: 2px;
                picture {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .account {
                picture {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .basket {
                picture {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .select{
                background: transparent;
                color: #69b5c2;
                border: none;
                padding-top: 3px;
                select{
                    color: #69b5c2;
                }
            }
        }
    }

    #nav {

        .categories-nav{
            background: $white;
            .guadeloupedia{
                a {
                    padding-left: rem( 14 );
                }
            }
            .lang,.static-icon,.account,.basket {
                display: block;
            }
            #logo{
                padding-top: 0px;
            }
        }
    }
}
