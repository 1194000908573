//------------------------------------
// #BOOKING PAGES
//------------------------------------

// Search Form
.offer-search-form {

  .bg-wrapper {
    background-position: 50% 50%;
    background-size: cover;
  }

  .form-header-booking {

    .field {
      box-sizing: border-box;
      padding-right: rem( 25 );

      &:nth-child(even){
        padding-left: rem( 25 );
        padding-right: 0;
      }
    }

    .associated-line {
      position: relative;
      @include clearfix;

      .line {
        box-sizing: border-box;
        float: left;
        padding-right: rem( 25 );
        width: 50%;

        &:nth-of-type(even){
          padding-left: rem( 25 );
          padding-right: 0;
        }
      }
      button {
        bottom: rem( 20 );
        display: block;
        left: 50%;
        margin: 0 0 0 rem( -12 );
        position: absolute;
        width: rem( 24 );

        [class*="icon"] {
          transform: none;

          .no-cssgradients & {
            transform: none;
          }
        }
      }
      // .separator {
      //   bottom: rem( 20 );
      //   display: block;
      //   left: 50%;
      //   margin: 0 0 0 rem( -12 );
      //   position: absolute;
      //   width: rem( 24 );

      //   [class*="icon"]{
      //     fill: $white;
      //     height: rem( 26 );
      //     width: rem( 24 );
      //   }
      // }
    }

    .cols {
      @include clearfix;

      +.cols {
        padding-top: rem( 14 );
      }

      .col {
        box-sizing: border-box;
        float: left;
        padding-right: rem( 25 );
        width: 50%;

        &:nth-of-type(even){
          padding-left: rem( 25 );
          padding-right: 0;
        }

        &:nth-of-type(odd){
          clear: left;
        }

        .error-msg {
          text-align: right;
        }
      }

      .half {
        box-sizing: border-box;
        float: left;
        width: 50%;

        &:nth-of-type(even){
          padding-left: rem( 1 );
        }
      }
    }
  }
}


// Blocks
.booking-block {
  // display: flex;
  display: table;
  padding: 0;
  width: 100%;

  .details {
    display: table-cell;
    padding-bottom: rem( 35 );
    position: relative;
    vertical-align: middle;
    // flex-grow: 1;
  }

  // Flight details
  .flight {
    @include clearfix;
  }

  // logo
  .logo {
    border: 0 none;
    box-sizing: border-box;
    float: left;
    padding: rem( 20 ) rem( 16 ) 0 rem( 10 );
    width: rem( 140 );
  }

  //schedule
  .schedule {
    float: left;
    width: calc( 100% - #{rem( 140 )} );

    .time {
      width: rem( 60 );

      &:last-of-type {
        width: rem( 100 );
      }
    }

    .info {
      font-size: rem( 14 );
    }
    .delay {
      font-size: rem( 16 );
    }
  }

  .no-cssgradients & {
    .logo {
      border-bottom: 1px solid rgba( $theme3, 0.4 );
      display: block;
      float: none;
      padding: rem( 10 );
      width: 100%;
    }
    .schedule {
      padding: rem( 14 ) rem( 10 );
      width: 100%;
    }
  }

  // link
  .action {
    clear: both;
    bottom: 0;
    display: block;
    height: auto;
    padding-bottom: rem( 6 );
    position: absolute;
  }

  // price
  .price {
    box-sizing: border-box;
    display: table-cell;
    padding: rem( 16 ) rem( 12 ) rem( 10 );
    text-align: center;
    vertical-align: middle;
    width: rem( 134 );

    div {
      float: none;
      width: auto;
    }
    p {
      margin: 0 0 rem( 30 );

      b {
        display: block;
      }
    }
    .cta {
      display: block;
      width: 100%;
    }
  }

  // .no-cssgradients & {
  //   display: table;
  //   width: 100%;

  //   .details {
  //     display: table-cell;
  //     vertical-align: top;
  //   }
  //   .price {
  //     display: table-cell;
  //     vertical-align: top;
  //   }
  // }
}

// Rental
.rental-block {
  display: table;
  width: 100%;

  .details {
    display: table-cell;
    vertical-align: top;
  }

  .sticker-label {
    margin: 0 0 rem( 10 );
    width: 100%;
  }

  .picture {
    box-sizing: border-box;
    float: left;
    padding: 0 rem( 10 ) 0 0;
    width: 50%;
  }

  .type {
    float: none;
    width: auto;
  }

  .desc {
    padding: rem( 10 ) rem( 10 ) 0 rem( 10 );

    ul {
      small {
        display: block;
        font-size: 1em;
        text-transform: none;
      }

      li {
        margin-bottom: rem( 4 );
      }
    }
  }

  .price {
    box-sizing: border-box;
    display: table-cell;
    padding: rem( 16 ) rem( 12 );
    text-align: center;
    vertical-align: middle;
    width: rem( 170 );

    div {
      float: none;
      width: auto;
      + div {
        text-align: center;
      }
    }
    p {
      margin: 0 0 rem( 12 );
      padding: 0;
    }
    .logo {
      display: block;
      margin: 0 auto rem( 10 );
      max-width: 100%
    }
    // .cta {
    //   display: block;
    //   width: 100%;
    // }
  }
}

// Form
.booking-form {

  .buttons {
    text-align: center;

    .cta {
      display: inline-block;
      min-width: rem( 180 );
      width: auto;
    }

    .rental-booking & {
      padding: rem( 6 ) 0 rem( 16 ) 0;
      text-align: right;
      width: auto;
    }
  }
}

// Actions
.booking-action {
  .form-filters {
    padding-left: rem( 8 );
    padding-right: rem( 8 );

    .form-details {
      padding-bottom: rem( 12 );
    }

    .legend {
      padding-left: pct( 10/284 );
      padding-right: pct( 10/284 );

      span {
        display: block;
        padding: 0 rem( 8 );
        position: relative;
      }
    }
  }
}

// Recap
.booking-recap {
  .wrap {
    padding-bottom: 0;
  }

  fieldset {
    display: table;
    max-width: 100%;
    // width: 100%;
  }
  .col {
    display: table-cell;
    width: rem( 318 );
    vertical-align: top;

    .line,
    .line + .line {
      padding: 0 rem( 10 ) 0 0;
    }
  }
  fieldset.small {
    .col {
      width: rem( 159 );

      .line {
        width: 100%;
      }
    }
  }
  .button {
    display: table-cell;
    vertical-align: top;
  }
  .class {
    display: none;
  }
  .banner {
    background: rgba( $theme3, 0.5 );
    box-sizing: border-box;
    display: block;
    min-height: rem( 30 );
    padding: rem( 5 ) 0;

    @include clearfix;

    p {
      margin: 0;

      &:first-child {
        float: left;
        width: 40%;
      }
      + p {
        float: right;
        text-align: right;
        width: 60%;
      }

    }
    b {
      font-size: rem( 15 );
      font-weight: 500;
    }
  }

  .rental-booking & {
    padding-bottom: rem( 14 );

    p {
      display: inline-block;
      vertical-align: middle;
      margin: 0 rem( 25 ) rem( 16 ) 0;

      > span {

        span {
          display: inline-block;
          padding-left: rem( 10 );
        }
      }
    }
    [class*="cta"]{
      display: inline-block;
      margin-bottom: rem( 16 );
      vertical-align: middle;
      width: auto;
    }
  }
}

// Search Results
.booking-results {
  padding: rem( 20 ) 0 0;
  position: relative;

  .results-actions {
    float: right;
    padding: 0;
    width: rem( 308 );

    div {
      padding: 0 pct( 5 / 308 ) 0 0;

      + div {
        padding: 0 0 0 pct( 5 / 308 );
      }

      &.full {
        padding: 0;
        text-align: right;

        button {
          padding-left: rem( 20 );
          padding-right: rem( 20 );
          min-width: 50%;
          margin: 0 0 0 auto;
          width: auto;
        }
      }
    }
  }
  .tt-block {
    margin-top: rem( 4 );
  }
}


// Popins
.popin-booking {

  .flight {
    display: table;
    width: 100%;

    .head {
      display: table-cell;
      padding: rem( 25 ) 0 0;
      vertical-align: top;
      width: rem( 94 );

      p {
        display: block;
        font-size: rem( 15 );
        margin: 0 0 rem( 10 );
        text-align: center;
      }
      .date-time {
        display: block;
        margin: 0 auto;
      }
    }
    .details {
      display: table-cell;
      vertical-align: top;
    }

    .desc {
      font-size: rem( 13 );
    }

    .travel {
      padding-right: rem( 60 );
      .total {
        width: rem( 45 );
      }
    }

    .connection {
      padding-right: rem( 60 );
      .total {
        width: rem( 45 );
      }
    }

    .time {
      height: rem( 50 );
      width: rem( 50 );
    }
  }

  .recap-details {
    padding: rem( 16 ) rem( 25 ) rem( 20 );
  }

  .rental-recap {
    @include clearfix

    ul {
      float: left;
      width: 50%;
    }
  }
}
