//------------------------------------
// #Range Slider
//------------------------------------

.range-slider {
  [data-range-min],
  [data-range-max] {
    @include hide;
  }
}

// NoUI
.noUi-target,
.noUi-target * {
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  height: 100%;
  margin: 0 auto;
  position: relative;
  // width: 100%;
  width: calc( 100% - #{rem(14)} );
  z-index: 1;
}
.noUi-origin {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: rem( 6 );
}
.noUi-vertical {
  width: rem( 6 );
}
.noUi-handle {
  box-sizing: border-box;
  height: rem( 22 );
  left: rem( -10 );
  top: rem( -10 );
  width: rem( 22 );
}


/* Styling;
 */
.noUi-background {
  background: #bfc7c9;
}
.noUi-connect {
  // background: #b5dd31;
  background: $theme2;
  transition: background 450ms;
}
.noUi-origin {
  border-radius: rem( 3 );
}
.noUi-target {
  border-radius: rem( 3 );
  margin: rem( 10 ) 0 rem( 35 );
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;

  .noUi-vertical & {
    cursor: n-resize;
  }
}
.noUi-handle {
  // background: #b5dd31;
  background: $theme2;
  border: 1px solid $white;
  border-radius: 50%;
  box-shadow: 3px 3px 5px rgba( $black, 0.2 );
  cursor: default;
}


/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: $grey-light2;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Tooltip;
 */
.noUi-tooltip {
  background: $white;
  // color: #729010;
  color: $theme2;
  font-size: rem( 15 );
  left: 50%;
  position: absolute;
  top: rem( 25 );
  transform: translateX( -50% );
}
.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  // left: rem( -2 );
  text-align: left;
}
.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
  // right: rem( -5 );
  text-align: right;
}
