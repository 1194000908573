a:hover {
    color: inherit;
}


.breadcrumb {
    flex-wrap: inherit;
    padding: inherit;
    list-style: none;
    background-color: inherit;
    border-radius: inherit;
}

.block-checkbox {
    label {
        margin-bottom: initial;
    }

    *::before,
    *::after {
      box-sizing: inherit; 
    }
}

[class*=header-main].small {
    font-size: inherit;
    font-weight: inherit;
   
}




