//------------------------------------
// #FONT-FACE
//------------------------------------


@font-face {
    font-family: $advent-pro;
    src: url('#{$url-font}AdventPro-Medium.woff2') format('woff2'),
         url('#{$url-font}AdventPro-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Roboto Thin
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}roboto-thin.woff2') format('woff2'),
         url('#{$url-font}roboto-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

// Roboto Light
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}roboto-light.woff2') format('woff2'),
         url('#{$url-font}roboto-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

// Roboto Regular
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}Roboto-Regular.woff2') format('woff2'),
         url('#{$url-font}Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}Roboto-Italic.woff2') format('woff2'),
         url('#{$url-font}Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

// Roboto Medium
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}Roboto-Medium.woff2') format('woff2'),
         url('#{$url-font}Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}Roboto-MediumItalic.woff2') format('woff2'),
         url('#{$url-font}Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

// Roboto Bold
@font-face {
    font-family: $roboto;
    src: url('#{$url-font}Roboto-Bold.woff2') format('woff2'),
         url('#{$url-font}Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

// Roboto Regular
@font-face {
    font-family: $roboto-condensed-regular;
    src: url('#{$url-font}RobotoCondensed-Regular.woff2') format('woff2'),
         url('#{$url-font}RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Roboto Condensed Light
@font-face {
    font-family: $roboto-condensed-light;
    src: url('#{$url-font}robotocondensed-light.woff2') format('woff2'),
         url('#{$url-font}robotocondensed-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Advent Pro extras
@font-face {
    font-family: $advent-pro-light;
    src: url('#{$url-font}AdventPro-Light.woff2') format('woff2'),
         url('#{$url-font}AdventPro-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $advent-pro-extra-light;
    src: url('#{$url-font}AdventPro-ExtraLight.woff2') format('woff2'),
         url('#{$url-font}AdventPro-ExtraLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $advent-pro-bold;
    src: url('#{$url-font}AdventPro-Bold.woff2') format('woff2'),
         url('#{$url-font}AdventPro-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $advent-pro-semi-bold;
    src: url('#{$url-font}AdventPro-SemiBold.woff2') format('woff2'),
         url('#{$url-font}AdventPro-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $advent-pro-thin;
    src: url('#{$url-font}AdventPro-Thin.woff2') format('woff2'),
         url('#{$url-font}AdventPro-Thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

//v2
@font-face {
    font-family: $orchide;
    src: url('#{$url-font}Orchide.ttf') format('ttf'),
        url('#{$url-font}Orchide.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $roboto-regular;
    src: url('#{$url-font}Roboto-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $roboto-bold;
    src: url('#{$url-font}Roboto-Bold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $roboto-light;
    src: url('#{$url-font}Roboto-Light.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $roboto-condensed;
    src: url('#{$url-font}RobotoCondensed-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $homemadeApple;
    src: url('#{$url-font}HomemadeApple-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}