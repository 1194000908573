//------------------------------------
// #PUSHES
//------------------------------------

// Slider push
.push-slider {
  background: #ffffff;
}

// Push discover
.feature-push-discover {

  .tt-block {
    color: #12415f;
    text-align: center;

    &:after {
      background: transparent;
    }

    .span1{
    font-family: $roboto;
    color: #69B5C2;
    font-size: 30px;
    text-transform: initial;
    font-weight: bold;
    }
    .span2{
      font-family: $homemadeApple;
      font-weight: 40;
      color: #182883;
      font-size: 25px;
      text-transform: lowercase;
    }
  }
}



// Booking push
.feature-push-booking {
  padding: rem( 10 ) 0 0;

  &.big {
    padding: rem( 30 ) 0 rem( 20 );
  }
}
.push-booking {
  color: $white;
  margin: 0 0 rem( 10 );
  text-align: left;

  [class*="tt-header"] {
    display: inline-block;
    margin-bottom: rem( 12 );

    span:first-child {
      background: $theme;
    }
  }

  .inner {
    background: no-repeat 50% 50%;
    background-size: cover;
    display: block;
    font-size: 0;
    letter-spacing: 0;

    &:before {
      @include valign;
      height: rem( 160 );
      vertical-align: middle;
    }
  }

  .text {
    box-sizing: border-box;
    display: inline-block;
    font-size: rem( $fz );
    letter-spacing: normal;
    padding: rem( 12 ) pct( 10/300 ) 0;
    vertical-align: middle;
    text-align: center;
    width: 100%;
  }

  .icon-flight,
  .icon-car,
  .icon-accommodation {
    display: block;
    fill: $white;
    margin: 0 auto;
  }

  .icon-flight {
    height: rem( 60 );
    width: rem( 80 );
  }

  .icon-accommodation {
    height: rem( 60 );
    width: rem( 65 );
  }

  .icon-car {
    height: rem( 20 );
    width: rem( 55 );
  }
}

.tt-html{
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 2.1875rem;
  color: #2bb7da;
}

.tt-dark-html{
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #69b5c2;
    font-size: 30px;
    text-transform: none;
    font-weight: bold;
    text-align: left;
}

.push-tiles{

  .wrap{

    .tt-block.left{
      text-align: left;
    }
  }

}