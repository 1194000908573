/* //------------------------------------
// #Sections
//------------------------------------

// New Island Page
.new-island-main {
	[class*="header-main"] {
		height: auto;
		.wrap {
			height: 100%;
		}
		.text {
			top: calc(5 * #{$baseline});
			bottom: $baseline;
			width: 640px;
			font-size: rem( 20 );
			.desc {
				max-width: 100%;
			}
		}
		.bg-wrapper {
			height: rem( 395 );
		}
	}

	.ct-header {
		span {
			font-size: rem( 80 );
		}
	}
}

.new-island-main {
	.block-island-slider {

		figcaption {
			padding: calc(0.5*#{$baseline}) rem( 5 );
			max-width: 300px;
			font-size: rem( 24 );
		}

		.slick-arrow {
			[class*="icon-"] {
				height: rem( 50 );
				width: rem( 40 );
			}
		}
	}
}

.block-island-map {
	height: 500px;
	.bg-wrapper {
		background-image: initial;
		background-position-x: 60%;
		height: 500px;
	}
	.text {
		width: 50%;
		padding: calc(3 * #{$baseline}) calc(#{$baseline}) calc(4 * #{$baseline});
		h2 {
			font-size: calc(2 * #{$baseline});
		}
	}
	.wrap {
		@include center(both);
	}
}

.block-island-encyclopedia {
	height: 500px;
	.bg-wrapper {
		background-position-x: 60%;
		height: 500px;
	}
	.text {
		width: 50%;
		padding: calc(3 * #{$baseline}) calc(#{$baseline}) calc(4 * #{$baseline});
		h2 {
			text-align: left;
		}
	}
	.wrap {
		@include center(both);
	}
}

.block-island-events {
	.block-agenda {
		padding: calc(4 * #{$baseline}) calc(12 * #{$baseline});
	}
}
 */
 .inspiration-section {
  
	.wrap{
	  background-image: url(../../B2C/img/Traits-inspiration.png);
	  background-position-y: -170px;
	}
}
.inspiration-section > div > div.container > div.third-block > div.block-inspiration-activity {
	float: left;
	margin-right: 90px;
}
#main > main > section.inspiration-section > div > div.container > div.third-block > div.block-inspiration-offer {
		float: left;
		margin-top: 45px;		
}