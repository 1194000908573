/*header mobile + tab */


header[role="banner"] {
    .banner{
        background-color: #182883;
        .wrap{
            width: 100%;
            button{
                padding: 0;
                padding-left: 20px;
            }
        }
    }
    .wrap {
        position: initial;
    }
    img, picture {
        width: 23px;
    color: $white;
    vertical-align: bottom;
    width: 22px;
    }
    .bt-nav[aria-expanded="true"]{
        background: transparent;
    }
    .home-nav
    {
    color: white;
    margin-right: 0px;
    margin-left: 0px;
    > ul
        > li
        {
            display: flex;
            float: left;
            p{
                margin-bottom: 0px;
                padding: 1px;
                padding-top: 0px;

                span{
                    vertical-align: top;
                    font-size: 13px;
                }
            }
            .pro{
                margin-left: 10px;
                padding-top: 2px;
                picture {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .account {
                picture {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .basket {
                picture {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .select{
                background: transparent;
                color: #69b5c2;
                border: none;
                padding-top: 3px;
                select{
                    color: #69b5c2;
                }
            }
        }
    }

    #nav {

        .categories-nav{
            background: $white;
            .guadeloupedia{
                a {
                    padding-left: 0;
                }
            }
            .lang,.static-icon,.account,.basket {
                display: none;
            }
            #logo{
                padding-top: 10px;
            }
        }
    }
    .menu {
        display: none;
    }

    //Nav Fouzia

    .header-mobile {
        display: block;
/*      position: fixed;
        top: 0;
        width: 100%; */


        .wrap::after {
            clear: inherit;
        }

        .hide-menu {
            //position: fixed;
            width: 100%;
            height: 100%;
        }

        .wrap {
            width: 100%;

        .home-nav {
            width: 100%;
            height: 60px;
            padding-left: initial;
            line-height: normal;

            .home-nav-mobile {
                display: flex;
                justify-content: space-around;
                top: 0;
                width: 100%;
                background-color: #182883;
                z-index: 25;
                position: absolute;
                height: inherit;
                &.sticky {
                    position: fixed;
                }

                li {
                    list-style: none;
                }

                .cross_nav {
                    width: 20px;
                }
            }

            .bt-nav {
                position: initial;
                left: initial;
                background-color: #182883;
            }

        }
    }
    img {
        width: 30px;
        max-width: initial;
    }



      .bottom-menu {
        background-color: #182883;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0px;

        .bottom {
          padding-left: 0;
          margin-bottom: inherit;
          margin-top: revert;
        }

        .static-icon {
          display: flex;
          color: white;
        }

        span {
            vertical-align: top;
        }

      }
      .middle-menu {
        height: 100vh;
        background-color: ghostwhite;
        width: 100%;
        //position: absolute;
        overflow: auto;

        li {
            list-style: none;
          }
        .first-sub-nav {
            padding-left: 25px;
            padding-right: 25px;
            background-color: white;
            margin-bottom: unset;
            padding-bottom: -8px;
          a, span {
            font-family: 'Roboto Condensed Regular';
            font-size: 15px;
            text-transform: uppercase;
            color: #69b5c2;
            font-weight: 700;
            line-height: 40px;

          }
          #searchbar {
              //margin-top: 65px;
              margin-bottom: 15px;
              padding-top: 20px;
            .search {
                border-radius: 50px;
                background: #ffffff;
                box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.14);
                input {
                    border: none;
                    height: 45x;
                    padding-left: 15px;
                    opacity: 0.7;
                    margin : 10px 0 10px 0;
                    width: 83%;
                }
                button[type="submit"]{
                    border: none;
                    background-color: white;
                    vertical-align: middle;
                    opacity: 0.7;
                }

                .bi {
                    font-size: 25px;
                }

              }
          }



            li {
                .bi {
                    font-size: 20px;
                }
            }

        }


        .dechirure-top {
            width: 100%;
            //position: absolute;
            z-index: 1;
            margin-top: -3px;
        }

        .second-sub-nav {
            background-color: ghostwhite;
            position: relative;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 25px;
            margin-bottom: unset;

            button:focus {outline:0;}

            li {
                span {
                font-family: 'Roboto Condensed Regular';
                font-size: 15px;
                text-transform: uppercase;
                color: #182883;
                font-weight: 700;
                line-height: 40px;
                }
            }

            .category-details-mobile {
                span, a {
                    font-family: 'Roboto Condensed Regular';
                    font-size: 15px;
                    text-transform: capitalize;
                    color: #182883;
                    font-weight: 500;
                    line-height: 40px;
                    white-space: nowrap;

                    .bi {
                        font-size: 20px;
                    }
                }

                .sub-nav {
                    display: flex;
                    position: unset;
                    width: unset;
                    height: unset;
                    overflow-x: auto;

                    li {
                        padding-right: 20px;

                        a.last-link {
                            border-right: none;
                        }
                    }

                    a {
                        padding-right: 20px;
                        animation: unset;
                        display: inline;
                        border-right:1px solid #69b5c2;


                    }

                }
            }
        }
        .bi {
            float: right;
        }
        svg {
            float: right;
            margin-top: 13px;
        }

        .choix-langue {
            input[type="radio"].langue {
                display: none;
              }
              input[type="radio"].langue + label {
                position: relative;
                padding-left: 1.7rem;
                font-weight: 600;
                font-family: 'Roboto Condensed Regular';

              }
              input[type="radio"].langue + label::before,
              input[type="radio"].langue + label::after {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                content:'';
                width: 1rem;
                height: 1rem;
                border-style: solid;
                border-width: 1px;
                border-color: #dadfdf;
                line-height: 30px;
                margin-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
              }

              input[type="radio"].langue:checked + label::after {
                background-color:#69B5C2;
                box-shadow: inset 0 0 0 2px #fff;
              }
        }

      }
    }
}
