//------------------------------------
// GLOBAL STYLSHEETS
//------------------------------------

$theme: 'b2c';

// theme variables
@import "theme";

// Common stylesheets
@import "../../../Common/styles/index";

// Base
@import "base/index";


// Layout
@import "layout/index";


// Components
@import "components/index";


// Pages
@import "pages/index";


// Mediaqueries
// w320
@media only screen and ( min-width: 20rem ) and ( max-width: 49rem ) {
  @import "mediaqueries/w320/index";
}
// w480
@media only screen and ( min-width: 30rem ) {
  @import "mediaqueries/w480/index";
}
// w640
@media only screen and ( min-width: 40rem ) {
  @import "mediaqueries/w640/index";
}
// w768
@media only screen and ( min-width: 48rem ) {
  @import "mediaqueries/w768/index";
}
// w1000
@media only screen and ( min-width: 62.5rem ) {
  @import "mediaqueries/w1000/index";
}
