//------------------------------------
// #BUTTONS
//------------------------------------

button,
a {
  outline-width: 0;
}

// Link
.link {
  color: $theme3;

  &:hover {
    color: $theme;
  }
}

.link-revert {
  color: $theme;

  &:hover {
    color: $theme3;
  }
}

.link-light {
  color: $white;

  &:hover {
    color: $white;
  }
}

.link-icon {
  color: $theme;
  display: inline-block;
  font-weight: bold;
  padding: 0 0 0 rem( 25 );
  position: relative;
  text-transform: uppercase;

  [class*="icon"]{
    fill: $theme;
    height: rem( 20 );
    left: 0;
    margin: rem( -10 ) 0 0;
    position: absolute;
    top: 50%;
    width: rem( 20 );
  }
}

// CTA
.cta {
  &.activity,
  &.accommodation,
  &.koudke,
  &.restaurant {
    color: $theme-secondary;

    &:hover,
    a:hover & {
      background: $white;
    }
  }

  &.activity {
    background: $activity;
    border-color: $activity;

    &.cta-toggle {
      border-color: $white;
    }

    &:hover,
    a:hover & {
      color: $activity;
    }
  }

  &.accommodation {
    background: $accommodation;
    border-color: $accommodation;

    &.cta-toggle {
      border-color: $white;
    }

    &:hover,
    a:hover & {
      color: $accommodation;
    }
  }

  &.koudke {
    background: $koudke;
    border-color: $koudke;

    &:hover,
    a:hover & {
      color: $koudke;
    }
  }

  &.restaurant {
    background: $restaurant;
    border-color: $restaurant;

    &:hover,
    a:hover & {
      color: $restaurant;
    }
  }

  &.interactive {
    background: $interactive;
    border-color: $interactive;
    color: $white;

    &:hover,
    a:hover & {
      background: $white;
      border-color: $interactive;
      color: $interactive;
    }
  }
}


// CTA labels
.cta-label {
  span {
    background: $theme2;
  }
}

.cta-push {
  background: $theme-light;
  border-color: $theme-light;
  color: $white;

  &:hover,
  a:hover & {
    background: $white;
    border-color: $theme-light;
    color: $theme-dark;
  }
}


.cta-submit {
  @extend .cta;
  background: $theme-light;
  border-color: $theme-light;
  color: $theme-secondary;

  &:hover {
    background: $white;
    border-color: $theme-light;
    color: $theme-light;
  }
}
