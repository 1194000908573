//------------------------------------
// #GIMMICKS
//------------------------------------


// Tags
.tag-list {

  a,
  button {
    min-width: rem( 100 );
  }
}


// Option pict
.option-pict {

  .badge {
    border-radius: rem( 33 );
    height: rem( 66 );
    margin-bottom:rem( 5 );
    width: rem( 66 );

    img {
      width: rem( 40 );
    }

    [class*="icon"]{
      height: rem( 26 );
      margin: rem( -13 ) 0 0;
    }
  }

  .txt {
    font-size: rem( 12 );
  }

}
