.discount{
    #toast-offer{
        height: auto; 
        position: fixed; 
        bottom: 5%; 
        right: 5%; 
        width: 300px; 
        opacity: 1; 
        z-index: 1000;
        background-color: white;
        border-color: #ffdd00;
        border-width: 0.25rem;
    }
    .promoIcon{
        border-radius: 3.5px;
        background: #ffdd00;
        font-size: 15px;
        font-weight: 700;
        line-height: 12px;
        padding: 12px;
        float: right;
    }
    .toast-header{
        padding: 0.25rem 1rem;
        .promo{
            border-radius: 3.5px;
            background: #ffdd00;
            font-size: 15px;
            font-weight: 700;
            line-height: 12px;
            padding: 7px;
            float: right;
        }
    }
    .toast-body{
        padding: 0rem 1rem 1rem 1rem;
        .divImageDiscount {
            height: 80px;
            padding-bottom: 0.5rem;
            .image{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            .on-site{
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                padding: 4px 4px 4px 4px;
                border: 1px solid #979797;
                border-radius: 3.5px;
                background-color: white;
                bottom: 10px;
                right: 10px;
                position: relative;
                white-space: nowrap;
                float: right;
            }
        }
        .text-block{
            .description{
                margin-top: 1rem;
                .pTitles{
                    margin-bottom: 0;
                    .title{
                        font-size: 17px;
                        font-weight: 700;
                        line-height: 18px;
                        color: #000;
                    }

                    .city{
                        font-family: "Roboto";
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 16px;
                        color: #000;
                    }

                    .category{
                        font-family: "Roboto";
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 16px;
                        color: #000;
                    }
                }
            }
            .price-block{
                .label-discount{
                    .promo{
                        border-radius: 3.5px;
                        background: #ffdd00;
                        font-size: 10px;
                        font-weight: 700;
                        line-height: 12px;
                        padding: 4px;
                        float: right;
                    }
                    .price_number{
                        color: #182883;
                        font-size: 22px;
                        font-weight: bold;
                        float: right;
                        padding-right: 5px;
                    }
                    .price_label{
                        color: #182883;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        float: right;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}
.discount-Mobile{
    display: none;
    #toast-offer-mobile{
        height: auto; 
        position: fixed; 
        bottom: 1%; 
        width: 100%; 
        opacity: 1; 
        z-index: 99;
        background-color: white;
        border-color: #ffdd00;
        border-width: 0.25rem;
        .title{
            font-size: 17px;
            font-weight: 700;
            line-height: 18px;
            color: #000;
        }
    }
    .promoIcon{
        border-radius: 3.5px;
        background: #ffdd00;
        font-size: 15px;
        font-weight: 700;
        line-height: 12px;
        padding: 12px;
        float: left;
        margin-right: 1rem;
    }
}