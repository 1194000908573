//------------------------------------
// #GIMMICKS
//------------------------------------


// Date sticker
.date-sticker {
  -webkit-font-kerning: none;
  background: $theme-secondary2;
  border: 2px solid $white;
  border-radius: 100%;
  color: $white;
  display: inline-block;
  font-family: $font-title;
  font-size: rem( 20 );
  height: rem( 75 );
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  width: rem( 75 );
  white-space: nowrap;

  &:before {
    @include valign;
  }
  span {
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
  }
}

// Number sticker
.num-sticker {
  background: $grey-light2;
  border: 2px solid $white;
  box-shadow: 3px 3px 2px rgba( $black, 0.13 );
  box-sizing: border-box;
  color: $purple;
  display: block;
  font-size: rem( 16 );
  font-weight: bold;
  margin: 0;
  padding: rem( 7 ) rem( 2 ) rem( 6 );
  text-align: center;
  width: rem( 60 );
}

// Date Time
[class*="date-time"] {
  background: $white;
  box-shadow: 3px 3px 2px rgba( $black, 0.13 );
  box-sizing: border-box;
  display: block;
  font-size: rem( 12 );
  text-align: center;
  padding: rem( 3 );
  width: rem( 60 );

  span {
    display: block;
  }

  .day {
    background: $grey-light;
    color: $purple;
    font-size: rem( 25 );
    margin: 0 0 rem( 2 ) 0;
    padding: rem( 1 ) 0;
  }

  .month {
    background: $purple;
    color: $white;
    font-weight: 500;
    padding: rem( 2 ) 0;
    text-transform: uppercase;
  }
}

.date-time-big {
  font-size: rem( 18 );
  width: rem( 86 );

  .day {
    font-size: rem( 36 );
    margin-bottom: rem( 3 );
  }
}

// Hour
.time-hour {
  background: $theme-secondary2;
  border-radius: 9px;
  box-sizing: border-box;
  color: $white;
  font-size: rem( 16 );
  height: rem( 60 );
  padding: rem( 20 ) 0 0;
  text-align: center;
  width: rem( 60 );
}


// Discount label
/* jjK 1.2.1.0 */
.discount-label {

  > span {
    background: $red;
    box-sizing: border-box;
    color: $white;
    display: inline-block;
    font-family: $font-roboto-condensed-regular;
    margin: 0 rem( -8 ) 0 0;
    min-width: rem( 144 );
    padding: rem( 5 ) rem( 16 );
    position: relative;
    text-align: right;

    strong {
      font-size: rem( 18 );
      font-weight: normal;
    }
  }

  &.revert,
  .revert & {
    > span {
      margin: 0 0 0 rem( -8 );
      text-align: left;

      &:after {
        @include triangle( 8px, $black, up-right );
        left: 0;
        right: auto;
      }

      &:before {
        background-position: 0 rem( -13 );
        left: rem( 8 );
        right: auto;
        text-align: right;
      }
    }
  }

  &.small,
  &.xsmall {
    > span {
      min-width: rem( 90 );

      &:before {
        background-size: rem( 100 ) auto;
        bottom: rem( -9 );
        height: rem( 9 );
        right: rem( 8 );
        width: rem( 100 );
      }
    }

    &.revert,
    .revert & {
      > span {

        &:before {
          background-position: 0 rem( -10 );
        }
      }
    }
  }
  &.xsmall {
    > span {
      padding: rem( 2 ) rem( 10 );
    }

    &.revert,
    .revert & {
      > span {

        &:before {
          background-position: 0 rem( -10 );
        }
      }
    }
  }
}

// Sticker label
.sticker-label {

  span {
    background: $theme2;
    box-sizing: border-box;
    color: $white;
    display: inline-block;
    font-size: rem( 16 );
    font-weight: 500;
    margin: 0 rem( -8 ) 0 0;
    min-width: rem( 115 );
    padding: rem( 6 ) rem( 16 );
    position: relative;
    text-align: center;
    // text-align: right;
    // text-transform: uppercase;

    strong {
      font-size: rem( 22 );
      font-weight: normal;
    }

    &:after {
      content: "";
      @include triangle( 8px, $black, up-left );

      bottom: rem( -8 );
      position: absolute;
      right: 0;
    }

    &:before {
      background: url( "#{$url-img}bg-label-shadow.png" ) no-repeat 0 0;
      background-size: rem( 110 ) auto;
      bottom: rem( -10 );
      content: "";
      height: rem( 10 );
      position: absolute;
      right: rem( 8 );
      text-align: left;
      width: rem( 110 );
    }
  }

  &.revert,
  .revert & {
    span {
      margin: 0 0 0 rem( -8 );
      // text-align: left;

      &:after {
        @include triangle( 8px, $black, up-right );
        left: 0;
        right: auto;
      }

      &:before {
        background-position: 0 rem( -13 );
        left: rem( 8 );
        right: auto;
        text-align: right;
      }
    }
  }
}

// Info Label
.info-label {
  span {
    background: $theme3;
    box-sizing: border-box;
    color: $white;
    display: inline-block;
    font-family: $font-roboto-condensed-regular;
    font-size: rem( 16 );
    font-weight: 500;
    margin: 0 rem( -5 ) 0 0;
    min-width: rem( 100 );
    padding: rem( 8 ) rem( 20 );
    position: relative;
    text-align: right;

    strong {
      font-size: rem( 22 );
      font-weight: normal;
    }

    &:after {
      content: "";
      @include triangle( 5px, $black, up-left );

      bottom: rem( -5 );
      position: absolute;
      right: 0;
    }

    &:before {
      background: url( "#{$url-img}bg-label-shadow.png" ) no-repeat 0 0;
      background-size: rem( 84 ) auto;
      bottom: rem( -8 );
      content: "";
      height: rem( 8 );
      position: absolute;
      right: rem( 5 );
      text-align: left;
      width: rem( 84 );
    }
  }

  &.revert,
  .revert & {
    span {
      margin: 0 0 0 rem( -5 );
      text-align: left;

      &:after {
        @include triangle( 5px, $black, up-right );
        left: 0;
        right: auto;
      }

      &:before {
        background-position: 0 rem( -9 );
        left: rem( 5 );
        right: auto;
        text-align: right;
      }
    }
  }
}

// Rating
.rating {
  margin: 0 0 rem( 10 );

  > p {
    display: inline-block;
    font-size: rem( 10 );
    margin: 0;
    padding: 0 rem( 10 ) 0 0;
    opacity: 0.5;
    vertical-align: middle;
  }
  .grade {
    background: url( "#{$url-img}stars.svg" ) 0 0;
    display: inline-block;
    height: rem( 13 );
    position: relative;
    vertical-align: middle;
    width: rem( 80 );

    .stars {
      background: url( "#{$url-img}stars.svg" ) 0 100%;
      display: block;
      height: 100%;
      width: 0;
    }
  }

  &.light {
    .grade,
    .grade .stars {
      background-image: url( "#{$url-img}stars-light.svg" );
    }
  }

  &.big {
    .grade {
      background-size: auto rem( 46 );
      height: rem( 23 );
      width: rem( 140 );

      .stars {
        background-size: auto rem( 46 );
      }
    }
  }
}


// Tags
.tag-list {
  list-style-type: none;
  margin: 0 0 rem( 14 );

  li {
    display: inline-block;
    padding: 0 rem( 6 ) rem( 6 ) 0;
    vertical-align: middle;
    width: auto;
  }

  a,
  button {
    background: $grey-light3;
    border: 0 none;
    border-radius: rem( 8 );
    box-sizing: border-box;
    color: $theme;
    display: block;
    font-weight: bold;
    padding: rem( 6 ) rem( 10 );
    // min-width: rem( 100 );
    min-width: rem( 75 );
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    .no-flexbox & {
      min-width: 0;
    }

    .grey & {
      background: $white;
    }

    &.on,
    &:hover {
      background: $theme-secondary-grey;
      color: $white;
    }
  }
}


// Sate sticker
.state-sticker {
  padding: rem( 5 ) 0 0;
  position: relative;
  margin: 0 0 rem( 5 );

  span {
    background: $theme-secondary-grey;
    color: $white;
    display: inline-block;
    padding: rem( 4 ) rem( 12 );
    position: relative;


    &:before {
      content: "";
      @include triangle( 10px, $theme-secondary-grey, up );
      left: 50%;
      margin: 0 0 0 rem( -5 );
      top: rem( -5 );
      position: absolute;
    }
  }

  &.online {
    span {
      background: $state-online;

      &:before {
        @include triangle( 10px, $state-online, up );
      }
    }
  }
  &.pending {
    span {
      background: $state-pending;

      &:before {
        @include triangle( 10px, $state-pending, up );
      }
    }
  }
  &.denied {
    span {
      background: $state-denied;

      &:before {
        @include triangle( 10px, $state-denied, up );
      }
    }
  }
  &.draft {
    span {
      background: $state-draft;

      &:before {
        @include triangle( 10px, $state-draft, up );
      }
    }
  }
}


// Option pict
.option-pict {
  text-align: center;

  .badge {
    background: $theme-secondary-grey;
    border-radius: rem( 22 );
    display: block;
    height: rem( 44 );
    margin: 0 auto rem( 2 );
    position: relative;
    width: rem( 44 );

    img {
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate( -50%, -50% );
      width: rem( 30 );
    }

    [class*="icon"]{
      fill: $white;
      height: rem( 18 );
      left: 0;
      margin: rem( -9 ) 0 0;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }

  .txt {
    font-size: rem( 10 );
    font-weight: 500;
    text-transform: uppercase;
  }

}
