//------------------------------------
// #LAYOUT #FOOTER
//------------------------------------


// Main footer
footer[role="contentinfo"] {
  padding-top: rem( 30 );

  .col {

    &:nth-child( 1 ),
    &:nth-child( 2 ) {
      border-top: 0 none;
      float: right;
      width: 50%;
     

      .block {
        border: 0;
        padding-top: 0;
      }
    }

    &:nth-child( 3 ) {
      clear: both;
    }
  }

  .contact {
    padding: 0;
    text-align: left;

    p {
      display: block;
      margin: 0;
    }
  }
  .links {
    display: block;

    [class*="tt-raw"] {
      margin-top: rem( 20 );

      ~[class*="tt-raw"] {
        margin-top: rem( 30 );
      }
    }
    
  }
}
