//------------------------------------
// #Sections
//------------------------------------

// Home top section
.home-section  {
  // margin: 0 0 rem( 20 );

  .aside {
    height: 0;
    margin: 0 auto;
    max-width: rem( 980 );
    position: relative;
    width: pct( 300/320 );

    .inner-wrap {
      bottom: 0;
      position: absolute;
      right: 0;
      width: rem( 320 );
    }
  }

  + .main-section {

    .aside {
      width: rem( 300 );

      .inner-wrap {
        margin-top: rem( -390 );
      }
    }
  }
}

// Home hero
.home-hero {
  padding: 0;

  .bg-wrapper {
    height: rem( 480 );
  }

  .wrap {
    height: 100%;
  }

  .hero-header {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  [data-fade] {
    height: rem( 70 );
    margin: 0 0 rem( 30 );
    position: relative;
  }

  .title {
    display: block;
    height: rem( 70 );
    margin: 0;
    padding: 0 0 0 rem( 70 );
    position: absolute;

    + .title {
      display: none;
    }

    .header-icon {
      height: rem( 70 );
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY( -50% );
      width: rem( 65 );

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY( -50% );
      }

      img {
        width: 100%;
      }
    }
  }
  [class*="tt-header"] {
    font-size: rem( 20 );
    line-height: ( 22/20 );
    margin: 0;

    span {
      display: inline-block;
      float: none;
    }

    span,
    span:first-child ~ span {
      border-width: 1px;
      padding: rem( 5 ) rem( 10 );
    }
  }

  .push {
    .inner {
      padding-right: rem( 340 )
    }
    .tt-block {
      @include baseline;
    }

    .button {
      text-align: left;
    }
  }
}

// Block push
.home-push {
  margin: 0 0 rem( 20 );

  .inner {
    padding-right: rem( 340 )
  }
  .button {
    text-align: left;
  }
}


// Content Header
[class*="header-main"] {

  .map-labels {
    width: 100%;

    li {

      // Grande-terre
      &:nth-child(1){
        bottom: pct( 315/390 );
        left: pct( 470/980 );
      }

      // Basse-terre
      &:nth-child(2){
        left: pct( 390/980 );
        top: pct( 210/390 );
      }
      // Les saintes
      &:nth-child(3){
        left: pct( 425/980 );
        top: pct( 345/390 );
      }
      // La désirade
      &:nth-child(4){
        left: pct( 635/980 );
        top: pct( 80/390 );
      }
      // Marie Galante
      &:nth-child(5){
        top: pct( 285/390 );
        left: pct( 560/980 );
      }
    }
  }

  &.center {
    .text {
      left: 50%;
    }
  }
  &.small {
    .bg-wrapper {
      height: rem( 195 );
    }
  }
}

// Header video
.header-main-video {
  .bg-wrapper {
    height: rem( 380 );
  }

  .youtube-cover {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    display: block;
    left: 0;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.8s ease-out;
    width: 100%;
    z-index: 2;
  }

  &.playing {
    .youtube-cover {
      opacity: 0;
    }
  }
}


// Main section
.main-section {
  .container {
    display: table;
    width: 100%;
  }
  .content,
  .aside {
    display: table-cell;
    vertical-align: top;
  }

  .content {
    padding-right: rem( 10 );
  }

  .aside {
    width: rem( 300 );

    .inner-wrap {
      margin-top: rem( -110 );
    }

    .inner,
    .inner-alt {
      padding: rem( 20 ) rem( 20 ) rem( 6 );
    }
  }
  .wrap .aside {
    margin-left: 0;
    margin-right: 0;
  }

  // breakpoint alternative
  &.alt-section {
    .content,
    .aside {
      display: block;
    }

    .content {
      padding-right: 0;
    }

    .aside {
      margin-left: pct( -10/300 );
      margin-right: pct( -10/300 );
      width: pct( 320/300 );
      // margin-left: pct( -10/300 );
      // width: calc( 100% + #{pct( 20/300 )} );

      .inner-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        width: 100%;

        .no-flexbox & {
          font-size: 0;
          letter-spacing: 0;
        }
      }
      .inner,
      .inner-alt {
        box-sizing: content-box;
        padding-left: pct( 10 / 300 );
        padding-right: pct( 10 / 300 );

        .no-flexbox & {
          display: inline-block;
          font-size: rem( $fz );
          letter-spacing: normal;
          vertical-align: top;
        }
      }
      .inner{
        // flex-shrink: 1;
        // flex-grow: 1;
        flex-basis: pct( 130 / 300 );
        width: pct( 130 / 300 );

        &.full {
          flex-basis: pct( 280 / 300 );
          width: pct( 280 / 300 );
        }
      }
    }
    [class*="block-support"] {
      @include clearfix;

      .contact {
        float: left;
        width: 50%;

        + .contact {
          margin-top: 0;
        }

        &:nth-of-type( 2n + 1 ) {
          clear: left;
        }
      }

    }
  }
}



// Push section
.tour-section {

  ul {
    margin-left: auto;
    margin-right: auto;
    max-width: rem( 660 );

    li + li {

      &:before {
        left: rem( 78 );
      }
    }
  }
}


// Contributions
.contrib-block {
  time {
    background: no-repeat 50% 50%;
    background-size: cover;
    box-sizing: border-box;
    color: $white;
    float: left;
    font-size: rem( 15 );
    height: rem( 100 );
    line-height: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    width: rem( 140 );

    img {
      display: block;
      // height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    span {
      background: rgba( #233c44, 0.4 );
      box-sizing: border-box;
      display: block;
      height: 100%;
      padding: rem( 28 ) 0 0;
      position: relative;
      width: 100%;
      z-index: 2;
    }

    b {
      font-size: rem( 25 );
      display: block;
    }
  }
  .text {
    padding: rem( 20 ) rem( 20 ) rem( 10 ) rem( 160 );
  }

  .actions {
    clear: both;
  }
}
