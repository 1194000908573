//------------------------------------
// #PAGINATE
//------------------------------------

// Pagination
.paginate {
  text-align: center;

  ul {
    display: inline-block;

    list-style: none;
    padding: 0;
    margin: 0 0 0.5rem 0;

    background-color: white;
    box-shadow: 0 .125rem .1875rem rgba(0,0,0,.1);
    border-radius: 4px;
  }

  li {
    display: inline-block;
    padding: 0.5rem 0;
    a {
      display: inline-block;
      width: 3rem;
      line-height: 1rem;
      margin: 0;
      border-right: 1px solid lightgrey;
      text-decoration: none;
      &:hover { text-decoration: underline }
    }

    &:last-child a { border: 0 }

    &.on a { font-weight: bold; color: $theme; }
  }
}
