//------------------------------------
// #BREADCRUMB
//------------------------------------

.breadcrumb {
  display: block;
  margin-top: rem( 50 );

  .header-main.small & {
    bottom: rem( -57 );
    left: 0;
    position: absolute;
    width: rem( 640 );
  }
}
