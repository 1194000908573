//------------------------------------
// #BREADCRUMB
//------------------------------------

.breadcrumb {
  @include clearfix;
  display: none;
  list-style-type: none;
  margin-bottom: 0;

  li {
    float: left;
    margin-right: rem( 1 );

    &:first-child {
      height: rem( 29 );
      width: rem( 48 );

      a,
      a span {
        height: 100%;
        padding: 0;
        width: 100%;

        &:before {
          display: none;
        }
      }

      [property="name"] {
        @include hide;
      }
    }
  }

  .icon-home {
    fill: $white;
    height: rem( 15 );
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate( -50%, -50% );
    width: rem( 19 );
  }

  span {
    color: $white;
    display: inline-block;
    padding: rem( 7 ) rem( 15 ) rem( 5 ) rem( 21 );
    position: relative;

    &:before {
      content: ">";
      position: absolute;
      transform: translateX( rem( -10 ) );
    }
  }

  a {
    display: inline-block;
    text-decoration: none;

    span {
      background-color: rgba( #001E26, .7 );
      transition: background-color $transition;
    }

    &:hover,
    &:focus {
      span {
        background-color: #001E26;
      }
    }
  }

  span {
    background-color: #001E26;
  }

}
