//------------------------------------
// #FORMS
//------------------------------------


// Form
.form {

  // Checkbox
  .checkbox {
    &.big {
      label {
        font-size: rem( 22 );
        padding: 0 rem( 45 ) 0 0;

        &:before {
          border-radius: rem( 5 );
          height: rem( 26 );
          left: auto;
          right: 0;
          width: rem( 26 );
        }

        [class*="icon-check"] {
          height: rem( 14 );
          left: auto;
          right: rem( 6 );
          top: rem( 6 );
          width: rem( 14 );
        }
      }
    }
  }
}
