//------------------------------------
//------------------------------------
// TABLE OF CONTENTS :
//
// LAYOUT
// URLs
// SPACING
// MEDIAQUERIES
// TYPOGRAPHY
// TRANSITIONS
//
//------------------------------------
//------------------------------------

$fb-blue: #3c5b9b;

$contrib: #6bd8da;
$contrib-action: #56aec7;
$contrib-disabled: #60c3d0;

$state-online: #2d464d;
$state-pending: #3467a9;
$state-denied: #e01e4b;
$state-draft: #b7c0c2;



//------------------------------------
// #LAYOUT
//------------------------------------

// Content width
$page-width: rem( 980 );
$max-width: rem( 1900 );




//------------------------------------
// #URLs
//------------------------------------

$url-font : "../fonts/";
$url-img  : "../img/";




// ------------------------------------
// #SPACING
// ------------------------------------

// Baseline Grid
// Based of the Single Direction Margin :
// http://csswizardry.com/2012/06/single-direction-margin-declarations/
$baseline: rem( 16 );




//------------------------------------
// #MEDIAQUERIES
//------------------------------------

$breakpoints: (
	large:     $page-width,
  medium:    rem( 850 ),
	small:     rem( 500 )
);



//------------------------------------
// #TYPOGRAPHY
//------------------------------------

$font-sans-serif        : "Helvetica Neue", Helvetica, Arial, sans-serif;

// Roboto
$roboto                 : "Roboto";
$font-roboto            : $roboto, $font-sans-serif;

// Roboto Condensed Regular
$roboto-condensed-regular       : "Roboto Condensed Regular";
$font-roboto-condensed-regular  : $roboto-condensed-regular, $font-sans-serif;

// Roboto Condensed Light
$roboto-condensed-light       : "Roboto Condensed Light";
$font-roboto-condensed-light  : $roboto-condensed-light, $font-sans-serif;

// Advent Pro
$advent-pro             : "Advent Pro";
$font-advent-pro        : $advent-pro, $font-sans-serif;
$font-title             : "Roboto Condensed Regular";

// Advent Pro extras
$advent-pro-bold             : "Advent Pro Bold";
$advent-pro-extra-light      : "Advent Pro Extra Light";
$advent-pro-light            : "Advent Pro Light";
$advent-pro-thin             : "Advent Pro Thin";
$advent-pro-semi-bold        : "Advent Pro Semi Bold";

$font-family            : $font-roboto;
$font-size              : 13;
$fz                     : $font-size;
$font-root              : pct( $font-size / 16 );
$line-height            : ( 17 / $font-size );

//JJK 1.2.1.0 V2

//orchide
$orchide                 : "Orchide";
//Homemade Apple
$homemadeApple                 : 'Homemade Apple', cursive;
//roboto
$roboto-regular                 : "Roboto-Regular";
$roboto-bold            : "Roboto-Bold";
$roboto-light            : "Roboto-Light";
$roboto-condensed           : "RobotoCondensed-Regular";
/*------------------------------------*\
    #TRANSITIONS
\*------------------------------------*/

$transition-duration:         0.25s;
$transition-delay:            0;
$transition-timing-function:  cubic-bezier( 0.42, 0, 0.58, 1 );

// Shorthand property : transition
$transition:                  $transition-duration $transition-timing-function;


///////////////////////////////////
//      Settings - Magnific      //
///////////////////////////////////

// overlay
$mfp-overlay-color:                   #0b0b0b !default;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.8 !default;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              8px !default;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       6px !default;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    1040 !default;                       // Base z-index of popup

// controls
$mfp-include-arrows:                  true !default;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65 !default;                       // Opacity of controls
$mfp-controls-color:                  #FFF !default;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F !default; 	                 // Border color of controls
$mfp-inner-close-icon-color:          #333 !default;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC !default;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF !default;                       // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              40px !default;                       // Iframe padding top
$mfp-iframe-background:               #000 !default;                       // Background color of iframes
$mfp-iframe-max-width:                900px !default;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true !default;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px !default;                       // Image padding top
$mfp-image-padding-bottom:            40px !default;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3 !default;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD !default;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers