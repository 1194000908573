//------------------------------------
// #OFFERS PAGES
//------------------------------------

#offer-search {
  overflow: hidden;
  padding: 0;
  @include clearfix;
}

// Search Form
.offer-search-form {

  // Search mobile layer
  .form-layer-container {
    position: relative;
  }
    .form-layer {
      background: transparent;
      left: auto;
      position: static;
      top: auto;
      // width: 100%;
      z-index: 1;

      &.on,
      & {

        .js & {
          [class*="form-header"] .form-details {
            display: flex;
          }
        }
        .js.no-cssgradients & {
          [class*="form-header"] .form-details {
            display: block;
          }
        }
      }
    }

  .breadcrumb {
    margin: 0 0 rem( 16 );
  }

  // Main Search form
  [class*="form-header"] {
    padding: rem( 12 ) 0 0;

    .wrap {
      padding-left: 0;
      padding-right: 0;
    }

    .toggle-bt {
      display: none;
    }

    fieldset > .line {
      box-sizing: border-box;
      float: left;
      padding-right: 1px;
      width: rem( 420 ); //rem( 320 );

      label {
        padding-right: 0;
      }
      input {
        padding-left: rem( 16 );
      }
    }

    .form-details {
      align-items: flex-start;
      display: flex;
      float: left;
      width: rem( 560 ); // rem( 660 );

      .fields {
        display: flex;
        flex-shrink: 1;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-end;

        .line {
          box-sizing: border-box;

          &,
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3){
            float: none;
            padding: 0 1px 0 0;
          }

          &:nth-child(1),
          &:nth-child(2){
            flex-grow: 1;
            width: rem( 140 );
          }
          &:nth-child(3){
            flex-basis: rem( 226 );
            flex-grow: 2;
          }
        }
        .select-block {
          padding: 0;

          + .select-block {
            padding-left: 1px;
          }
        }
      }

      .kids-form {
        flex-shrink: 1;
        flex-grow: 1;
        text-align: right;
        margin-left: rem( -70 );
        margin-right: 0;
        // width: 100%;
        min-width: 100%;

        label {
          display: inline-block;
          line-height: rnd( 20 / $fz );
          margin: rem( 13 ) 0 0;
          vertical-align: top;
        }

        .selects {
          display: inline-block;
          margin: 0 0 rem( 16 ) rem( 4 );
          max-width: rem( 400 );
          vertical-align: top;
        }

        .select-block {
          margin: 0 0 1px;
          padding-right: 1px;
          text-align: left;
          width: rem( 80 );

          + .select-block {
            padding-left: 0;
          }
        }

        .select {
          span {
            padding-left: rem( 7 );
            padding-right: rem( 20 );
          }
        }
      }

      .buttons {
        flex-grow: 0;
        flex-shrink: 1;
        margin-bottom: rem( 16 );
        padding: rem( 29 ) 0 0 0;

        [class*="cta"]{
          width: 100%;
        }
      }
    }

    // IE9
    .no-cssgradients & {
      .form-details {
        float: left;

        .line {
          &,
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3){
            clear: none;
            float: left;
          }

          &:nth-child(3){
            width: rem( 240 );
          }
        }
        .buttons {
          float: left;
          margin-top: rem( 29 );
          width: rem( 140 );
        }
      }

      &.activity {
        .form-details {

          .line {
            &:nth-child(1){
              width: rem( 240 );
            }
            &:nth-child(2){
              width: rem( 280 );
            }
          }
        }
      }

      &.restaurant {
        .form-details {

          .line {
            &:nth-child(1){
              width: rem( 300 );
            }
            &:nth-child(2){
              width: rem( 220 );
            }
          }
        }
      }
    }
  }

  // Actions Form
  .form-actions {
    background: none;
    color: inherit;
    display: block;
    padding: 0;

    .js & {
      display: block;
    }

    > .wrap {
      &:after,
      &:before {
        display: none;
      }
    }
  }

  // Filters form
  .form-filters {
    box-shadow: 5px 5px 5px rgba( $black, 0.1 );
    box-sizing: border-box;
    float: left;
    margin: 0 0 rem( 30 );
    padding: rem( 16 );
    position: relative;
    width: rem( 320 );
    z-index: 2;

    .legend-tt {
      display: none;
    }
    .legend {
      padding: 0 0 rem( 8 );
    }

    .fieldset + .fieldset {
      border-top: 1px solid rgba( $theme3, 0.3 );
      padding-top: rem( 12 );
    }

    .price-part,
    .grades-part,
    .type-part {
      float: none;
      padding: 0;
      width: auto;
    }

    .grades-part {
      &:before {
        display: none;
      }
      @include clearfix;

      .legend {
        float: left;
        padding-top: rem( 5 );
        width: rem( 96 );
      }
      .form-fields {
        float: left;
        width: rem( 192 );
      }
    }

    .grades-part,
    .type-part {
      border-top: 1px solid rgba( $theme3, 0.3 );
      padding-top: rem( 14 );
    }

    .options-part {
      padding-top: rem( 14 );

      .form-fields {
        columns: auto;
        column-gap: auto;
      }
    }

    .buttons {
      box-sizing: border-box;
      margin: 0 rem( -6 );
      padding: 0;
      width: auto;
    }
  }

  // Mode switch
  .form-display {
    position: absolute;
    bottom: auto;
    z-index: 1;

    &.sticky {
      position: absolute;
    }

    &:before {
      background: $theme3;
      content: "";
      height: rem( 40 );
      left: 0;
      position: absolute;
      top: 0;
      width: calc( 50% + 190px );
    }
  }

  .switch {
    height: rem( 40 );
    padding: rem( 8 ) 0 0 0;
    position: relative;
    text-align: right;

    .icon-list {
      height: rem( 17 );
      width: rem( 17 );
    }
    .icon-map {
      height: rem( 25 );
      width: rem( 25 );
    }

    label {
      font-size: rem( 16 );

      &:nth-of-type(1){
        margin-right: rem( -25 );
        padding: 0 rem( 60 ) 0 0;
      }
      &:nth-of-type(2){
        margin-left: rem( -50 );
        padding: 0 0 0 rem( 60 );
      }
    }

    .cursor {
      background: $theme3;
      box-shadow: inset 0 2px 3px rgba( $black, 0.4 );
      height: rem( 22 );
      margin: rem( -11 ) 0 0 rem( -25 );
      width: rem( 50 );

      &:before {
        border-radius: rem( 11 );
        height: rem( 22 );
        left: rem( 20 );
        // transform: translateX( rem( 20 ));
        width: rem( 30 );
      }
    }
  }
}

// Search Results
.offer-list {
  border-top: rem( 40 ) solid $theme3;
  padding-bottom: 0;

  > div {
    overflow: hidden;
  }

  .wrap {
    margin: 0;
    width: rem( 660 );
  }

  .inner-wrap {
    padding-left: rem( 25 );
  }

  // Header
  .results-header {
    display: block;
    position: relative;

    &:before {
      content: "";
      background: $white;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: 0;
    }

    .inner-wrap {
      padding-top: rem( 26 );
      position: relative;
      z-index: 1;
    }

    [class*="tt-block"]{
      font-size: rem( 33 );
      margin-bottom: rem( 16 );
    }
  }

  // Content
  .results-content {

    .results-list {
      text-align: left;
    }
  }
}

.offer-sort {
  .tag-list {
    button {
      min-width: rem( 120 );

      .no-cssgradients & {
        min-width: 0;
      }
    }
  }
}


/* MAP View
-------------------*/
#offer-search.map-view{

  // Search Form
  .offer-search-form {

    .form-filters-bt {
      background: $white;
      border: 0 none;
      bottom: rem( -32 );
      display: block;
      font-size: rem( 15 );
      font-weight: 500;
      line-height: ( 32 / 15 );
      padding: 0 rem( 42 ) 0 rem( 16 );
      text-align: left;
      position: absolute;
      width: rem( 320 );
      z-index: 2;

      [class*="icon-prev"] {
        fill: $text-color;
        height: rem( 19 );
        margin: rem( -8 ) 0 0 0;
        position: absolute;
        right: rem( 16 );
        top: 50%;
        transform: matrix(-0.000,-1,1,-0.000,0,0);
        width: rem( 10 );
      }

      &.on {
        display: none;
      }
    }

    // Filters form
    .form-filters {
      display: none;
      padding: rem( 16 ) rem( 16 );
      width: rem( 650 );

      &.on {
        display: block;
      }

      fieldset {
        box-sizing: border-box;
        border: 0 none;
        margin: 0;
        padding: 0;
      }

      .price-part,
      .grades-part {
        float: left;
        padding-right: rem( 21 );
        width: 50%;
      }
      .type-part {
        float: right;
        padding-left: rem( 21 );
        width: 50%;
      }

      .price-part,
      .grades-part,
      .type-part {
        border: none;
      }

      .grades-part {
        &:before {
          background: rgba( $theme3, 0.3 );
          content: "";
          display: block;
          height: 1px;
          margin: 0 0 rem( 12 );
        }

        .legend {
          width: rem( 95 );
        }
        .form-fields {
          width: rem( 190 );
        }
      }

      .options-part{
        border-top: 1px solid rgba( $theme3, 0.3 );
        clear: both;
        padding-top: rem( 12 );

        .form-fields {
          columns: 2;
          column-gap: rem( 42 );
        }
      }

      .buttons {
        box-sizing: border-box;
        margin: 0 rem( -6 ) 0 auto;
        padding: 0 0 0 rem( 6 );
        width: 50%;
      }
    }
  }

  // Search Results
  .offer-results {
    position: relative;
  }

  // Search List
  .offer-list {
    box-shadow: 0 0 25px 5px rgba( $theme-secondary-grey, 0.3 );
    display: block;
    position: relative;
    width: calc( 50% + 190px );
    z-index: 1;

    > div {
      overflow: visible;
    }

    .wrap {
      margin: 0 0 0 auto;
      width: rem( 680 );
    }
    .inner-wrap {
      padding-left: 0;
    }

    // Header
    .results-header {
      padding-top: rem( 26 );

      &:before {
        left: auto;
        right: 0;
      }

      .inner-wrap {
        clear: both;
        padding-top: 0;
      }
    }
  }

  // Search Map
  .offer-map {
    height: 100%;
    max-height: rem( 1100 );
    position: absolute;
    right: 0;
    top: 0;
    width: calc( 50% - 190px );
    z-index: 0;
  }
}


/* Offer Page
-------------------*/
.offer-selection {

  .wrap {
    box-sizing: border-box;
    padding: 0 rem( 330 ) 0 10px;
  }
  .grey-background {
    background: rgba(119, 135, 83, 0.05);
    width: 1280px;
    margin: auto;
  }
}

.dechirure-offer-div{
  text-align: center;

  .dechirure-offer-img{
    width: 1280px;
    background: rgba(119, 135, 83, 0.05);
  }
}
// Heading
.offer-heading {
  padding: rem( 20 ) 0;

  .address {
    padding-top: 0;
  }
  .rating {
    padding-right: rem( 20 );
  }

  .col {
    + .col {
      padding-right: rem( 20 );
      width: rem( 220 );
    }

    .rating {
      padding-right: 0;
    }
  }
}

// Offer desc
.offer-description-block {
  [data-zoom-slider-target] {
    .zoom{
      transform: none;
      width: rem( 650 );
    }
  }
}
.offer-description {
  padding-top: rem( 32 );

  .desc-text {
    padding-bottom: rem( 40 );
  }
}

// Offer picture
.offer-picture {
  [class*="tt-raw"]{
    font-size: rem( 30 );
  }
}

// Offer services
.offer-services {
  padding: rem( 32 ) 0 rem( 30 );

  .list {
    margin-bottom: rem( 40 );
    position: relative;

    &:before {
      background: $white;
      content: "";
      height: 90%;
      left: 50%;
      position: absolute;
      top: 5%;
      width: 1px;

      .no-cssgradients & {
        display: none;
      }
    }
  }
  ul {
    column-gap: rem( 210 );
    column-rule: none;
    font-size: rem( 13 );
    margin-bottom: 0;
  }

  li {
    padding: rem( 6 ) 0;
  }

  .pict {
    width: rem( 50 );
  }

  p {
    width: calc( 100% - 55px );
  }

  .more-services {
    font-size: rem( 18 );
    padding: rem( 6 ) rem( 40 ) rem( 6 ) rem( 16 );

    &:before {
      width: rem( 18 )
    }

    &:after {
      height: rem( 18 );
      margin-top: rem( -9 );
      right: rem( 20 );
    }
  }
}

// Offer services
.offer-services-equipments {
  padding: rem( 26 ) 0 rem( 24 );

  [class*="tt-raw"]{
    font-size: rem( 25 );
  }
}

// Offer schedule
.offer-schedule {
  padding: rem( 26 ) 0 rem( 24 );

  .info {
    padding-left: rem( 30 );
    text-align: left;
  }
}

// Offer info
.offer-info {
  min-height: rem( 440 );
  padding: 0;
  position: relative;
  width: 1280px;
  margin: auto;

  .wrap {
    position: static;
    padding-left: 10px;
  }
  [class*="tt-block"] {
    padding-top: rem( 70 );
  }
  .offer-cat-block {
    width: rem( 380 );
  }
  .info {
    box-sizing: border-box;
    padding-bottom: rem( 50 );
    padding-top: 0;
    // width: rem( 380 );
    width: auto;
  }
  .activity-map {
    height: 100%;
    right: 0;
    margin: 0;
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 50%;

    .legend {
      font-size: rem( 12 );
      margin-left: rem( 70 );

      p {
        padding: rem( 5 ) rem( 26 ) rem( 5 ) rem( 32 );

        + p {
          margin: 0;
        }
      }
    }
    .mask {
      display: block;
      height: 100%;
      left: 0;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: rem( 130 );
      z-index: 2;

      &:before {
        border-radius: 100%;
        box-shadow: 0 0 0 rem( 200 ) $white, inset 0 0 rem( 10 ) rgba( $black, 0.4 );
        content: "";
        height: rem( 860 );
        left: 0;
        margin-top: rem( -430 );
        position: absolute;
        width: rem( 860 );
        top: 50%;
      }
    }
  }

  &.grey {
    .info {
      background: $grey-light3;
    }

    .activity-map {
      .mask {
        &:before {
          box-shadow: 0 0 0 rem( 200 ) $grey-light3, inset 0 0 rem( 10 ) rgba( $black, 0.4 );
        }
      }
    }
  }

  &.grey-light {
    .info {
      background: $grey-light2;
    }

    .activity-map {
      .mask {
        &:before {
          box-shadow: 0 0 0 rem( 200 ) $grey-light2, inset 0 0 rem( 10 ) rgba( $black, 0.4 );
        }
      }
    }
  }
}


// Offer votes
.offer-votes {
  padding: rem( 40 ) 0 rem( 32 );

  .tt {
    margin-top: rem( 10 );
    padding-bottom: rem( 5 );
    text-align: center;
    float: left;

    b {
      display: block;
    }
  }

  .vote-details {
    float: left;
    width: rem( 640 );
  }
  ul {
    column-gap: rem( 20 );
    margin: 0;
  }

  .vote-desc {
    .vote-details {
      float: none;
      margin-bottom: rem( 20 );
      width: auto;
    }
  }
}

// Offer Choices
.offer-choices {
  padding: rem( 30 ) 0 rem( 50 );

  .choices-list {
    .head {

      .desc {
        // padding: rem( 4 ) 0 0;

        ul {
          font-size: rem( 12 );
          padding: 0 rem( 14 );
        }

        li {
          &:before {
            height: rem( 30 );
          }
        }

        [class*="icon"]{
          height: rem( 20 );
          margin: rem( -10 ) 0 0;
          width: rem( 30 );
        }
      }
    }
  }

  .price {
    font-size: rem( 17 );

    strong {
      font-size: rem( 27 );
    }
  }
}


// offer recap
.offer-selection {
  position: relative;
  width: $page-width-B2C;
  margin: auto;

  ~ section:last-child {
    margin-bottom: 0;
  }
}
.offer-recap{
  bottom: auto;
  height: 100%;
  left: 50%;
  margin: 0 0 0 rem( 320);
  padding: 0 0 rem( 70 );
  position: absolute;
  top: rem( -70 );
  width: rem( 320 );
  z-index: 2;

  .inner {
    box-shadow: 0 2px 3px rgba( $black, 0.1 );
    min-height: rem( 220 );
    padding: 0 rem( 28 ) 1px;

    .js & {
      display: block;
    }
  }

  &.activity {
    .inner {
      background: #182883;
    }
  }

  &.accommodation {
    .inner {
      background: #182883;
    }
  }

  &.koudke {
    .inner {
      background: $koudke;
    }
  }

  &.restaurant {
    .inner {
      background: $restaurant;
    }
  }


}
.offer-recap-block {
  color: white;
  left: auto;
  bottom: auto;
  padding: rem( 20 ) 0;
  position: absolute;
  top: 0;
  width: rem( 320 );

  > [class*="cta"] {
    display: none;
  }

  &.bottom {
    bottom: 0;
    top: auto;
  }
  &.fixed {
    top: rem( 40 );
  }

  .form {
    .offer-recap-selection-title {
      display: block;
    }
    .offer-recap-selection {
      &.no-selection {
        color: $white;
      }
      .price-unavailable {
        color: $white;
      }
    }
  }

  .tt {
    background: inherit;
    margin: 0 0 rem( 20 ) rem( -34 );
    padding: rem( 7 ) rem( 26 ) rem( 10 );
    position: relative;
    width: 100%;

    &:after {
      content: "";
      @include triangle( 6px, $black, up-right );

      bottom: rem( -6 );
      left: 0;
      position: absolute;
    }

    &:before {
      background: url( "#{$url-img}bg-label-shadow.png" ) no-repeat 0 rem( -13 );
      background-size: rem( 136 ) auto;
      bottom: rem( -13 );
      content: "";
      height: rem( 13 );
      left: rem( 6 );
      position: absolute;
      text-align: right;
      width: rem( 136 );
    }
  }

  b {
    font-size: rem( 17 );
  }

  li {
    [class*="icon"]{
      fill: $white;
    }
  }
  .total {
    border-top: 0 none;
    padding-bottom: rem( 15 );
    padding-top: 0;
  }
  .buttons {
    margin: 0 0 rem( 26 );

    .cta {
      background: $theme-secondary-grey;
      border-color: $theme-secondary-grey;

      &:hover,
      a:hover & {
        background: $white;
        border-color: $theme-secondary-grey;
        color: $theme-secondary-grey;
      }
    }

    .cta-outline {
      background-color: transparent;

      &:hover,
      a:hover & {
        background: $theme3;
        color: $white;
      }
    }
  }

  .disabled {
    .cta {
      &:hover {
        background: $theme-secondary-grey;
        color: $white;
      }
    }
    .cta-outline {
      &:hover {
        background: transparent;
        color: $theme-secondary-grey;

        [class*="icon"]{
          fill: inherit;
        }
      }

      &.shop {
        &:hover {
          [class*="icon"]{
            fill: $theme-secondary-grey;
          }
        }
      }
    }
  }
}


// Popins
#popin {
  .popin-booking.choice {
    .popin-content {
      width: rem( 780 );
    }
    .wrap {
      width: rem( 680 );
    }
  }
  .options-list {
    font-size: rem( 15 );
    margin: 0 0 rem( 40 );
    padding: rem( 14 ) 0 0;

    li {
      padding-left: rem( 24 );
      padding-right: rem( 24 );

      > label {
        padding-top: rem( 4 );
        width: rem( 490 );
      }
    }
    .option {
      .icon-check {
        height: rem( 14 );
        top: rem( 8 );
        width: rem( 14 );
      }
    }
  }
  .offer-actions {
    padding-bottom: rem( 22 );

    .total {
      font-size: rem( 19 );

      small {
        font-size: rem( 13 );
      }
    }
    .full-total {
      font-size: rem( 22 );
    }
  }

  .offer-confirmation {
    font-size: rem( 15 );
    padding-bottom: rem( 10 );
  }
}
