//------------------------------------
// #PUSHES
//------------------------------------



// Feature push
[class*="feature-push"] {

  [class*="col-"] {
    .intro {
      padding-right: rem( 50 );
    }
  }
  .revert [class*="col-"] {
    .intro {
      padding-left: rem( 50 );
      padding-right: 0;
    }
  }

  .picture {
    .discount {
      top: rem( 20 );
    }
  }
  .slider {
    margin-top: rem( 40 );
  }

  .main-section & {
    .col-one-quarter,
    .col-three-quarter {
      width: 45%;
    }

    .cols-centered {
      .col-one-quarter,
      .col-three-quarter {
        box-sizing: content-box;
        flex-basis: 45%;
      }
    }
  }

}

// Push islands
.feature-push-island {
  .text {
    padding-top: rem( 30 );
  }
}

// Number push
.push-numbers {
  background: transparent;
  box-sizing: border-box;
  height: rem( 410 );
  padding: rem( 26 ) 0 rem( 10 );
  position: relative;

  .bg-wrapper,
  .wrap {
    height: 100%;
  }

  .bg-wrapper {
    &:not(.on){
      display: none;
    }
  }

  .pictures {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .wrap {
    z-index: 2;
  }

  ul {
    margin-bottom: rem( 50 );
    margin-top: rem( 50 );
  }
  li {
    padding: 0 0 0 0;
    width: auto;

    @include clearfix;

    + li {
      padding: 0 0 0 rem( 50 );
    }

    &:not(.on){
      opacity: 0.6;
    }
  }
  .pict {
    display: inline-block;
    margin: rem( 40 ) 0 0;
    vertical-align: top;
  }
  .desc {
    border-radius: rem( 100% );
    cursor: default;
    display: inline-block;
    font-size: rem( 20 );
    height: rem( 140 );
    position: relative;
    vertical-align: top;
    width: rem( 140 );

    &:after {
      background: $white;
      content: "";
      height: rem( 1 );
      left: rem( -55 );
      position: absolute;
      top: rem( 95 );
      width: rem( 60 );
    }

    strong {
      font-size: rem( 18 );
      line-height: ( 30/26 );
    }
  }
  .cta {
    background: $purple;
    border-color: $purple;
    color: $white;

    &:hover {
      background: $white;
      border-color: $purple;
      color: $purple;
    }
  }
}


// Map push
.push-map {

  .text {
    padding-right: rem( 20 );
  }
  .picture {
    width: rem( 625 );
  }

  // CTA labels
  .cta-label {
    font-size: rem( 20 );

    span {
      padding: rem( 8 ) rem( 10 ) rem( 8 ) rem( 10 );
      min-width: rem( 108 );

      &:after {
        background-size: rem( 100 ) auto;
        bottom: rem( -9 );
        height: rem( 9 );
        right: rem( 8 );
        width: rem( 100 );
      }
    }

    &.revert,
    .revert & {
      span {

        &:after {
          background-position: 0 rem( -10 );
          left: rem( 8 );
        }
      }
    }
  }
}


// Hero image block
.push-picture {
  height: rem( 335 );
  padding: 0;

  .pict-wrapper {
    position: relative;
  }

  img {
    left: 50%;
    position: relative;
    transform: translateX( -50% );
  }
}


// Tour push
.push-tour {
  &:after {
    height: rem( 405 );
  }

  .date-sticker {
    left: rem( 70 );
    z-index: 2;
  }
  .picture {
    width: rem( 660 );
  }
  .text {
    margin: 0 rem( 100 ) 0 auto;
    padding: rem( 30 ) rem( 30 ) rem( 14 );
    background-color: initial;

    .block-form {
      background-color: initial;
    }
  }
}


// Hero push
.push-hero {
  .container {

    &:after {
      height: rem( 390 );
    }
  }

  &.condensed {
    .container {

      &:after {
        height: rem( 190 );
      }
    }
  }
}


// Slider push
.push-slider {
  padding-top: rem( 36 );

  .slick-arrow {
    margin-top: rem( -35 );

    [class*="icon-"] {
      height: rem( 35 );
      width: rem( 20 );
    }
  }
}


// Certification push - Advantages
.push-certification {
  background-color: $white;
  padding-bottom: rem( 64 );

  .program-register {
    margin-top: rem( 36 );

    ol {
      background: url( "#{$url-img}dyn/desktop/push-certified.png" ) no-repeat 50% 100%;
      border-bottom: rem( 6 ) solid $light;
      height: rem( 250 );
      margin-bottom: 0;
      padding-bottom: 0;
      position: relative;
    }

    li {
      cursor: default;
      font-size: rem( 18 );
      margin-top: 0;
      position: absolute;
      transition: color $transition;

      &:hover {
        color: $purple;

        .icon {
          background-color: $purple;
        }
      }
    }

    .icon {
      background-color: $theme4;
      border: rem( 3 ) solid $white;
      height: rem( 28 );
      transition: $transition;
      transform-origin: 50% 50%;
      width: rem( 28 );

      svg {
        height: rem( 13 );
        transition: background-color $transition;
        width: rem( 14 );
      }

      + span {
        padding-left: 0;
      }
    }

    // top item
    .item-a {
      bottom: rem( 160 );
      left: 50%;
      padding-bottom: rem( 48 );
      text-align: center;
      transform: translateX( -50% );
      width: rem( 325 );

      .icon {
        bottom: 0;
        left: 50%;
        top: auto;
        transform: translateX( -50% );
      }

      &:hover {
        .icon {
          transform: translateX( -50% ) scale( 1.3 );
        }
      }
    }

    // middle items
    .item-b {
      padding-right: rem( 60 );
      right: rem( 615 );
      text-align: right;

      .icon {
        right: 0;
      }
    }

    .item-d {
      padding-left: rem( 60 );
      left: rem( 615 );

      .icon {
        left: 0;
      }
    }

    .item-b,
    .item-d {
      bottom: rem( 122 );
      width: rem( 240 );

      .icon {
        bottom: 0;
        top: auto;
        transform: translateY( 50% );
      }

      &:hover {
        .icon {
          transform: translateY( 50% ) scale( 1.3 );
        }
      }
    }



    // bottom items
    .item-c {
      padding-right: rem( 60 );
      right: rem( 670 );
      text-align: right;

      .icon {
        right: 0;
      }
    }

    .item-e {
      padding-left: rem( 60 );
      left: rem( 670 );

      .icon {
        left: 0;
      }
    }

    .item-c,
    .item-e {
      bottom: rem( 32 );
      width: rem( 270 );

      .icon {
        bottom: 0;
        top: auto;
        transform: translateY( 25% );
      }

      &:hover {
        .icon {
          transform: translateY( 25% ) scale( 1.3 );
        }
      }
    }

    .cta {
      margin-top: rem( -35 );
      position: relative;
    }
  }
}


// Certification modules
.push-certification-modules {
  .modules {
    display: block;
    text-align: center;

    article {
      display: inline-block;
      max-width: rem( 245 );
      padding: 0 rem( 5 );
      vertical-align: top;
      width: 25%;

      &:nth-child( n + 3 ) {
        margin-top: 0;
      }

      .wrap {
        width: auto;
      }

      .icon ~ * {
        position: relative;
      }
    }

    [aria-expanded="true"] {
      transition: none;
      visibility: hidden;
    }

    article.on {
      .icon {
        &:before {
          background: url( '#{$url-img}drop.png' ) no-repeat;
          background-size: 100% auto;
          content: "";
          left: 50%;
          height: rem( 293 );
          position: absolute;
          top: 0;
          transform: translateX( -50% );
          width: rem( 236 );
        }
      }
    }
  }
}


// Push Explain
.push-highlight {
  padding: rem( 30 ) 0;

  ul {
    margin-bottom: rem( 20 );
  }
}


// Push discover
.feature-push-discover {
  padding-bottom: rem( 24 );
}
.push-discover {

  [class*="tt-header"] {
    font-size: rem( 28 );
    line-height: ( 30/28 );

    span {
      padding: rem( 10 ) rem( 8 );
    background-color: transparent;
    border: 0px;
    }
  }

  .inner{
    &:before {
      height: rem( 290 );
    }
  }

  &.middle {
    .inner{
      &:before {
        height: rem( 290 );
      }
    }
  }

  &.big {
    .inner{
      &:before {
        height: rem( 390 );
      }
    }
  }

  &.large {
    .inner {
      text-align: right;

      &:before {
        vertical-align: middle;
      }
    }
    .text {
      padding-right: rem( 36 );
      vertical-align: middle;
    }
    [class*="tt-header"] {
      font-size: 0;
      letter-spacing: 0;

      span {
        font-size: rem( 48 );
      }
    }
    .highlight {
      display: inline-block;
      margin: 0;
      text-align: left;
      padding: 0;
      color: black;
      margin-top: 25px;

      .sk-Rectangle{
        width: 80px;
      }
    }
    p{
      font-size: 18px;
    }
  }

  &.small {
    .inner{
      &:before {
        height: rem( 330 );
      }
    }
    .text {
      padding-bottom: rem( 22 );
    }
  }
}


// Push Facebook
.push-facebook {

  .bg-wrapper {
    background-image: url("#{$url-img}bg-facebook-block.jpg");
  }
  .inner {
    min-height: rem( 209 );
  }
}


// Advatages
.push-advantages {
  .tt-block {
    font-size: rem( 35 );
  }

  ul {
    margin: 0 rem( -40 );
  }

  li {
    padding: 0 rem( 40 );
    width: rem( 250 );
  }
}


// Guadeloupedia
.push-guadeloupedia-empty {

  .inner {
    height: rem( 390 );
  }
}


.push-guadeloupedia {
  min-height: rem( 390 );
  padding-top: rem( 25 );

  .push-tt {
    margin-bottom: rem( 10 );
  }
}
