//------------------------------------
// #SLIDERS
//------------------------------------

// Slider
.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  left: 0;
  position: relative;
  top: 0;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    border: 1px solid transparent;
    display: block;
    height: auto;
  }
}
.slick-arrow {
  backface-visibility: hidden;
  background: none;
  border: 0 none;
  padding: 0;
  transition: $transition;

  [class*="icon-"] {
    fill: $theme;
    height: rem( 20 );
    width: rem( 12 );
  }
  &.slick-next {
    [class*="icon-"] {
      transform:  matrix( -1, 0, -0, -1, 0, 0 );
    }
  }
  span {
    @include hide;
  }

  &.slick-hidden {
    display: none;
  }
  &:hover {
    opacity: 0.5;
  }
}
.slick-dots {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 rem( 4 );
  }
  button {
    background: none;
    border: 2px solid rgba( $theme, 0.5 );
    border-radius: 100%;
    box-sizing: border-box;
    font-size: 0;
    height: rem( 16 );
    line-height: 0;
    padding: 0;
    transition: $transition;
    width: rem( 16 );

    &:hover {
      background: rgba( $theme, 0.5 );
    }
  }
  .slick-active {
    button {
      background: $theme;
      border-color: $theme;

      &:hover {
        background: $theme;
      }
    }
  }
}

// Logo Slider
[data-slider="logo"]{
  margin: 0 auto;
  width: rem( 270 );

  .js & {
    overflow: hidden;
    white-space: nowrap;

    &.slick-initialized {
      overflow: visible;
      white-space: normal;
    }
  }

  .slide {
    display: inline-block;
    float: none;
    margin-bottom: rem( 10 );
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    width: rem( 90 );
  }
  img {
    margin: 0 auto;
    max-width: rem( 75 );
  }
  .slick-arrow {
    margin: rem( -15 ) 0 0 0;
    position: absolute;
    top: 50%;

    &.slick-prev {
      left: rem( -15 );
    }
    &.slick-next {
      right: rem( -15 );
    }

    [class*="icon-"] {
      fill: $theme;
    }
  }
}


// Icon Slider
[data-slider="icon"]{

  .icon + .icon {
    display: none;
  }

  .js & {

    &.slick-initialized {
      .icon + .icon {
        display: block;
      }
    }
  }
}


// Agencies Slider
[data-slider="agencies"]{
  height: rem( 60 );
  margin: 0 rem( 30 );
  overflow: hidden;

  &.slick-initialized {
    overflow: visible;
  }

  .slide {
    height: rem( 60 );
    text-align: center;
    white-space: nowrap;

    &:before {
      @include valign;
    }
  }

  .slick-arrow {
    margin: rem( -12 ) 0 0 0;
    position: absolute;
    top: 50%;

    &.slick-prev {
      left: rem( -30 );
    }
    &.slick-next {
      right: rem( -30 );
    }

    [class*="icon-"] {
      fill: $white;
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
  }
}


// Pictures slider
[data-slider="pictures"] {
  position: relative;

  .slide {
    padding: 0 rem( 5 );
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  &.slick-initialized {
    padding-bottom: rem( 35 );
  }
  .slick-dots {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .slick-arrow {
    margin: rem( -29 ) 0 0 0;
    padding: rem( 5 );
    position: absolute;
    top: 50%;
    z-index: 2;

    &.slick-prev {
      left: rem( 5 );
    }
    &.slick-next {
      right: rem( 5 );
    }
  }
  .slick-dots {
    text-align: center;

    li {
      display: inline-block;
      padding: 0 rem( 4 );
    }
    button {
      border-color: rgba( $white, 0.5 );

      &:hover {
        background: rgba( $white, 0.5 );
      }
    }
    .slick-active {
      button {
        background: $white;
        border-color: $white;

        &:hover {
          background: $white;
        }
      }
    }
  }
}

// Partner slider
[data-slider="partners"] {
  margin-left: rem( -10 );
  margin-right: rem( -10 );

  .slide {
    padding-left: rem( 10 );
    padding-right: rem( 10 );
  }
}


// Offers
[data-slider="offers"]{
  margin-left: auto;
  margin-right: auto;
  // width: pct( 286/300 );
  width: rem( 286 );

  @include clearfix;

  .slide {
    margin-left: auto;
    margin-right: auto;
    padding: 0 rem( 15 );
    width: rem( 256 );
  }
  .slick-arrow {
    margin: rem( -29 ) 0 0 0;
    padding: rem( 5 );
    position: absolute;
    top: 50%;
    z-index: 2;

    [class*="icon-"] {
      fill: $theme-secondary-grey;
      height: rem( 24 );
      width: rem( 14 );
    }

    &.slick-prev {
      left: rem( -12 );
    }
    &.slick-next {
      right: rem( -12 );
    }
  }
}

[data-slider="small-offers"]{
  margin-left: auto;
  margin-right: auto;
  // width: pct( 286/300 );
  width: rem( 286 );

  @include clearfix;

  .slide {
    margin-left: auto;
    margin-right: auto;
    padding: 0 2px;
    width: rem( 276 );
  }
  .slick-arrow {
    margin: rem( -29 ) 0 0 0;
    padding: rem( 5 );
    position: absolute;
    top: 50%;
    z-index: 2;

    [class*="icon-"] {
      fill: $theme-secondary-grey;
      height: rem( 24 );
      width: rem( 14 );
    }

    &.slick-prev {
      left: rem( -12 );
    }
    &.slick-next {
      right: rem( -12 );
    }
  }
}

// Calendar slider
[data-calendar-slider] {
  margin: 0 auto rem( 16 );
  max-width: rem( 300 );

  .slide {
    padding: 0 rem( 8 );
  }

  .slick-arrow {
    margin: rem( -4 ) 0 0;
    position: absolute;
    top: 50%;

    [class*="icon-"]{
      fill: $theme3;
      height: rem( 13 );
      width: rem( 8 );
    }
  }
  .slick-disabled {
    visibility: hidden;
  }
  .slick-prev {
    left: rem( -8 );
  }
  .slick-next {
    right: rem( -8 );
  }

  .calendar {
    .calendar-title {
      background: #56696f;
    }
    .calendar-table {
      thead {
        th {
          background: #bfc7c9;
        }
      }
      tbody {
        td {
          height: rem( 54 );
          padding: 0;
          vertical-align: top;
        }
        span {
          box-sizing: border-box;
          display: block;
          font-size: rem( 17 );
          padding: rem( 8 ) 0 0;
          height: rem( 34 );
        }
        p {
          background: $yellow;
          box-sizing: border-box;
          color: $black;
          font-family: $font-roboto-condensed-regular;
          font-size: rem( 14 );
          font-weight: normal;
          height: rem( 20 );
          margin: 0;
        }
      }
    }
  }
}
