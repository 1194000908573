//------------------------------------
// #POPINS
//------------------------------------

#popin {

  .popin-content {
    margin: rem( 40 ) 0;
    width: rem( 636 );
  }
}
