//------------------------------------
// #SCROLLBAR
//------------------------------------

.scroller {
  margin: 0 rem( 26 ) 0 0;

  .scroller-content {
    overflow-y: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
.scroller-track {
  background: rgba( $theme3, 0.15 );
  bottom: 0;
  border-radius: rem( 6 );
  display: none; /* Invisible by default */
  position: absolute;
  right: rem( -26 );
  top: 0;
  width: rem( 12 );

  .scroller & {
    display: block;
  }
}
.scroller-bar {
  background: $theme3;
  border-radius: rem( 6 );
  position: absolute;
  z-index: 1;
  right: 0;
  width: rem( 12 );
}
