//------------------------------------
// #Sections
//------------------------------------

// Home top section
.home-section  {

  .aside {
    width: rem( 980 );
  }
}

// Home hero
.home-hero {
  [data-fade] {
    margin: 0 0 rem( 40 );
  }
  .push {
    display: inline-block;
    max-width: 50%;
    padding-bottom: rem( 20 );
    padding-top: rem( 20 );
    width: auto;

    .inner {
      padding: 0;
    }

    .tt-block {
      font-size: rem( 35 );
      @include baseline;
    }
  }
}

// Block push
.home-push {
  padding: rem( 26 ) 0 rem( 10 );

  .inner {
    min-height: rem( 140 );
    padding: 0 rem( 175 ) 0 0;
    position: relative;
    width: rem( 460 );
  }
  .tt-block {
    font-size: rem( 25 );
    @include baseline;
  }
  [class*="icon-"]{
    display: block;
    fill: $white;
    position: absolute;
    right: 0;
    top: rem( -15 );
    width: rem( 98 )
  }
}


// Content Header
[class*="header-main"] {

  // Hero slider
  [data-slider="simple"]{
    .slick-prev {
      margin-left: rem( -490 );
      left: 50%;
    }
    .slick-next {
      margin-right: rem( -490 );
      right: 50%;
    }
  }

  .icon-arrow-curv {
    display: block;
    fill: $white;
    height: rem( 42 );
    left: rem( 150 );
    position: absolute;
    top: rem( -75 );
    width: rem( 50 );
  }

  .text {
    .desc {
      color: $white;
      display: block;
      max-width: rem( 210 );
    }
  }

  .map-labels {
    font-size: rem( 18 );

    a {
      padding: rem( 5 ) rem( 10 ) rem( 6 ) rem( 6 );

      [class*="icon-"] {
        display: inline-block;
        fill: $white;
        height: rem( 13 );
        margin: 0 rem( 8 ) 0 0;
        transform:  matrix( -1, 0, -0, -1, 0, 0 );
        vertical-align: middle;
        width: rem( 9 );
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    li {

      // Grande-terre
      &:nth-child(1){
        bottom: pct( 315/390 );
        left: pct( 470/980 );
      }

      // Basse-terre
      &:nth-child(2){
        left: pct( 350/980 );
        top: pct( 210/390 );
      }
      // Les saintes
      &:nth-child(3){
        left: pct( 400/980 );
        top: pct( 345/390 );
      }
      // La désirade
      &:nth-child(4){
        left: pct( 705/980 );
        top: pct( 78/390 );
      }
      // Marie Galante
      &:nth-child(5){
        top: pct( 285/390 );
        left: pct( 588/980 );
      }
    }
  }
}

// Header video
.header-main-video {
  .bg-wrapper {
    height: rem( 480 );
  }
}

// Sub navigation
.sub-navigation {
  .select {
    display: none;
  }
  .select-list {
    background: none;
    color: $white;
    height: auto;
    padding: 0;

    a:not(.selected) {
      display: block;
    }

    .js & {
      display: flex;
    }
  }
  ul {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    list-style-type: none;
    margin: 0;

    li {
      flex-grow: 1;
      text-align: center;

      + li {

        a {
          &:before {
            background: rgba( $white, 0.5 );
            content: "";
            height: rem( 24 );
            left: 0;
            position: absolute;
            top: rem( 6 );
            width: rem( 1 );
          }
        }
      }
    }
    a {
      display: block;
      height: rem( 38 );
      margin-left: rem( -1 );
      padding: 0 rem( 10 );
      position: relative;
      transition: $transition;

      &:after {
        @include valign;
      }

      &:hover,
      &:focus,
      &.selected {
        background: $white;
        color: $theme-secondary-grey;

        &.grey-light {
          background: $grey-light2;
        }

        div {
          margin: 0 rem( -1 );
        }

        [class*="icon"] {
          fill: $theme-secondary-grey;
        }
      }
    }
  }
  [class*="icon"] {
    fill: $white;
  }

  .select-list {
    .no-flexbox & {
      display: table;
      width: 100%;

      li {
        display: table-cell;
      }
    }
  }
}


// Main section
.main-section {
  .content {
    padding-bottom: rem( 20 );

    .block-documents-list {
      padding-right: rem( 60 );
    }
  }
  &.alt-section {
    .content,
    .aside {
      display: table-cell;
    }

    .content {
      padding-right: rem( 20 );
    }

    .aside {
      margin-left: 0;
      width: rem( 320 );

      .inner-wrap {
        display: block;
        margin-top: rem( -110 );
      }
      .inner{
        width: 100%;

        .no-flexbox & {
          font-size: rem( $fz );
          letter-spacing: normal;
        }
      }
      .inner,
      .inner-alt {
        box-sizing: border-box;
        padding-left: rem( 20 );
        padding-right: rem( 20 );

        .no-flexbox & {
          display: block;
        }
      }
    }

    [class*="block-support"] {
      min-height: rem( 364 );
      .contact {
        float: none;
        width: auto;

        + .contact {
          margin-top: rem( 10 );
        }
      }
    }

    .block-contact {
      padding-top: 0;

      article {
        margin-right: 3%;
        width: 47%;

        &:nth-child( 2n + 2 ) {
          margin-left: 0;
        }

        &:nth-child( 2n + 3 ) {
          clear: left;
        }
      }
    }
  }
}


// Push section
.push-feature-section {
  [class*="feature-push"] {
    padding-bottom: rem( 10 );
    padding-top: rem( 36 );
  }
}

// tour section
.tour-section {
  padding: rem( 30 ) 0 rem( 24 );

  li + li {
    padding-top: rem( 20 );

    &:nth-child(odd){
      &:before {
        left: rem( 130 );
        top: rem( -65 );
      }
    }
    &:nth-child(even){
      &:before {
        right: rem( 160 );
        top: rem( -98 );
      }

      .push-tour {

        .date-sticker {
          left: auto;
          right: rem( 100 );
        }
      }
    }
  }
}


// Section
.offers-section {

  [class*="tt-raw"]{
    font-size: rem( 35 );
  }

  
  .sk-Rectangle{
    width: 80px;
  }
}

// Contributions
.contrib-block {

  .inner {
    @include clearfix;
  }
  .text {
    box-sizing: border-box;
    float: left;
    padding-left: rem( 20 );
    width: rem( 526 );
  }

  .actions {
    clear: none;
    float: left;
    width: rem( 314 );
  }
  .details {
    padding-left: rem( 666 );
  }
}
//Section Instagram //
.instagram {
	.container{ 
   
  
    .row-cols-2 {
    width: 980px;

    }
  
  }
}  