
.SearchHeader {
    .container {
        .coverSearch {

            .cover {
                height: 260px;
              }

            .dechirure_bottom {
                bottom: 24px;
              }

            .titleSearch {
                position: absolute;
                top: 0px;
                margin : 50px 20px 0 45px;

                .span_1 {
                    font-size: 33px;
                  }

                .span_2 {
                    font-size: 28px;
                    white-space: break-spaces;
                    word-break: break-all;
                }
            }
        }
    }
}


#offer-search.new {

    .offer-search-form {
        display: block;

        .form-display {
            background: initial;

            .switch {
                height: initial;
                padding: initial;
                margin: 0 auto;
                border-radius: 45px;
                background: #182883;
                width : 220px;

                label {
                    font-size: 1rem;
                }
            }
        }

        .sticky {
            bottom : 15px;
        }
    }

    .offer-results {
        .wrap {
            width: 100%;

            .offer-list {
                width: 100% !important;

                .results-content {
                    margin-top : initial;

                    .inner-wrap {
                        .results-list {
                            max-width: initial;

                            > li {
                                max-width: initial;
                                min-width: stretch;

                             
                                .divImage {
                                    height: 255px;
                                    width: 92.1vw;
                                }
                            }
                        }
                    }
                }
            }

            .offer-map {
                width: initial !important;
                height: calc( 100vh - 2.5rem );

                a:hover {
                    text-decoration: inherit;
                }

                .title {
                    display: none;
                }

                .small-block-offers {
                    display: block;
                    position: absolute;
                    bottom: 50px;
                    width: 100%;
                }

                .sticky {
                    bottom: 65px;
                    position: fixed;
                }

                .small-offer {
                    display: flex;
                    width: inherit;

                    .image_offer {
                        width: 100px;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px 0 0 10px;
                    }

                    .description-block {
                        width: 100%;
                        background-color: white;
                        border-radius: 0 10px 10px 0;

                        .description {
                            margin: 2px;

                            .offer-title {
                              font-size: 17px;
                              font-weight: 700;
                              line-height: 26px;
                              color: #000;
                            }

                            .City, .Category {
                              font-family: "Roboto";
                              font-size: 12px;
                              font-weight: 300;
                              line-height: 16px;
                              color: #000;
                            }
                        }

                        .label-offer {
                            padding-top: 5px;

                            .promo {
                              border-radius: 3.5px;
                              background: #ffdd00;
                              font-size: 10px;
                              font-weight: 700;
                              line-height: 12px;
                              padding: 4px;
                              float: right;
                              margin-top: 4px;
                            }


                            .price_label {
                              color: #182883;
                              font-size: 11px;
                              font-weight: 400;
                              line-height: 16px;
                              float: right;
                              padding-right: 5px;
                            }

                            .price_number {
                              color: #182883;
                              font-size: 18px;
                              font-weight: bold;
                              // line-height: 16px;
                              float: right;
                              padding-right: 5px;
                            }
                          }
                    }
                }

                .bloc_contenu_hebergements_activite {
                    display: none;
                }
            }
        }
    }

    .offer-info{
        width: auto;
    }
}

