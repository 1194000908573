//------------------------------------
// #SECTIONS
//------------------------------------


// Site map
.site-map-cols {
  font-size: 0;
  letter-spacing: 0;
  text-align: center;

  .col {
    display: inline-block;
    font-size: rem( $fz );
    letter-spacing: normal;
    margin: 0 rem( 32 );
    text-align: left;
    vertical-align: top;
    width: rem( 182 );
    padding: 0;

    &:last-of-type {
      margin: 0 rem( 32 );
    }

    &.wide {
      width: rem( 220 );
    }
  }

  .cat-title {
    margin: 0 0 rem( 10 );
    text-align: center;

    a {
      box-sizing: border-box;
      font-size: rem( 22 );
      padding: rem( 48 ) rem( 10 ) rem( 8 );

      > span {
        &:before {
          display: none;
        }
        text-align: left;
      }

      picture + span,
      img + span {
        span {
          padding-right: 0;
        }
      }

      &:hover {
        box-shadow: inset 0 0 0 5px #102b33;
      }
    }

    img {
      left: 50%;
      top: rem( 10 );
      transform: translateX( -50% );
    }

    .icon-prev {
      display: inline-block;
      margin: 0 rem( 6 ) 0 0;
      position: static;
      vertical-align: middle;
    }

    &.alt {
      a {
        min-height: rem( 87 );
        padding-bottom: 0;
        padding-left: rem( 70 );
        padding-right: rem( 20 );
        padding-top: 0;
        white-space: nowrap;

        &:before {
          @include valign;
          height: rem( 87 );
        }

        > span {
          display: inline-block;
          vertical-align: middle;
          white-space: normal;

          span {
            display: inline;
          }
        }
      }
      img {
        left: rem( 20 );
        top: 50%;
        transform: translateY( -50% );
      }
      .icon-prev {
        display: inline;
      }
    }
  }

  .cat-push {
    display: block;

    a {
      display: block;
      position: relative;

      img {
        border: 0 solid #102b33;
        box-sizing: border-box;
        transition: $transition;
        width: 100%;
      }

      &:hover {
        img {
          border: rem( 5 ) solid #102b33;
        }
      }

      // &:after {
        //   content: "";
        //   height: 100%;
        //   left: 0;
        //   position: absolute;
        //   top: 0;
        //   transition: $transition;
        //   width: 100%;
        // }
        // &:hover {

          //   &:after {
            //     box-shadow: inset 0 0 0 5px #102b33;
            //   }
            // }
          }

          + .cat-title {
            display: none;
          }
        }

        #nav & {
          .cat-list {
            display: block;
            padding-bottom: 0;
          }

          .cat-title:last-child {
            margin-bottom: 0;
          }
        }
      }


      // Directory Section
      .directory-section {
        background: #bbdeff;
        height: rem( 810 );
        position: relative;

        .tt-header {
          margin: 0 0 rem( 10 );
        }
        .breadcrumb {
          margin: 0 0 rem( 20 );
        }

        .content {
          position: static;
          transition: none;

          .wrap {
            z-index: auto;
          }

          &.visible {
            padding: 0;

            .map-container {
              opacity: 1;
            }
          }
        }

        .map-container {
          display: block;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 1;
          position: absolute;
          width: 100%;
          z-index: 1;

          .map {
            height: 100%;
          }

          .bt-results {
            display: none;
          }
        }
        header {
          position: relative;
          z-index: 2;
        }
        .map-info {
          margin: 0;
          position: relative;
          width: rem( 320 );
          z-index: 2;
        }
        .list-intro {
          background: #edf8ff;
        }
        .list-details {
          background: #edf8ff;
          position: relative;

          [class*="view-"] {
            background: #edf8ff;
            padding-left: 0;
            padding-right: 0;
          }

          .inner {
            padding: 0 rem( 14 ) 0 rem( 20 );
          }

          .view-cat {
            left: 0;
            position: absolute;
            top: 0;

            .scroll {
              height: rem( 410 );

              > ul,
              .scroller-content,
              .scroller-wrapper {
                height: rem( 410 );
              }
            }

            .cat-button {
              margin-left: rem( -20 );
              padding-left: rem( 90 );
              width: rem( 280 );

              .arrow {
                background: $theme3;
                left: rem( 0 );
                text-align: center;
                top: 0;
                transform: none;
                height: 100%;
                width: rem( 20 );
              }
              img {
                left: rem( 37 );
              }
              .icon-prev-light {
                fill: $white;
                height: rem( 16 );
                left: rem( 5 );
                margin: rem( -8 ) 0 0;
                position: absolute;
                top: 50%;
                width: rem( 10 );
              }
            }
          }
        }

        .scroll {
          height: rem( 460 );
          margin-right: rem( 26 );

          > ul,
          .scroller-content,
          .scroller-wrapper {
            height: rem( 460 );
            overflow: auto;
          }
        }
      }



      // sitemap section
      .sitemap-section {
        padding: 0;

        .breadcrumb {
          margin-top: 0;

          a span {
            background-color: $theme-dark2;
          }
        }

        .sitemap-row {
          box-sizing: border-box;
          margin: 0 auto;
          padding: rem( 12 ) rem( 48 ) rem( 28 );
          width: rem( 884 );
        }

        .site-map-cols {
          text-align: left;
        }

        .col {
          margin-left: 0;
          margin-right: 0;
          width: rem( 182 );

          ~ .col {
            margin-left: rem( 20 );

            &.wide {
              margin-left: rem( 50 );
            }
          }

          &:nth-of-type( 2n + 3 ) {
            clear: none;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }


      // Simple Header
      .header-simple{
        display: block;
        padding: rem( 12 ) 0;

        .breadcrumb {
          margin: 0;
        }
      }

      // Interactive movie
      .interactive-movie {
        display: block;
      }

      // Interactive mobile
      .interactive-mobile {
        display: none;
      }

      // interactive share
      .interactive-share {

        li {
          margin: 0 0 0 rem( 3 );
          width: rem( 28 )
        }
      }

      // interactive section
      .interactive-section{
        .tt-interactive {
          display: block;
        }

        .text {
          strong {
            font-size: rem( 22 );
            font-weight: normal;
          }
        }

        .choices {
          font-size: rem( 20 );
          padding: 0 0 rem( 24 );
          width: 100%;

          li {
            margin: 0 0 rem( 24 );
            padding: 0;
            width: rem( 184 );

            + li {
              margin-left: rem( 81 )
            }
          }

          div {
            width: rem( 184 );
          }

          p {
            margin-bottom: rem( 20 )
          }
        }

        [class*="cta"]{
          font-size: rem( 18 );
        }
      }

      // New island page
      .new-island-main {
        [class*="header-main"] {
          height: auto;

          .wrap {
            height: 100%;
          }

          .text {
            top: calc(5 * #{$baseline});
            bottom: $baseline;
            width: 1000px;
            font-size: rem( 20 );

            .desc {
              @include center(horizontal);
              max-width: 640px;
              text-align: justify;
            }
          }
        }

        .ct-header {
          text-align: center;
          span {
            font-size: rem( 93 );
          }
        }
      }

      .block-island-map {
        height: 600px;
        .text {
          padding: calc(3 * #{$baseline}) calc(1 * #{$baseline});
          h2 {
            text-align: left;
            font-size: calc(2.1 * #{$baseline});
          }
          .desc, .link-light {
            font-size: calc(1 * #{$baseline});
          }
          .desc{
            padding-right: 24rem;
          }
        }
        .bg-wrapper {
          height: 100%;
          background-position-x: 50%;
        }
      }

      .block-island-encyclopedia {
        height: 600px;
        .bg-wrapper {
          background-position-x: 50%;
          margin: 0;
          height: 600px;
        }
        .text {
          padding: calc(3 * #{$baseline}) calc(1 * #{$baseline});
          font-size: $baseline;

          h2 {
            font-size: calc(2.1 * #{$baseline});
          }
          .desc {
            font-size: calc(1 * #{$baseline});
            margin-top: calc(4 * #{$baseline});
            width: 85%;
          }
          .link-light {
            font-size: calc(1 * #{$baseline});
          }
        }
      }

      .block-island-events {
        padding: rem( 16 ) 0 rem( 4 );

        .block-agenda {
          padding: calc(4 * #{$baseline}) calc(1 * #{$baseline});
        }

        .wrap {
          & > div {
            display: table-cell;
            &:first-of-type {
              width: 50%;
            }
            &:last-of-type {
              width: 30%;
            }
          }
        }
      }

      .new-island-main {
        .block-island-slider {

          .slick-arrow {
            [class*="icon-"] {
              height: rem( 75 );
              width: rem( 60 );
            }
            &.slick-prev {
              left: 5%;
            }
            &.slick-next {
              right: 5%;
            }
          }

          figcaption {
            padding: calc(0.5*#{$baseline}) rem( 20 );
            max-width: 800px;
            font-size: rem( 30 );
          }

          .slick-dots {
            button {
              width: rem( 40 );
              height: rem( 40 );
            }
          }

        }
      }


      .anchor-nav {
        display: block;
      }

// l'inspiration //
.inspiration-section {

  .list-activity {
    float: right;
  
  }
  .third-bloc {
    display: block;
  }
  .second-bloc {
    margin-top: 45px;
  }
}
.inspiration-mobileoffers-target {
  display: none;
  .slick-slider {
    display: none;
  }
}

.inspiration-section > div > div.container > div.third-block > div.block-inspiration-activity {
	float: none;
	margin-right: 0px;
}
#main > main > section.inspiration-section > div > div.container > div.third-block > div.block-inspiration-offer {
		float: none;
		margin-top: 0px;		
}
// End l'inspiration //
.page_404 {
  .block-island-encyclopedia {
    .text {
      .link-light {
        font-size: 20px;
      }
    }
  }

}
.push-picture {
  width: 1280px;
  margin: auto;
}

#map-island-section {
  width: 1280px;
  margin: auto;
  height: 100%;

  h2 {
    font-family: "Roboto Condensed Regular";
  }
 
}