//------------------------------------
// #PAGER
//------------------------------------

.pager {
  text-align: left;
  @include clearfix;

  p {
    float: left;
    margin-top: rem( 5 );
    max-width: 50;
  }
}
.pager-list {
  float: right;
  max-width: 50%;


  [class*="bt"] {
    height: rem( 28 );
    line-height: rem( 26 );
    width: rem( 28 );

    [class*="icon-prev"] {
      height: rem( 28 );
      width: rem( 8 );
    }
  }
  .bt {
    background-color: transparent;
  }
}
