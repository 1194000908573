//------------------------------------
// #ISLAND MAIN PAGE
//------------------------------------

.island-main {

  .header-main {
    .link-alt-cta {
      display: inline-block;
      margin-top: rem( 16 );
    }
  }
}
