/*Encyclo Blade Desc box*/
.encyclo-header {
  padding-right: 29rem;
}

.encyclo-desc {
  padding-right: 25rem;
}

.block-island-encyclopedia .text.light {
  padding-top: 8.05rem;
  .link_cta{
    padding-top: 1rem;
    color: $white;
    font-family: "Roboto Condensed Regular";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    overflow: hidden;
    margin: auto;
    display: block;

    &::before {
      content: "";
      border-bottom: 1px ridge $white;
      width: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }
}
